import axios from "axios";
import { configs } from "../../config";

export const getAllAttendanceApi = async (accessToken, empId) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q= SELECT Id, isSignedIn__c, Log_In__c,name, Log_out__c, Status__c, Date1__c, isRegularized__c,CreatedDate,Emp_Attendance__r.EmpId__c,Emp_Attendance__c FROM Attendence__c where Emp_Attendance__c ='${empId}'`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};
