import { useState } from "react";
import Card from "react-bootstrap/Card";
import BackButton from "../../Common/BackButton";
import "./MyMr.css";
import emailIcon from "./../../assets/images/mail-2553.svg";
import { useSelector } from "react-redux";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
// import divisionIcon from "./../../assets/images/suitcase.png";
import { getAllMrLIstApi } from '../../services/getAllMrLIstApi';
import empicon from "./../../assets/images/driver-license.png";
import { useEffect } from "react";
import profileIcon from "./../../assets/images/user-3297.svg";
import clockIcon from "./../../assets/images/appointment.png";
import Loader from "../../Common/Loader";
import CommonSearch from "../../Common/CommonSearch";
import CommonDropdown from "../../Common/CommonDropdown";
import designationProf from "./../../assets/images/designation.svg";

const MyMr = () => {
  const employeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const [loading, setLoading] = useState(false);
  const [myMrData, setMyMrData] = useState([]);
  const [filteredMrData, setFilteredMrData] = useState([]);
  const [uniqueDesignations, setUniqueDesignations] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getAllMrLIstApi(employeeId, accessToken);
        console.log("response", response);
        if (response?.status === 200 && response?.data) {
          const extractedData = response.data.records.map(record => ({
            EmpName: record.Employee_Name__c,
            empid: record.EmpId__c,
            mobno: record.Mobile_Number__c,
            email: record.Email,
            division: record.Division__c,
            joiningdate: record.Joining_Date__c,
            designation: record.Designation__c
          }));
          console.log("extractedData", extractedData);
          setMyMrData(extractedData);
          setLoading(false);

          // Extract unique designations
          const designations = [...new Set(extractedData.map(item => item.designation))];
          setUniqueDesignations(designations);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    })();
  }, [employeeId, accessToken]);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredArray = myMrData.filter((mr) =>
      mr.EmpName.toLowerCase().includes(searchTerm) ||
      mr.empid.toLowerCase().includes(searchTerm) ||
      mr.mobno.toLowerCase().includes(searchTerm) ||
      mr.email.toLowerCase().includes(searchTerm)
    );
    console.log("filteredArray", filteredArray);
    setFilteredMrData(filteredArray);
  };
  const [selectedDesignation, setSelectedDesignation] = useState('Select Designation');
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);


  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
    if (selectedOption === "Select Designation") {
      setFilteredMrData([]);
    } else {
      const filteredArray = myMrData.filter(mr => mr.designation === selectedOption);
      setFilteredMrData(filteredArray);
    }
  };

  return (
    <div className="m-3">
      <div className="row">
        <div className="mb-2 ml-3">
          {(designation !== "VSE" || designation !== "BE") && (
            <BackButton title={"My Team"} />
          )}
        </div>
      </div>

      <div className="customers_search mt-3 row" style={{ display: "flex", justifyContent: "center" }}>
        <div className="mrsearch col-12 col-sm-12 col-lg-6 col-md-6" style={{ textAlign: "center" }}>
          <CommonDropdown
            defaultOption={"Select Designation"}
            options={uniqueDesignations}
            handleDropdownChange={handleDesignationChange}
          />
        </div>
        <div className="customers_search mt-3 row" style={{ display: "flex", justifyContent: "center" }}>
          <div className="mrsearch col-12 col-sm-12 col-lg-6 col-md-6" style={{ textAlign: "center" }}>
            {selectedDesignation === 'Select Designation' && (
              <p style={{ fontFamily: 'GilroySemibold', fontSize:'18px' }}>
                Please select a designation to check your team.
              </p>
            )}
          </div>
        </div>
      </div>
      {selectedDesignation !== 'Select Designation' && (
        <div className="myMr_box">
          {(filteredMrData.length > 0 ? filteredMrData : myMrData).map((mr, index) => (
            <Card key={index} className="myMrCard col-md-12">
              <Card.Body>
                <div className="myMrPharm_Name">
                  <span className="myMrPharm_NameTitle">
                    <img
                      src={profileIcon}
                      alt="mobile"
                      style={{
                        height: "1rem",
                        marginRight: "3px",
                        marginBottom: "2px",
                      }}
                    />
                    {mr.EmpName}
                  </span>
                </div>
                <div className="myMrPharm_Post">
                  <span>
                    <img
                      src={empicon}
                      alt="mobile"
                      style={{
                        height: "1rem",
                        marginRight: "3px",
                        marginBottom: "2px",
                      }}
                    />
                    {mr.empid}
                  </span>
                </div>
                <div className="myMrPharm_Post">
                  <span>
                    <img
                      src={mobileIcon}
                      alt="mobile"
                      style={{
                        height: "1rem",
                        marginRight: "3px",
                        marginBottom: "2px",
                      }}
                    />
                    {mr.mobno}
                  </span>
                </div>
                <div className="myMrPharm_Post">
                  <img
                    src={emailIcon}
                    alt="location"
                    style={{
                      height: "1rem",
                      marginRight: "3px",
                      marginBottom: "2px",
                    }}
                  />
                  {mr.email}
                </div>
                <div className="myMrPharm_Post">
                  <img
                    src={clockIcon}
                    alt="location"
                    style={{
                      height: "1rem",
                      marginRight: "3px",
                      marginBottom: "2px",
                    }}
                  />
                  Date of Joining: {mr.joiningdate}
                </div>
                <div className="myMrPharm_Post">
                  <img
                    src={designationProf}
                    alt="location"
                    style={{
                      height: "1rem",
                      marginRight: "3px",
                      marginBottom: "2px",
                    }}
                  />
                  {mr.designation}
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default MyMr;