export function getFormatedDate(date, isReschedule = false) {
  const month = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let weekday = [];
  if (!isReschedule) {
    weekday = [
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
      "Friday", "Saturday"
    ];
  } else {
    weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  }
  let formatedDate = `${weekday[date.getDay()]}, ${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;

  return formatedDate;
}

export function getNumBetween0And8() {
  return Math.floor(Math.random() * (7 - 0 + 1) + 0);
}

export const getNewFormatDate = (dateString) => {
  if (!dateString) return "";

  const [year, month, day] = dateString.split('-');
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return `${day} ${months[parseInt(month) - 1]} ${year}`;
};

export function formatDateDDMMYYYY(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}

export function formatDateStringDDMMYYYY(dateString) {
  if (!dateString) return "";

  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}
