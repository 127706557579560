import axios from "axios";
import { configs } from "../config";

export const getAllMrLIstApi = async (employeeId,accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT AccountId,Active__c,CreatedById,CreatedDate,Daily_Allowance_Type__c,Daily_Allowance__c,Date_of_Birth__c,Department,Designation__c,Distance_Travelled_Km__c,Division__c,Email,EmailBouncedDate,EmailBouncedReason,EmpId__c,Employee_Name__c,Fax,FirstName,Gender__c,HQ__c,Id,IsDeleted,IsEmailBounced,Jigsaw,JigsawContactId,Joining_Date__c,LastActivityDate,LastCURequestDate,LastCUUpdateDate,LastModifiedById,LastModifiedDate,LastName,LastReferencedDate,LastViewedDate,Level__c,MailingAddress,MailingCity,MailingCountry,MailingGeocodeAccuracy,MailingLatitude,MailingLongitude,MailingPostalCode,MailingState,MailingStreet,MasterRecordId,MiddleName,MobilePhone,Mobile_Number__c,Name,OwnerId,Password__c,Phone,PhotoUrl,Region_Code__c,Region__c,Reporting_HQ__c,ReportsToId,Role__c,Route__c,Salutation,State__c,Suffix,SystemModstamp,Title,Total_Expense__c,Tour_Plan__c,Travel_allowance_Type__c,Travel_allowance__c,Zone2__c,Zone__c FROM Contact where ReportsToId ='${employeeId}'`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    };
    try {
      const response = await axios(options);
      return response;
    } catch (error) {
      console.log(error);
    }
  };