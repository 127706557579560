import axios from "axios";
import { configs } from "../config";

export const blankRecordCreateApi = async (accessToken, empId) => {
  let customId = '';
  const options = {
    url: `${configs?.base_url}/services/apexrest/MarkAttendance/markSignIn`,
    method: "patch",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      empId: empId
    }
  };
  try {
    const response = await axios(options);
    customId = response?.data;
    return response;
  }
  catch (error) {
    console.log(error)
  }
};