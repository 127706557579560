import React, { useState, useRef } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import "./UploadRoutes.css";
import CommonButton from '../Common/CommonButton';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Common/Loader';
import BackButton from "../Common/BackButton";
import upload from "../assets/images/file.png";
import { createCustomersApi } from '../services/createRout/createCustomersApi';

const UploadCustomer = () => {
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState();
    const [fileError, setFileError] = useState('');
    const [responseData, setResponseData] = useState([]);

    const [formData, setFormData] = useState({
        Division: '',
        HQ: '',
        Route: '',
        Customer: '',
        Category: '',
        Population_of_Animals: '',
        Visit_Frequency: '',
        Hester_Business: '',
        Total_Potential: '',
        DOB: '',
        Focus_Product_1: '',
        Focus_Product_2: '',
        Focus_Product_3: '',
        Therapeutic_Segments: '',
        Customer_Type: '',
        ADDRESS: '',
        Country: '',
        State: '',
        City: '',
        PINcode: '',
        Area: '',
        ContactPerson: '',
        Designation: '',
        EmailID: '',
        WatsappNO: '',
        MobileNumber2: '',
        Remarks: '',
        ISSecCustomer: ''
    });

    const fieldLabels = {
        Division: 'Division',
        HQ: 'HQ',
        Route: 'Route',
        Customer: 'Customer',
        Category: 'Category',
        Population_of_Animals: 'Population of Animals',
        Visit_Frequency: 'Visit Frequency',
        Hester_Business: 'Hester Business',
        Total_Potential: 'Total Potential',
        DOB: 'Date of Birth',
        Focus_Product_1: 'Focus Product 1',
        Focus_Product_2: 'Focus Product 2',
        Focus_Product_3: 'Focus Product 3',
        Therapeutic_Segments: 'Therapeutic Segments',
        Customer_Type: 'Customer Type',
        ADDRESS: 'Address',
        Country: 'Country',
        State: 'State',
        City: 'City',
        PINcode: 'PIN code',
        Area: 'Area',
        ContactPerson: 'Contact Person',
        Designation: 'Designation',
        EmailID: 'Email ID',
        WatsappNO: 'WhatsApp Number',
        MobileNumber2: 'Mobile Number 2',
        Remarks: 'Remarks',
        ISSecCustomer: 'Is Sec Customer'
    };

    const accessToken = useSelector((state) => state?.accessToken?.accessToken);

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        setFileName(file?.name);
        setFileError('');

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                if (binaryStr) {
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet);

                    const formattedData = {
                        Customer: data.map(item => {
                            const dobValue = item['Date of Birth'];
                            const dobFormatted = typeof dobValue === 'number'
                                ? formatExcelDate(dobValue)
                                : dobValue || '';

                            return {
                                Route: item['Route'] || '',
                                Customer: item['Customer'] || '',
                                Category: item['Category'] || '',
                                Population_of_Animals: item['Population of Animals'] || '',
                                Visit_Frequency: String(item['Visit Frequency']) || '',
                                Hester_Business: item['Hester Business'] || '',
                                Total_Potential: item['Total Potential'] || '',
                                DOB: dobFormatted,
                                Focus_Product_1: item['Focus Product 1'] || '',
                                Focus_Product_2: item['Focus Product 2'] || '',
                                Focus_Product_3: item['Focus Product 3'] || '',
                                Therapeutic_Segments: item['Therapeutic Segments'] || '',
                                Customer_Type: item['Customer Type'] || '',
                                ADDRESS: item['Address'] || '',
                                Country: item['Country'] || '',
                                State: item['State'] || '',
                                City: item['City'] || '',
                                PINcode: String(item['PIN code']) || '',
                                Area: item['Area'] || '',
                                ContactPerson: item['Contact Person'] || '',
                                Designation: item['Designation'] || '',
                                EmailID: item['Email ID'] || '',
                                WatsappNO: item['WhatsApp Number'] || '',
                                MobileNumber2: String(item['Mobile Number 2']) || '',
                                Remarks: item['Remarks'] || '',
                                Division: item['Division'] || '',
                                ISSecCustomer: item['Is Sec Customer'] || ''
                            };
                        })
                    };


                    setJsonData(formattedData);
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const formatExcelDate = (excelDate) => {
        const utcDays = excelDate - 25569;
        const date = new Date(utcDays * 86400 * 1000);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}/${month}/${year}`;
    };

    const handleSubmit = async () => {
        if (!fileName) {
            setFileError("Please select a file before submitting");
            return;
        }

        setLoading(true);
        try {
            const response = await createCustomersApi(accessToken, jsonData);

            // Access the data property and parse it
            const parsedData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

            console.log("parsed data", parsedData)

            if (parsedData) {
                setResponseData(parsedData.customers || []);
                toast.success("Data uploaded successfully");
            } else {
                toast.error("An error occurred");
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            toast.error("An error occurred while uploading data");
        } finally {
            setLoading(false);
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'PINcode' || name === 'WatsappNO' || name === 'MobileNumber2' || name === 'ContactPerson' || name === 'empCode') {
            if (/^\d*$/.test(value)) { // Check if value is numeric
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

        // setFormData({
        //     ...formData,
        //     [name]: value
        // });

        // Only clear errors when input changes, but not for email validation
        if (name !== 'EmailID') {
            setErrors({
                ...errors,
                [name]: ''  // Clear error message for other fields
            });
        }
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};

        // Check for required fields
        const requiredFields = [
            'Division', 'HQ', 'Route', 'Customer',
            'Category', 'Population_of_Animals', 'Visit_Frequency', 'Hester_Business',
            'Total_Potential', 'DOB', 'Focus_Product_1', 'Focus_Product_2', 'Focus_Product_3',
            'Therapeutic_Segments', 'Customer_Type', 'ADDRESS', 'Country', 'State', 'City',
            'PINcode', 'Area', 'ContactPerson', 'Designation', 'EmailID', 'WatsappNO',
            'MobileNumber2', 'Remarks', 'ISSecCustomer'
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${fieldLabels[field]} is required`;
            }
        });

        // Email validation
        if (formData.EmailID && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.EmailID)) {
            newErrors.EmailID = 'Invalid email format';
        }

        // Numeric fields validation
        const numericFields = ['TotalDistanceCovered', 'empCode', 'PINcode', 'WatsappNO', 'MobileNumber2', 'Population_of_Animals', 'Visit_Frequency'];
        numericFields.forEach(field => {
            if (formData[field] && isNaN(Number(formData[field]))) {
                newErrors[field] = `${fieldLabels[field]} must be a number`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        // Perform form validation
        if (!validateForm()) return;  // Prevent submission if validation fails

        const payload = { Customer: [formData] };


        try {
            setLoading(true);
            const response = await createCustomersApi(accessToken, payload);

            if (response) {
                toast.success("Form submitted successfully");
            } else {
                toast.error("An error occurred");
            }

            // Reset form and errors
            setFormData({
                Division: '',
                HQ: '',
                Route: '',
                Customer: '',
                Category: '',
                Population_of_Animals: '',
                Visit_Frequency: '',
                Hester_Business: '',
                Total_Potential: '',
                DOB: '',
                Focus_Product_1: '',
                Focus_Product_2: '',
                Focus_Product_3: '',
                Therapeutic_Segments: '',
                Customer_Type: '',
                ADDRESS: '',
                Country: '',
                State: '',
                City: '',
                PINcode: '',
                Area: '',
                ContactPerson: '',
                Designation: '',
                EmailID: '',
                WatsappNO: '',
                MobileNumber2: '',
                Remarks: '',
                ISSecCustomer: ''
            });
            setErrors({});
        } catch (error) {
            toast.error("An error occurred while submitting the form.");
        } finally {
            setLoading(false);
        }
    };

    const handlePhotoIconClick = () => {
        fileInputRef.current.click();
    };

    const handleDownloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const headers = Object.keys(fieldLabels).map(key => fieldLabels[key]);
        const sampleRecord = [
            'AH', // Division
            'Akola-1 (pool)', // HQ
            'AHMH7AK1-01', // Route
            'DR. ANIL SHELKE', // Customer
            'LSA-AI Worker', // Category
            '17', // Population of Animals
            '1', // Visit Frequency
            'C', // Hester Business
            'B', // Total Potential
            '21/11/1999', // Date of Birth
            'ProtinC', // Focus Product 1
            'AMASTIC INJ', // Focus Product 2
            'Fensafe', // Focus Product 3
            '', // Therapeutic Segments
            'Secondary', // Customer Type
            'KHADAKI, TAL-AKOLA', // Address
            'INDIA', // Country
            'Maharashtra', // State
            'Khadaki', // City
            '444004', // PIN code
            'MH 7', // Area
            '9822135047', // WhatsApp Number
            '9822135047', // Mobile Number 2
            '', // Remarks
            '1', // Is Sec Customer
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers, sampleRecord]);
        XLSX.utils.book_append_sheet(wb, ws, "Headers");
        const fileName = 'customers.xlsx';
        XLSX.writeFile(wb, fileName);
    };

    return (
        <div>
            <div className="container-fluid mt-2">
                <div className="row mt-3 mb-4">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mb-3">
                        <BackButton title={"Upload Customers"} />
                    </div>
                </div>
                <div className="markAttendance Approve_plansTab_heading">
                    <Tabs
                        defaultActiveKey="Pending"
                        id="fill-tab-example"
                        className="my-3 markAttendance_tab Approve_plansTab"
                        fill
                        style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
                    >
                        <Tab
                            eventKey="Pending"
                            title={"Multiple"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="" style={{ textAlign: "center" }}>
                                        Upload Excel File
                                    </h4>
                                    <div className="markAttendance_section container-fluid">
                                        <img alt="upload image" src={upload} style={{ height: '3rem' }} onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" />

                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileUpload}
                                        />
                                        {fileError && <p className="error-message" style={{ color: 'red', textAlign: "center" }}>{fileError}</p>}
                                    </div>
                                    <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                                    <span className="leaveApply_button" onClick={handleSubmit}>
                                        <CommonButton title={"Submit file"} width={'18rem'} />
                                    </span>

                                    <div className='mt-2'>
                                        <span className="leaveApply_button" onClick={handleDownloadExcel}>
                                            <CommonButton title={"Download Excel"} width={'18rem'} />
                                        </span>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: "80%" }} className='mt-5 mb-5'>
                                <Card.Body>
                                    <div style={{ overflowX: 'auto' }} className='default-scrollbar'>
                                        <table className="text-center w-100">
                                            <thead>
                                                <tr className="ladder_heading">
                                                    <td className='pl-3 custom-header'>Customer</td>
                                                    <td className="custom-header">Message</td>
                                                    <td className="custom-header">Status</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responseData.length > 0 ? (
                                                    responseData.map((customer, index) => (
                                                        <tr key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white', fontFamily: 'GilroyMedium' }}>
                                                            <td className='pl-3'>{customer.customer}</td>
                                                            <td className='pl-3'>{customer.message}</td>
                                                            <td className='pl-3'>{customer.status}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab
                            eventKey="Deleted"
                            title={"Single"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="mb-4" style={{ textAlign: "center" }}>
                                        Upload Customers
                                    </h4>
                                    <div className="formContainer">
                                        <form onSubmit={formSubmit}>
                                            <div className="form-row">
                                                {Object.keys(formData).map((field, index) => (
                                                    <div className="form-group" key={field}>
                                                        <p className="PlanMTP_leftHead" htmlFor={field} style={{ textAlign: 'start' }}>
                                                            {fieldLabels[field]}
                                                        </p>
                                                        {field === 'DOB' ? (
                                                            <input
                                                                className="CommonDropdown form-select"
                                                                type="date"
                                                                name="DOB"
                                                                value={formData.DOB}
                                                                disabled={false}
                                                                // min={new Date().toISOString().split("T")[0]}
                                                                onChange={handleDateChange}
                                                                style={{
                                                                    width: "100%",
                                                                    color: "rgb(46, 49, 146)",
                                                                    fontSize: "14px",
                                                                    backgroundImage: "none",
                                                                    paddingRight: "14px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <input
                                                                className="CommonDropdown form-select"
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                    color: "rgb(46, 49, 146)",
                                                                    fontSize: "14px",
                                                                    backgroundImage: "none",
                                                                    paddingRight: "14px",
                                                                }}
                                                                id={field}
                                                                name={field}
                                                                value={formData[field]}
                                                                onChange={handleInputChange}
                                                            />
                                                        )}
                                                        {errors[field] && <p className="error-message" style={{ color: 'red' }}>{errors[field]}</p>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ marginTop: '1rem' }}>
                                                <span className="leaveApply_button" onClick={formSubmit}>
                                                    <CommonButton title={"Submit"} width={'12rem'} value="Submit" />
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loader />}
        </div>
    );
};

export default UploadCustomer;
