import axios from "axios";
import { configs } from "../config";

export const signInOutApi = async (blankRecordId, accessToken, empName, Login, Logout, empId,userId) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/AttendanceController`,
    method: "post",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      EmpId: empId,
      Name: empName,
      Log_In: Login,
      Log_Out: Logout,
      RecordId:blankRecordId,
      Status: ''
    }
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

