import axios from "axios";
import { configs } from "../../config";

export const viewAllClaimsManager = async (accessToken, empDesignation, empId, lastId) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/ExpenseClaimAccess`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: empId,
      designation: empDesignation,
      lastRecordId:lastId
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};