import axios from "axios";
import { configs } from "../../config";
import { isLocalServer } from "../../Components/isLocalServer";

export const sendFirebaseNotificationApi = async (
  sfId,
  title,
  description,
  accessToken
) => {
  const nodeBaseUrl = isLocalServer()
    ? "http://localhost:5000"
    : "https://hester-npharma-uat-2ffe9a99645d.herokuapp.com";

  try {
    const response = await axios.post(`${nodeBaseUrl}/firebase`, {
      sfId,
      title,
      description,
      accessToken,
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
