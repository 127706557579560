import axios from "axios";
import { configs } from "../../config";

export const createNTPApi = async (UserId, date, station, tour, routeName,fixedPob, pob, ReportToId, doctorIds , place, accessToken, routeAllDesc,vseName,hq) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/CreateNewTourPlan`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        UserId:UserId,
        TPdate : date,
        station: station,
        tour: tour,
        routeName: routeName,
        ManagerId: ReportToId,
        doctorIds : doctorIds,
        Place: place,
        POB : pob,
        FixedPOB :fixedPob,
        routeDesc:routeAllDesc,
        VSEName: vseName,
        HQ:hq
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };