import axios from "axios";
import { configs } from "../../config";

export const getRoutesApi = async (userId ,accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Division__c,Employee_Route__c,End_Point__c,HQ__c,Id,Mid_Point__c,Name,Route_Description__c,Route__c,Starting_Point__c,Total_Distance_Covered__c,Type_of_Station__c FROM Route__c where Employee_Route__c='${userId}'`,
      method: "get",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };


  export const getRoutesApiManager = async (empId,designation,accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/getRouteForManager`,
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data : {
        empId: empId,
        designation : designation
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };