import React, { useEffect } from "react";
import Header from "../Common/Header";
import AttendanceComponent from "../Components/AttendanceComponent";

const Attendance = () => {

  return (
    <>
      {/* <Header title={"Leaves"} isShowDate={false} /> */}
      <AttendanceComponent />
    </>
  );
};

export default Attendance;
