import CommonSearch from "../../Common/CommonSearch";
import "./Customers.css";
import DoctorCard from "../DoctorCard";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomersFilterModal from "./CustomersFilterModal";
import filterIcon from "./../../assets/images/filter-6556.svg";
import BackButton from "../../Common/BackButton";
import { Helmet } from 'react-helmet';
import { getCustomersApi } from "../../services/customers/getCustomersApi";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";

const Customers = () => {

  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);

  const initialState = 6;
  const [noOfElement, setnoOfElement] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [customersData, setCustomersData] = useState([]);
  const [doctorsInfo, setDoctorsInfo] = useState([]);
  const [loading, setLoading] = useState([]);
  const [customersExist, setCustomersExist] = useState(false)


  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getCustomersApi(empId, accessToken);
      if (response?.status === 200) {
        setCustomersData(response?.data);
        setLoading(false)
      } else {
        setLoading(false)
        setError(response?.msg);
      }
    })();
  }, [empId, accessToken]);

  useEffect(() => {
    if (customersData?.length > 0) {
      setDoctorsInfo(customersData)
      setCustomersExist(true)
    }

  }, [customersData])

  const handleModal = () => {
    setShowModal(!showModal);
  };


  const handleInputChange = (e) => {
    let filteredArray = customersData?.filter((doctor) =>
      doctor?.Name
        .toLocaleLowerCase()
        .includes(e.target.value.toLocaleLowerCase())
    );
    setDoctorsInfo(filteredArray);
  };


  useEffect(() => {
    setnoOfElement(customersData.slice(0, initialState));
  }, [customersData]);


  // const loadMore = () => {
  //   if (noOfElement?.length === initialState) {
  //     setnoOfElement(customersData.slice(0, customersData?.length));
  //   } else {
  //     setnoOfElement(customersData.slice(0, initialState));
  //   }
  // };
  return (
    <div className="customers_container mx-4 mb-4">
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <div className="mt-3">
        <BackButton title={"Customers"} />
      </div>
      <CustomersFilterModal showModal={showModal} handleClose={handleModal} />
      <div className="customers_search mt-4 row" style={{ display: "flex", justifyContent: "center" }}>
        <div className="col-12 col-sm-12 col-lg-6 col-md-6 " style={{ textAlign: "center" }}>
          <CommonSearch
            searchPlaceHolder={`Search...`}
            handleInputChange={(e) => handleInputChange(e)}
          />
        </div>
        {/* <div className="col-2">
          <span onClick={handleModal}>
            {" "}
            <img
              src={filterIcon}
              alt="icon"
              style={{
                height: "2rem",
                marginRight: "1rem",
                borderRadius: "10px",
              }}
            />
          </span>
        </div> */}
      </div>

      {customersExist ? (
        <div className="row customersProfile">
          <DoctorCard showButtons={false} filterDoctors={doctorsInfo} />
        </div>
      ) : (
        <div className="text-center mt-3" >
          <p style={{ fontFamily: 'GilroySemibold', fontSize:'18px' }}>Customers not found.</p></div>
      )}

      <div className="customers_footer text-right mr-3">
        <Link>
          {/* <SuccessButton buttonText={"View More"} width={"15rem"} />{" "} */}

          {/* {noOfElement?.length === 6 ? (
            <Button className="viewMore" onClick={loadMore}>
              View More...
            </Button>
          ) : (
            <Button className="viewMore" onClick={loadMore}>
              View Less...
            </Button>
          )} */}
        </Link>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Customers;
