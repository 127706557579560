import "./SplashScreen.css";
import logo from "../assets/images/Hester-Logo-High-Resolution.jpg";
const SplashScreen = () => {
  return (
    <div className="splashScreen">
      <img src={logo} alt="" srcSet="" className="splashScreen_logo" />
      {/* <GiMedicines className="splashScreen_logo" color="#fff" />{" "} */}
    </div>
  );
};

export default SplashScreen;
