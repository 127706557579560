import axios from "axios";
import { configs } from "../../config";
import { isLocalServer } from "../../Components/isLocalServer";

export const createExpensesApi = async (data, accessToken) => {
  const nodeBaseUrl = isLocalServer()
    ? "http://localhost:5000"
    : "https://hester-npharma-uat-2ffe9a99645d.herokuapp.com";

  try {
    const response = await axios.post(`${nodeBaseUrl}/expenses/save-expenses`, {
      data,
      accessToken,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
