import { yellow } from "@mui/material/colors";
import { fontWeight } from "@mui/system";

const VideoDescription = ({videoDet}) => {
    return ( <>
    <div className="p-4">
        <h3>{videoDet.title}</h3>
        <div style={{display: "flex", flexDirection:"row"}}>
            <h5>{videoDet.dr}</h5> 
            <p style={{width: "7rem", height: "30px", background: "linear-gradient(#FAD961, #F76B1C)", borderRadius: "4px", textAlign: "center", marginLeft: "1rem"}}>{videoDet.catagory}</p>
        </div> 
        <p style={{fontSize: "1rem",color: "#756c6c"}}> 
          {videoDet.descrpt}
        </p> 
        <div style={{display: "flex", flexDirection:"row"}}>
          <p style={{color: "#756c6c"}}>Duration : <b>{videoDet.len} minutes</b> </p>
          <p style={{marginLeft: "3rem",color: "#756c6c"}}>No of views : <b>51k</b> </p>

        </div>
         
        <p className="mb-0">  
            <img src={videoDet.rating} alt="" style={{height: "2rem"}} />
        </p>
    </div>
    </> );
}
 
export default VideoDescription;