import { useState } from "react";
import Header from "../Common/Header";
import Customers from "../Components/Customers/Customers";

const CustomersPage = () => {
  return (
    <div>
      {/* <Header title={"Stakeholders"} /> */}
      <Customers />
    </div>
  );
};

export default CustomersPage;
