import axios from "axios";
import { configs } from "../../config";

export const CreateEmployeeApi = async (accessToken, jsonData) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/CreateEmployees`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            Employees: jsonData.Employees
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};