import axios from "axios";
import { configs } from "../config";

export const forgotPasswordApi = async (accessToken, employeeId) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/EmployeeAuthAPI/`,
    method: "post",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      employeeId: employeeId,
    },
  };
  try {
    const response = await axios(options);
    console.log("response", response);
    if (response?.data.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: true,
        data: response?.data,
      };
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
