import Header from "../Common/Header";
import MonthlyTourPlan from "../Components/MonthlyTour/MonthlyTourPlan";
const MonthlyTourPlanPage = () => {
  return (
    <>
      {/* <Header title={"Monthly Tour Plan"} isShowDate={false} /> */}
      <MonthlyTourPlan />
    </>
  );
};

export default MonthlyTourPlanPage;
