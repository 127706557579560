import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getVisitHistoryApi } from '../../services/customers/getVisitHistoryApi';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../Common/CommonButton';
import "./VisitFrequency.css"


const VisitFrequency = ({ doctorId }) => {
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const [visitData, setVisitData] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getVisitHistoryApi(accessToken, doctorId);
                if (response?.status === 200 && response?.data?.records) {
                    const extractedData = response.data.records.map(record => ({
                        date: new Date(record.Date__c),
                        mrName: record.Employee__r?.Name,
                        fieldWork: record.Type_of_Tour_Plan__c
                    }));
                    setVisitData(extractedData);
                } else {
                    console.log("Error fetching visit history:", response?.error);
                }
            } catch (error) {
                console.error("Error fetching visit history:", error);
            }
        };

        fetchData();
    }, [accessToken, doctorId]);

    const handleShowMore = () => {
        setShowAll(true)
        navigate('/full-visit-frequency', { state: { visitData } });
    };

    const calculateVisitFrequency = () => {
        const visitFrequency = {};
        visitData.forEach(visit => {
            const year = visit.date.getFullYear(); // Extract year from the date
            const month = visit.date.getMonth();
            const mrName = visit.mrName;
            if (!visitFrequency[mrName]) {
                visitFrequency[mrName] = {};
            }
            if (!visitFrequency[mrName][year]) { // Store visits by both month and year
                visitFrequency[mrName][year] = {};
            }
            if (!visitFrequency[mrName][year][month]) {
                visitFrequency[mrName][year][month] = 1;
            } else {
                visitFrequency[mrName][year][month]++;
            }
        });
        return visitFrequency;
    };

    const visitFrequency = calculateVisitFrequency();



    return (
        <>
            <div>
                <table className="text-center w-100">
                    <tbody>
                    <tr className="ladder_heading">
                        <td className='custom-header pl-3'>Month</td>
                        <td className="custom-header">MR</td>
                        <td className="custom-header">No Of Visits</td>
                    </tr>

                    {visitData.length === 0 ? (
                        <tr>
                            <td colSpan="5" className="text-center">
                                No visit frequency available.
                            </td>
                        </tr>
                    ) : (
                            Object.keys(visitFrequency).map(mrName => (
                                Object.keys(visitFrequency[mrName]).map(year => (
                                    Object.keys(visitFrequency[mrName][year]).map(month => (
                                        <tr key={`${mrName}-${year}-${month}`} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                                            <td className='pl-3'>{new Date(0, parseInt(month)).toLocaleString('default', { month: 'long' })} {year}</td>
                                            <td>{mrName}</td>
                                            <td>{visitFrequency[mrName][year][month]}</td>
                                        </tr>
                                    ))
                                ))
                            ))
                    )}
                    </tbody>
                </table>

                <span onClick={handleShowMore} style={{ display: 'flex', marginBottom:'0.5em',position:'absolute',left:'0',bottom:'0',marginLeft:'1rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        {!showAll && visitData.length >= 4 && (
                            <CommonButton title={"Show More"} />
                        )}
                    </div>
                </span>


            </div>
        </>
    );
}

export default VisitFrequency;
