import React, { useEffect, useState } from "react";
import BackButton from "../../Common/BackButton";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MonthlyTourPlan.css";
import check from "./../../assets/images/check-mark-3280.svg";
import question from "./../../assets/images/red-question-11802.svg";
import draft from "./../../assets/images/draft.png";
import remove from "./../../assets/images/remove.png";
import CommonButton from "../../Common/CommonButton";
import { Helmet } from "react-helmet";
import { getAllNTPApi } from "../../services/NTP/getAllNTPApi";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import FailButton from "./../../Common/FailButton";
import ApproveGif from "./../../assets/images/approve.gif";
import DeleteGif from "./../../assets/images/delete1.gif";
import { deleteNTPApi } from "../../services/NTP/deleteNTPApi";
import { sendForApprovalApi } from "../../services/NTP/sendForApprovalApi";
import Loader from "../../Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { sendFirebaseNotificationApi } from "../../services/firebase/sendFirebaseNotification";
import { deleteRequestApi } from "../../services/NTP/deleteRequestApi";

const MonthlyTourPlan = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const UserId = useSelector((state) => state?.userDetails?.details?.Id);
  const userDetails = useSelector((state) => state?.userDetails?.details);
  const designation = useSelector(
    (state) => state?.userDetails?.details?.Designation__c
  );
  const division = useSelector(
    (state) => state?.userDetails?.details?.Division__c
  );

  const [viewAllDetails, setViewAllDetails] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTourPlan, setSelectedTourPlan] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedPlace, setSelectedPlace] = useState("");
  const [selectedTourPlanType, setSelectedTourPlanType] = useState("");
  const [deleteMsg, setDeleteMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState("")
  const [tourPlanApproval, setTourPlanApproval] = useState("")


  const fetchAllNTP = async () => {
    setLoading(true);
    const response = await getAllNTPApi(empId, accessToken);
    if (response?.status === 200) {
      setViewAllDetails(response?.data?.records);
      setLoading(false);
    } else {
    }
  };

  useEffect(() => {
    // (async () => {
    //   setLoading(true);
    //   const response = await getAllNTPApi(empId, accessToken);
    //   if (response?.status === 200) {
    //     setViewAllDetails(response?.data?.records);
    //     setLoading(false);
    //   } else {
    //   }
    // })();
    fetchAllNTP();
  }, [empId, accessToken]);

  const combinedCount = viewAllDetails.reduce((acc, obj) => {
    const key = `${obj?.TourPlan_SR_No__c}_${obj?.Date__c}_${obj?.Type_of_Tour_Plan__c}_${obj?.Place__c}`; // Create a combined key using both Date__c and status
    acc[key] = acc[key]
      ? { count: acc[key].count + 1, ...obj }
      : { count: 1, ...obj };
    return acc;
  }, {});

  const mappedData = Object.entries(combinedCount).map(
    ([key, { count, ...rest }]) => ({
      ...rest,
      count,
    })
  );

  mappedData.sort((a, b) => new Date(a.Date__c) - new Date(b.Date__c));

  const handleshowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const handleshowDeleteRequestModal = () => {
    setShowDeleteRequestModal(!showDeleteRequestModal);
  };

  const handleDeleteClick = (tourPlanSRNo, date, tourType, place, messageData, cloneEmpId) => {
    setShowDeleteModal(true);

    console.log("-->>", tourPlanSRNo, date, tourType, place, messageData, cloneEmpId)
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setSelectedTourPlanType(tourType);
    setSelectedPlace(place);
    setMessage(messageData)
    setTourPlanApproval(cloneEmpId)
    setStatus(status);
  };

  const handleDeleteRequestClick = (tourPlanSRNo, place, messageDelete) => {
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedPlace(place);
    setMessage(messageDelete)
    setShowDeleteRequestModal(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await deleteRequestApi(
      UserId,
      selectedTourPlan,
      selectedPlace,
      accessToken
    );
    if (response?.status === 200) {
      toast.success("Tour plan delete request sent successfully!");
      try {
        await sendFirebaseNotificationApi(
          userDetails.ReportsToId,
          "NTP Delete Request",
          "Delete NTP request is created",
          accessToken
        );
      } catch (err) {
        setLoading(false)
        console.error(err);
      }
      setShowDeleteRequestModal(false);
      setLoading(false);
      fetchAllNTP();
    } else {
      setLoading(false);
      toast.error("Unable to send delete tour plan request");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const response = await deleteNTPApi(
      UserId,
      selectedTourPlan,
      selectedDate,
      selectedTourPlanType,
      selectedPlace,
      accessToken,
      message
    );
    if (response?.status === 200) {
      toast.success("Tour plan deleted successfully!");
      setDeleteMsg(response?.data);
      setShowDeleteModal(false);
      setLoading(false);
      fetchAllNTP();
    } else {
      setLoading(false);
      toast.error("Unable to delete tour plan");
    }
  };

  const handleApproveClick = (tourPlanSRNo, date, place) => {
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setSelectedPlace(place)
    setShowApproveModal(true);
  };

  const handleSendForApprove = async () => {
    setLoading(true);
    const response = await sendForApprovalApi(
      UserId,
      selectedTourPlan,
      selectedDate,
      selectedPlace,
      accessToken
    );
    if (response?.status === 200) {
      toast.success("Tour plan sent for approval!");
      try {
        await sendFirebaseNotificationApi(
          userDetails.ReportsToId,
          "NTP Request",
          "New NTP request is created",
          accessToken
        );
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
      setShowApproveModal(false);
      setLoading(false);
      fetchAllNTP();
    } else {
      toast.error("Unable to approve tour plan");
      setLoading(false);
    }
  };

  const DateFormating = (data) => {
    let date = new Date();
    date?.setDate(date?.getDate() - 1);
    return new Date(data.Date__c) > date;
  };


  return (
    <div>
      <Helmet>
        <title>New Tour Plan</title>
      </Helmet>

      <div className="container-fluid">
        <div className="MTP_heading mt-4 mb-4">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
              <BackButton title={"New Tour Plan"} />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-2 mt-md-0">
              <div className="row">
                <div className="col-6">
                  {(designation === "VSE" && division === "PET") ? (
                    <div className="tourPlanNameBtn" style={{ float: 'right' }}>
                      <Link to="/approve-plans" className="mr-2">
                        <CommonButton
                          title={"Approve Tours"}
                          className="MTP_createMTP"
                          width={"9rem"}
                        />
                      </Link>
                    </div>
                  )
                    : (designation === "VSE" || designation === "BE") ? "" :
                      (
                        <div className="tourPlanNameBtn" style={{ float: 'right' }}>
                          <Link to="/approve-plans" className="mr-2">
                            <CommonButton
                              title={"Approve Tours"}
                              className="MTP_createMTP"
                              width={"9rem"}
                            />
                          </Link>
                        </div>
                      )}
                </div>
                <div className="col-6">
                  <div className="tourPlanNameBtn">
                    <Link to="/plan-mtp" >
                      <CommonButton
                        title={"Create NTP"}
                        className="MTP_createMTP"
                        width={"9rem"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {mappedData.map((data, index) => {
            if (DateFormating(data)) {
              return (
                <div className="MTP_mainContent mt-4" key={index}>
                  <Card>
                    <Card.Body style={{ padding: "10px" }}>
                      <div className="row">
                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          Tour Planned Date{" "}
                          <p className="MTP_data">
                            {format(new Date(data.Date__c), "dd MMM yyyy")}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          Tour Type{" "}
                          <p className="MTP_data">
                            {data?.Type_of_Tour_Plan__c}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                            <>
                              No. of Customers
                              <br />
                              <span className="MTP_data">{data?.count}</span>
                            </>
                          ) : (
                            <>
                              Place
                              <br />
                              <span className="MTP_data">{data?.Place__c}</span>
                            </>
                          )}
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading MTP_mobileBtn"
                          style={{ fontWeight: "700" }}
                        >
                          Status
                          <div className="MTP_data">
                            {data?.Status__c === "Pending For Approval" ? (
                              <img
                                className="MTP_approved"
                                src={question}
                                alt=""
                              />
                            ) : data?.Status__c === "Saved" ? (
                              <img className="MTP_draft" src={draft} alt="" />
                            ) : data?.Status__c === "Rejected" ? (
                              <img
                                className="MTP_draft mr-1"
                                src={remove}
                                alt=""
                              />
                            ) : (
                              <img
                                className="MTP_approved"
                                src={check}
                                alt=""
                              />
                            )}
                            <span style={{ color: "black", fontWeight: "500" }}>
                              {data?.Status__c}
                            </span>
                            {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                          </div>
                        </div>

                        {data?.Status__c === "Saved" ? (
                          <>
                            <div className="col-lg-2 col-md-2 col-6 mt-2">
                              <span
                                onClick={() =>
                                  handleApproveClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Place__c
                                  )
                                }
                              >
                                <CommonButton
                                  title={"Send For Approval"}
                                  width={"10rem"}
                                />
                              </span>
                            </div>
                          </>
                        ) : data?.Type_of_Tour_Plan__c === "Field Work" ? (
                          <div className="col-lg-2 col-md-2 col-6 mt-2">
                            <Link
                              to={{
                                pathname: `/view-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}`,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <CommonButton
                                title={"View Details"}
                                width={"10rem"}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="col-lg-2 col-md-2 col-6 mt-2">
                            <Link
                              to={{
                                pathname: `/view-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Place__c}`,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <CommonButton
                                title={"View Details"}
                                width={"10rem"}
                              />
                            </Link>
                          </div>
                        )}
                        <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                          {data?.Type_of_Tour_Plan__c === "Field Work" &&
                            data?.Status__c === "Saved" ? (
                            <div>
                              <Link
                                to={{
                                  pathname: `/plan-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}`,
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                <span>
                                  <ModeEditIcon className="MTP_Delete" />
                                </span>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {(data?.Status__c === "Approved" && data?.SYD_Status__c === "Pending") || (data?.Status__c === "Tour Plan Cloned" && data?.SYD_Status__c === "Pending") ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                            <span
                              onClick={() => {
                                if (data?.Status__c === "Tour Plan Cloned" && data?.Clone_TP_EmpID__c != null || data?.Status__c === "Saved" || data?.Status__c === "Pending For Approval" || data?.Status__c === "Rejected") {
                                  handleDeleteClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Type_of_Tour_Plan__c,
                                    data?.Place__c,
                                    "Delete",
                                    data?.Clone_TP_EmpID__c
                                  );
                                } else {
                                  handleDeleteRequestClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Place__c,
                                    "Delete"
                                  );
                                }
                              }}
                            >
                              <DeleteIcon className="MTP_Delete" />
                            </span>
                          </div>
                        ) : (data?.SYD_Status__c === "Completed" && (data?.Status__c === "Approved" || data?.Status__c === "Tour Plan Cloned")) ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center"></div>
                        ) : data?.Status__c ===
                          "Delete Request Sent To Manager" ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center"></div>
                        ) : (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                            <span
                              onClick={() => {
                                if (data?.Status__c === "Tour Plan Cloned" && data?.Clone_TP_EmpID__c != null || data?.Status__c === "Saved" || data?.Status__c === "Pending For Approval" || data?.Status__c === "Rejected") {
                                  handleDeleteClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Type_of_Tour_Plan__c,
                                    data?.Place__c,
                                    "Delete",
                                    data?.Clone_TP_EmpID__c
                                  );
                                } else {
                                  handleDeleteRequestClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Place__c,
                                    "Delete"
                                  );
                                }
                              }}
                            >
                              <DeleteIcon className="MTP_Delete" />
                            </span>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            }
          })}

          <hr className="mt-5" />
          <p
            className="mt-4"
            style={{
              marginLeft: "3rem",
              fontWeight: "600",
              fontSize: "1.5rem",
              fontFamily: 'GilroyBold'
            }}
          >
            Previous Tour Plan
          </p>
          {mappedData.map((data, index) => {
            if (!DateFormating(data)) {
              return (
                <div className="MTP_mainContent mt-4" key={index}>
                  <Card>
                    <Card.Body style={{ padding: "10px" }}>
                      <div className="row">
                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          Tour Planned Date{" "}
                          <p className="MTP_data">
                            {format(new Date(data.Date__c), "dd MMM yyyy")}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          Tour Type{" "}
                          <p className="MTP_data">
                            {data?.Type_of_Tour_Plan__c}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}
                        >
                          {data?.Type_of_Tour_Plan__c === "Field Work" ? (
                            <>
                              No. of Customers
                              <br />
                              <span className="MTP_data">{data?.count}</span>
                            </>
                          ) : (
                            <>
                              Place
                              <br />
                              <span className="MTP_data">{data?.Place__c}</span>
                            </>
                          )}
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading MTP_mobileBtn"
                          style={{ fontWeight: "700" }}
                        >
                          Status
                          <div className="MTP_data">
                            {data?.Status__c === "Pending For Approval" ? (
                              <img
                                className="MTP_approved"
                                src={question}
                                alt=""
                              />
                            ) : data?.Status__c === "Saved" ? (
                              <img className="MTP_draft" src={draft} alt="" />
                            ) : data?.Status__c === "Rejected" ? (
                              <img
                                className="MTP_draft mr-1"
                                src={remove}
                                alt=""
                              />
                            ) : (
                              <img
                                className="MTP_approved"
                                src={check}
                                alt=""
                              />
                            )}
                            <span style={{ color: "black", fontWeight: "500" }}>
                              {data?.Status__c}
                            </span>
                            {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                          </div>
                        </div>

                        {data?.Status__c === "Saved" ? (
                          <>
                            <div className="col-lg-2 col-md-2 col-6 mt-2">
                              <span
                                onClick={() =>
                                  handleApproveClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Place__c
                                  )
                                }
                              >
                                <CommonButton
                                  title={"Send For Approval"}
                                  width={"10rem"}
                                />
                              </span>
                            </div>
                          </>
                        ) : data?.Type_of_Tour_Plan__c === "Field Work" ? (
                          <div className="col-lg-2 col-md-2 col-6 mt-2">
                            <Link
                              to={{
                                pathname: `/view-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}`,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <CommonButton
                                title={"View Details"}
                                width={"10rem"}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="col-lg-2 col-md-2 col-6 mt-2">
                            <Link
                              to={{
                                pathname: `/view-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}:${data?.Place__c}`,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <CommonButton
                                title={"View Details"}
                                width={"10rem"}
                              />
                            </Link>
                          </div>
                        )}
                        <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                          {data?.Type_of_Tour_Plan__c === "Field Work" &&
                            data?.Status__c === "Saved" ? (
                            <div>
                              <Link
                                to={{
                                  pathname: `/plan-mtp/${data?.TourPlan_SR_No__c}:${data?.Date__c}`,
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                <span>
                                  <ModeEditIcon className="MTP_Delete" />
                                </span>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {(data?.Status__c === "Approved" && data?.SYD_Status__c === "Pending") || (data?.Status__c === "Tour Plan Cloned" && data?.SYD_Status__c === "Pending") ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                            <span
                              onClick={() => {
                                if (data?.Status__c === "Tour Plan Cloned" && data?.Clone_TP_EmpID__c != null || data?.Status__c === "Saved" || data?.Status__c === "Pending For Approval" || data?.Status__c === "Rejected") {
                                  handleDeleteClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Type_of_Tour_Plan__c,
                                    data?.Place__c,
                                    "Delete",
                                    data?.Clone_TP_EmpID__c
                                  );
                                } else {
                                  handleDeleteRequestClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Place__c,
                                    "Delete"
                                  );
                                }
                              }}
                            >
                              <DeleteIcon className="MTP_Delete" />
                            </span>
                          </div>
                        ) : (data?.SYD_Status__c === "Completed" && (data?.Status__c === "Approved" || data?.Status__c === "Tour Plan Cloned")) ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center"></div>
                        ) : data?.Status__c ===
                          "Delete Request Sent To Manager" ? (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center"></div>
                        ) : (
                          <div className="col-lg-1 col-md-2 col-3 mt-2 text-center">
                            <span
                              onClick={() => {
                                if (data?.Status__c === "Tour Plan Cloned" && data?.Clone_TP_EmpID__c != null || data?.Status__c === "Saved" || data?.Status__c === "Pending For Approval" || data?.Status__c === "Rejected") {
                                  handleDeleteClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Date__c,
                                    data?.Type_of_Tour_Plan__c,
                                    data?.Place__c,
                                    "Delete",
                                    data?.Clone_TP_EmpID__c
                                  );
                                } else {
                                  handleDeleteRequestClick(
                                    data?.TourPlan_SR_No__c,
                                    data?.Place__c,
                                    "Delete"
                                  );
                                }
                              }}
                            >
                              <DeleteIcon className="MTP_Delete" />
                            </span>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            }
          })}

          <Modal
            show={showDeleteModal}
            onHide={handleshowDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="doctor360Modal_survey"
          >
            <Modal.Body className="show-grid">
              <h5>Delete Tour Plan</h5>
              <div className="row mb-5">
                <h6>Are you sure you want to delete tour plan.</h6>
              </div>
              <div className="row w-100" style={{ textAlign: "center" }}>
                <div
                  className="col-6"
                  style={{ marginLeft: "-5.5px" }}
                  onClick={handleshowDeleteModal}
                >
                  <span onClick={handleDelete}>
                    <CommonButton title={"Delete"} width={"100%"} />
                  </span>
                </div>
                <div className="col-6" onClick={handleshowDeleteModal}>
                  <FailButton
                    buttonText={"No"}
                    height={"2rem"}
                    width={"100%"}
                    variant="contained"
                    className="doctorName_button"
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showApproveModal}
            onHide={handleshowApproveModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="doctor360Modal_survey"
          >
            <Modal.Body className="show-grid">
              <div className="row text-center">
                <h5>Send For Approval</h5>
                <div className="row mb-5">
                  <h6>Send tour plan to Manager for approval.</h6>
                </div>
              </div>
              <div className="row w-100" style={{ textAlign: "center" }}>
                <div
                  className="col-6"
                  style={{ marginLeft: "-5.5px" }}
                  onClick={handleshowApproveModal}
                >
                  <span onClick={handleSendForApprove}>
                    <CommonButton title={"Send"} width={"100%"} />
                  </span>
                </div>
                <div className="col-6" onClick={handleshowApproveModal}>
                  <FailButton
                    buttonText={"No"}
                    height={"2rem"}
                    width={"100%"}
                    variant="contained"
                    className="doctorName_button"
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showDeleteRequestModal}
            onHide={handleshowDeleteRequestModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="doctor360Modal_survey"
          >
            <Modal.Body className="show-grid p-4">
              <h5>Delete Approved Tour Plan</h5>
              <div className="row mb-5">
                <h6>To delete approved tour plan send request to Manager.</h6>
              </div>
              <div className="row w-100" style={{ textAlign: "center" }}>
                <div
                  className="col-6"
                  style={{ marginLeft: "-5.5px" }}
                  onClick={handleshowDeleteRequestModal}
                >
                  <span onClick={handleDeleteRequest}>
                    <CommonButton title={"Yes"} width={"100%"} />
                  </span>
                </div>
                <div className="col-6" onClick={handleshowDeleteRequestModal}>
                  <FailButton
                    buttonText={"No"}
                    height={"2rem"}
                    width={"100%"}
                    variant="contained"
                    className="doctorName_button"
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </div >
  );
};

export default MonthlyTourPlan;
