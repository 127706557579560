import React from "react";
import owl1 from "./../assets/images/owl1.png";
import { Card } from "react-bootstrap";
import { Button } from "@mui/material";
import "./Campaign.css";
import { getFormatedDate } from "./../Common/functions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CampaignsLeft from "./Campaigns/CampaignsLeft";
import CampaignsRight from "./Campaigns/CampaignsRight"
import BackButton from "../Common/BackButton";
import banner1 from "../assets/images/owl1.png";
import CommonButton from "../Common/CommonButton";
import CampaignVisitPlan from "./CampaignVisitPlan";

const Campaign = () => {
  const VisitPlan = [
    {
      Frequency: "First",
      VisitDate: "Mon Apr 17 2023",
      Input: "Solid Frame",
      Status: "Completed",
    },
    {
      Frequency: "Second ",
      VisitDate: "Mon Apr 17 2023",
      Input: "Pen",
      Status: "Pending",
    },
    {
      Frequency: "Third",
      VisitDate: "Mon Apr 17 2023",
      Input: "Solid Frame",
      Status: "Completed",
    },
    {
      Frequency: "Fourth",
      VisitDate: "Mon Apr 17 2023",
      Input: "Mug",
      Status: "Completed",
    },
  ];
  const formatedDate = getFormatedDate(new Date());
  return (
    <div
      className="container"
      style={{ maxWidth: "1500px", paddingTop: "2rem" }}
    >
      <div className="row">
        <BackButton title={"DCR > Campaign"} />
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <CampaignsLeft />
        </div>
        <div className="col-lg-6 col-md-12 ">
          {/* <CampaignVisitPlan /> */}
          <CampaignsRight />
        </div>
      </div>
      <div className="row m-3 ">
        <div className="col-6 Campaign_Button">
          <div className="row " style={{justifyContent: "center"}}>
            <Button className="campaign_callButton" style={{height:'2rem', width: "30rem"}}>Add Campaign</Button>
          </div>
        </div>
        {/* <div className="col-2"></div> */}
        <div className="col-6 Campaign_Next_Button">
          <div className="row" style={{justifyContent: "center"}}>
            <CommonButton title={"Next -->"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaign;
