import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { Container } from "react-bootstrap";
import "./Login.css";
import HesterLogo from "./../assets/images/Hester-Logo-High-Resolution.jpg";
import CommonButton from "../Common/CommonButton";
import { connect, useSelector } from "react-redux";
import { accessTokenAction } from '../Redux/actions/accessTokenAction';
import { loginAction } from "../Redux/actions/loginAction";
import { setProfileImageAction } from '../Redux/actions/setProfileImageAction';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { forgotPasswordApi } from '../services/forgotPasswordApi'
import { resetPasswordApi } from "../services/resetPasswordApi";
import Loader from "../Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from "../AuthContext";
import { getProfileUrlApi, finalProfileUrlApi } from "../services/profileImageChangeApi";
import defaultImage from '../assets/images/Default-Profile.jpg';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import FailButton from "../Common/FailButton";

const Login = ({ accessTokenAction, loginAction, setProfileImageAction }) => {
  const { handleLoginAuth } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showId, setShowId] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [resetPassword, setResetPassword] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginPassword, setLoginPassword] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [profImage, setProfImage] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState();

  const dialogRef = useRef(null);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  // const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBackdropClick = (event) => {
    if (dialogRef.current && dialogRef.current === event.target) {
      event.stopPropagation();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowId('');
    setNewPassword('');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setResetPassword(event.target.value)
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);

      if (newPassword !== value) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
  };

  // const handleCheckPassword = () => {
  //   if (newPassword !== confirmPassword) {
  //     setPasswordMessage("Passwords don't match.");
  //   } else {
  //     setPasswordMessage('');
  //   }
  // };

  // const validatePassword = (password) => {
  //   const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
  //   return regex.test(password);
  // };

  // const togglePasswordVisibility = (type) => {
  //   if (type === 'new') {
  //     setShowNewPassword(!showNewPassword);
  //   } else if (type === 'confirm') {
  //     setShowConfirmPassword(!showConfirmPassword);
  //   }

  // };

  const togglePasswordVisibile = (type) => {
    if (type === 'loginPassword') {
      setLoginPassword(!loginPassword)
    }
  }
  // const toggleConfirmPasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const onChangeId = (event) => {
    setShowId(event.target.value)
  }
  const navigate = useNavigate();

  const inputClass = formSubmitted && (!showId) ? "inputStyle error" : "inputStyle";

  // const handleForgotPassword = async (event) => {
  //   try {s
  //     if (!showId || !newPassword || !confirmPassword) {
  //       event.preventDefault();
  //       setFormSubmitted(true);
  //       return;
  //     }
  //     if (!showId) {
  //       event.preventDefault();
  //       setPasswordMessage('Please enter your Employee ID / Email / Phone Number');
  //       return;
  //     }
  //     if (!newPassword || !confirmPassword) {
  //       event.preventDefault();
  //       setPasswordMessage('Please enter a new password and confirm it');
  //       return;
  //     }
  //     if (newPassword !== confirmPassword) {
  //       event.preventDefault();
  //       setConfirmPasswordError('Passwords do not match');
  //       return;
  //     }

  //     const resp = await forgotPasswordApi(accessToken, showId);
  //     const loginEmpId = resp?.data?.records[0]?.Id;
  //     await resetPasswordApi(accessToken, loginEmpId, resetPassword);
  //     setOpen(false);
  //     toast.success('Password reset successful!');
  //   } catch (error) {
  //     console.error('Password reset failed:', error);
  //   }
  // };
  const handleForgotPassword = async (event) => {
    try {
      if (!showId) {
        event.preventDefault();
        setFormSubmitted(true);
        return;
      }
      if (!showId) {
        event.preventDefault();
        setPasswordMessage('Please enter your Employee ID');
        return;
      }
      // if (!newPassword) {
      //   event.preventDefault();
      //   setPasswordMessage('Please enter a email');
      //   return;
      // }
      const resp = await forgotPasswordApi(accessToken, showId);
      if (resp?.success && resp?.data === "Employee code is Invalid") {
        toast.error(resp?.data);
      }
      else if (resp?.success) {
        toast.success("Password reset link has been successfully sent to your registered email address.");
        setOpen(false);
        setShowId('');
        setNewPassword('');
      } else {
        toast.error(resp?.msg);
      }
    } catch (error) {
      console.error('Password reset failed:', error);
    }
  };

  const handleLogin = async (event) => {
    if (!userName) {
      setUsernameError('Username is required.');
      return;
    }
    if (!password) {
      setPasswordError('Password is required.');
      return;
    }
    setLoading(true);

    try {
      const { data } = await loginAction(userName, password, accessToken);
      if (data && data.totalSize === 1) {
        const currentDate = new Date().toISOString().split('T')[0];
        // localStorage.setItem('loginDate', currentDate);
        const EmployeeId = data?.records[0]?.Id
        // const updatedImageUrl = await getProfileUrlApi(accessToken, EmployeeId);
        // const getImageUrl = updatedImageUrl?.data?.records[0]?.VersionData;
        // if (!getImageUrl) {
        //   setProfImage(true);
        //   setProfileImageAction(defaultImage);
        // } else {
        //   const { dataImage } = await setProfileImageAction(
        //     accessToken,
        //     getImageUrl
        //   );
        //   setProfileImageUrl(dataImage);
        //   // dispatch(setProfileImageAction(dataImage));
        // }
        handleLoginAuth();
        navigate('/home')

      } else {
        toast.error("Check username and password");
      }
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleUsername = (event) => {
    setUserName(event.target.value);
    if (usernameError) setUsernameError('');
  }

  const handlepassword = (event) => {
    setPassword(event.target.value);
    if (passwordError) setPasswordError('');
  }

  useEffect(() => {
    (async () => {
      await accessTokenAction();
    })();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div>
      <Container className="login_page ">
        <div className="logo_box">
          <img style={{ marginBottom: "10px" }} src={HesterLogo} width={"100%"} alt="" />

        </div>
        <div className="login_content">
          <p className="login_text mt-4">Sign In</p>
          {/* <p className="login_sub_text">Hi there! Nice to see you again</p> */}
          <div style={{ display: 'flex' }}>
            <TextField
              className="login_input"
              label="Employee ID / Email Address / Phone Number"
              color="secondary"
              focused
              onChange={handleUsername}
            />
            <span style={{ marginRight: '15px' }}></span>
          </div>
          {usernameError && <p className="error-message" style={{ color: 'red', fontSize: '14px', fontWeight: '600' }}>{usernameError}</p>}
          <div style={{ display: 'flex' }}>
            <TextField
              type={loginPassword ? 'text' : 'password'}
              // type="password"
              className="login_input"
              label="Password"
              color="secondary"
              focused
              style={{ marginBottom: "1rem" }}
              onChange={handlepassword}
              onKeyPress={handleKeyPress}
            />
            <span onClick={() => togglePasswordVisibile('loginPassword')} className="eyeIconn">
              {loginPassword ? < FaEye /> : <FaEyeSlash />}
            </span>
          </div>
          {passwordError && <p className="error-message" style={{ color: 'red', fontSize: '14px', fontWeight: '600' }}>{passwordError}</p>}
          <div className="forgetpassword">
            <span className="forgot-password" onClick={handleClickOpen}>
              Forgot Password?
            </span>
          </div>
          <span onClick={handleLogin}>
            <CommonButton
              className="login-button"
              title={"Sign In"}
              width={"15rem"}
            />
          </span>
        </div>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
        ref={dialogRef}
        onClick={handleBackdropClick}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries(formData.entries());
            // const email = formJson.email;
            // console.log(email);
            handleClose();
          },
          style: { borderRadius: '20px' }
        }}
      >
        <DialogTitle style={{ fontFamily: 'proxima-nova, sans-serif', fontWeight: '500' }}>Forgot Password</DialogTitle>
        <DialogContent className="inputContainer">
          <input
            placeholder="Employee ID"
            className={`inputStyle ${inputClass}`}
            value={showId}
            // onChange={onChangeId}
            onChange={(event) => {
              onChangeId(event);
              setFormSubmitted(false);
            }}
          />
          {/* <input
            placeholder="Email"
            className={`inputStyle ${inputClass}`}
            type='text'
            value={newPassword}
            name="newPassword"
            // onChange={handleChange}
            onChange={(event) => {
              handleChange(event);
              setFormSubmitted(false);
            }}
          /> */}


          {/* <input
            placeholder="Confirm password"
            className={`inputStyle ${inputClass}`}
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            name="confirmPassword"
            // onChange={handleChange}
            onChange={(event) => {
              handleChange(event);
              setFormSubmitted(false);
            }}
            onBlur={handleCheckPassword}
          /> */}

          {/* <div style={{ color: 'red' }}>{confirmPasswordError}</div> */}
          <div style={{ color: 'red', textAlign: 'center', fontSize: '12px' }}>Please enter a valid Employee ID. The password reset link has been successfully sent to your registered email address.</div>

        </DialogContent>

        {/* <span className='passwordMsg'>Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long.</span> */}
        <DialogActions style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
          }}>
            <div onClick={handleClose}>
              <FailButton width={"7rem"} buttonText={"Cancel"} />
            </div>
            <div onClick={handleForgotPassword}>
              <CommonButton width={"7rem"} title={"Save"} />
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {loading && <Loader />}
      <ToastContainer />
    </div>
  );
};

export default connect(null, {
  accessTokenAction,
  loginAction,
  setProfileImageAction
})(Login);

