import React from 'react';
import BackButton from "../../Common/BackButton";



const FullVisitHistoryTable = ({ visitData }) => {
    if (!visitData) {
        return null;
    }
    return (
        <div className="container">
            <table className="text-center w-100">
                <thead>
                    <tr className="ladder_heading">
                        <td className='custom-header pl-3'>Visit date</td>
                        <td className="custom-header">MR</td>
                        <td className="custom-header">Product Discuss</td>
                        <td className="custom-header">Input</td>
                        <td className="custom-header">Notes</td>
                    </tr>
                </thead>
                <tbody>
                    {visitData.map((visit, index) => (
                        <tr key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                            <td className='pl-3'>{visit.date}</td>
                            <td>{visit.contactName}</td>
                            <td>{visit.productDiscuss}</td>
                            <td>{visit.input}</td>
                            <td>{visit.fieldWork}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FullVisitHistoryTable;
