import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import mobileIcon from "./../assets/images/mobile-phone-2640.svg";
import doctorIcon from "./../assets/images/stethoscope-3539.svg";
import BellIcon from "./../assets/images/notification-bell-13085.svg";
import CalendarIcon from "./../assets/images/red-calendar-11016.svg";
import AnnIcon from "./../assets/images/wedding-date-8454.svg";
import StarIcon from "./../assets/images/star-2769.svg";
import locationIcon from "./../assets/images/location-59.svg";
import whatsAppIcon from "./../assets/images/chat.png";
import svg8 from "./../assets/images/modules/attendance.svg";
import svg3 from "./../assets/images/modules/eDetailing.svg";
import { Link, useParams } from "react-router-dom";
import cake from "./../assets/images/wedding-cake-9006.svg";
import VisitHistory from "./Doctor360/VisitHistory";
import VisitFrequency from "./Doctor360/VisitFrequency"
import BackButton from "../Common/BackButton";
import "./Doctor3601.css";
import DateAndTime from "./../Common/DateAndTime";
import SkipVisit from "./../Common/SkipVisit";
import DoctorLogo from "../assets/images/doctorBlack.jpg"
import PharmaLogo from "../assets/images/pharmaLogo.jpg"
import CanvasConfetti from "../Common/CanvasConfetti";
// import {
//   productsArray,
// } from "../Common/Constants";
import EdetailingModal from "../Common/EdetailingModal";
import { useSelector } from "react-redux";
import { getCustomer360Api } from "../services/customers/getCustomer360Api";
import Loader from "../Common/Loader";
import { getDcrStatusApi } from '../services/getDcrAllListApi';

const Doctor3601 = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const [doctor, setDoctor] = useState([]);
  let { doctorId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showEdetailingModal, setShowEdetailingModal] = useState(false);
  const [customer360, setCustomer360] = useState([]);
  const [loading, setLoading] = useState(false);
  const [birthdayReminder, setBirthdayReminder] = useState(false);
  const [quoteIndex, setQuoteIndex] = useState(0);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [formattedCustomerDOByear, setFormattedCustomerDOByear] = useState('');
  const [dcrStatus, setDcrStatus] = useState([]);
  const quotes = [
    "Until one has loved an animal a part of one's soul remains unawakened.",
    "Animals are such agreeable friends ,they ask no questions, they pass no criticisms."
  ];

  const handleEdetailingModal = () => {
    setShowEdetailingModal(!showEdetailingModal);
  };

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleModal1 = () => {
    setShowModal1(!showModal1);
  };


  useEffect(() => {
    setLoading(true);
    (async () => {
      const response = await getCustomer360Api(doctorId, accessToken);

      if (response?.status === 200) {
        setCustomer360(response?.data?.records[0]);
        setLoading(false);
      } else {
        setLoading(false);
        // setError(response?.msg);
      }
    })();
  }, [doctorId, accessToken]);



  useEffect(() => {
    // Check if customer360 is defined before performing any operations on it
    if (customer360?.DOB__c) {

      // for showing / format in date
      const partformat = customer360.DOB__c.split('.');
      const formattedslashdob = `${partformat[0]}/${partformat[1]}/${partformat[2]}`;
      setFormattedCustomerDOByear(formattedslashdob);

      const today = new Date();
      const formattedToday = `${today.getMonth() + 1}/${today.getDate()}`;

      // Extract customer's date of birth from customer360.DOB__c and compare with today's date
      const dobParts = customer360.DOB__c.split('.');
      const month = parseInt(dobParts[1]) - 1;
      const day = parseInt(dobParts[0]);


      const customerDOB = new Date(today.getFullYear(), month, day);

      const formattedCustomerDOB = `${customerDOB.getMonth() + 1}/${customerDOB.getDate()}`;

      // Check if it's the customer's birthday
      if (formattedCustomerDOB === formattedToday) {
        setBirthdayReminder(true)
        setFireConfetti(true);
      } else {
        setBirthdayReminder(false)
      }
    }
  }, [customer360]);

  useEffect(() => {
    (async () => {
      const response = await getDcrStatusApi(doctorId, empId, accessToken);
      // setDcrStatus(response?.data?.records[0]?.Date__c);
      const rawDate = response?.data?.records[0]?.Date__c;
      if (rawDate) {
        const date = new Date(rawDate);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
        setDcrStatus(formattedDate);
      }
    })();
    const interval = setInterval(() => {
      setQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);


  const modules = [
    {
      image: svg3,
      title: "eDetailing",
      link: "/eDetailing/Dr. Rajeev Verma",
      showLink: false,
    },
    // {
    //   image: svg8,
    //   title: "DCR Survey",
    //   link: `/dcr-survey/${doctorId}?`,
    //   showLink: true,
    // },
  ];

  const handleConfetti = (e) => {
    e.preventDefault();
    setFireConfetti(!fireConfetti);
  };

  return (
    <div>
      <EdetailingModal
        showModal={showEdetailingModal}
        handleClose={handleEdetailingModal}
        doctorName={doctor.name}
      />
      <DateAndTime showModal={showModal} handleClose={handleModal} />
      <SkipVisit showModal={showModal1} handleClose={handleModal1} />
      <div className="container-fluid">
        <div className="row mt-3">
          <BackButton title={"Customer`s 360"} />
        </div>
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-sm-12 col-12"
            style={{ marginTop: "1rem" }}
          >
            <div className="DoctorCardWrapper">
              <Card style={{ overflow: "auto" }} className="DoctorCard profile Doctor360Profile">
                <Card.Body>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      {customer360?.Category__c === "Retailer" ?
                        <img
                          src={PharmaLogo}
                          className="ProfileIcon"
                          style={{
                            height: "7rem",
                            width: "7rem",
                            borderRadius: "15px",
                          }}
                          alt=""
                        />
                        :
                        <img
                          src={DoctorLogo}
                          className="ProfileIcon"
                          style={{
                            height: "7rem",
                            width: "7rem",
                            borderRadius: "15px",
                          }}
                          alt=""
                        />}
                    </div>
                    <div className="col-md-9 col-sm-12">
                      <div className="DoctorPharm_Name">
                        <span className="DoctorPharm_NameTitle">
                          {customer360.Name}
                        </span>
                      </div>
                      <div className="DoctorPharm_Post" style={{ marginTop: "5px" }}>
                        <div className="row">
                          <div className="col-sm-6">
                            <span className="mr-3">
                              <img
                                src={doctorIcon}
                                style={{ height: "1rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              {customer360.Designation__c}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <span>
                              <img
                                src={BellIcon}
                                style={{ height: "1rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              {customer360.Visit_Frequency__c}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <span className="mr-3">
                              <img
                                src={mobileIcon}
                                style={{ height: "1rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              {customer360.MobileNumber__c}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <span>
                              <img
                                src={whatsAppIcon}
                                style={{ height: "1.2rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              {customer360.Whatsapp_Number__c}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <span className="mr-3">
                              <img
                                src={CalendarIcon}
                                style={{ height: "1rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              DOB: {formattedCustomerDOByear}
                            </span>
                          </div>
                          <div className="col-sm-6">
                            <span>
                              <img
                                src={AnnIcon}
                                style={{ height: "1rem", marginRight: "5px" }}
                                alt="icon"
                              />
                              Total Potential: {customer360.Total_Potential__c}
                            </span>
                          </div>
                          <div className="col-12">
                            <span>
                              <img
                                src={StarIcon}
                                style={{ height: "1rem", marginRight: "7px" }}
                                alt="icon"
                              />
                              P1: {customer360.Focus_Product_1__c} | P2:{" "}
                              {customer360.Focus_Product_2__c} | P3:{" "}
                              {customer360.Focus_Product_3__c}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div
            className="col-lg-6 col-md-12 col-sm-12 col-12"
            style={{ marginTop: "1rem" }}
          >
            <Card
              style={{
                boxShadow: "0 1px 1px rgba(-5,122,218,.5)",
                // maxHeight: "178px",
                height: "176px",
                // overflow: "auto",
              }}
            >
              <Card.Body>
                <p style={{ color: "orange", margin: "0" }}>
                  <img
                    src={locationIcon}
                    style={{
                      height: "1rem",
                      marginRight: "7px",
                      marginBottom: "7px",
                    }}
                    alt="location"
                  />
                  Address
                </p>
                <div style={{ fontSize: "14px", marginLeft: "1.5rem" }}>
                  <span><b>Street: </b> {customer360?.Address__c}</span>
                  <br />
                  <span><b>Area: </b> {customer360?.Area__c}</span>
                  <br />
                  <span><b>City: </b> {customer360?.City__c}</span>
                  <br />
                  <span><b>Pincode: </b> {customer360?.Pincode__c}</span>
                  <p>
                    <b>State, Country: </b> {customer360?.State__c}, {customer360?.Country__c}
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="row">
          {/* eDetailing and DCR Survey Cards */}
          <div className="col-lg-6 col-md-6">
            <div className="row">
              {modules.map((module, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                  {module.showLink && (
                    <Card style={{ borderRadius: "2rem", marginTop: "1rem" }} className="doctor360_module_card">
                      <Card.Body>
                        <Link className="module_link" to={module.link}>
                          <div className="icon-boxme">
                            <img src={module.image} alt="" />
                            <h5>{module.title}</h5>
                          </div>
                        </Link>
                      </Card.Body>
                    </Card>
                  )}
                  {!module.showLink && (
                    <Card
                      style={{ borderRadius: "2rem", marginTop: "1rem" }}
                      className="doctor360_module_card"
                    // onClick={handleEdetailingModal}
                    >
                      <Card.Body>
                        <Link className="module_link" to={module.link}>
                          <div className="icon-boxme">
                            <img src={module.image} alt="" />
                            <h5>{module.title}</h5>
                          </div>
                        </Link>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              ))}
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-center">
                <Card style={{ borderRadius: "2rem", marginTop: "1rem" }} className="doctor360_module_card">
                  <Card.Body>
                    <span className="module_link">
                      <div className="icon-boxme">
                        <img src={svg8} alt="" />
                        <h5>Last DCR Filled Date: {dcrStatus}</h5>
                      </div>
                    </span>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          
          <div
            className="col-lg-6 col-md-6"
            style={{ marginTop: "1rem", paddingRight: "0" }}
          >

            {birthdayReminder ? (
              <Card onClick={handleConfetti} style={{ borderRadius: "2rem", marginRight: "0.6rem" }}>
                <Card.Body>
                  <div className="row">
                    <div
                      className="col-lg-3 col-md-3 col-sm-4 col-4"
                      style={{ paddingLeft: "4px" }}
                    >
                      <span>
                        <img
                          className="CakeImg"
                          src={cake}
                          style={{ height: "6rem" }}
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="col-8">
                      <h4>Birthday Reminder</h4>
                      <p style={{ fontStyle: "italic" }}>
                        <b>{customer360?.ContactPerson__c}</b> has a Birthday today wish him on
                        behalf of Hester family.
                      </p>
                    </div>

                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Card style={{ borderRadius: "2rem", marginRight: "0.9rem", height: "130px" }}>
                <Card.Body>
                  <h4>Quotes</h4>
                  <p style={{ fontStyle: "italic" }}>{quotes[quoteIndex]}</p>
                  <p style={{ fontStyle: "italic" }}>{quotes[quoteIndex].split(" - ")[1]}</p>
                </Card.Body>
              </Card>
            )}
          </div>

          {/* <div className="col-lg-3 col-md-5" style={{ marginTop: "1rem" }}>
          </div> */}
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 col-md-12" style={{ marginTop: "1rem" }}>
            <h4 style={{ color: "#2E3192", marginBottom: '1rem' }}>Visit History</h4>
            <Card style={{ maxHeight: "400px", overflow: "auto", height: '350px' }}>
              <Card.Body className="p-2">
                <VisitHistory
                  doctorId={doctorId} />
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-6 col-md-12" style={{ marginTop: "1rem" }}>
            <h4 style={{ color: "#2E3192", marginBottom: '1rem' }}>Visit Frequency</h4>
            <Card style={{ maxHeight: "400px", overflow: "auto", height: '350px' }}>
              <Card.Body className="p-2">
                < VisitFrequency
                  doctorId={doctorId}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row mt-4">
          <div
            className="col-lg-4 col-md-12 SampleInput"
            style={{ marginTop: "1rem" }}
          >
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {birthdayReminder && fireConfetti && <CanvasConfetti />}

    </div>
  );
};

export default Doctor3601;
