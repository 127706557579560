import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./NewVisitFilterModal.css";
import doctorImage from './../../assets/images/doctorgif-1.gif'
import Modal from "react-bootstrap/Modal";
import SuccessButton from "../../Common/SuccessButton";
import FailButton from "../../Common/FailButton";
import CommonButton from "../../Common/CommonButton"
import { Button } from "react-bootstrap";

const NewVisitFilterModal = ({ showModal, handleClose }) => {
  const specializationOptions = [
    "orthopedics",
    "Internal Medicine",
    "Obstetrics and Gynecology",
    "Dermatology",
    "Pediatrics",
    "Radiology",
    "General Surgery",
    "Ophthalmology",
  ];

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="NewVisitModal"
    >
      <Modal.Body className="show-grid" style={{width: '31rem'}}>
        <div className="form_body">
          <div className="text-center mb-3">
          <img src={doctorImage} style={{width:'90%'}}/>
          </div>
          <div className="row">
            <div className="col-6" style={{width: '15rem'}}>
              <h5 >Filter By</h5>
              
              <FormGroup className="NewVisitModal_content">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Not Visited in Last Week"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Last Visited in last 15 days"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Last Visited in last 30 days"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Skipped Doctors"
                  style={{whiteSpace:'nowrap'}}
                />
              </FormGroup>
           
            </div>
            <div className="col-6" style={{width: '15rem'}}>
              <h5>Speciality</h5>
              
              <FormGroup className="NewVisitModal_content">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Cardiologist"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Gastroenterologist"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Gynecologist"
                  style={{whiteSpace:'nowrap'}}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ophthalmology"
                  style={{whiteSpace:'nowrap'}}
                />
              </FormGroup>
           
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="doctor360Modal_survey_button">
        <div className="row">
          <div className="col-6">
          <div onClick={handleClose}>
            <CommonButton title={'Save Changes'} width={'10rem'}/>
          </div>
          </div>
          <div className="col-6">
          <div onClick={handleClose} >
            <Button style={{fontSize: '14px', fontWeight: '600', border: '0', backgroundColor: '#4ad84a', borderRadius: '20px', background:'rgb(225, 219, 219)', color: 'black', width:'10rem'}} >Cancel</Button>
          </div>
          </div>
         
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NewVisitFilterModal;
