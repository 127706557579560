import axios from "axios";
import { configs } from "../../config";

export const sampleInput = async (empId, responseToken) => {
  const requestBody = {
    salesPersonCode: empId
  };
  const endpoint = `https://api.businesscentral.dynamics.com/v2.0/b4183d8e-77fa-431a-8341-83d973a5ba4b/Production/api/dhyey/bcapi/V2.0/companies(0d4250f9-4c45-ed11-9471-6045bd72fa87)/materialissueheaderAPIs/?$expand=materialissuelineAPIs&$filter=status eq 'Released' and issueType eq 'Consume at "To Location"' and employeeCode eq '${empId}'`;
  try {
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${responseToken}`
      }
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return {
        success: false,
        error: `Unexpected status code: ${response.status}`,
        data: response.data
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        status: error.response.status,
        data: error.response.data
      };
    } else if (error.request) {
      return {
        success: false,
        error: "No response received from server",
        request: error.request
      };
    } else {
      return {
        success: false,
        error: error.message
      };
    }
  }
};
