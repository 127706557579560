import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers/index";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from 'redux-thunk';


const middleware = [thunk];
export const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);