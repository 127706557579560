import { getGlobalAccessTokenApi } from "../../services/getGlobalAccessTokenApi";
import {
    GET_ACCESS_TOKEN_SUCCESS,
    GET_ACCESS_TOKEN_FAIL,
} from "../constants/action-types";

export const accessTokenAction = () => async (dispatch) => {
    try {
        const response = await getGlobalAccessTokenApi(); // Wait for the Promise to resolve
        if (response ) {
            dispatch({
                type: GET_ACCESS_TOKEN_SUCCESS,
                payload: response?.data?.accessToken, // Assuming response.data contains the actual data
            });
        } else {
            dispatch({
                type: GET_ACCESS_TOKEN_FAIL,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ACCESS_TOKEN_FAIL,
        });
    }
};
