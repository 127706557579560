import SignUpForm from "../Components/SignUpForm";

const SignUp = () => {
  return (
    <>
      <SignUpForm />
    </>
  );
};

export default SignUp;
