import Card from "react-bootstrap/Card";
import "./EDetailingCard.css";
import { useEffect, useState } from "react";
import PlaylistModal from "./PlaylistModal";
import ReactPlayer from "react-player";
// import pptImage from "../../assets/images/orthopedic-instruments.jpg";
import { Link } from "react-router-dom";
import VideoPlayerModal from "../../Common/VideoPlayerModal";
// import pdfImage from "../../assets/images/pdfImage.png";

const EDetailingCard = ({ video, provided }) => {
  const [showModal, setShowModal] = useState(false);
  const [videoPlayerModal, setVideoPlayerModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleVideoPlayerModal = () => {
    setVideoPlayerModal(!videoPlayerModal);
  };

  const handleVideoUrl = (url) => {
    setVideoUrl(url);
  };
  return (
    <>
      <PlaylistModal
        showModal={showModal}
        handleShowModal={handleModal}
        type={"addPlaylist"}
        playlistData={[]}
      />
      <VideoPlayerModal
        showModal={videoPlayerModal}
        handleClose={handleVideoPlayerModal}
        videoUrl={videoUrl}
        videoDr={video}
      />
      <div
        className="playListModal_card mb-4 eDetailingCard_cardBox"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {video.type === "Video" && (
          <Card
            className="eDetailingCard_card"
            onClick={() => {
              setVideoUrl(video.videoUrl);
              handleVideoPlayerModal();
            }}
          >
            {video.contentType === "pdf" && (
              <img
                src={video.videoImage}
                style={{
                  height: "10rem",
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem",
                }}
                alt=""
                srcset=""
              />)}
            <div className="eDetailingCard_title">
              <div>
                {/* {videoIcon} */}
                {video.title}
                <span class="DoctorPharm_badge2" style={{ marginLeft: "7px" }}>
                  {video.contentType}
                </span>
                <br />
                <span style={{ color: "gray", fontWeight: "400" }}>
                  {video.subTitle}
                </span>
              </div>
              <p style={{ color: "gray", fontWeight: "400" }}>
                {video.playTime}
              </p>
            </div>
          </Card>
        )}
        {video.contentType === "pdf" && (
          <Link to={video.url} target="_blank">
            <Card className="eDetailingCard_card">
              <div style={{
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: "2px solid #00a87e",
                backgroundColor: 'rgb(221, 241, 238)'
              }}>
                {/* <img
                  src={pdfImage}
                  style={{
                    height: "4rem",
                    width: "4rem",
                    borderTopLeftRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                  }}
                  alt=""
                  srcset=""
                /> */}
              </div>
              <div className="eDetailingCard_title">
                <div>
                  {/* {documentIcon} */}
                  {video.title}
                  <span
                    class="DoctorPharm_badge2"
                    style={{ marginLeft: "7px",position:'absolute', right:0,fontSize:'15px',width:'5rem' ,textAlign:'center',textTransform:'uppercase'}}
                  >
                    {video.contentType}
                  </span>
                  <br />
                  <span style={{ color: "gray", fontWeight: "400" }}>
                    {video.subTitle}
                  </span>
                </div>
              </div>
            </Card>
          </Link>
        )}
        {video.contentType === "pptx" && (
          <Link
            to="https://1drv.ms/p/s!AmI0636GrYKUakWw-_fVj3a1bys?e=lEgZl5"
            target="_blank"
          >
            <Card className="eDetailingCard_card">

              <div style={{
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: "2px solid #00a87e",
                backgroundColor: 'rgb(221, 241, 238)'
              }}>
                {/* <img
                  src={pptImage}
                  style={{
                    height: "10rem",
                    borderTopLeftRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                  }}
                  alt=""
                  srcset=""
                /> */}
              </div>
              <div className="eDetailingCard_title">
                <div>
                  {video.title}
                  <span
                    class="DoctorPharm_badge2"
                    style={{ marginLeft: "7px",position:'absolute', right:0,fontSize:'15px',width:'5rem' ,textAlign:'center',textTransform:'uppercase'}}
                  >
                    {video.contentType}
                  </span>
                  <br />
                  <span style={{ color: "gray", fontWeight: "400" }}>
                    {video.subTitle}
                  </span>
                </div>
              </div>
            </Card>
          </Link>
        )}
        {video.contentType === "jpg" && (
          <Link to={video.url} target="_blank">
            <Card className="eDetailingCard_card">
              <div style={{
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: "2px solid #00a87e",
                backgroundColor: 'rgb(221, 241, 238)'
              }}>
                {/* <img
                  src={imageLogo}
                  style={{
                    height: "10rem",
                    borderTopLeftRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                  }}
                  alt=""
                  srcset=""
                /> */}
              </div>
              <div className="eDetailingCard_title">
                <div>
                  {/* {documentIcon} */}
                  {video.title}
                  <span
                    class="DoctorPharm_badge2"
                    style={{ marginLeft: "7px",position:'absolute', right:0,fontSize:'15px',width:'5rem' ,textAlign:'center',textTransform:'uppercase'}}
                  >
                    {video.contentType}
                  </span>
                  <br />
                  <span style={{ color: "gray", fontWeight: "400" }}>
                    {video.subTitle}
                  </span>
                </div>
              </div>
            </Card>
          </Link>
        )}
        {video.contentType === "txt" && (
          <Link to={video.url} target="_blank">
            <Card className="eDetailingCard_card">
              <div style={{
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: "2px solid #00a87e",
                backgroundColor: 'rgb(221, 241, 238)'
              }}>
                {/* <img
                src={textImage}
                style={{
                  height: "10rem",
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem",
                }}
                alt=""
                srcset=""
              /> */}
              </div>
              <div className="eDetailingCard_title">
                <div>
                  {/* {documentIcon} */}
                  {video.title}
                  <span
                    class="DoctorPharm_badge2"
                    style={{ marginLeft: "7px",position:'absolute', right:0 ,fontSize:'15px',width:'5rem',textAlign:'center',textTransform:'uppercase'}}
                  >
                    {video.contentType}
                  </span>
                  <br />
                  <span style={{ color: "gray", fontWeight: "400" }}>
                    {video.subTitle}
                  </span>
                </div>
              </div>
            </Card>
          </Link>
        )}

        {/* <div className="eDetailingCard_add" onClick={handleModal}>
                  <QueueIcon style={{ fontSize: "x-large" }} />
                </div> */}
      </div>
    </>
  );
};

export default EDetailingCard;
