import React from 'react'
import LeaveApproveComponent from '../Components/LeaveApproveComponent'

function LeaveApprove() {
  return (
    <>
      <LeaveApproveComponent />
    </>
  )
}

export default LeaveApprove