import axios from "axios";
import { configs } from "../../config";

export const reportPOBReportDownloadApi = async (
    accessToken,
    empCode,
    startDate,
    endDate,
    designation,
    userId,
    lastRecordId,
    division
) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/ReportForPOBUpdated`,
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            empCode: empCode,
            startDate: startDate,
            endDate: endDate,
            designation: designation,
            userId: userId,
            lastRecordId: lastRecordId ? lastRecordId : "",
            division: division
        },
    };

    try {
        const response = await axios(options);
        return response;
    } catch (error) {
        console.error("Error fetching report data:", error);
        throw error; // rethrow the error to be handled by the calling function
    }
};