import React from 'react'
import CommunityAndMentorship1 from '../Components/Elearning/CommunityAndMentorship';
import BackButton from '../Common/BackButton';

const CommunityAndMentorship = () => {
  return (
    <div className='container mt-4'>
      <BackButton title={'Community And Mentorship'} />
      <CommunityAndMentorship1 />
    </div>
  )
}

export default CommunityAndMentorship
