import React from "react";
import CampaignComp from "../Components/Campaign";
import Header from "../Common/Header";

const Campaign = () => {
  return (
    <div>
      {/* <Header title={"Campaigns"} isShowDate={false} /> */}
      <CampaignComp />
    </div>
  );
};

export default Campaign;
