import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { reportDownloadApi } from "../../services/reports/reportDownloadApi";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import CommonButton from "../../Common/CommonButton";
import { DCRSurveyReportDownloadApi } from "../../services/reports/dcrSurveyReportApi.js";
import Loader from "../../Common/Loader";
import { toast, ToastContainer } from "react-toastify";

const DcrReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterEmpId, setFilterEmpId] = useState("");
  const [loading, setLoading] = useState(false);
  const [allDcr, setAllDcr] = useState([]);
  const [error, setError] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [lastRecordId, setLastRecordId] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner
  const [hasMore, setHasMore] = useState(true);
  const [excloading, setExcloading] = useState(false)

  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector(
    (state) => state?.userDetails?.details?.Designation__c
  );
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);


  const fetchData = async (loadMore = false, id = '') => {
    setLoading(true); // Start loading state

    try {
      // Fetch data from API, use the id (lastRecordId) when loading more
      const response = await DCRSurveyReportDownloadApi(
        accessToken,
        filterEmpId,
        startDate,
        endDate,
        designation,
        loadMore ? id : '',  // Pass the id (lastRecordId) if loading more
        division,
        EmployeeId
      );

      // Parse the response data
      const dataArray = JSON.parse(response.data);
      console.log("API Response:", dataArray);


      // Check if data and records are available
      if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
        setAllDcr([]); // Reset data to an empty array
        setHasMore(false); // No more records to fetch
        return;
      }

      const { records, nextLastRecordId } = dataArray;

      if (loadMore) {
        // Append new records to the existing data
        setAllDcr((prevData) => [...prevData, ...records]);
      } else {
        // Set data for the first load
        setAllDcr(records);
      }

      // Update the lastRecordId for pagination
      setLastRecordId(nextLastRecordId);

      // If there are more records, allow loading more
      setHasMore(!!nextLastRecordId);

      console.log("Fetched Records:", records);

      // Set dataFetched based on the presence of data
      setDataFetched(records.length > 0);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Provided employee code is not a valid subordinate.");
        setAllDcr([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setAllDcr([]); // Reset data to an empty array in case of other errors
      }
      setDataFetched(false); // Indicate no data was fetched in case of error
    } finally {
      setLoading(false); // End loading state
    }
  };



  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError("Please enter only numbers.");
    } else {
      setError("");
    }
  };

  const exportToExcel = async () => {
    let allData = [];  // Array to store all the fetched data
    let lastRecordId = '';  // Keep track of the last fetched record ID
    let hasMore = true;  // Boolean to indicate if more records are available
    const limit = 500;  // Limit for the number of records per API call

    setExcloading(true);  // Show loading indicator during export process

    try {
      // Loop until all data is fetched
      while (hasMore) {
        // Fetch a chunk of data
        const response = await DCRSurveyReportDownloadApi(
          accessToken,
          filterEmpId,
          startDate,
          endDate,
          designation,
          lastRecordId,  // Use lastRecordId to fetch the next batch of records
          division,
          EmployeeId
        );

        // Parse the response
        const dataArray = JSON.parse(response.data);


        // If no records are returned, stop fetching
        if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
          setAllDcr([]); // Reset data to an empty array
          hasMore = false;
          break;
        }

        // Extract records and the next lastRecordId from the response
        const { records, nextLastRecordId } = dataArray;

        // Merge the new records into allData
        allData = [...allData, ...records];

        // Update the lastRecordId for the next API call
        lastRecordId = nextLastRecordId;

        // If there are no more records, stop the loop
        hasMore = !!nextLastRecordId;
      }

      // After fetching all records, export to Excel
      if (allData.length > 0) {
        // Generate Excel file from allData
        generateExcelFile(allData);
      } else {
        console.log('No data available to export');
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Provided employee code is not a valid subordinate.");
        setAllDcr([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setAllDcr([]); // Reset data to an empty array in case of other errors
      }
    } finally {
      setExcloading(false);  // Hide loading indicator after export process
    }
  };

  const generateExcelFile = (allDcr) => {
    const ws = XLSX.utils.json_to_sheet(
      allDcr.map((dcr) => ({
        "Call Date": formatDateStringDDMMYYYY(dcr?.Date__c),
        "Emp Code": dcr?.Employee__r?.EmpId__c,
        "Emp Name": dcr?.Employee__r?.Name,
        "Joint Work Emp Code": dcr?.Tour_Plan__r?.isJointWorking__c
          ? dcr?.Employee__r?.ReportsTo?.EmpId__c
          : "-",
        "Joint Work Emp Name": dcr?.Tour_Plan__r?.isJointWorking__c
          ? dcr?.Employee__r?.ReportsTo?.Name
          : "-",
        "Type of TP": dcr?.Type_of_tour_plan__c
          ? "Other Type Of Tour Plan"
          : "Field Work",
        "Type of Customer Activity":
          dcr?.Type_of_tour_plan__c === "Customer Engagement Activity" ||
            dcr?.Type_of_tour_plan__c === "Seminar/Conference" ||
            dcr?.Type_of_tour_plan__c === "Admin Work"
            ? dcr?.Type_of_tour_plan__c
            : "-",
        "Customer Name": dcr?.Customer__r?.Name,
        City: dcr?.Customer__r?.City__c,
        "Customer Category": dcr?.Customer__r?.Category__c,
        "Class (Hester)": dcr?.Customer__r?.Hester_Business__c,
        "Total Potential": dcr?.Customer__r?.Total_Potential__c,
        "Route No.": dcr?.Customer__r?.Route_Mapping__c,
        "Product Discussed": dcr?.Product_discuss__c,
        "Gift input": (dcr?.Promotional_Inputs__c || "").split(":")[0] || "-",
        "Gift Reminder Qty.":
          (dcr?.Promotional_Inputs__c || "").split(":")[1] || "-",
        "Product Sample": (dcr?.Samples__c || "").split(":")[0] || "-",
        "Product Sample Qty.": (dcr?.Samples__c || "").split(":")[1] || "-",
        POB: dcr?.POB__c,
        Time: dcr?.Visit_Time__c,
        Remarks: dcr?.Remarks__c,
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DCR Report");

    XLSX.writeFile(wb, "DCR_Report.xlsx");
  };

  // const exportToExcel = () => {
  //   const ws = XLSX.utils.json_to_sheet(
  //     allDcr.map((dcr) => ({
  //       "Call Date": formatDateStringDDMMYYYY(dcr?.Date__c),
  //       "Emp Code": dcr?.Employee__r?.EmpId__c,
  //       "Emp Name": dcr?.Employee__r?.Name,
  //       "Joint Work Emp Code": dcr?.Tour_Plan__r?.isJointWorking__c
  //         ? dcr?.Employee__r?.ReportsTo?.EmpId__c
  //         : "-",
  //       "Joint Work Emp Name": dcr?.Tour_Plan__r?.isJointWorking__c
  //         ? dcr?.Employee__r?.ReportsTo?.Name
  //         : "-",
  //       "Type of TP": dcr?.Type_of_tour_plan__c
  //         ? "Other Type Of Tour Plan"
  //         : "Field Work",
  //       "Type of Customer Activity":
  //         dcr?.Type_of_tour_plan__c === "Customer Engagement Activity" ||
  //           dcr?.Type_of_tour_plan__c === "Seminar/Conference" ||
  //           dcr?.Type_of_tour_plan__c === "Admin Work"
  //           ? dcr?.Type_of_tour_plan__c
  //           : "-",
  //       "Customer Name": dcr?.Customer__r?.Name,
  //       City: dcr?.Customer__r?.City__c,
  //       "Customer Category": dcr?.Customer__r?.Category__c,
  //       "Class (Hester)": dcr?.Customer__r?.Hester_Business__c,
  //       "Total Potential": dcr?.Customer__r?.Total_Potential__c,
  //       "Route No.": dcr?.Customer__r?.Route_Mapping__c,
  //       "Product Discussed": dcr?.Product_discuss__c,
  //       "Gift input": (dcr?.Promotional_Inputs__c || "").split(":")[0] || "-",
  //       "Gift Reminder Qty.":
  //         (dcr?.Promotional_Inputs__c || "").split(":")[1] || "-",
  //       "Product Sample": (dcr?.Samples__c || "").split(":")[0] || "-",
  //       "Product Sample Qty.": (dcr?.Samples__c || "").split(":")[1] || "-",
  //       POB: dcr?.POB__c,
  //       Time: dcr?.Visit_Time__c,
  //       Remarks: dcr?.Remarks__c,
  //     }))
  //   );

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "DCR Report");

  //   XLSX.writeFile(wb, "DCR_Report.xlsx");
  // };

  const handleSubmit = () => {
    fetchData();
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchData(true, lastRecordId); // Pass true for load more and the lastRecordId
    }
  };


  return (
    <>{excloading && <Loader />}
      <div className="markAttendance_section container-fluid">
        <div className="row mb-5">
          <Card className="monthlyClaim_mobile">
            <Card.Body>
              <h4 className="mb-4 text-center">DCR Report</h4>
              <div className="filter-section mb-4">
                <label style={{ marginRight: "1rem" }}>
                  Start Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    value={startDate}
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: "1rem" }}>
                  End Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: "1rem" }}>
                  EmpId
                  <input
                    className="CommonDropdown form-select"
                    type="text"
                    maxLength={4}
                    style={{
                      width: "8rem",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={filterEmpId}
                    onChange={(e) => setFilterEmpId(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </label>
                <span onClick={handleSubmit}>
                  <CommonButton title={"Submit"} className="btn btn-primary" />
                </span>
                {dataFetched && (
                  <span style={{ marginLeft: "10px" }} onClick={exportToExcel}>
                    <CommonButton title={"Download Excel"} />
                  </span>
                )}
              </div>
              {error && <span className="dcr-form-errors">{error}</span>}
              {loading ? (
                <div>Loading...</div>
              ) : (
                dataFetched && (
                  <div
                    id="table-to-xls"
                    className="table-wrapper-scroll-y custom-scrollbar"
                    style={{ padding: "20px", overflow: "auto" }}
                  >
                    <table style={{ textAlign: "center", width: "100%" }}>
                      <thead>
                        <tr className="ladder_heading">
                          <th className="custom-header">Call Date</th>
                          <th className="custom-header">Emp Code</th>
                          <th className="custom-header">Emp Name</th>
                          <th className="custom-header">Joint Work Emp Code</th>
                          <th className="custom-header">Joint Work Emp Name</th>
                          <th className="custom-header">Type of TP</th>
                          <th className="custom-header">
                            Type of Customer Activity
                          </th>
                          <th className="custom-header">Customer Name</th>
                          <th className="custom-header">City</th>
                          <th className="custom-header">Customer Category</th>
                          <th className="custom-header">Class (Hester)</th>
                          <th className="custom-header">Total Potential</th>
                          <th className="custom-header">Route No.</th>
                          <th className="custom-header">Product Discussed</th>
                          <th className="custom-header">Gift input</th>
                          <th className="custom-header">Gift Reminder Qty.</th>
                          <th className="custom-header">Product Sample</th>
                          <th className="custom-header">Product Sample Qty.</th>
                          <th className="custom-header">POB</th>
                          <th className="custom-header">Time</th>
                          <th className="custom-header">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allDcr.length > 0 ? (
                          allDcr.map((dcr, index) => {
                            const promotionalInput =
                              dcr?.Promotional_Inputs__c?.split(":") || [];
                            const sampleInput = dcr?.Samples__c?.split(":") || [];
                            return (
                              <tr key={index} className="ladder_heading_data">
                                <td className="pl-3">
                                  {formatDateStringDDMMYYYY(dcr?.Date__c)}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Employee__r?.EmpId__c}
                                </td>
                                <td className="pl-3">{dcr?.Employee__r?.Name}</td>
                                <td className="pl-3">
                                  {dcr?.Tour_Plan__r?.isJointWorking__c
                                    ? dcr?.Employee__r?.ReportsTo?.EmpId__c
                                    : "-"}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Tour_Plan__r?.isJointWorking__c
                                    ? dcr?.Employee__r?.ReportsTo?.Name
                                    : "-"}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Type_of_tour_plan__c
                                    ? "Other Type Of Tour Plan"
                                    : "Field Work"}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Type_of_tour_plan__c ===
                                    "Customer Engagement Activity" ||
                                    dcr?.Type_of_tour_plan__c ===
                                    "Seminar/Conference" ||
                                    dcr?.Type_of_tour_plan__c === "Admin Work"
                                    ? dcr?.Type_of_tour_plan__c
                                    : "-"}
                                </td>
                                <td className="pl-3">{dcr?.Customer__r?.Name}</td>
                                <td className="pl-3">
                                  {dcr?.Customer__r?.City__c}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Customer__r?.Category__c}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Customer__r?.Hester_Business__c}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Customer__r?.Total_Potential__c}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Customer__r?.Route_Mapping__c}
                                </td>
                                <td className="pl-3">
                                  {dcr?.Product_discuss__c}
                                </td>
                                <td className="pl-3">
                                  {promotionalInput[0] || "-"}
                                </td>
                                <td className="pl-3">
                                  {promotionalInput[1] || "-"}
                                </td>
                                <td className="pl-3">{sampleInput[0] || "-"}</td>
                                <td className="pl-3">{sampleInput[1] || "-"}</td>
                                <td className="pl-3">{dcr?.POB__c}</td>
                                <td className="pl-3">{dcr?.Visit_Time__c}</td>
                                <td className="pl-3">{dcr?.Remarks__c}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="20" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              )}
            </Card.Body>
          </Card>
          <ToastContainer />
          {/* {hasMore && !isLoading && <button onClick={handleLoadMore}>Load More</button>} */}
          {(hasMore && !isLoading && (allDcr.length > 0)) &&
            <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
              <CommonButton title={"Load More"} />
            </span>
          }
        </div>
      </div>
    </>
  );
};

export default DcrReport;