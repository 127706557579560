import axios from "axios";
import { configs } from "../../config";
export const DCRSurveyReportDownloadApi = async (
    accessToken,
    empCode,
    startDate,
    endDate,
    designation,
    lastRecordId,
    division,
    empId,
) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/DCRSurveyAccessUpdated`,
        method: "post",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            empCode: empCode,
            startDt: startDate,
            endDt: endDate,
            designation: designation,
            lastRecordId: lastRecordId ? lastRecordId : "",
            division: division,
            userId: empId,
        },
        "empCode": "",
        "startDt": "2024-06-12",
        "endDt": "2024-09-12",
        "designation": "Associate Vice President"
    };

    try {
        const response = await axios(options);
        return response;
    } catch (error) {
        console.error("Error fetching report data:", error);
        throw error; // rethrow the error to be handled by the calling function
    }
};
