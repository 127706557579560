import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./DateAndTime.css";
import { getFormatedDate } from "./functions";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import dayjs from "dayjs";
import SuccessButton from "./SuccessButton";
import RescheduleImg from "./../assets/images/Reschedule.gif";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import CommonButton from "./CommonButton";

const DateAndTime = ({ showModal, handleClose }) => {
  const [value, setValue] = useState(new Date());
  const [timeValue, setTimeValue] = useState(dayjs(new Date()));
  const [clockView, setClockView] = useState("hours");
  const [formatedDate, setFormatedDate] = useState(
    getFormatedDate(new Date(), true)
  );
  const handleShowModal = () => {
    handleClose();
  };
  const handleDateChange = (date) => {
    setFormatedDate(getFormatedDate(date, true));
  };
  const handleClockView = (view) => {
    setClockView(view);
  };
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey RescheduleModal"
    >
      <Modal.Body className="show-grid">
        <div className=" text-center">
          <img
            src={RescheduleImg}
            style={{ height: "10rem", width: "10rem" }} alt=""
          />
          <h4 className="mt-4">Reschedule Call</h4>
          <p className="m-0" style={{ fontWeight: "500" }}>
            Next Visit is Planned on 08 Feb 2023
          </p>
          <p style={{ fontWeight: "500" }}>
            Are you sure you want to Reschedule Call ?
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DatePicker
              value={timeValue}
              onChange={(newValue) => setTimeValue(newValue)}
            /> */}
             <MobileDateTimePicker defaultValue={dayjs('2023-07-18T12:30')} />
          </LocalizationProvider>
        </div>
      </Modal.Body>
      <Modal.Footer
        className="doctor360Modal_survey_button"
        style={{ alignSelf: "center" }}
      >
        <span onClick={handleShowModal}>
          <CommonButton title={"Reschedule"} />
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default DateAndTime;
