import axios from "axios";
import { configs } from "../../config";

export const leaveApprovalList = async (accessToken, empId) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Application_Date__c,Approver_Employee__c,CreatedDate,Employee__c,Employee__r.name,Employee__r.empid__c,From_Date__c,Id,isFullday__c,isHalfday__c,Leave_End_Date__c,Leave_Start_Date__c,Leave_Type__c,Name,OwnerId,Reason__c,Session_1__c,Session_2__c,Status__c,To_Date__c,hasAttachments__c FROM Leave__c where Approver_Employee__c='${empId}'`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};
