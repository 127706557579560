import React, { useEffect, useState, useRef } from 'react';
import { viewAllClaimsManager } from '../../services/expenses/viewAllClaimsManager';
import { useSelector } from "react-redux";
import BackButton from "../../Common/BackButton";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import CommonButton from '../../Common/CommonButton';
import Loader from '../../Common/Loader';
import { Card } from "react-bootstrap";


function ViewAllClaims() {
    const empDesignation = useSelector((state) => state?.userDetails?.details?.Designation__c);
    const empId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);

    const [allClaims, setAllClaims] = useState();
    const [loading, setLoading] = useState(false)
    const tableRef = useRef(null);
    useEffect(() => {
        setLoading(true);
        (async () => {
            const response = await viewAllClaimsManager(accessToken, empDesignation, empId);
            const dataArray = JSON.parse(response?.data);
            setAllClaims(dataArray);
            setLoading(false);
            // console.log(dataArray)
        })();
    }, []);
    return (
        <>
            <div className='view-all-claims container-fluid'>
                <div className="row mt-3 mb-4">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12 mb-3">
                        <BackButton title={"View All Claims"} />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-2" style={{ textAlign: '-webkit-right' }}>
                        <DownloadTableExcel
                            filename="View All Claims"
                            sheet="Expense Claim"
                            currentTableRef={tableRef.current}
                        >
                            <span style={{ textAlign: 'right' }} >
                                <CommonButton title={"Download"} width={"7rem"} />
                            </span>
                        </DownloadTableExcel>
                    </div>
                </div>
                <div className="markAttendance_section container-fluid">
                    <div className="row mb-5">
                        <Card className="monthlyClaim_mobile">
                            <Card.Body>
                                <div
                                    id="table-to-xls"
                                    className="table-wrapper-scroll-y custom-scrollbar"
                                >
                                    <table
                                        className="w-100"
                                        style={{ textAlign: "center" }}
                                        ref={tableRef}
                                    >
                                        <thead>
                                            <tr className="ladder_heading" >
                                                <th className="custom-header">Expense Date</th>
                                                <th className="custom-header">Transaction Date</th>
                                                <th className="custom-header">Employee Id</th>
                                                <th className="custom-header">Employee Name</th>
                                                <th className="custom-header">Department</th>
                                                <th className="custom-header">Claim Type</th>
                                                <th className="custom-header">Claim Sub Type</th>
                                                <th className="custom-header">Amount</th>
                                                <th className="custom-header">Zone</th>
                                                <th className="custom-header">Head Quarter</th>
                                                {/* <th className="custom-header">View Attachment</th> */}
                                                <th className="custom-header">Status</th>
                                                <th className="custom-header">Pending From</th>
                                                <th className="custom-header">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allClaims?.map((claim, index) => {
                                                let transactionDate = claim.Name.split(" - ")[0];
                                                let expenseDate;
                                                // let expenseDate = claim.From_Date__c && claim.To_Date__c ? `${claim.From_Date__c} - ${claim.To_Date__c}` : claim.Date__c;
                                                if (claim.From_Date__c && claim.To_Date__c) {
                                                    expenseDate = `${formatDateStringDDMMYYYY(claim.From_Date__c)} To ${formatDateStringDDMMYYYY(claim.To_Date__c)}`;
                                                } else if (claim.Date__c) {
                                                    expenseDate = formatDateStringDDMMYYYY(claim.Date__c);
                                                }
                                                else if (claim.DADate__c) {
                                                    expenseDate = formatDateStringDDMMYYYY(claim.DADate__c);
                                                }
                                                else if (claim.TA_Depature_Date__c && claim.TA_Arrival_Date__c) {
                                                    expenseDate = `${formatDateStringDDMMYYYY(claim.TA_Depature_Date__c)} To ${formatDateStringDDMMYYYY(claim.TA_Arrival_Date__c)}`;
                                                } else {
                                                    expenseDate = "-";
                                                }
                                                let claimSubType;
                                                let amount;

                                                switch (claim.Allowance_Type__c) {
                                                    case "Travel Allowance":
                                                        claimSubType = claim.Travel_allowance_Type__c;
                                                        amount = claim.TA_Claim_Amount__c;
                                                        break;
                                                    case "Daily Allowance":
                                                        claimSubType = claim.Daily_Allowance_Type__c;
                                                        amount = claim.Daily_Allowance__c;
                                                        break;
                                                    case "Local Conveyance":
                                                        claimSubType = claim.Local_Conveyance_Type__c;
                                                        amount = claim.GE_Claim_Amount__c;
                                                        break;
                                                    case "General Expense":
                                                        claimSubType = claim.Type_of_General_Expense__c;
                                                        amount = claim.GE_Claim_Amount__c;
                                                        break;
                                                    default:
                                                        claimSubType = "-";
                                                        amount = "-";
                                                        break;
                                                }

                                                return (
                                                    <tr key={index} className="ladder_heading_data">
                                                        <td className='pl-3'>{expenseDate}</td>
                                                        <td>{formatDateStringDDMMYYYY(transactionDate)}</td>
                                                        <td>{claim.Contact__r?.EmpId__c}</td>
                                                        <td>{claim.Contact__r?.Name}</td>
                                                        <td>{claim.Contact__r?.Division__c}</td>
                                                        <td>{claim?.Allowance_Type__c}</td>
                                                        <td>{claimSubType}</td>
                                                        <td>{amount}</td>
                                                        <td>{claim.Contact__r?.Zone__c}</td>
                                                        <td>{claim.Contact__r?.HQ__c}</td>
                                                        {/* <td>-</td> */}
                                                        <td>{claim?.Status__c}</td>
                                                        <td>{claim?.Expense_Claim_Approval__r?.Name}</td>
                                                        <td>{claim?.Claim_Rejection_Reason__c}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default ViewAllClaims