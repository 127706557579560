import React, { useEffect, useState } from "react";
import "./Header.css";
import { getFormatedDate } from "./functions";
import { Link, useNavigate } from "react-router-dom";
import AttendanceModal from "./../Common/AttendanceModal";
import LogoutModal from "./LogoutModal";
import Profile from "./../assets/images/MR_2.jpg";
import CommonButton from "./CommonButton";
import SortIcon from '@mui/icons-material/Sort';
import Hester from './../assets/images/Hester.jpg';
import RegularizationModal from './RegularizationModal'
import getRegularizationsApi from '../services/getRegularizationsApi';
import { connect, useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';


const Header = ({ sidebar, setSidebar }) => {
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [allDates, setAllDates]=useState([])

  const [formatedDate, setFormatedDate] = useState(getFormatedDate(new Date()));
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);


  const handleAttendanceModal = async () => {
    setShowAttendanceModal(!showAttendanceModal);
    const records = await getRegularizationsApi(accessToken, empId,EmployeeId);
    const test= records.data.records;
    const dates = test.map(record => record.Date__c);
    const regularizationId = records?.data?.records[0]?.Id
    localStorage.setItem("regularizationId",regularizationId)
    setAllDates(dates)
  };

  const handleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const handleDateChange = (value) => {
    setFormatedDate(value);
  };
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);

  const datesArray = [];

  for (let i = 3; i >= 0; i--) {
    let date = new Date();
    let tempDate = date.setDate(date.getDate() - i);
    datesArray.push({
      id: Math.random(),
      date: getFormatedDate(new Date(tempDate)),
    });
  }
  const toogleSearch = () => {
    if (searchValue === "") {
      setToggleSearch(!toggleSearch);
    }
  };
  return (
    <div className="header-main" style={{ height: "6rem" }}>
      <div className="header">
        <div className="header_left">
          <span
            className="medicine-icon"
            onClick={() => {
              setSidebar(!sidebar);
            }}
          >
            <MenuIcon className="medicine-icon"/>
          </span>
          <span>
            <Link to={"/home"}>
              <img src={Hester} className="HeaderProfile" />
            </Link>
          </span>
          <span className="date">{formatedDate}</span>
          {/* <span
            onClick={handleAttendanceModal}
            style={{ marginLeft: "1rem", display: "inline-flex" }}
          >
            <CommonButton title={"Regularization"} />
          </span> */}
        </div>
        <div className="header_right">
          

          
        </div>
        <LogoutModal
          showModal={showLogoutModal}
          handleClose={handleLogoutModal}
        />
        {/* <AttendanceModal
          showModal={showAttendanceModal}
          handleClose={handleAttendanceModal}
          dates={datesArray}
          handleDateChange={handleDateChange}
          modalText="Change Date"
        /> */}
        {/* <RegularizationModal
        showModal={showAttendanceModal}
        handleClose={handleAttendanceModal}
        regularizations={allDates}
        dates={allDates}
        /> */}
      </div>
    </div>
  );
};

export default Header;
