import React, { useState, useEffect } from "react";
import BackButton from "../Common/BackButton";
import FailButton from "../Common/FailButton";
import "./DCRSurvey.css";
import CommonButton from "../Common/CommonButton";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createFeedback } from '../services/feedback/createFeedback'
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';
import { getAllEmpListApi } from "../services/feedback/getAllEmpLIstApi";
import SearchableDropdown from "../Common/SearchableDropdown";



const FeedbackForm = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const managername = useSelector((state) => state?.userDetails?.details?.Employee_Name__c);
  const employeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [rating6, setRating6] = useState(0);
  const [rating7, setRating7] = useState(0);
  const [rating8, setRating8] = useState(0);
  const [rating9, setRating9] = useState(0);
  const [rating10, setRating10] = useState(0);
  const [rating11, setRating11] = useState(0);
  const [rating12, setRating12] = useState(0);
  const [question7, setQuestion7] = useState("");
  const [question8, setQuestion8] = useState("");
  const [nextDate, setNextDate] = useState('');
  const [mrSelectionError, setMrSelectionError] = useState("");
  const [inputErrors, setInputErrors] = useState({
    rating1: "",
    rating2: "",
    rating3: "",
    rating4: "",
    rating5: "",
    rating6: "",
    rating7: "",
    rating8: "",
    rating9: "",
    rating10: "",
    rating11: "",
    rating12: "",
    question7: "",
    question8: ""
  });

  const [MRData, setMRData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [mrChange, setMrChange] = useState([]);
  const [mrNameChange, setMrChangeName] = useState([])

  const handleMrChange = (selectedValue) => {
    const selectedCustomer = MRData.find(customer => customer.MRName === selectedValue);
    if (selectedCustomer) {
      setMrChange(selectedCustomer.MRId);
      setMrChangeName(selectedCustomer.MRName);
      setMrSelectionError("");
    } else {
      setMrSelectionError("Please select a valid MR");
    }
  };

  const handleRatingChange1 = (event, newValue1) => {
    setRating1(newValue1);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating1: newValue1 !== 0 ? '' : prevErrors.rating1
    }));
  };

  const handleRatingChange2 = (event, newValue2) => {
    setRating2(newValue2);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating2: newValue2 !== 0 ? '' : prevErrors.rating2
    }));
  };

  const handleRatingChange3 = (event, newValue3) => {
    setRating3(newValue3);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating3: newValue3 !== 0 ? '' : prevErrors.rating3
    }));
  };

  const handleRatingChange4 = (event, newValue4) => {
    setRating4(newValue4);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating4: newValue4 !== 0 ? '' : prevErrors.rating4
    }));
  };

  const handleRatingChange5 = (event, newValue5) => {
    setRating5(newValue5);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating5: newValue5 !== 0 ? '' : prevErrors.rating5
    }));
  };

  const handleRatingChange6 = (event, newValue6) => {
    setRating6(newValue6);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating6: newValue6 !== 0 ? '' : prevErrors.rating6
    }));
  };

  const handleQuestion7 = (e) => {
    setQuestion7(e.target.value);
    setInputErrors(prevErrors => ({ ...prevErrors, question7: "" }));
  }

  const handleRatingChange7 = (event, newValue7) => {
    setRating7(newValue7);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating7: newValue7 !== 0 ? '' : prevErrors.rating7
    }));
  };

  const handleRatingChange8 = (event, newValue8) => {
    setRating8(newValue8);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating8: newValue8 !== 0 ? '' : prevErrors.rating8
    }));
  };

  const handleRatingChange9 = (event, newValue9) => {
    setRating9(newValue9);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating9: newValue9 !== 0 ? '' : prevErrors.rating9
    }));
  };

  const handleRatingChange10 = (event, newValue10) => {
    setRating10(newValue10);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating10: newValue10 !== 0 ? '' : prevErrors.rating10
    }));
  };

  const handleRatingChange11 = (event, newValue11) => {
    setRating11(newValue11);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating11: newValue11 !== 0 ? '' : prevErrors.rating11
    }));
  };

  const handleRatingChange12 = (event, newValue12) => {
    setRating12(newValue12);
    setInputErrors(prevErrors => ({
      ...prevErrors,
      rating12: newValue12 !== 0 ? '' : prevErrors.rating12
    }));
  };

  const handleQuestion8 = (e) => {
    setQuestion8(e.target.value);
    setInputErrors({ ...inputErrors, question8: "" });
  }

  const handleNextDateChange = (e) => {
    setNextDate(e.target.value)
    setInputErrors(prevErrors => ({
      ...prevErrors,
      nextDate: ''
    }));
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllEmpListApi(accessToken, employeeId, designation);
        const dataArray = JSON.parse(response?.data);
        if (response?.status === 200 && response?.data) {
          const extractedData = dataArray.map(record => ({
            MRName: record.Name,
            MRId: record.Id
          }));
          setMRData(extractedData);
          setLoading(false);
        } else {
          console.error("Bad request:", response);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    })();
  }, [employeeId, accessToken,designation]);

  const MRNames = MRData.map(MR => MR.MRName);
  // const MRId = MRData.map(MRid => MRid.MRId)

  const handleSubmit = async () => {
    if (!mrChange || mrChange.length === 0) {
      setMrSelectionError("Please select MR");
      return;
    }
    const ratingErrors = {};
    if (designation === "ASM" || designation === "ABM") {
      if (rating1 === 0) ratingErrors.rating1 = "Please provide a rating for Visual AID Detailing";
      if (rating2 === 0) ratingErrors.rating2 = "Please provide a rating for Pre Call Activity";
      if (rating3 === 0) ratingErrors.rating3 = "Please provide a rating for Technical Knowledge of the VSE";
      if (rating4 === 0) ratingErrors.rating4 = "Please provide a rating for Selling Skills of the VSE";
      if (rating5 === 0) ratingErrors.rating5 = "Please provide a rating for Relationship With Customer";
      if (rating6 === 0) ratingErrors.rating6 = "Please provide a rating for Overall Strategy Implementation";
      if (nextDate.trim() === "") ratingErrors.nextDate = "Please select a feedback date";
    } else if (designation === "ZSM") {
      if (rating7 === 0) ratingErrors.rating7 = "Please provide a rating for Overall Strategy Implementation";
      if (rating8 === 0) ratingErrors.rating8 = "Please provide a rating for Relationship with customer, of ASM";
      if (rating9 === 0) ratingErrors.rating9 = "Please provide a rating for Query handling by ASM";
      if (rating10 === 0) ratingErrors.rating10 = "Please provide a rating for VSE development by ASM";
      if (rating11 === 0) ratingErrors.rating11 = "Please provide a rating for Technical Knowledge of ASM";
      if (rating12 === 0) ratingErrors.rating12 = "Please provide a rating for Per customer output of VSE";
      if (nextDate.trim() === "") ratingErrors.nextDate = "Please select a feedback date";
    }

    if (Object.keys(ratingErrors).length > 0) {
      setInputErrors(ratingErrors);
      return;
    }
    try {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const formattedDate = `${dd}/${mm}/${yyyy}`;
      const name = `${managername} - ${mrNameChange} ${formattedDate}`;
      const response = await createFeedback(
        accessToken,
        name,
        rating1,
        rating2,
        rating3,
        rating4,
        rating5,
        rating6,
        rating7,
        rating8,
        rating9,
        rating10,
        rating11,
        rating12,
        question7,
        question8,
        nextDate,
        employeeId,
        mrChange
      );
      if (response?.data.trim() === "No matching Tour Plan record found") {
        toast.error("No matching Tour Plan record found")
      }
      else if(response?.data.trim() === "Feedback Form Created Successfully"){
        toast.success("Feedback submitted successfully");
      }
      else if(response?.data.trim() === "Feedback form has already been submitted for this tour plan"){
        toast.error("Feedback form has already been submitted for this tour plan");
      }
      else{
        toast.error("An error occcured while submitting feedback form")
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const getThreeDaysAgo = () => {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 7);
    return threeDaysAgo.toISOString().split('T')[0];
  };
  return (
    <>
      <div style={{ margin: "0 auto" }} className="feedback-form-container">
        <div className="row mr-3 mb-3 mt-3">
          <BackButton title={"Feedback Form"} />
        </div>

        <SearchableDropdown
          value="Doctor Selection"
          options={MRNames}
          handleDropdownChange={handleMrChange}
        />

        {/* <CommonDropdown
          defaultOption="MR Selection"
          value="Doctor Selection"
          options={MRNames}
          handleDropdownChange={handleMrChange}
        /> */}

        {mrSelectionError && <p style={{ color: 'red' }}>{mrSelectionError}</p>}

        {(designation === "ASM" || designation === "ABM") && (
          <>
            <div className="row mt-2" style={{ paddingLeft: "4px" }}>
              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Feedback Date *</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={nextDate}
                  min={getThreeDaysAgo()}
                  // min={new Date().toISOString().split('T')[0]}

                  onChange={(e) => {
                    handleNextDateChange(e);
                  }}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />
                {inputErrors.nextDate && <p style={{ color: 'red' }}>{inputErrors.nextDate}</p>}

              </div>

              <div className="col-md-6">

                <p className="mt-4 PlanMTP_leftHead"> Visual AID Detailing	*</p>
                <Rating
                  name="half-rating"
                  value={rating1}
                  className="text-center mt-0 PlanMTP_leftHead ml-0"
                  onChange={handleRatingChange1}
                />
                {inputErrors.rating1 && <p style={{ color: 'red' }}>{inputErrors.rating1}</p>}

              </div>



              <div>

                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> Technical Knowledge of the VSE	 *</p>
                    <Rating
                      name="half-rating"
                      value={rating3}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange3}
                    />
                    {inputErrors.rating3 && <p style={{ color: 'red' }}>{inputErrors.rating3}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> Selling Skills of the VSE *</p>
                    <Rating
                      name="half-rating"
                      value={rating4}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange4}
                    />
                    {inputErrors.rating4 && <p style={{ color: 'red' }}>{inputErrors.rating4}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Relationship With Customer	*</p>
                    <Rating
                      name="half-rating"
                      value={rating5}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange5}
                    />
                    {inputErrors.rating5 && <p style={{ color: 'red' }}>{inputErrors.rating5}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Overall Strategy Implementation *</p>
                    <Rating
                      name="half-rating"
                      value={rating6}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange6}
                    />
                    {inputErrors.rating6 && <p style={{ color: 'red' }}>{inputErrors.rating6}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Pre Call Activity	 *</p>
                    <Rating
                      name="half-rating"
                      value={rating2}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange2}
                    />
                    {inputErrors.rating2 && <p style={{ color: 'red' }}>{inputErrors.rating2}</p>}

                  </div>
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Any other remarks </p>
                    <input
                      className="CommonDropdown form-select"
                      type="text"
                      style={{
                        width: "100%",
                        color: "rgb(46, 49, 146)",
                        fontSize: "14px",
                        backgroundImage: "none",
                        paddingRight: "14px",
                      }}
                      onChange={(e) => {
                        handleQuestion7(e);
                      }}
                    />
                    {inputErrors.question7 && <p style={{ color: 'red' }}>{inputErrors.question7}</p>}
                  </div>

                </div>
              </div>


            </div>

          </>
        )}

        {designation === "ZSM" && (
          <>
            <div className="row mt-2" style={{ paddingLeft: "4px" }}>
              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Feedback Date *</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={nextDate}
                  // min={new Date().toISOString().split('T')[0]}
                  min={getThreeDaysAgo()}
                  onChange={(e) => {
                    handleNextDateChange(e);
                  }}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />
                {inputErrors.nextDate && <p style={{ color: 'red' }}>{inputErrors.nextDate}</p>}

              </div>

              <div className="col-md-6">

                <p className="mt-4 PlanMTP_leftHead"> Overall Strategy Implementation	*</p>
                <Rating
                  name="half-rating"
                  value={rating7}
                  className="text-center mt-0 PlanMTP_leftHead ml-0"
                  onChange={handleRatingChange7}
                />
                {inputErrors.rating7 && <p style={{ color: 'red' }}>{inputErrors.rating7}</p>}

              </div>



              <div>

                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> Query handling by ASM *</p>
                    <Rating
                      name="half-rating"
                      value={rating9}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange9}
                    />
                    {inputErrors.rating9 && <p style={{ color: 'red' }}>{inputErrors.rating9}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> VSE development by ASM *</p>
                    <Rating
                      name="half-rating"
                      value={rating10}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange10}
                    />
                    {inputErrors.rating10 && <p style={{ color: 'red' }}>{inputErrors.rating10}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Technical Knowledge of ASM	*</p>
                    <Rating
                      name="half-rating"
                      value={rating11}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange11}
                    />
                    {inputErrors.rating11 && <p style={{ color: 'red' }}>{inputErrors.rating11}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Per customer output of VSE *</p>
                    <Rating
                      name="half-rating"
                      value={rating12}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange12}
                    />
                    {inputErrors.rating12 && <p style={{ color: 'red' }}>{inputErrors.rating12}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Relationship with customer, of ASM	*</p>
                    <Rating
                      name="half-rating"
                      value={rating8}
                      className="text-center mt-0 PlanMTP_leftHead ml-0s"
                      onChange={handleRatingChange8}
                    />
                    {inputErrors.rating8 && <p style={{ color: 'red' }}>{inputErrors.rating8}</p>}

                  </div>
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Any other remarks </p>
                    <input
                      className="CommonDropdown form-select"
                      type="text"
                      style={{
                        width: "100%",
                        color: "rgb(46, 49, 146)",
                        fontSize: "14px",
                        backgroundImage: "none",
                        paddingRight: "14px",
                      }}
                      onChange={(e) => {
                        handleQuestion8(e);
                      }}
                    />
                    {inputErrors.question8 && <p style={{ color: 'red' }}>{inputErrors.question8}</p>}
                  </div>

                </div>
              </div>


            </div>

          </>
        )}

        {(designation === "RSM" || designation === "RBM" || designation === "SM") && (
          <>
            <div className="row mt-2" style={{ paddingLeft: "4px" }}>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Feedback Date *</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={nextDate}
                  // min={new Date().toISOString().split('T')[0]}
                  min={getThreeDaysAgo()}
                  onChange={(e) => {
                    handleNextDateChange(e);
                  }}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />
                {inputErrors.nextDate && <p style={{ color: 'red' }}>{inputErrors.nextDate}</p>}

              </div>

              <div className="col-md-6">

                <p className="mt-4 PlanMTP_leftHead"> Overall Strategy Implementation	*</p>
                <Rating
                  name="half-rating"
                  value={rating7}
                  className="text-center mt-0 PlanMTP_leftHead ml-0"
                  onChange={handleRatingChange7}
                />
                {inputErrors.rating7 && <p style={{ color: 'red' }}>{inputErrors.rating7}</p>}

              </div>



              <div>

                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> Query handling by ASM *</p>
                    <Rating
                      name="half-rating"
                      value={rating9}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange9}
                    />
                    {inputErrors.rating9 && <p style={{ color: 'red' }}>{inputErrors.rating9}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead"> VSE development by ASM *</p>
                    <Rating
                      name="half-rating"
                      value={rating10}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange10}
                    />
                    {inputErrors.rating10 && <p style={{ color: 'red' }}>{inputErrors.rating10}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Technical Knowledge of ASM	*</p>
                    <Rating
                      name="half-rating"
                      value={rating11}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange11}
                    />
                    {inputErrors.rating11 && <p style={{ color: 'red' }}>{inputErrors.rating11}</p>}
                  </div>

                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Per customer output of VSE *</p>
                    <Rating
                      name="half-rating"
                      value={rating12}
                      className="text-center mt-0 PlanMTP_leftHead ml-0"
                      onChange={handleRatingChange12}
                    />
                    {inputErrors.rating12 && <p style={{ color: 'red' }}>{inputErrors.rating12}</p>}
                  </div>

                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Relationship with customer, of ASM	*</p>
                    <Rating
                      name="half-rating"
                      value={rating8}
                      className="text-center mt-0 PlanMTP_leftHead ml-0s"
                      onChange={handleRatingChange8}
                    />
                    {inputErrors.rating8 && <p style={{ color: 'red' }}>{inputErrors.rating8}</p>}

                  </div>
                  <div className="col-md-6 ">
                    <p className="mt-4 PlanMTP_leftHead">Any other remarks </p>
                    <input
                      className="CommonDropdown form-select"
                      type="text"
                      style={{
                        width: "100%",
                        color: "rgb(46, 49, 146)",
                        fontSize: "14px",
                        backgroundImage: "none",
                        paddingRight: "14px",
                      }}
                      onChange={(e) => {
                        handleQuestion8(e);
                      }}
                    />
                    {inputErrors.question8 && <p style={{ color: 'red' }}>{inputErrors.question8}</p>}
                  </div>

                </div>
              </div>


            </div>

          </>
        )}

        <div className="row" style={{ marginTop: '3rem', marginBottom: '1rem' }}>
          <div className="col-md-6 text-center">
            <span className="leaveApply_button" onClick={handleSubmit}>
              <CommonButton title={"Save"} />
            </span>
          </div>
          <div className="col-md-6 text-center cancelBtn_mob">
            <Link to={"/home"}>
              <FailButton buttonText={"Cancel"} style={{ width: '100% !important' }} />
            </Link>
          </div>
        </div>

        {loading && <Loader />}
        <ToastContainer />
      </div>
    </>
  );
};

export default FeedbackForm;
