import React from "react";
import Modal from "react-bootstrap/Modal";
import { FormControl, FormControlLabel, Radio } from "@mui/material";
import { useState, useEffect } from "react";
import CommonButton from "./CommonButton";
import ClockGif from "./../assets/images/clock.gif";
import { signInOutApi } from "../services/signInOutApi";
import { useSelector, connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AttendanceModal.css';
import { signInAction } from "../Redux/actions/signInAction";

const AttendanceModal = ({
  showModal,
  handleClose,
  dates,
  handleDateChange,
  modalText,
  // setHasLoggedOut,
  setIsSignIn,
  signInAction
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const userId = useSelector((state) => state?.userDetails?.details?.Id);
  const empName = useSelector((state) => state?.userDetails?.details?.Employee_Name__c);
  const blankRecordId = useSelector((state) => state?.blankRecordId?.customId);
  const formatDate = (date) => {
    return date.toISOString().slice(0, 10);
  };

  const formatTime24Hour = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes are less than 10
    return hours + ':' + minutes;
  };

  // Usage
  const Login = formatTime24Hour(currentDateTime);


  const handleShowModal = () => {
    handleClose();
  };
  const handleInputChange = (e) => {
    setSelectedDate(new Date(e.target.value));
  };

  const onSubmit = async () => {
    // const lastExecutionDate = localStorage.getItem('customIdActionLastExecution');
    // const today = new Date();
    // const todayDate = today.toISOString().slice(0, 10);

    // Check if CustomIdAction has already been executed today
    // const hasCustomIdActionRunToday = lastExecutionDate === todayDate;
    // Check if CustomIdAction has already been executed
    // const hasCustomIdActionRun = localStorage.getItem('customIdActionExecuted');
    // if (!hasCustomIdActionRun) {
    //   const result = await CustomIdAction(accessToken, empId);
    //   console.log(result)
    //   localStorage.setItem('customIdActionExecuted', true);
    //   localStorage.setItem('customIdActionLastExecution', todayDate);
    //   handleDateChange(selectedDate.toISOString().slice(0, 10));
    //   handleClose();
    //   await signInOutApi(blankRecordId, accessToken, empName, Login, '', empId);
    //   localStorage.setItem('isSignedIn', true);
    //   const currentDate = formatDate(currentDateTime);
    //   localStorage.setItem('currentDate', currentDate);
    // }
    // else {
    // Proceed with other actions
    handleDateChange(selectedDate.toISOString().slice(0, 10));
    handleClose();
    setIsSignIn(true)
    // console.log(blankRecordId)
    const response = await signInAction(blankRecordId, accessToken, empName, Login, '', empId, userId);
    localStorage.setItem("isMarked", true)
    localStorage.setItem('isSignedIn', true);
    const currentDate = formatDate(currentDateTime);
    localStorage.setItem('currentDate', currentDate);
    toast.success('Attendance marked successfully!');
    if (response?.data?.data?.trim() === `Log in time already set for ${empName}`) {
      localStorage.setItem("firstSignIn", true)
    }
    // }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 86400000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleShowModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="AttendanceModal"
        backdrop="static"
      >
        <Modal.Body className="show-grid">
          <div className="text-center">
            <img src={ClockGif} style={{ height: "8rem", width: "10rem" }} alt="image1" />
          </div>
          <h4 className="text-center mb-3">{modalText}</h4>
          <div
            onChange={(e) => {
              handleInputChange(e);
            }}
          >
            {/* <div style={{ marginLeft: "3.5rem" }}>
              <FormControl>
                <FormControlLabel
                  value={formatDate(currentDateTime)}
                  control={<Radio />}
                  label={formatDate(currentDateTime)}
                  checked={formatDate(selectedDate) === formatDate(currentDateTime)}
                />
              </FormControl>
            </div> */}
            <div onClick={onSubmit} className="text-center">
              <CommonButton width={"7rem"} title={"Submit"} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

// export default AttendanceModal;
export default connect(null, {
  signInAction
})(AttendanceModal);
