import MarkAttendance from "../Components/MarkAttendance";
import Header from "../Common/Header";
import BottomNavbar from "../Common/BottomNavbar";
import { Helmet } from 'react-helmet';

const MarkAttendancePage = () => {
  return (
    <>
    <Helmet>
        <title>Mark Attendance</title>
      </Helmet>
      {/* <Header /> */}
      <MarkAttendance />
      {/* <BottomNavbar /> */}
    </>
  );
};

export default MarkAttendancePage;
