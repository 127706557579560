import React, { useState, useRef, useEffect } from "react";
import CommonDropdown from "../Common/CommonDropdown";
import BackButton from "../Common/BackButton";
import FailButton from "../Common/FailButton";
import "./DCRSurvey.css";
import CommonButton from "../Common/CommonButton";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Rating from '@mui/material/Rating';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCustomerDataDcrApi, getCustomersApi } from "../services/customers/getCustomersApi";
import { useSelector } from "react-redux";
import { createDcr, createOtherTypeDcr } from '../services/dcr/createDcr'
import Loader from "../Common/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { getPromotionalInputs } from "../services/dcr/getPromotionalInputsApi"
import { actualBudgetApiTokenApi } from "../services/actualBudgetApiTokenApi";
import { sampleInput } from "../services/dcr/sampleInput";
import Form from "react-bootstrap/Form";

const DCRSurveyForm = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const employeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const repoertsToid = useSelector((state) => state?.userDetails?.details?.ReportsToId);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);
  const userIds = useSelector((state) => state?.userDetails?.details?.Id);


  const [selectedSample, setSelectedSample] = useState(['']);
  const [selectedPob, setSelectedPob] = useState("");
  const [selectedRemark, setSelectedRemark] = useState("");
  const [selectedProduct, setSelectProduct] = useState("");
  const [SelectedStartTime, setSelectedStartTime] = useState(null);
  const [selectPromotional, setSelectPromotional] = useState(['']);
  const [value, setValue] = useState(0);
  const [date, setDate] = useState("");
  const [sampleInputs, setSampleInputs] = useState(['']);
  const [inputs, setInputs] = useState(['']);
  const fileInputRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [pobError, setPobError] = useState('');
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [nextDate, setNextDate] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [customerName, setCustomerName] = useState();
  const [photoError, setPhotoError] = useState();
  let { doctorId } = useParams();
  const [sampleQtyError, setSampleQtyError] = useState('');
  const [inputQtyError, setInputQtyError] = useState('');
  const [freezDate, setFreezDate] = useState();
  const [fileName, setFileName] = useState();
  const [fileType, setFileType] = useState();
  const [imageData, setImageData] = useState();
  const [customerActivity, setCustomerActivity] = useState('');
  const [fieldWork, setFieldWork] = useState(false);
  const navigate = useNavigate();
  const [showfeedbackModal, setShowFeedbackModal] = useState(false);
  const [promotionalInputs, setPromotionalInputs] = useState([]);
  const [sampleName, setSampleName] = useState([]);

  // const customersDataId = doctorId.split(':')[0];
  const handleCustomerChange = (selectedValue) => {
    const selectedCustomer = customersData?.find(customer => customer.contactName === selectedValue);
    if (selectedCustomer) {
      setSelectedCustomerId(selectedCustomer.customerId);
    }
  };


  const handleStartTimeChange = (e) => {
    const value = e.target.value;
    setSelectedStartTime(value);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      SelectedStartTime: ''
    }));
  };

  const handleProductDiscuss = (e) => {
    const value = e.target.value;
    setSelectProduct(value);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      selectedProduct: ''
    }));
  }

  const handleSampleChange = (e, index) => {
    const  value  = e?.target?.value
    const newSelectedSamples = [...selectedSample];
    newSelectedSamples[index] = value;
    setSelectedSample(newSelectedSamples);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      selectedSample: ''
    }));
    const itemNo = value?.split(' - ')[0];
    const sample = sampleName?.find(sample => sample?.itemNo === itemNo);
    if (value === "Not Sample Given") {
      const newInputs = [...sampleInputs];
      newInputs[index] = 0; // Set quantity to 0
      setSampleInputs(newInputs);
    }
    else if (sample) {
      const newInputs = [...sampleInputs];
      newInputs[index] = sample.issuedQuantity || 0; // Set quantity to the issuedQuantity
      setSampleInputs(newInputs);
    }  else  if (value === "Select Sample") {
      const newInputs = [...sampleInputs];
      newInputs[index] = ''; // Set quantity to 0
      setSampleInputs(newInputs);
    }
  }

  const handleSampleQty = (index, e) => {
    const newInputs = [...sampleInputs];
    const value = e.target.value;
    newInputs[index] = value;
    setSampleInputs(newInputs);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      sampleInputs: ''
    }));
  };

  const handleSampleAddLine = () => {
    const newInputs = [...sampleInputs, ''];
    setSampleInputs(newInputs);
  };

  const handleSampleDeleteLine = (index) => {
    const newInputs = [...sampleInputs];
    newInputs.splice(index, 1);
    setSampleInputs(newInputs);
  };

  const handlePromotionalChange = (index, value) => {
    const newSelectedinputs = [...selectPromotional];
    newSelectedinputs[index] = value;
    setSelectPromotional(newSelectedinputs);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      selectPromotional: ''
    }));
    if (value === "Not Promotional Input Given") {
      const newInputs = [...inputs];
      newInputs[index] = 0;
      setInputs(newInputs);
    }
    else {
      const newInputs = [...inputs];
      newInputs[index] = '';
      setInputs(newInputs);
    }
  };
  useEffect(() => {
    const fetchPromotionalInputs = async () => {
      try {
        const response = await getPromotionalInputs(employeeId, accessToken);
        setPromotionalInputs(response.data.records);
      } catch (error) {
        console.log("error occured")
      }
    };

    fetchPromotionalInputs();
  }, [employeeId, accessToken]);

  const dropdownOptions = promotionalInputs?.map(record => (
    ` ${record.Item_No__c} : ${record.Description_2__c} - ${record.Description__c}`
  ));

  dropdownOptions.push("Not Promotional Input Given");

  const handleInputChange = (index, e) => {
    const newInputs = [...inputs];
    const value = e.target.value;
    newInputs[index] = value;
    setInputs(newInputs);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      inputs: ''
    }));
  };

  const handleAddLine = () => {
    const newInputs = [...inputs, ''];
    setInputs(newInputs);
  };

  const handleDeleteLine = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handlePobChange = (e) => {
    const value = e.target.value;
    setSelectedPob(value);
  }

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setPobError('Please enter only numbers.');
    } else {
      setPobError('');
    }
  };

  const handleSampleQtyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setSampleQtyError('Please enter only numbers.');
    } else {
      setSampleQtyError('');
    }
  };

  const handleinputQtyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setInputQtyError('Please enter only numbers.');
    } else {
      setInputQtyError('');
    }
  };

  const handleRemarkChange = (e) => {
    setSelectedRemark(e.target.value);
  }
  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      value: ''
    }));
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDate(value);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      date: ''
    }));
  }

  const handleNextDateChange = (e) => {
    setNextDate(e.target.value)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
        setPhotoError('Please select a JPG or PNG file.');
        return;
      }
    }
    if (file) {
      setPhotoError("")
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        const imageData = base64String?.split(',')[1];
        const imageType = file?.type;
        const imageExtension = imageType?.split('/').pop().toLowerCase()
        setImageData(imageData);
        setFileType(imageExtension);
        // console.log(imageExtension);
        setFileName(file.name)
        // console.log("File name:", file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoIconClick = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    const errors = {};
    if (!date) {
      errors.date = "Visited Date is required *.";
    }
    if (!SelectedStartTime) {
      errors.SelectedStartTime = "Visit time is required *.";
    }
    if (!selectedProduct) {
      errors.selectedProduct = "Product Discuss is required *.";
    }
    // if (!selectedSample.some(input => input !== '')) {
    //   errors.selectedSample = "Sample is required *.";
    // }
    // if (!sampleInputs.some(input => input !== '')) {
    //   errors.sampleInputs = "Sample quantity is required *.";
    // }
    if (!selectPromotional.some(input => input !== '')) {
      errors.selectPromotional = "Promotional input is required *.";
    }
    if (!inputs.some(input => input !== '')) {
      errors.inputs = "Promotional quantity is required *.";
    }
    if (!value) {
      errors.value = "Rating is required *.";
    }
    return errors;
  };
  const [showSaveButton, setShowSaveButton] = useState(true);
  const handleSubmit = async () => {
    // const Newdate = new Date();
    // const todayDate = Newdate.toISOString().slice(0, 10);
    if (customerActivity === "Customer Engagement Activity" || customerActivity === "Seminar/Conference" || customerActivity === "Admin Work") {
      const response = await createOtherTypeDcr(accessToken, employeeId, selectedRemark, date, imageData, fileType, userIds, customerActivity);
      if (response?.data === "No matching record found in Tour Plan") {
        setLoading(false)
        toast.error("No matching record found in Tour Plan");
      }
      else if (response?.data === "DCR is already filled for this customer") {
        setLoading(false)
        toast.error("DCR is already filled for this customer");
        setShowSaveButton(false)
      }
      else if (response?.data === "DCR is already filled") {
        setLoading(false)
        toast.error("DCR is already filled");
        setShowSaveButton(false)
      }
      else if (response?.status === 200) {
        setLoading(false);
        toast.success("Form submitted successfully");
        setShowSaveButton(false)
      }
    }
    else {
      const errors = validateForm();
      setFormErrors(errors);
      let finalEmployeeId = selectedCustomerId;
      if (Object.keys(errors).length === 0) {
        const combinedSamples = sampleInputs.map((quantity, index) => {
          if (selectedCustomerId) {
            finalEmployeeId = selectedCustomerId;
          }
          const selectedSamples = selectedSample[index];
          return `${selectedSamples}:${quantity}`;
        }).join(", ");
        const combinedPromotionals = inputs.map((quantity, index) => {
          const selectedPromotionals = selectPromotional[index];
          return `${selectedPromotionals}:${quantity}`;
        }).join(", ");
        setLoading(true)
        const numericValue = parseInt(selectedPob, 10);
        // console.log(accessToken, employeeId, finalEmployeeId, SelectedStartTime, selectedProduct, combinedSamples, combinedPromotionals, selectedPob, selectedRemark, value, nextDate, date)
        const response = await createDcr(accessToken, employeeId, finalEmployeeId, SelectedStartTime, selectedProduct, combinedSamples, combinedPromotionals, numericValue, selectedRemark, value, nextDate, date, imageData, fileType, repoertsToid, userIds);
        if (response?.data === "No matching record found in Tour Plan") {
          setLoading(false)
          toast.error("Please check: No matching record found in tour plan");
          // navigate('/dcr-survey')
        }
        else if (response?.data === "DCR is already filled for this customer") {
          setLoading(false)
          toast.error("DCR is already filled for this customer");
          setShowSaveButton(false)
        }
        else if (response?.data === "Feedback form was not filled") {
          setLoading(false)
          setShowFeedbackModal(true)
          // toast.error("Feedback form was not filled");
          setShowSaveButton(false)
        }
        else if (response?.status === 200) {
          setLoading(false);
          toast.success("Form submitted successfully");
          setShowSaveButton(false)
          // navigate('dcr-survey');
          // window.location.reload();
          // setSelectedCustomerId(null);
          // setSampleInputs(Array(sampleInputs.length).fill(''));
          // setInputs(Array(inputs.length).fill(''));
          // setSelectProduct(['']);
          // setSelectedStartTime(null);
          // setSelectProduct(null);
          // setSelectedSample(Array(selectedSample.length).fill(''));
          // setSelectPromotional(Array(selectPromotional.length).fill(''));
          // setSelectedRemark('');
          // setValue(null);
          // setDate('');
          // setNextDate(null);
        }
      } else {
        // console.log("Form has validation errors", errors);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // setLoading(false)
    (async () => {
      if (designation !== "VSE" || designation !== "BE") {
        if (doctorId) {
          setLoading(true)
          const customersDataId = doctorId?.split(':')[0];
          const customerResponse = await getCustomerDataDcrApi(customersDataId, accessToken);
          if (customerResponse?.status === 200 && customerResponse?.data) {
            const customerDataId = customerResponse?.data?.records[0]?.Id;
            setSelectedCustomerId(customerDataId)
            setCustomerName(customerResponse?.data?.records[0]?.Name);
            const today = new Date()?.toISOString()?.split('T')[0];
            setDate(today);
            setLoading(false)
          }
        }
        setLoading(false)
      }
      else if (designation === "VSE" || designation !== "BE") {
        setLoading(true)
        const response = await getCustomersApi(empId, accessToken);
        if (response?.status === 200 && response?.data) {
          const extractedData = response?.data.map(record => ({
            contactName: record.Name,
            customerId: record.Id,
          }));
          setCustomersData(extractedData);
          if (doctorId) {
            // console.log(doctorId)
            const [Id, Date__c] = doctorId?.split(':');
            const customer = extractedData?.find(customer => customer.customerId === doctorId || customer.customerId === Id || customer.customerId === Id);
            if (customer) {
              setSelectedCustomerId(customer.customerId);
            } else {
              console.log("Customer not found. Please select a customer name.");
            }
            const doctorName = customer ? customer.contactName : null;
            setCustomerName(doctorName)
            setCustomerActivity("Field Work");
            setLoading(false)
            if (Date__c) {
              setDate(Date__c);
              setFreezDate(Date__c);
            }
            else {
              const today = new Date().toISOString()?.split('T')[0];
              setDate(today);
            }
          }
          else {
            const today = new Date().toISOString()?.split('T')[0];
            setDate(today);
            setSelectedCustomerId('')
            setCustomerName('')
            setFreezDate('')
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      }
      else {
        const today = new Date().toISOString()?.split('T')[0];
        setDate(today);
        setSelectedCustomerId('')
        setCustomerName('')
        setFreezDate('');
        setLoading(false)
      }
    })();
    // if (Date__c) {
    //   setDate(Date__c);
    //   setFreezDate(Date__c)
    // }
    // }
    // else {
    //   const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    //   setDate(today);
    // }

  }, [empId, accessToken, doctorId, customerName, designation]);
  // console.log(freezDate)

  const contactNames = customersData.map(customer => customer.contactName);
  // console.log(contactNames)
  const getThreeDaysAgo = () => {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 7);
    return threeDaysAgo?.toISOString()?.split('T')[0];
  };

  const handleActivityChange = (value) => {
    if (value === "Field Work") {
      setFieldWork(true);
      setCustomerActivity(value);
    }
    else {
      setFieldWork(false);
    }
    setCustomerActivity(value);
  };

  const handleFbloneModal = () => {
    setShowFeedbackModal(!showfeedbackModal);
  };

  useEffect(() => {
    (async () => {
      try {
        const responseToken = await actualBudgetApiTokenApi(accessToken);
        const response = await sampleInput(empId, responseToken);
        const values = response?.data?.value?.[0]?.materialissuelineAPIs;
        console.log("response", values);
        setSampleName(values);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [accessToken, empId]);  // Added dependencies to useEffect


  return (
    <>
      <div className="container-fluid">
        <div className="row pt-3">
          <div className="col-lg-8 col-md-8 col-sm-6 col-12">
            <BackButton title={"DCR Survey"} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            {/* {(designation !== "VSE" || designation !== "BE") && (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="text-center tourPlanNameBtn mr-2">
                    <Link to="/dcr-all-list">
                      <CommonButton title={"MR DCR List"} width={"9rem"} />
                    </Link>
                  </div>
                  <div className="text-center tourPlanNameBtn ml-2">
                    <Link to="/dcr-manager-list">
                      <CommonButton title={"DCR List"} width={"9rem"} />
                    </Link>
                  </div>
                </div>
              </>)} */}

            {(designation === "VSE" && division === "PET") ? (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="text-center tourPlanNameBtn mr-2">
                    <Link to="/dcr-all-list">
                      <CommonButton title={"MR DCR List"} width={"9rem"} />
                    </Link>
                  </div>
                  <div className="text-center tourPlanNameBtn ml-2">
                    <Link to="/dcr-manager-list">
                      <CommonButton title={"My DCR List"} width={"9rem"} />
                    </Link>
                  </div>
                </div>
              </>)
              : (designation === "VSE" || designation === "BE") ? "" :
                (<>
                  <div className="d-flex justify-content-end ">
                    <div className="text-center tourPlanNameBtn mr-2">
                      <Link to="/dcr-all-list">
                        <CommonButton title={"MR DCR List"} width={"9rem"} />
                      </Link>
                    </div>
                    <div className="text-center tourPlanNameBtn ml-2">
                      <Link to="/dcr-manager-list">
                        <CommonButton title={"My DCR List"} width={"9rem"} />
                      </Link>
                    </div>
                  </div>
                </>)}
          </div>
        </div>
        {!doctorId && (
          <>
            <p className="mt-4 PlanMTP_leftHead">Type of Tour Plan</p>
            <CommonDropdown
              handleDropdownChange={handleActivityChange}
              className="PlanMTP_leftDrop"
              defaultOption={"Select Tour"}
              selectedOption={customerActivity}
              // disableEdit={tourNo ? true : false}
              options={[
                "Customer Engagement Activity",
                // "Field Work",
                "Seminar/Conference",
                "Admin Work",
              ]}
            />
          </>
        )}

        {(fieldWork || doctorId) && (
          <>
            <p className="mt-4 PlanMTP_leftHead">Doctor's Name *</p>
            {customerName ? (
              <input
                type="text"
                className="CommonDropdown form-select"
                value={customerName}
                readOnly
                onChange={() => { }}
                style={{
                  width: "100%",
                  color: "rgb(46, 49, 146)",
                  fontSize: "14px",
                  backgroundImage: "none",
                  paddingRight: "14px",
                }}
              />
            ) : (
              <CommonDropdown
                defaultOption="Doctor Selection"
                value="Doctor Selection"
                options={contactNames}
                handleDropdownChange={handleCustomerChange}
              />
            )}
            <div className="row mt-2" style={{ paddingLeft: "4px" }}>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Visited Date</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={date}
                  readOnly={freezDate ? true : false}
                  min={getThreeDaysAgo()}
                  onChange={(e) => {
                    handleDateChange(e);
                  }}
                  max={new Date()?.toISOString()?.split('T')[0]}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />
                {formErrors?.date && (
                  <span className="dcr-form-errors">{formErrors?.date}</span>
                )}
              </div>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Enter Visit Time *</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => {
                    handleStartTimeChange(e)
                  }}
                />
                {formErrors?.SelectedStartTime && (
                  <span className="dcr-form-errors">{formErrors?.SelectedStartTime}</span>
                )}
              </div>

              <div>
                {sampleInputs?.map((input, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-6 ">
                      <p className="mt-4 PlanMTP_leftHead">Select Sample</p>
                      <Form.Select
                        aria-label="Default select example"
                        className="CommonDropdown PlanMTP_leftDrop"
                        value={selectedSample[index] || ""}
                        name="claimType"
                        onChange={(e) => handleSampleChange(e, index)}
                        style={{ fontFamily: 'GilroyMedium' }}
                      >
                        <option value={"Select Sample"} style={{ fontFamily: 'GilroyMedium' }}>Select Sample</option>
                        {sampleName?.map((option) => {
                          return <option value={`${option.itemNo} - ${option.description}`} style={{ fontFamily: 'GilroyMedium' }}>{option.itemNo} - {option.description}</option>;
                        })}
                        <option value={"Not Sample Given"} style={{ fontFamily: 'GilroyMedium' }}>Not Sample Given</option>
                      </Form.Select>
                      {/* {formErrors?.selectedSample && (
                        <span className="dcr-form-errors">{formErrors?.selectedSample}</span>
                      )} */}
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-6 col-12">
                      <p className="mt-4 PlanMTP_leftHead">Enter Quantity for Samples</p>
                      <div style={{ display: 'flex' }}>
                        <input
                          className="CommonDropdown form-select"
                          type="number"
                          style={{
                            width: "100%",
                            color: "rgb(46, 49, 146)",
                            fontSize: "14px",
                            backgroundImage: "none",
                            paddingRight: "14px",

                          }}
                          value={input}
                          onChange={(e) => handleSampleQty(index, e)}
                          onKeyPress={handleSampleQtyPress}
                        />
                        {index === 0 ? (
                          <span className="ml-2" style={{ cursor: 'pointer' }}>
                            <AddIcon title={"Add"} width={'5rem'} style={{ fontSize: 30, color: '#00a87e' }} onClick={handleSampleAddLine} />
                          </span>
                        ) : (
                          <span className="ml-2" style={{ cursor: 'pointer' }}>
                            <DeleteIcon title={"Delete"} width={'5rem'} style={{ fontSize: 30, color: '#00a87e' }} onClick={() => handleSampleDeleteLine(index)} />
                          </span>
                        )}
                      </div>
                      {/* {formErrors?.sampleInputs && (
                        <span className="dcr-form-errors">{formErrors?.sampleInputs}</span>
                      )} */}
                      {sampleQtyError && <span className="dcr-form-errors">{sampleQtyError}</span>}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                {inputs?.map((input, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-6">
                      <p className="mt-4 PlanMTP_leftHead">Select Promotional inputs *</p>
                      <CommonDropdown
                        value={selectPromotional[index] || ""}
                        handleDropdownChange={(value) => handlePromotionalChange(index, value)}
                        className="PlanMTP_leftDrop"
                        defaultOption="Select Promotional Inputs"
                        options={dropdownOptions}
                      />
                      {formErrors?.selectPromotional && (
                        <span className="dcr-form-errors">{formErrors?.selectPromotional}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-6 col-12">
                      <p className="mt-4 PlanMTP_leftHead">Enter Quantity for the Promotional Inputs *</p>
                      <div style={{ display: 'flex' }}>
                        <input
                          className="CommonDropdown form-select"
                          type="number"
                          style={{
                            width: "100%",
                            color: "rgb(46, 49, 146)",
                            fontSize: "14px",
                            backgroundImage: "none",
                            paddingRight: "14px",

                          }}
                          value={input}
                          onChange={(e) => handleInputChange(index, e)}
                          onKeyPress={handleinputQtyPress}
                        />
                        {index === 0 ? (

                          <span className="ml-2" style={{ cursor: 'pointer' }}>
                            <AddIcon title={"Add"} width={'5rem'} style={{ fontSize: 30, color: '#00a87e' }} onClick={handleAddLine} />
                          </span>
                        ) : (
                          <span className="ml-2" style={{ cursor: 'pointer' }}>
                            <DeleteIcon title={"Delete"} width={'5rem'} style={{ fontSize: 30, color: '#00a87e' }} onClick={() => handleDeleteLine(index)} />
                          </span>
                        )}
                      </div>
                      {formErrors?.inputs && (
                        <span className="dcr-form-errors">{formErrors?.inputs}</span>
                      )}
                      {inputQtyError && <span className="dcr-form-errors">{inputQtyError}</span>}
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Enter Product Discuss *</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",

                  }}
                  onChange={(e) => {
                    handleProductDiscuss(e);
                  }}
                />
                {formErrors?.selectedProduct && (
                  <span className="dcr-form-errors">{formErrors?.selectedProduct}</span>
                )}
              </div>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Enter POB</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",

                  }}
                  // value={selectedPob}
                  onChange={(e) => {
                    handlePobChange(e);
                  }}
                  onKeyPress={handleKeyPress}
                />
                {pobError && <span className="dcr-form-errors">{pobError}</span>}
              </div>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Next Visit Date</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={nextDate}
                  min={new Date()?.toISOString()?.split('T')[0]}

                  onChange={(e) => {
                    handleNextDateChange(e);
                  }}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />

              </div>

              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Enter Remarks</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",

                  }}
                  onChange={(e) => {
                    handleRemarkChange(e);
                  }}

                />
              </div>

              <div className="col-md-6">
                <div style={{ display: 'flex' }}>
                  <p className="mt-4 PlanMTP_leftHead">Rating *</p>
                  <Rating
                    name="half-rating"
                    value={value}
                    // precision={0.5}
                    className="text-center mt-4 PlanMTP_leftHead ml-4"
                    onChange={handleRatingChange}
                  />
                </div>
                {formErrors?.value && (
                  <span className="dcr-form-errors" style={{ marginBottom: '1rem' }}>{formErrors?.value}</span>
                )}
              </div>

              <div className="col-md-6">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p className="mt-4 PlanMTP_leftHead">Select Photograph</p>
                  <PhotoCameraIcon onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" style={{ color: '#00a87e', fontSize: 30 }} />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                </div>
                <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>
              </div>
            </div>

          </>
        )}
        {!fieldWork && !doctorId && (
          <>
            <div className="row" style={{ marginTop: '1rem', marginBottom: '3rem' }}>
              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Select Visited Date</p>
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  name="date"
                  value={date}
                  readOnly={freezDate ? true : false}
                  min={getThreeDaysAgo()}
                  onChange={(e) => {
                    handleDateChange(e);
                  }}
                  max={new Date()?.toISOString()?.split('T')[0]}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                />
                {formErrors?.date && (
                  <span className="dcr-form-errors">{formErrors?.date}</span>
                )}
              </div>
              <div className="col-md-6 ">
                <p className="mt-4 PlanMTP_leftHead">Enter Remarks</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",

                  }}
                  onChange={(e) => {
                    handleRemarkChange(e);
                  }}

                />
              </div>
            </div>
            <div className="row" style={{ marginTop: '1rem', marginBottom: '3rem' }}>
              <div className="col-md-6">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p className="mt-4 PlanMTP_leftHead">Select Photograph</p>
                  <PhotoCameraIcon onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" style={{ color: '#00a87e', fontSize: 30 }} />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                </div>
                <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>
              </div>
            </div>
          </>
        )}
        {showSaveButton && (
          <div className="row" style={{ marginTop: '1rem', marginBottom: '3rem' }}>
            <div className="col-md-6 text-center">
              <span className="leaveApply_button" onClick={handleSubmit}>
                <CommonButton title={"Save"} width={'18rem'} />
              </span>
            </div>
            <div className="col-md-6 text-center cancelBtn_mob">
              <Link to={"/home"}>
                <FailButton buttonText={"Cancel"} style={{ width: '100% !important' }} width={'18rem'} />
              </Link>
            </div>
          </div>
        )}
        {loading && <Loader />}
        <Modal
          show={showfeedbackModal}
          onHide={handleFbloneModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="doctor360Modal_survey RescheduleModal"
        >
          <Modal.Body className="show-grid">
            <div className=" text-center">
              <h4 className="">Feedback Form was not filled.</h4>
              <p style={{ fontWeight: "500" }}>
                Please fill the feedback form.
              </p>
            </div>
            <div
              className="doctor360Modal_survey_button mt-5"
              style={{ alignSelf: "center" }}
            >
              <span style={{ marginRight: '1rem' }}>
                <Link to="/feedback-form">
                  <CommonButton title={"Yes"} width={"7rem"} />
                </Link>
              </span>
              <span onClick={handleFbloneModal}>
                <FailButton buttonText={"No"} width={"7rem"} />
              </span>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default DCRSurveyForm;
