import React from "react";
import { Card } from "react-bootstrap";
import { useState } from "react";
import tablet from "../assets/images/Tablet.jpg";
import "./StockItem.css";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import { Button } from "react-bootstrap";
import { productsArray } from "../Common/Constants";

const StockItems = () => {
  const [Instock, setInstock] = useState([
    {
      name: "AL5Zyme Digestive Liquid Saunf",

      ptr: "114",
      mrp: "190",
      quantity: 0,
      pcs: 456,
      tabimage: { tablet },
    },
    {
      name: "Acicon 40mg Tablet",

      ptr: "157",
      mrp: "204",
      quantity: 0,
      pcs: 350,
      tabimage: { tablet },
    },
    {
      name: "Alcephine 250mg Capsule",

      ptr: "127",
      mrp: "157",
      quantity: 0,
      pcs: 206,
      tabimage: { tablet },
    },
    {
      name: "Bistrepen New Forte Injection",
      ptr: "289",
      mrp: "310",
      quantity: 0,
      pcs: 320,
      tabimage: { tablet },
    },
    {
      name: "Bladmir-S 25 Tablet ER",
      ptr: "27.25",
      mrp: "30.45",
      quantity: 0,
      pcs: 420,
      tabimage: { tablet },
    },
    {
      name: "Dubstran 500mg Tablet",
      ptr: "102",
      mrp: "114",
      quantity: 0,
      pcs: 321,
      tabimage: { tablet },
    },
    {
      name: "Emvex 8mg Tablet",
      ptr: "27.30",
      mrp: "30.75",
      quantity: 0,
      pcs: 20,
      tabimage: { tablet },
    },
    {
      name: "Enblok 2.5mg Tablet",
      ptr: "83.12",
      mrp: "114.07",
      quantity: 0,
      pcs: 5,
      tabimage: { tablet },
    },
  ]);
  let incNum = (index) => {
    let tempStocks = [...Instock];
    if (tempStocks[index].quantity < tempStocks[index].pcs) {
      tempStocks[index].quantity++;
    }
    setInstock(tempStocks);
  };
  let decNum = (index) => {
    let tempStocks = [...Instock];
    if (tempStocks[index].quantity > 0) {
      tempStocks[index].quantity--;
    }
    setInstock(tempStocks);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="MTP_heading mt-4">
          <div className="row">
            <div className="mb-3">
              <BackButton title={"Enter POB"} />
            </div>
            {Instock.map((data, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <Card className="TabletCardInner">
                    <Card.Body style={{ padding: "5px" }}>
                      <div className="row">
                        <div className="col-4 text-center">
                          <img
                            src={productsArray[index].img}
                            alt=""
                            style={{ height: "5rem" }}
                          />
                        </div>
                        <div className="col-8 TabbletDetail">
                          <h6>{productsArray[index].name}</h6>
                          <div className="TabletPrice">
                            <p className="mr-4">
                              PTR:{" "}
                              <span
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                &#x20b9; {data.ptr}
                              </span>{" "}
                            </p>
                            <p>
                              MRP:{" "}
                              <span
                                style={{ color: "black", fontWeight: "700" }}
                              >
                                &#x20b9; {data.mrp}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="IncrementCounter mt-4">
                        <div>
                          <p className="m-0"> Available Pcs</p>
                          <span style={{ color: "black", fontWeight: "700" }}>
                            {data.pcs} pcs
                          </span>
                        </div>
                        <div>
                          <div className="row">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <button
                                  className="shadow-none btn"
                                  type="button"
                                  onClick={() => decNum(index)}
                                >
                                  -
                                </button>
                              </div>
                              <input
                                type="text"
                                className="shadow-none form-control"
                                value={data.quantity}
                                // onChange={handleChange}
                                style={{
                                  width: "3rem",
                                  textAlign: "center",
                                  border: "2px solid",
                                  borderRadius: "7px",
                                }}
                              />
                              <div className="input-group-prepend">
                                <button
                                  className="shadow-none btn "
                                  type="button"
                                  onClick={() => incNum(index)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
            <div className="row mt-3 mb-5">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 rcpa_Submitbtn">
            <CommonButton title={"Submit POB"} width={"15rem"} />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12 rcpa_cancelbtn">
            <Button
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  border: "0",
                  backgroundColor: "#4ad84a",
                  borderRadius: "20px",
                  background: "rgb(225, 219, 219)",
                  color: "black",
                  width: "15rem",
                }}
              >
                Cancel
              </Button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockItems;
