import React, { useState } from "react";
import Calendar from "moedim";
import { Card } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CommonDropdown from "../Common/CommonDropdown";
import "./CreateMTP.css";
import SuccessButton from "../Common/SuccessButton";
import FailButton from "../Common/FailButton";
import MTPModal from "./MTP/MTPModal";
import { deleteIcon } from "../Common/Icons";

const MTP = () => {
  const [value, setValue] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [mtpData, setMtpData] = useState([
    {
      Type: "Doctor",
      Name: "",
      Speciality: "Cardiology",
      VisitFrequency: "Weekly",
    },
  ]);
  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleAddMtp = () => {
    let tempMtp = [
      ...mtpData,
      {
        Type: "Doctor",
        Name: "",
        Speciality: "Cardiology",
        VisitFrequency: "Weekly",
      },
    ];
    setMtpData(tempMtp);
  };

  const handleDeleteMtp = (index) => {
    let tempMtp = [...mtpData];
    tempMtp.splice(index, 1);
    setMtpData(tempMtp);
  };

  return (
    <div>
      <div className="conatiner m-5">
        <div className="row">
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col-10">
                  <h5 className="mb-4">Create New MTP Form</h5>
                </div>
                <div className="col-2">
                  <div
                    onClick={handleClose}
                    className="addNewVisit_filterIcon"
                    style={{ textAlign: "right" }}
                  >
                    <FilterAltIcon className="filter_icon" fontSize="large" />
                  </div>
                </div>
              </div>

              <table className="w-100 CreateMTP_table">
                <tr className="mb-5">
                  <th>Type </th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Speciality</th>
                  <th>Visit Frequency</th>
                </tr>
                {mtpData.map((MTP, index) => {
                  return (
                    <tr>
                      <td>
                        <CommonDropdown
                          defaultOption={MTP.Type}
                          options={["Doctor", "Pharmacy"]}
                        />
                      </td>
                      <td style={{ width: "10rem" }}>
                        <input></input>
                      </td>
                      <td style={{ width: "10rem" }}>
                        <input type="date"></input>
                      </td>
                      <td>
                        <CommonDropdown
                          defaultOption={MTP.Speciality}
                          options={["Orthopedics", "Cardiology", "Nephrology"]}
                        />
                      </td>
                      <td>
                        <CommonDropdown
                          defaultOption={MTP.VisitFrequency}
                          options={["Daily", "Weekly", "Monthly"]}
                        />
                      </td>
                      {mtpData.length > 1 && (
                        <td
                          className="delete_icon"
                          onClick={() => handleDeleteMtp(index)}
                        >
                          {deleteIcon}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </table>
              <div className="row">
                {/* <div className="col-lg-2 col-md-2"></div> */}
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <SuccessButton buttonText={"Save"} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <FailButton buttonText={"Cancel"} />
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 col-4"
                  onClick={handleAddMtp}
                >
                  <SuccessButton buttonText={"Add MTP"} />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <MTPModal showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default MTP;
