import HamburgerMenuMobile from "../Components/HamburgerMenu";

const HamburgerMenu = ({ setSidebar, sidebar }) => {
  return (
    <>
      <HamburgerMenuMobile setSidebar={setSidebar} sidebar={sidebar} />
    </>
  );
};

export default HamburgerMenu;
