import { Card } from "react-bootstrap";
import banner1 from "../../assets/images/owl1.png";
import "./CampaignsLeft.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Helmet } from 'react-helmet';

const CampaignsLeft = () => {
  return (
    <>
    <Helmet>
        <title>Campaign</title>
      </Helmet>
      <div className="container mt-3">
        <div className="campaignsLeft_image">
          <img src={banner1} alt="" />
        </div>
        <div className=" mt-3">
          <Card>
            <Card.Body>
              <h5>EyeCareWeCare</h5>
              <div className="row campaignLeft_timeAndLocation">
                <div className="col-6">
                  <CalendarMonthIcon /> 07:00 am , 31 July 2023
                </div>
                <div className="col-6">
                  <LocationOnIcon />  Pharma HQ Andheri East
                </div>
              </div>
              <div className="mt-2">
                <p>
                  Eyecarewecare has been created specifically for key stage 2
                  children and aims to partner teachers and Eye Care
                  Professionals together to improve knowledge of eye health in
                  an innovative and fun way for children.
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CampaignsLeft;
