import axios from "axios";
import { configs } from "../../config";

export const getApproveViewAllTpForMnApi = async (TPSrNo, tourDate,tourEmpId, Place, accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/GetTPForViewAllDetailsMR`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data:{
        MREmpId : tourEmpId,
        TPSrNo: TPSrNo,
        TPdate: tourDate,
        Place: Place
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };