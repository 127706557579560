import React, { useEffect, useState } from 'react';
import { feedbackAverageDashboard } from "../services/home/getFeedbackDataApi";
import { useSelector } from "react-redux";

function FeedbackData({ accessToken, EmployeeId }) {
  const [feedbackData, setFeedbackData] = useState([]);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

  useEffect(() => {
    (async () => {
      try {
        const response = await feedbackAverageDashboard(accessToken, EmployeeId);
        setFeedbackData(response?.data || []); // Handle the case where data might be undefined
      } catch (error) {
        console.error("Error fetching feedback data", error);
      }
    })();
  }, [accessToken, EmployeeId]);

  const formatMonthYear = (input) => {
    const [year, month] = input.split('-');
    return `${month} ${year}`;
  };
  return (
    <div>
      <div className="container table-wrapper-scroll-y custom-scrollbar">
        {/* <table className="text-center w-100">
          <thead>
            <tr className="ladder_heading">
              <th className='custom-header pl-3'>Month</th>
              <th className="custom-header">MR Name</th>
              <th className="custom-header">VSE Development By ASM Dev</th>
              <th className="custom-header">Overall Strategy Implementation</th>
              <th className="custom-header">Overall Strategy Implementation ASM Dev</th>
              <th className="custom-header">Per Customer Output Of VSE ASM Dev</th>
              <th className="custom-header">Pre-call Activity</th>
              <th className="custom-header">Query Handling By ASM Dev</th>
              <th className="custom-header">Relationship with Customer</th>
              <th className="custom-header">Relationship With Customer ASM Dev</th>
              <th className="custom-header">Selling Skills</th>
              <th className="custom-header">Technical Knowledge</th>
              <th className="custom-header">Technical Knowledge Of ASM Dev</th>
              <th className="custom-header">Visual Aid Detailing</th>
            </tr>
          </thead>
          <tbody>
            {feedbackData.length > 0 ? (
              feedbackData.map((feedback, index) => (
                <tr key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                  <td className='pl-3'>{feedback.month}</td>
                  <td>{feedback.mrName}</td>
                  <td>{feedback.VSEDevelopmentByASMASMDev}</td>
                  <td>{feedback.overallStrategyImplementation}</td>
                  <td>{feedback.overallStrategyImplementationASMDev}</td>
                  <td>{feedback.perCustomerOutputOfVSEASMDev}</td>
                  <td>{feedback.preCallActivity}</td>
                  <td>{feedback.queryHandlingByASMASMDev}</td>
                  <td>{feedback.relationshipWithCustomer}</td>
                  <td>{feedback.relationshipWithCustomerASMDev}</td>
                  <td>{feedback.sellingSkillsOfTheVSE}</td>
                  <td>{feedback.technicalKnowledgeOfTheVSE}</td>
                  <td>{feedback.technicalKnowledgeOfASMASMDev}</td>
                  <td>{feedback.visualAIDDetailing}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className='text-center mt-5 mb-5'>No data available</td>
              </tr>
            )}
          </tbody>
        </table> */}

        <table className="text-center w-100">
          <thead>
            <tr className="ladder_heading">
              <th className='custom-header pl-3'></th>
              {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                <th key={index} className='custom-header pl-3'>
                  <div >{formatMonthYear(feedback.month)}</div>
                </th>
              )) : <th colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 custom-header pl-3'>No Month</th>}
            </tr>

          </thead>
          {designation === "VSE" && (
            <tbody>
              <tr>
                <td className='ladder_heading_data'>Overall Strategy Implementation</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.overallStrategyImplementation}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Pre-call Activity</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.preCallActivity}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Relationship with Customer</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.relationshipWithCustomer}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Selling Skills</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.sellingSkillsOfTheVSE}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Technical Knowledge</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.technicalKnowledgeOfTheVSE}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Visual Aid Detailing</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.visualAIDDetailing}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>

            </tbody>
          )}
          {designation === "ASM"  && (
            <tbody>
              <tr>
                <td className='ladder_heading_data'>VSE Development</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.VSEDevelopmentByASMASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Overall Strategy Implementation</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.overallStrategyImplementationASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Query Handling</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.queryHandlingByASMASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Relationship With Customer ASM</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.relationshipWithCustomerASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Technical Knowledge</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.technicalKnowledgeOfASMASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
              <tr>
                <td className='ladder_heading_data'>Per customer output of VSE</td>
                {feedbackData.length > 0 ? feedbackData.map((feedback, index) => (
                  <td key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                    {feedback.perCustomerOutputOfVSEASMDev}
                  </td>
                )) : <td colSpan={feedbackData.length + 1} className='text-center mt-5 mb-5 ladder_heading_data'>-</td>}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

export default FeedbackData;
