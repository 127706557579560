import React from "react";
import MTPComp from "../Components/CreateMTP";
import Header from "../Common/Header";

const MTP = () => {
  return (
    <div>
      {/* <Header title={"Create New MTP"} isShowDate={false} /> */}
      <MTPComp />
    </div>
  );
};

export default MTP;
