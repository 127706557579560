import React from "react";
import Modal from "react-bootstrap/Modal";
import GaugeChart from "./GaugeChart";
import CommonButton from "./CommonButton";

const LoginModalPopUp = ({
  showModal,
  handleClose,
  modalHeading,
  modalContent,
  actualValue,
  targetValue,
  yearlyaActualValue,
  yearlyTargetValue
}) => {
  const handleShowModal = () => {
    localStorage.setItem("loginModal", false);
    handleClose();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey login-modal-popup"
    >
      <Modal.Body className="show-grid login-modal-popup">
        <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <GaugeChart
            values={[targetValue, actualValue]}
            title={"MTD"}
            P1={"Monthly target"}
            P2={"Target achieved"}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <GaugeChart
            values={[yearlyTargetValue, yearlyaActualValue]}
            title={"YTD"}
            P1={"Yearly target"}
            P2={"Target achieved"}
          />
        </div>
        </div>
        <div className="row text-center mt-3">
          <h6>{modalContent}</h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="doctor360Modal_survey_button">
        <div className="row w-100" style={{ textAlign: "center" }}>
          <div className="col-3"></div>
          <div
            className="col-6"
            onClick={handleShowModal}
            style={{ marginLeft: "-5.5px" }}
          >
            <CommonButton
              title={"Accept"}
              height={"2rem"}
              width={"100%"}
              variant="contained"
              color="success"
              className="doctorName_button"
            />
          </div>
          <div className="col-3"></div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModalPopUp;
