import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./DoughnutChart.css";
const DoughnutChart = () => {
  const state = {
    labels: ["Completed Appointments", "Pending Appointments", "New Requests",  "Others"],
    datasets: [
      {
        data: [2000, 3000, 750, 300 ],
        backgroundColor: ["#0A4D68", "#088395", "#05BFDB", "#00FFCA"],
        hoverBorderColor: ["#0A4D68", "#088395", "#05BFDB", "#00FFCA"],
        hoverBackgroundColor: ["#0A4D68", "#088395", "#05BFDB", "#00FFCA"],
        hoverBorderWidth: 8,
        offset: 30,
        borderRadius: 10,
      },
    ],
    Legend: {
      position: 'right'
    }
  };
  const options = {
    cutoutPercentage: 60,
    responsive: true,
    legend: {
      display: true,
      position: "right",
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    //custom tooltip
    tooltips: {
      callbacks: {
        title: (items, data) => {
          let x = Math.ceil(
            data.datasets[items[0].datasetIndex].data[items[0].index]
          );
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        label: (items, data) => data.labels[items.index],
      },

      backgroundColor: "#FFF",
      borderColor: "rgb(0,0,0)",
      titleFontSize: 14,
      titleFontColor: "#000",
      bodyFontColor: "#000",
      bodyFontSize: 10,
      displayColors: false,
    },
  };
  return (
    <div className="chart">
      <h2>Number of Appointments</h2>
      <Doughnut data={state} options={options} />
      
    </div>
  );
};
ChartJS.register(ArcElement, Tooltip, Legend);
export default DoughnutChart;
