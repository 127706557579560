import React from 'react';
import Elearning1 from '../Components/Elearning/Elearning1';
import BackButton from '../Common/BackButton';

const Elearning = () => {
  return (
    <div className='container mt-4'>
        <BackButton title={'E-Learning'} />
      <Elearning1 />
    </div>
  )
}

export default Elearning
