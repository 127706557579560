import React from "react";
import "./Module.css";
import svg1 from "./../assets/images/modules/campaign.svg";
import svg2 from "./../assets/images/modules/dashboards.svg";
import svg3 from "./../assets/images/modules/eDetailing.svg";
import svg4 from "./../assets/images/modules/expense-claim.svg";
import svg5 from "./../assets/images/modules/leaves.svg";
import svg6 from "./../assets/images/modules/monthly-tour-plan.svg";
import svg7 from "./../assets/images/modules/report.svg";
import svg8 from "./../assets/images/modules/attendance.svg";
import svg9 from "./../assets/images/modules/customers.svg";
import svg10 from "./../assets/images/modules/input-inventory.svg";
import svg11 from "./../assets/images/modules/DCR.svg";
import { Link } from "react-router-dom";

const Module = () => {
  const modules = [
    {
      image: svg2,
      title: "Dashboard",
      link: "/home",
    },
    {
      image: svg8,
      title: "Attendance Details",
      link: "/markAttendance",
    },
    {
      image: svg6,
      title: "New Tour plan",
      link: "/monthlyTour",
    },
    {
      image: svg1,
      title: "Campaigns",
      link: "/campaign",
    },
    {
      image: svg9,
      title: "Customers",
      link: "/customers",
    },
    {
      image: svg4,
      title: "Expense Claim",
      link: "/expenseClaim",
    },
    {
      image: svg3,
      title: "eDetailing",
      link: "/eDetailing",
    },
    {
      image: svg10,
      title: "Input Inventory",
      link: "/inputInventory",
    },
    {
      image: svg5,
      title: "Leaves",
      link: "/leaves",
    },
    {
      image: svg11,
      title: "DCR Survey",
      link: "/dcr-survey",
    },
    {
      image: svg7,
      title: "Reports",
      link: "/reports",
    },
  ];
  return (
    <div className="container">
      <div className="module_mainHeading mt-5">
        <h3 className="module_heading mb-5">
          <span>Modules</span>
        </h3>
        <div className="row new-icons">
          {modules.map((module) => {
            return (
              <div className="col-lg-2 col mt-4 ">
                <Link className="module_link" to={module.link}>
                  <div className="icon-boxme">
                    <img src={module.image} />
                    <h5>{module.title}</h5>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Module;
