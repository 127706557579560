import CreateEmployeeComponent from "../Components/CreateEmployeeComponent";

const CreateEmployee = () => {
    return (
      <>
        <CreateEmployeeComponent />
      </>
    );
  };
  
  export default CreateEmployee;
  