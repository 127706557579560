import React from "react";
import CommonDropdown from "./../../Common/CommonDropdown";
import Modal from "react-bootstrap/Modal";
import SuccessButton from "./../../Common/SuccessButton";
import FailButton from "./../../Common/FailButton";

const MTPModal = ({ showModal, handleClose }) => {
  const handleShowModal = () => {
    handleClose();
  };
  const filters = [
    {
      name: "Territory",
      drop: "Select Territory",
      options : ["Karnataka", "Odisha", "Chandigarh","Gujrat","Rajasthan","Maharashtra","West Bengal","Kerala","Assam","Meghalaya","Goa","Tripura","Punjab","Haryana","Nagaland"],
    },
    {
      name: "Activity",
      drop: "Select Activity",
      options : ["Representation of Firm","Product Promotion", "Feedack about Product", "Selling the Product","Maintaining Reports"],
    },
    {
      name: "Path",
      drop: "Select Path",
      options : [""],
    },
  ];
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey"
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="playlistModal_title"
          id="contained-modal-title-vcenter"
          centered
        >
          MTP Filters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        {filters.map((filter) => {
          return (
            <div className="row mb-2">
              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <p>{filter.name}</p>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                <CommonDropdown
                  defaultOption={filter.drop}
                  options={filter.options}
                />
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <SuccessButton buttonText={"Apply Filters"} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <FailButton buttonText={"Clear Filters"} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="doctor360Modal_survey_button"></Modal.Footer>
    </Modal>
  );
};

export default MTPModal;
