import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import "./TodaysPlan.css";
import { useState } from "react";
import locationIcon from "./../../assets/images/location-59.svg";
import profileIcon from "./../../assets/images/user-3297.svg";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
import clockIcon from "./../../assets/images/circular-wall-clock-black-outline-17714.svg";
import survey from "./../../assets/images/online-survey-icon.svg";
import calendarIcon from "./../../assets/images/calendar-and-check-mark-11072.svg";
import skipClock from "./../../assets/images/circular-wall-clock-black-lineal-17715.svg";
import { Link } from "react-router-dom";
import CommonButton from "../../Common/CommonButton";
import Modal from "react-bootstrap/Modal";
import FailButton from "../../Common/FailButton";
import Loader from "../../Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { rescheduleVisitApi } from "../../services/SYD/rescheduleVisitApi";
import { skipVisitApi } from "../../services/SYD/skipVisitApi";
import { cloneDCRApi } from "../../services/SYD/cloneDCRApi";
import doctorIcon from "../../assets/images/stethoscope-3539.svg";

const DoctorCard = ({ showButtons = true, doctors, fetchDateWise }) => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const managerId = useSelector((state) => state?.userDetails?.details?.Id);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [contactId, setContactId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDCRCloneModal, setShowDCRCloneModal] = useState(false);
  const [showfeedbackModal, setShowFeedbackModal] = useState(false);

  const handleRescheduleModal = () => {
    setShowRescheduleModal(!showRescheduleModal);
  };

  const handleSkipModal = () => {
    setShowSkipModal(!showSkipModal);
  };

  const handleDCRCloneModal = () => {
    setShowDCRCloneModal(!showDCRCloneModal);
  };
  const handleFbloneModal = () => {
    setShowFeedbackModal(!showfeedbackModal);
  };

  const handleRescheduleClick = (Id) => {
    handleRescheduleModal();
    setCustomerId(Id);
  };

  const handleDCRCLoneClick = (customerId, contactId, date) => {
    setCustomerId(customerId);
    setContactId(contactId);
    setSelectedDate(date);
    setShowDCRCloneModal(true);
  };


  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSkipClick = (Id) => {
    handleSkipModal();
    setCustomerId(Id);
  };

  const handleReschedule = async () => {
    setLoading(true);
    const response = await rescheduleVisitApi(
      customerId,
      selectedDate,
      accessToken
    );
    if (response?.status === 204) {
      toast.success("Rescheduled successfully!");
      setShowRescheduleModal(false);
      setLoading(false);
      fetchDateWise();
    } else {
      toast.error("Unable to reschedule");
      setLoading(false);
      showRescheduleModal(false);
    }
  };

  const handleSkip = async () => {
    setLoading(true);
    const response = await skipVisitApi(customerId, accessToken);
    if (response?.status === 204) {
      toast.success("Skiped successfully!");
      setShowSkipModal(false);
      setLoading(false);
      fetchDateWise();
    } else {
      toast.error("Unable to skip");
      setLoading(false);
      showSkipModal(false);
    }
  };

  const handleDCRClone = async () => {
    setLoading(true);
    const response = await cloneDCRApi(customerId, contactId, selectedDate, accessToken, managerId);
    if (response?.status === 200) {
      if (response?.data === "Feedback form was not filled") {
        setLoading(false)
        setShowFeedbackModal(true)
        setShowDCRCloneModal(false);
      }
      else if (response?.data === "Feedback form was not filled / Or DCR for particular customer is not filled if you are trying to clone.") {
        setLoading(false)
        setShowFeedbackModal(true)
        setShowDCRCloneModal(false);
      }
      else if (response?.data === "Please fill DCR Survey first for the tour plan from which you have cloned") {
        toast.error("Please fill DCR Survey first for the tour plan from which you have cloned")
        setShowDCRCloneModal(false);
        setLoading(false);
      }
      else {
        toast.success("DCR cloned successfully");
        setShowDCRCloneModal(false);
        setLoading(false);
        fetchDateWise();
      }
    } else {
      // toast.error("Unable To Clone");
      setLoading(false);
      setShowDCRCloneModal(false);
    }
  };


  return (
    <>
      {doctors.map((doctor) => {
        return (
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {doctor.DocPharmtype !== "Doctor" && (
              <Card className="mt-4 DoctorCard">
                <Card.Body>
                  <div className="DoctorPharm_Name">
                    <span className="DoctorPharm_NameTitle">
                      {doctor?.Customer__r?.Name}
                    </span>
                  </div>
                  <div
                    className="DoctorPharm_Name"
                    style={{ marginTop: "5px" }}
                  >
                    <span className="DoctorPharm_lastVisit">
                      <img
                        src={clockIcon}
                        alt="clock"
                        style={{
                          height: "1rem",
                          marginRight: "5px",
                          marginBottom: "2px",
                        }}
                      />
                      {doctor?.Date__c}
                    </span>
                  </div>
                  <div className="DoctorPharm_Post">
                    <span className="mr-3">
                      <img
                        src={profileIcon}
                        alt="profile"
                        style={{
                          height: "1rem",
                          marginRight: "3px",
                          marginBottom: "2px",
                        }}
                      />
                      {doctor?.Customer__r?.ContactPerson__c}
                    </span>
                    <span>
                      <img
                        src={mobileIcon}
                        alt="mobile"
                        style={{
                          height: "1rem",
                          marginRight: "3px",
                          marginBottom: "2px",
                        }}
                      />
                      {doctor?.Customer__r?.MobileNumber__c}
                    </span>
                  </div>
                  <div className="DoctorPharm_Post">
                    <img
                      src={doctorIcon}
                      alt="location"
                      style={{
                        height: "1rem",
                        marginRight: "3px",
                        marginBottom: "2px",
                      }}
                    />
                    <span>Category: {doctor?.Customer__r?.Designation__c}</span>
                  </div>
                  <div className="DoctorPharm_Post">
                    <img
                      src={locationIcon}
                      alt="location"
                      style={{
                        height: "1rem",
                        marginRight: "3px",
                        marginBottom: "2px",
                      }}
                    />
                    {doctor?.Customer__r?.Address__c},
                    {doctor?.Customer__r?.Pincode__c},
                    <br />
                    <span>
                      {doctor?.Customer__r?.State__c},
                      {doctor?.Customer__r?.Country__c}
                    </span>
                  </div>
                  {showButtons && (
                    <div className="row">
                      <div style={{ marginLeft: "-5.5px" }}>
                        {/* {(designation !== "VSE" && designation !== "BE") && doctor?.Status__c === "Tour Plan Cloned" && (
                          <>

                            <Button
                              className="DoctorPharm_reschedule"
                              style={{
                                marginRight: "7px",
                              }}
                              onClick={() =>
                                handleDCRCLoneClick(
                                  doctor?.Clone_TP_EmpID__c,
                                  doctor?.Customer__c,
                                  doctor?.Date__c
                                )
                              }
                            >
                              <img
                                src={survey}
                                alt="survey"
                                style={{
                                  height: "1rem",
                                  marginRight: "7px",
                                  marginBottom: "3px",
                                  filter: "invert(100%)",
                                }}
                              />
                              Clone DCR
                            </Button>

                          </>
                        )
                        } */}

                        <Link
                          to={`/dcr-survey/${doctor?.Customer__c}:${doctor?.Date__c}`}
                        >
                          <Button
                            className="DoctorPharm_reschedule"
                            style={{
                              marginRight: "7px",
                              fontFamily: 'GilroyMedium'
                            }}
                          >
                            <img
                              src={survey}
                              alt="survey"
                              style={{
                                height: "1rem",
                                marginRight: "7px",
                                marginBottom: "3px",
                                filter: "invert(100%)",
                              }}
                            />
                            DCR Survey
                          </Button>
                        </Link>

                        <Button
                          className="DoctorPharm_reschedule"
                          onClick={() => handleRescheduleClick(doctor?.Id)}
                          style={{ fontFamily: 'GilroyMedium' }}
                        >
                          <img
                            src={calendarIcon}
                            alt="calendar"
                            style={{
                              height: "1rem",
                              marginRight: "7px",
                              marginBottom: "3px",
                            }}
                          />
                          Reschedule
                        </Button>

                        <Button
                          className="DoctorPharm_skip"
                          style={{ marginRight: "5.5px", fontFamily: 'GilroyMedium' }}
                          onClick={() => handleSkipClick(doctor?.Id)}
                        >
                          <img
                            src={skipClock}
                            alt="clock"
                            style={{
                              height: "1rem",
                              marginRight: "5px",
                              marginBottom: "3px",
                            }}
                          />
                          Skip Visit
                        </Button>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        );
      })}

      {/* Reschedule modal */}
      <Modal
        show={showRescheduleModal}
        onHide={handleRescheduleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey RescheduleModal"
      >
        <Modal.Body className="show-grid">
          <div className=" text-center">
            <h4 className="mt-4">Reschedule Visit</h4>
            <p style={{ fontWeight: "500", fontFamily: 'GilroyMedium' }}>
              Are you sure you want to reschedule visit?
            </p>
            <input
              type="date"
              className="rescheduleDate"
              onChange={handleDateChange}
              value={selectedDate}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          className="doctor360Modal_survey_button"
          style={{ alignSelf: "center" }}
        >
          <span onClick={handleReschedule}>
            <CommonButton title={"Reschedule"} width={"10rem"} />
          </span>
        </Modal.Footer>
      </Modal>

      {/* skip modal */}
      <Modal
        show={showSkipModal}
        onHide={handleSkipModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey RescheduleModal"
      >
        <Modal.Body className="show-grid">
          <div className=" text-center">
            <h4 className="">Skip Visit</h4>
            <p style={{ fontWeight: "500", fontFamily: 'GilroyMedium', paddingTop: '2rem' }}>
              Are you sure you want to skip this visit ?
            </p>
          </div>
          <div
            className="doctor360Modal_survey_button mt-5"
            style={{ alignSelf: "center" }}
          >
            <span onClick={handleSkip} style={{ marginRight: '1rem' }}>
              <CommonButton title={"Yes"} width={"7rem"} />
            </span>
            <span onClick={handleSkipModal}>
              <FailButton buttonText={"No"} width={"7rem"} />
            </span>
          </div>
        </Modal.Body>
      </Modal>

      {/* DCR Clone modal */}
      <Modal
        show={showDCRCloneModal}
        onHide={handleDCRCloneModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey RescheduleModal"
      >
        <Modal.Body className="show-grid">
          <div className=" text-center">
            <h4 className="">Clone DCR</h4>
            <p style={{ fontWeight: "500" }}>
              Are you sure you want to Clone DCR ?
            </p>
          </div>
          <div
            className="doctor360Modal_survey_button mt-5"
            style={{ alignSelf: "center" }}
          >
            <span onClick={handleDCRClone} style={{ marginRight: '1rem' }}>
              <CommonButton title={"Yes"} width={"7rem"} />
            </span>
            <span onClick={handleDCRCloneModal}>
              <FailButton buttonText={"No"} width={"7rem"} />
            </span>
          </div>
        </Modal.Body>
      </Modal>

      {/* Feedback form unable modal */}
      <Modal
        show={showfeedbackModal}
        onHide={handleFbloneModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey RescheduleModal"
      >
        <Modal.Body className="show-grid">
          <div className=" text-center">
            <h4 className="">Feedback Form was not filled.</h4>
            <p style={{ fontWeight: "500" }}>
              Please fill the feedback form.
            </p>
          </div>
          <div
            className="doctor360Modal_survey_button mt-5"
            style={{ alignSelf: "center" }}
          >
            <span style={{ marginRight: '1rem' }}>
              <Link to="/feedback-form">
                <CommonButton title={"Yes"} width={"7rem"} />
              </Link>
            </span>
            <span onClick={handleFbloneModal}>
              <FailButton buttonText={"No"} width={"7rem"} />
            </span>
          </div>
        </Modal.Body>
      </Modal>

      {loading && <Loader />}
      <ToastContainer />
    </>
  );
};

export default DoctorCard;
