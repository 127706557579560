import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./CommonSearch.css";
import SearchIcon from '@mui/icons-material/Search';

const CommonSearch = ({ searchPlaceHolder, handleInputChange = () => { } }) => {
  const handleChange = (e) => {
    handleInputChange(e);
  };
  return (
    <>
      <InputGroup className="commonSearch">
        <InputGroup.Text id="basic-addon1"
          style={{
            border: '1px solid #CCCCCC',
            borderRight: 'none',
            fontFamily:'GilroyMedium'
          }}>
          <SearchIcon />
        </InputGroup.Text>
        <Form.Control
          placeholder={searchPlaceHolder}
          aria-label="Search by Specialization"
          aria-describedby="inputGroup-sizing-default"
          onChange={(e) => {
            handleChange(e);
          }}
          style={{
            // borderRadius: '20px',
            border: '2px solid #CCCCCC',
            padding: '10px',
            fontSize: '16px',
            borderLeft: 'none',
            fontFamily:'GilroyMedium'
          }}
        />
      </InputGroup>
    </>
  );
};

export default CommonSearch;
