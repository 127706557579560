import React from 'react'

const LMS = () => {
  return (
    <div className='mt-4'>
      <ul style={{fontSize:'1.2rem'}}>
        <li>Courses and lessons creation tool.</li>
        <li>Assessments creation tool.</li>
        <li>Courses scheduling and management.</li>
        <li>Certificates creation.</li>
        <li>Surveys and polls creation.</li>
        <li>Assigning users to courses.</li>
        <li>Different user roles and multiple access levels.</li>
    </ul>
    </div>
  )
}

export default LMS
