import Button from "react-bootstrap/Button";
import "./FailButton.css";
const FailButton = ({ buttonText, height, width }) => {
  const handleButtonClick = () => {};
  return (
    <Button onClick={handleButtonClick} className="failButton" style={{height:height,width:width}}>
      {buttonText}
    </Button>
  );
};

export default FailButton;
