import React from 'react';
import LMS1 from '../Components/Elearning/LMS';
import BackButton from '../Common/BackButton';

const LMS = () => {
  return (
    <div className='container mt-4'>
        <BackButton title={'Learning Management System'} />
      <LMS1 />
    </div>
  )
}

export default LMS
