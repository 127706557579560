import axios from "axios";
import { configs } from "../../config";

export const getCustomersApi = async (empId, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/GetCustomerDataEmpWise/getCustomerDataEmpWise`,
    method: "post",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      empId: empId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

export const getCustomerDataDcrApi = async (empId, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=select id,name from Customer__c where id = '${empId}'`,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};