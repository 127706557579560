import { Card } from "react-bootstrap";
import banner1 from "../../assets/images/hepatites.jpg";
import "./CampaignsLeft.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const CampaignsLeft = () => {
  return (
    <>
      <div className="container mt-3">
        <div className="campaignsLeft_image">
          <img src={banner1} alt="" />
        </div>
        <div className=" mt-3">
          <Card>
            <Card.Body>
              <h5>Hepatitis Day</h5>
              <div className="row campaignLeft_timeAndLocation">
                <div className="col-6">
                  <CalendarMonthIcon /> 08:00 am , 28 July 2023
                </div>
                <div className="col-6">
                  <LocationOnIcon />  Pharma HQ Andheri East
                </div>
              </div>
              <div className="mt-2">
                <p>
                  There is an effective antiviral drugs available against Hepatitis B and Hepatitis C 
                  will be provided free of cost. Although, the treatment duration for Hepatitis C has been 
                  defined for duration of 12-24 weeks under the programme.
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CampaignsLeft;
