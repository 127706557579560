import { BarGauge } from "devextreme-react/bar-gauge";
import { Card } from "react-bootstrap";
import blue from "./../assets/images/square-4084.svg";
import red from "./../assets/images/square-4084 (1).svg";
import { useEffect } from "react";

const GaugeChart = ({ values, title, P1, P2 }) => {
  const formatNumber = (value) => {
    if (value >= 10000000) {
      return `${(Math.floor(value / 100000) / 100).toFixed(2)} Cr`; 
    } else if (value >= 100000) {
      return `${(Math.floor(value / 1000) / 100).toFixed(2)} L`; 
    } else if (value >= 1000) {
      return `${(Math.floor(value) / 1000).toFixed(2)} K`; 
    }
    return value.toString(); 
  };

  useEffect(() => {
    // console.log('GaugeChart values updated:', values);
  }, [values]);

  const svgStyle = `
    <style>
      .dxb-connector {
        stroke-width: 2px;  
      }
      .connector-blue {
        stroke: #389dff;  
      }
      .connector-red {
        stroke: #FF4500;  
      }
    </style>
  `;

  return (
    <>
      <div>
        <Card>
          <Card.Body style={{ paddingLeft: "0",paddingRight: "0" }}>
            <h5 style={{ fontSize: "1.2rem" }}>{title}</h5>
            <BarGauge
              id="gauge"
              startValue={0}
              endValue={values?.[1] > values?.[0] ? values?.[1] : values?.[0] }  
              values={values}
              style={{ height: "12rem" }}
              palette={['#389dff', '#FF4500']}  
              barSpacing={10}  
              label={{
                visible: true,  
                customizeText: (arg) => formatNumber(arg.value),
                font: {
                  size: 14 
                },
                position: 'outside',  
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: svgStyle }} />
            <p className="m-1" style={{ fontSize: "14px", textAlign: 'left' }}>
              <img src={blue} style={{ height: "1rem", width: "2rem" }} alt="Monthly target" />
              {P1} : {formatNumber(values?.[0])}
            </p>
            <p className="m-1" style={{ fontSize: "14px", textAlign: 'left' }}>
              <img src={red} style={{ height: "1rem", width: "2rem" }} alt="Target achieved" />
              {P2} : {formatNumber(values?.[1])}
            </p>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default GaugeChart;
