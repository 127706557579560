import Header from "../Common/Header";
import EDetailing from "../Components/EDetailing/EDetailing";

const EDetailingPage = () => {
  return (
    <>
      {/* <Header title={"eDetailing"} isShowDate={false} /> */}
      <EDetailing />
    </>
  );
};

export default EDetailingPage;
