import React, { useEffect, useState } from 'react';
import { getDcrAllListApi, getManagerDcrAllListApi } from '../services/getDcrAllListApi';
import { useSelector } from "react-redux";
import BackButton from "../Common/BackButton";
import Loader from '../Common/Loader';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}

function DccrAllList() {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const [dcrList, setDcrList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getDcrAllListApi(empId, accessToken);
      const managerResponse = await getManagerDcrAllListApi(empId,accessToken)
      // console.log(response?.data?.records);
      setManagerList(managerResponse?.data?.records || []);
      setDcrList(response?.data?.records || []);
      setLoading(false);

    })();
  }, [empId, accessToken]);

  return (
    <div>
      <div className="container">
        <div className="row mt-3 mb-4">
          <BackButton title={"MR DCR List"} />
        </div>
        <div className='default-scrollbar'>
        <table className="text-center w-100">
          <thead>
            <tr className="ladder_heading">
              <td className='pl-3 custom-header'>DCR Filled Date</td>
              <td className="custom-header">Customer Name</td>
              <td className="custom-header">MR Name</td>
            </tr>
          </thead>
          <tbody>
            {dcrList.map((record, index) => (
              <tr key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white',fontFamily:'GilroyMedium' }}>
                <td className='pl-3' style={{fontFamily:'GilroyMedium'}}>{formatDate(record.Date__c)}</td>
                <td className='pl-3' style={{fontFamily:'GilroyMedium'}}>{record.Customer__r?.Name || '-'}</td>
                <td className='pl-3' style={{fontFamily:'GilroyMedium'}}>{record.Employee__r?.Name || '-'}</td>
              </tr>
            ))}
            {dcrList.length === 0 && (
              <tr className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                <td className='pl-3'>-</td>
                <td className='pl-3'>-</td>
                <td className='pl-3'>-</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default DccrAllList