import { actualBudget } from "../../services/actualBudget";
import { ACTUAL_BUDGET_API_SUCCESS,ACTUAL_BUDGET_API_FAIL } from "../constants/action-types";

export const actualBudgetAction = (empId, responseToken) => async (dispatch) => {
    // console.log("response usert action callll");
    try {
        const response = await actualBudget(empId, responseToken); // Wait for the Promise to resolve
        // console.log("response usert----------->", response?.data?.value);
        if (response ) {
            dispatch({
                type: ACTUAL_BUDGET_API_SUCCESS,
                payload: response?.data?.value, // Assuming response.data contains the actual data
            });
            return { success: true, data: response.data }; // Return success and data
        } else {
            dispatch({
                type: ACTUAL_BUDGET_API_FAIL,
            });
            return { success: false, error: "Invalid response" }; // Return failure and error message
        }
    } catch (error) {
        // console.log("Network error or other error:", error);
        dispatch({
            type: ACTUAL_BUDGET_API_FAIL,
        });
        return { success: false, error: error.message }; // Return failure and error message
    }
};
