import React, { useState, forwardRef, useEffect } from "react";
import CommonButton from "../../Common/CommonButton";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../Components/Expense/ExpenseClaim.css"
import { toast, ToastContainer } from "react-toastify";
import { balanceApi } from "../../services/reports/balanceApi";
import Loader from "../../Common/Loader";
import * as XLSX from 'xlsx';

const LeaveBalanceReport = forwardRef((props, ref) => {
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const empId = useSelector((state) => state?.userDetails?.details?.Id);

    const [leaveData, setLeaveData] = useState([]);
    const [lodaing, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await balanceApi(accessToken, empId);
                console.log(response)
                setLeaveData(response?.data?.records)
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [accessToken, empId]);

    const handleExport = () => {
        if (!leaveData || leaveData?.length === 0) {
          alert('No data available to export');
          return;
        }
        const filteredData = leaveData.filter(item => item?.contactDesignation !== "VSE" && item?.contactDesignation !== "BE");
    console.log(filteredData)
        // Convert data to worksheet
        const worksheet = XLSX?.utils?.json_to_sheet(filteredData?.map(item => ({
          'Employee Code': item?.EmpId__c,
          'Employee Name': item?.Name,
          'CL': item?.CL__c,
          'PL': item?.PL__c,
          'SL': item?.SL__c,
        })));
    
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Leave Balance");
    
        // Export the workbook
        XLSX.writeFile(workbook, "Leave_Balance_Report.xlsx");
      };
    

    return (
        <>
            <div className="mb-3" style={{ textAlign: '-webkit-right', marginTop: '1rem' }}>
                <span onClick={handleExport}>
                    <CommonButton title={"Download Excel"} />
                </span>
            </div>

            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4 text-center">Leave Balance Report</h4>
                            <div
                                id="table-to-xls"
                                className="table-wrapper-scroll-y custom-scrollbar"
                                style={{ padding: "20px", overflow: "auto" }}
                            >
                                <table style={{ textAlign: "center", width: "100%" }} ref={ref}>
                                    <thead>
                                        <tr className="ladder_heading">
                                            <th className="custom-header">Emp Code</th>
                                            <th className="custom-header">Emp Name</th>
                                            <th className="custom-header">CL</th>
                                            <th className="custom-header">PL</th>
                                            <th className="custom-header">SL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaveData?.map((data, index) => (
                                            <tr key={index} className="ladder_heading_data">
                                                <td className="pl-3">{data?.EmpId__c}</td>
                                                <td className="pl-3">{data?.Name}</td>
                                                <td className="pl-3">{data?.CL__c}</td>
                                                <td className="pl-3">{data?.PL__c}</td>
                                                <td className="pl-3">{data?.SL__c}</td>
                                            </tr >
                                        ))}
                                    </tbody >
                                </table >
                            </div >
                        </Card.Body >
                    </Card >
                    {lodaing && <Loader />}
                </div >
            </div >
        </>
    );
});

export default LeaveBalanceReport;