import axios from "axios";
import { configs } from "../../config";

export const sendForApprovalApi = async (empId, tourSR, TPDate, Place, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/TourPlanApproval`,
    method: "patch",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: empId,
      tourSR: tourSR,
      TPDate: TPDate,
      Place: Place
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
