import Header from "../../Common/Header";
import SingleClaim from "./SingleCalim";
const CreateExpenseClaim = ({ setLoading, getExpenses }) => {
  return (
    <>
      <SingleClaim setLoading={setLoading} getExpenses={getExpenses} />
    </>
  );
};

export default CreateExpenseClaim;
