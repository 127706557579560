import React from "react";
import Header from "./../Common/Header";
import ReportsComp from "./../Components/Reports/Reports";
import { Helmet } from 'react-helmet';

const Reports = () => {
  return (
    <div>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      {/* <Header title={"Reports"} /> */}
      <ReportsComp />
    </div>
  );
};

export default Reports;
