import axios from "axios";
import { configs } from "../../config";

export const balanceApi = async (accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Id,name,EmpId__c,SL__c,PL__c,CL__c,Employee_Status__c FROM Contact where Employee_Status__c = 'Active'`,
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
