import axios from "axios";
import { configs } from "../../config";


export const getExpenseClaimDataForManager = async (accessToken, empId, startDate, endDate, managerId,lastRecordId,limitSize) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/getExpenseClaimData`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            empId: empId,
            startDate: startDate,
            endDate: endDate,
            ManagerId: managerId,
            lastRecordId: lastRecordId ? lastRecordId : "",
            limitSize:limitSize
        }
    };

    try {
        const response = await axios(options);
        return response.data;
    } catch (error) {
        console.error("Error fetching expense claim data:", error);
        throw error; // Optionally re-throw the error
    }
};