import BackButton from "../../Common/BackButton";
import Card from "react-bootstrap/Card";
import "./EnterRcpa.css";
import CommonButton from "../../Common/CommonButton";
import { Button } from "react-bootstrap";
import { useState } from "react";

const EnterRcpa = () => {
  const [rcpaData, setRcpaData] = useState([
    {
      brand: "Omeprazole",
      rcpa: [
        { name: "Amazeo 50mg Tablet", quan: 0 , desc: "Amazeo 50 Tablet is a prescription medicine used in the treatment of schizophrenia, a mental disorder."},
        { name: "Amazeo 200mg Tablet", quan: 0, desc: "belong to the group called 'antipsychotics' primarily used to treat long term (chronic) schizophrenia and postoperative nausea/vomiting" },
        { name: "Amazeo OD 100mg Tablet", quan: 0, desc: "Amazeo OD 100 Tablet 10's belongs to a group of medicines called 'antipsychotics' primarily used to treat long term (chronic) schizophrenia" },
        { name: "Amazeo OD 200mg Tablet", quan: 0 , desc: "Amazeo OD 200 Tablet 10's contains Amisulpride, which helps restore the balance of certain natural substances in the brain"},
      ],
    },
    {
      brand: "Quinapril",
      rcpa: [
        { name: "Ampoxin Dry Syrup", quan: 0,desc: "Ampoxin Dry Syrup 30 ml belongs to the group of antibiotic medicines called penicillins. Ampoxin Dry Syrup 30 ml is composed of two antibiotics, namely: ampicillin and cloxacillin." },
        { name: "Ampoxin 250mg Injection", quan: 0,desc: "AMPOXIN 250MG INJECTION is a combination of Ampicillin and Cloxacillin which belongs to the group of medicines called Penicillin Antibiotics" },
        { name: "Ampoxin 1gm Injection", quan: 0, desc: "AMPOXIN 1GM INJECTION is a combination of Ampicillin and Cloxacillin which belongs to the group of medicines called Penicillin Antibiotics" },
        { name: "Ampoxin 250mg Capsule", quan: 0, desc: "AMPOXIN 250MG CAPSULE is a combination of Ampicillin and Cloxacillin which belongs to the group of medicines called Penicillin Antibiotics" },
      ],
    },
    {
      brand: "Biocon",
      rcpa: [
        { name: "Britzilam 25 Tablet", quan: 0, desc: "Britzilam 25 Tablet is an anti-epileptic medicine used to treat seizures (fits) in epilepsy. It can be used alone or along with other medicines." },
        { name: "Britzilam oral solution", quan: 0, desc: "Britzilam Oral Solution is an antiepileptic medication. It works by attaching itself to specific sites (SV2A) on the surfaces of nerve cells" },
        { name: "Britzilam injection", quan: 0, desc: "BRITZILAM INJECTION contains Brivaracetam, which belongs to the group of medicines called anti-epileptics. It is used to treat epilepsy" },
        { name: "Britzilam 100 tablet", quan: 0, desc: " Britzilam 100 Tablet is an anti-epileptic medicine used to treat seizures (fits) in epilepsy" },
      ],
    },
    {
      brand: "Effexor",
      rcpa: [
        { name: "Olmetor 40mg Tablet", quan: 0, desc: "OLMETOR 40MG TABLET can be considered a drug of choice for high blood pressure. It contains olmesartan which helps to relax the blood vessels" },
        { name: "Olmetor AM 20mg Tablet", quan: 0, desc: "Olmetor AM Tablet is a combination of two medicines: Olmesartan Medoxomil and Amlodipine which lower blood pressure effectively" },
        { name: "Olmetor CH 20mg Tablet", quan: 0, desc: "Olmetor CH 20 Tablet 10's is used to treat hypertension, where Olmesartan medoxomil works by relaxing the blood vessels" },
        { name: "Olmetor H 20mg Tablet", quan: 0, desc: "Olmetor-H Tablet is a combination of two medicines: Hydrochlorothiazide and olmesartan which lower blood pressure effectively" },
      ],
    },
  ]);

  let incNum = (indexBrand, indexProduct) => {
    let tempRcpaData = [...rcpaData];
    tempRcpaData[indexBrand].rcpa[indexProduct].quan++;
    setRcpaData(tempRcpaData);
  };
  let decNum = (indexBrand, indexProduct) => {
    let tempRcpaData = [...rcpaData];
    if (tempRcpaData[indexBrand].rcpa[indexProduct].quan > 0) {
      tempRcpaData[indexBrand].rcpa[indexProduct].quan--;
    }
    setRcpaData(tempRcpaData);
  };
  return (
    <>
      <div className="container mt-3">
        <BackButton title={"Enter RCPA"} />
        {rcpaData.map((data, indexBrand) => {
          return (
            <div className="mt-4">
              <h5 style={{ fontSize: "1.2rem" }}>{data.brand}</h5>
              <Card>
                <Card.Body>
                  <div className="row">
                    {data.rcpa.map((ele, indexProduct) => {
                      return (
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-2">
                          <Card className="enterRcpa_medicineCard">
                            <Card.Body style={{height: '8rem'}}>
                              <div className="row">
                                <div className="col-6" style={{paddingRight: '0'}}><p style={{fontWeight:'500'}}>{ele.name}</p></div>
                                <div className="col-6">
                                  {" "}
                                  <div className="row">
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <button
                                          className="shadow-none btn"
                                          type="button"
                                          onClick={() =>
                                            decNum(indexBrand, indexProduct)
                                          }
                                        >
                                          -
                                        </button>
                                      </div>
                                      <input
                                        type="text"
                                        className="shadow-none form-control"
                                        value={ele.quan}
                                        // onChange={handleChange}
                                        style={{
                                          width: "2rem",
                                          textAlign: "center",
                                          border: "2px solid",
                                          borderRadius: "7px",
                                          height:'2rem'
                                        }}
                                      />
                                      <div className="input-group-prepend">
                                        <button
                                          className="shadow-none btn "
                                          type="button"
                                          onClick={() =>
                                            incNum(indexBrand, indexProduct)
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <p style={{fontSize: '9px', fontWeight: '300'}}>{ele.desc}</p>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </Card.Body>
              </Card>
            </div>
          );
        })}
        <div className="row mt-3 mb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 rcpa_Submitbtn">
          <CommonButton title={"Submit RCPA"} width={"15rem"} />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 rcpa_cancelbtn">
          
          <Button
            style={{
              fontSize: "14px",
              fontWeight: "600",
              border: "0",
              backgroundColor: "#4ad84a",
              borderRadius: "20px",
              background: "rgb(225, 219, 219)",
              color: "black",
              width: "15rem",
            }}
          >
            Cancel
          </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterRcpa;
