import React from "react";
import { Button, Card } from "react-bootstrap";
import "./SyncData.css";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import { Helmet } from 'react-helmet';

const SyncData = () => {
  const SyncData = [
    {
      date: "12 Apr 2023 05:00 PM",
      status: "Success",
    },
    {
      date: "12 Apr 2023 05:00 PM",
      status: "Success",
    },
    {
      date: "12 Apr 2023 05:00 PM",
      status: "Success",
    },
    {
      date: "12 Apr 2023 05:00 PM",
      status: "Success",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Synchronize</title>
      </Helmet>
      <div className="container mt-3">
        <BackButton title={"Synchronize"} />
      </div>
      <div className="container mt-3 SyncData" style={{ textAlign: "center" }}>
      <span className="leaveApply_button">
        <CommonButton title={'Sync Data Now'}/>
        </span>
      </div>
      <div className="container mt-5">
        <Card>
          <Card.Body>
            <table className="w-100" style={{ textAlign: "center" }}>
              <tr
                style={{
                  borderBottom: "1px solid lightgray",
                  background: "#f8f9fa",
                  color: "gray",
                  height: "3rem",
                }}
              >
                <th>Date and Time</th>
                <th>Status</th>
              </tr>
              {SyncData.map((data) => {
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid lightgray",
                      color: "black",
                      height: "3rem",
                    }}
                  >
                    <td>{data.date}</td>
                    <td>{data.status}</td>
                  </tr>
                );
              })}
            </table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default SyncData;
