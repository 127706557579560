import React, { useState } from "react";
import { Card } from "react-bootstrap";
import cardiology from "./../../assets/images/cardiology.jpg";
import obstetrics from "./../../assets/images/ObstetricsGynecology_Dpage.png";
import nephrology from "./../../assets/images/nephrology.png";
import "./Rehersal.css";
import PlaylistComponent from "./PlaylistComponent";
import { useParams } from "react-router-dom";
import BackButton from "../../Common/BackButton";
function Rehersal({ showName = true }) {
  const [playlist, setPlaylist] = useState("Cardiology");
  let { doctorName } = useParams();
  return (
    <div>
      <div className="row m-4">
        {showName && (
          <>
            <BackButton title={"Rehearsal"} />
            <div className="mt-2 mb-2">
              <h5>Welcome {doctorName ? doctorName : "Dr. Rajeev Verma"}</h5>
            </div>
          </>
        )}
        <h4 className="mb-4">My Playlists</h4>
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          onClick={() => {
            setPlaylist("Cardiology");
          }}
        >
          <Card>
            <Card.Body>
              <img
                src={cardiology}
                alt="image"
                className="w-100 rehersal-image"
                style={{ height: "10rem" }}
              />
            </Card.Body>
          </Card>
          <p className="mt-2 Rehersal_imageText">Cardiology</p>
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          onClick={() => {
            setPlaylist("Obstetrics");
          }}
        >
          <Card>
            <Card.Body>
              <img
                src={obstetrics}
                alt="image"
                className="w-100 rehersal-image"
                style={{ height: "10rem" }}
              />
            </Card.Body>
          </Card>
          <p className="mt-2 Rehersal_imageText">Obstetrics and Gynecology</p>
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 col-12"
          onClick={() => {
            setPlaylist("Nephrology");
          }}
        >
          <Card>
            <Card.Body>
              <img
                src={nephrology}
                alt="image"
                className="w-100 rehersal-image"
                style={{ height: "10rem" }}
              />
            </Card.Body>
          </Card>
          <p className="mt-2 Rehersal_imageText">Nephrology</p>
        </div>
        <PlaylistComponent playlistType={playlist} />
      </div>
    </div>
  );
}

export default Rehersal;
