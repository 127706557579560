import React, { useEffect, useState } from "react";
import "./PlanMTP.css";
import BackButton from "../../Common/BackButton";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import clockIcon from "./../../assets/images/circular-wall-clock-black-outline-17714.svg";
import locationIcon from "./../../assets/images/location-59.svg";
import profileIcon from "./../../assets/images/user-3297.svg";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { getApproveViewAllTpForMnApi } from "../../services/NTP/getApproveViewAllTpForMnApi";
import CommonButton from "../../Common/CommonButton";
import Modal from "react-bootstrap/Modal";
import FailButton from "./../../Common/FailButton";
import { cloneNTPApi } from "../../services/NTP/cloneNTPApi";
import { deleteNTPApi } from "../../services/NTP/deleteNTPApi";
import { ToastContainer, toast } from "react-toastify";
import { approveNTPApi } from "../../services/NTP/approveNTPApi";
import { sendFirebaseNotificationApi } from "../../services/firebase/sendFirebaseNotification";
import { useNavigate } from "react-router-dom";
import doctorIcon from "../../assets/images/stethoscope-3539.svg";

const ViewPlannedMTP = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const Id = useSelector((state) => state?.userDetails?.details?.Id);
  const UserId = useSelector((state) => state?.userDetails?.details?.Id);
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };
  const { tourNo } = useParams();

  let [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [allDetails, setAllDetails] = useState([]);
  const [tourSrno, setTourSrNo] = useState("");
  const [tourDate, setTourDate] = useState("");
  const [tourEmpId, settourEmpId] = useState("");

  const [selectedTourPlan, setSelectedTourPlan] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTourPlanType, setSelectedTourPlanType] = useState("");
  const [selectedContactId, setselectedContactId] = useState("");
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [place, setPlace] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [customerPob, setCustomerPob] = useState("")


  useEffect(() => {
    if (tourNo !== undefined) {
      setTourSrNo(tourNo?.split(":")?.[0]);
      setTourDate(tourNo?.split(":")?.[1]);
      settourEmpId(tourNo?.split(":")?.[2]);
      setPlace(tourNo?.split(":")?.[3])
    }
  }, [tourNo]);

  const fetchApproveToursData = async () => {
    setLoading(true);
    const response = await getApproveViewAllTpForMnApi(
      tourSrno,
      tourDate,
      tourEmpId,
      place,
      accessToken
    );
    if (response?.status === 200) {
      const jsonData = response?.data;
      const data = JSON.parse(jsonData);
      // const customerCount = data?.customers.length * data.POB;
      setCustomerPob(data?.FixedPOB)
      setAllDetails(data);
      setCustomers(data?.customers);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tourSrno && tourDate) {
      fetchApproveToursData();
    }
  }, [tourSrno, tourDate, tourEmpId, UserId, accessToken]);

  const handleCloneClick = (tourPlanSRNo, date, tourType, place) => {
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setSelectedTourPlanType(tourType);
    setShowCloneModal(true);
    setPlace(place);
  };

  const handleApproveClick = (tourPlanSRNo, date, tourType, place) => {
    setStatus("Approved");
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setSelectedTourPlanType(tourType);
    setPlace(place)
    setShowApproveModal(true);
  };

  const handleRejectClick = (tourPlanSRNo, date, tourType, place) => {
    setStatus("Rejected");
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setSelectedTourPlanType(tourType);
    setPlace(place);
    setShowRejectModal(true);
  };

  const handleshowCloneModal = () => {
    setShowCloneModal(!showCloneModal);
  };
  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };
  const handleshowRejectModal = () => {
    setShowRejectModal(!showRejectModal);
  };
  const handleshowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleClone = async () => {
    setLoading(true);
    const response = await cloneNTPApi(
      Id,
      selectedTourPlan,
      selectedDate,
      selectedTourPlanType,
      place,
      accessToken
    );
    if (response?.data?.trim() === "Tour Plan cloned successfully") {
      toast.success("Tour plan cloned successfully!");
      await sendFirebaseNotificationApi(
        tourEmpId,
        "NTP",
        "Your NTP request has been cloned",
        accessToken
      );
      setShowCloneModal(false);
      setLoading(false);
      navigate("/monthlyTour");
      fetchApproveToursData();
    }
    else if (response?.data?.trim() === "A leave record exists for the selected date with full-day leave. Cloning tour plan is restricted.") {
      toast.error("A leave record exists for the selected date with leave. Cloning tour plan is restricted.");
      setLoading(false)
    } else {
      setLoading(false);
      toast.error("Unable to clone tour plan");
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    const response = await approveNTPApi(
      Id,
      selectedTourPlan,
      selectedDate,
      selectedTourPlanType,
      status,
      place,
      accessToken
    );
    if (response?.status === 200) {
      toast.success("Tour plan approved successfully!");
      await sendFirebaseNotificationApi(
        tourEmpId,
        "NTP",
        "Your NTP request has been approved",
        accessToken
      );
      setShowApproveModal(false);
      setLoading(false);
      navigateBack();
      fetchApproveToursData();
    } else {
      setLoading(false);
      toast.error("Unable to approve tour plan");
    }
  };

  const handleReject = async () => {
    setLoading(true);
    const response = await approveNTPApi(
      Id,
      selectedTourPlan,
      selectedDate,
      selectedTourPlanType,
      status,
      place,
      accessToken
    );
    if (response?.status === 200) {
      toast.success("Tour plan rejected successfully!");
      await sendFirebaseNotificationApi(
        tourEmpId,
        "NTP",
        "Your NTP request has been rejected",
        accessToken
      );
      setShowApproveModal(false);
      setLoading(false);
      navigateBack();
      fetchApproveToursData();
    } else {
      setLoading(false);
      toast.error("Unable to reject tour plan");
    }
  };

  const handleDeleteClick = (tourPlanSRNo, date, tourType, contactId, place, messageDelete) => {
    setSelectedTourPlan(tourPlanSRNo);
    setSelectedDate(date);
    setselectedContactId(contactId);
    setSelectedTourPlanType(tourType);
    setPlace(place);
    setMessage(messageDelete)
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const response = await deleteNTPApi(
      selectedContactId,
      selectedTourPlan,
      selectedDate,
      selectedTourPlanType,
      place,
      accessToken,
      message
    );
    if (response?.data?.trim() === "Tour plans rejected successfully") {
      toast.success("Tour plans rejected successfully!");
      setShowDeleteModal(false);
      setLoading(false);
      navigateBack();
    }
    else if (response?.data?.trim() === "Tour plans deleted successfully") {
      await sendFirebaseNotificationApi(
        tourEmpId,
        "NTP",
        "Your delete NTP request has been approved",
        accessToken
      );
      toast.success("Tour plan deleted successfully!");
      setShowDeleteModal(false);
      setLoading(false);
      navigateBack();
    } else {
      setLoading(false);
      toast.error("Unable to delete tour plan");
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div
            className="col-lg-4 col-md-4 col-sm-12 col-12"
            style={{ background: "white" }}
          >
            <BackButton title={"View Tour Plan"} />
            <p className="mt-4 PlanMTP_leftHead">Select Day</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="date"
              name="date"
              value={allDetails?.TPDate}
              disabled
            />
            <p className="mt-4 PlanMTP_leftHead">Station</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="text"
              name="date"
              value={allDetails?.Station}
              disabled
            />
            <p className="mt-4 PlanMTP_leftHead">Type of Tour Plan</p>
            <input
              className="CommonDropdown form-select ViewMTP_input"
              type="text"
              name="text"
              value={allDetails?.TourPlan}
              disabled
            />
            {allDetails?.TourPlan === "Field Work" ? (
              <>
                <p className="mt-4 PlanMTP_leftHead ">HQ</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={allDetails?.HQ}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead ">VSE Name</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={allDetails?.VSEName}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead ">Route</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={`${allDetails?.Route} - ${allDetails?.RouteDesc}`}
                  disabled
                />

                <p className="mt-4 PlanMTP_leftHead">Enter POB</p>
                <input
                  className="CommonDropdown form-select ViewMTP_input"
                  type="text"
                  name="text"
                  value={customerPob}
                  disabled
                />
              </>
            ) : (
              <>
                {allDetails?.TourPlan === "Customer Engagement Activity" ? (
                  <>
                    <p className="mt-4 PlanMTP_leftHead ">
                      Type of Customer Engagement
                    </p>
                    <input
                      className="CommonDropdown form-select ViewMTP_input"
                      type="text"
                      name="text"
                      value={allDetails?.Engagement}
                      disabled
                    />
                  </>
                ) : (
                  ""
                )}
                <p className="mt-4 PlanMTP_leftHead">Start Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  value={allDetails?.StartTime}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead">End Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  value={allDetails?.EndTime}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  disabled
                />
                <p className="mt-4 PlanMTP_leftHead">Place</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  value={allDetails?.Place}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                    marginBottom: "1rem",
                  }}
                  disabled
                />
              </>
            )}
            {allDetails?.TourPlan === "Field Work" ? (
              ""
            ) : (
              <>
                <div className="row">
                  {allDetails?.Status === "Pending For Approval" && (
                    <>
                      <div className="col-lg-2 col-md-4 col-sm-3 col-4 mt-2">
                        <span
                          onClick={() =>
                            handleCloneClick(
                              allDetails?.TourPlanSRNo,
                              allDetails?.TPDate,
                              allDetails?.TourPlan,
                              allDetails?.Place
                            )
                          }
                        >
                          <CommonButton title={"Clone"} width={"5rem"} />
                        </span>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-3 col-4 mt-2">
                        <span
                          onClick={() =>
                            handleApproveClick(
                              allDetails?.TourPlanSRNo,
                              allDetails?.TPDate,
                              allDetails?.TourPlan,
                              allDetails?.Place
                            )
                          }
                        >
                          <CommonButton title={"Approve"} width={"5rem"} />
                        </span>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-3 col-4 mt-2">
                        <span
                          onClick={() =>
                            handleRejectClick(
                              allDetails?.TourPlanSRNo,
                              allDetails?.TPDate,
                              allDetails?.TourPlan,
                              allDetails?.Place
                            )
                          }
                        >
                          <CommonButton title={"Reject"} width={"5rem"} />
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="row mb-5">
                  {allDetails?.Status === "Delete Request Sent To Manager" && (
                    <>
                      <div className="col-4 ">
                        <span
                          onClick={() =>
                            handleDeleteClick(
                              allDetails?.TourPlanSRNo,
                              allDetails?.TPDate,
                              allDetails?.TourPlan,
                              allDetails?.Contact,
                              allDetails?.Place,
                              "Delete"
                            )
                          }
                        >
                          <CommonButton title={"Delete"} width={"5rem"} />
                        </span>
                      </div>
                      <div className="col-4 ">
                        <span
                          onClick={() =>
                            handleDeleteClick(
                              allDetails?.TourPlanSRNo,
                              allDetails?.TPDate,
                              allDetails?.TourPlan,
                              allDetails?.Contact,
                              allDetails?.Place,
                              "Reject"
                            )
                          }
                        >
                          <FailButton buttonText={"Reject Request"} width={"9rem"} />
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          {allDetails?.TourPlan === "Field Work" && (
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="row">
                {customers?.map((doctor) => {
                  return (
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <Card className="mt-4 DoctorCard">
                        <Card.Body>
                          <div className="DoctorPharm_Name">
                            <span className="DoctorPharm_NameTitle">
                              {doctor?.Name}
                            </span>
                          </div>
                          <div
                            className="DoctorPharm_Name"
                            style={{ marginTop: "5px" }}
                          >
                            <span className="DoctorPharm_lastVisit">
                              <img
                                src={clockIcon}
                                alt="clock"
                                style={{
                                  height: "1rem",
                                  marginRight: "5px",
                                  marginBottom: "2px",
                                }}
                              />
                              <b>DOB: {doctor?.DOB}</b>
                            </span>
                            {/* <span className="DoctorPharm_badge2">{doctor.visitNo}</span> */}
                          </div>
                          <div className="DoctorPharm_Post">
                            <span className="mr-3">
                              <img
                                src={profileIcon}
                                alt="profile"
                                style={{
                                  height: "1rem",
                                  marginRight: "3px",
                                  marginBottom: "2px",
                                }}
                              />
                              {doctor?.ContactPerson}
                            </span>
                            <span>
                              <img
                                src={mobileIcon}
                                alt="mobile"
                                style={{
                                  height: "1rem",
                                  marginRight: "3px",
                                  marginBottom: "2px",
                                }}
                              />
                              {doctor?.MobileNumber}
                            </span>
                          </div>
                          <div className="DoctorPharm_Post">
                            <img
                              src={doctorIcon}
                              alt="location"
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                            />
                            <span>Category: {doctor?.Category}</span>
                          </div>
                          <div className="DoctorPharm_Post">
                            <img
                              src={locationIcon}
                              alt="location"
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                            />
                            {doctor?.Address}
                            <br />
                            <span>
                              {doctor?.Area},{doctor?.City}
                            </span>
                            <br />
                            <span>
                              {doctor?.State},{doctor?.Country}
                            </span>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>

              <div className="row mt-3">
                {allDetails?.Status === "Pending For Approval" && (
                  <>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-2">
                      <span
                        onClick={() =>
                          handleCloneClick(
                            allDetails?.TourPlanSRNo,
                            allDetails?.TPDate,
                            allDetails?.TourPlan,
                            allDetails?.Place
                          )
                        }
                      >
                        <CommonButton title={"Clone"} width={"7rem"} />
                      </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-2">
                      <span
                        onClick={() =>
                          handleApproveClick(
                            allDetails?.TourPlanSRNo,
                            allDetails?.TPDate,
                            allDetails?.TourPlan,
                            allDetails?.Place
                          )
                        }
                      >
                        <CommonButton title={"Approve"} width={"7rem"} />
                      </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-2">
                      <span
                        onClick={() =>
                          handleRejectClick(
                            allDetails?.TourPlanSRNo,
                            allDetails?.TPDate,
                            allDetails?.TourPlan,
                            allDetails?.Place
                          )
                        }
                      >
                        <CommonButton title={"Reject"} width={"7rem"} />
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="row mb-5">
                {allDetails?.Status === "Delete Request Sent To Manager" && (
                  <>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-2">
                      <span
                        onClick={() =>
                          handleDeleteClick(
                            allDetails?.TourPlanSRNo,
                            allDetails?.TPDate,
                            allDetails?.TourPlan,
                            allDetails?.Contact,
                            allDetails?.Place,
                            "Delete"
                          )
                        }
                      >
                        <CommonButton title={"Delete"} width={"10rem"} />
                      </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-2">
                      <span
                        onClick={() =>
                          handleDeleteClick(
                            allDetails?.TourPlanSRNo,
                            allDetails?.TPDate,
                            allDetails?.TourPlan,
                            allDetails?.Contact,
                            allDetails?.Place,
                            "Reject"
                          )
                        }
                      >
                        <FailButton buttonText={"Reject Request"} width={"10rem"} />
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Clone Tour plan modal */}
      <Modal
        show={showCloneModal}
        onHide={handleshowCloneModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Body className="show-grid">
          <div className="row text-center">
            <h5>Clone Tour Plan</h5>
            <div className="mb-5">
              <h6>Are you sure you want to clone tour plan?</h6>
            </div>
          </div>
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowCloneModal}
            >
              <span onClick={handleClone}>
                <CommonButton title={"Clone"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowCloneModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* approve Tour plan modal */}
      <Modal
        show={showApproveModal}
        onHide={handleshowApproveModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Body className="show-grid">
          <div className="row text-center">
            <h5>Approve Tour Plan</h5>
            <div className="mb-5">
              <h6>Are you sure you want to approve tour plan?</h6>
            </div>
          </div>
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowApproveModal}
            >
              <span onClick={handleApprove}>
                <CommonButton title={"Approve"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowApproveModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* reject Tour plan modal */}
      <Modal
        show={showRejectModal}
        onHide={handleshowRejectModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Body className="show-grid">
          <div className="row text-center">
            <h5>Reject Tour Plan</h5>
            <div className="mb-5">
              <h6>Are you sure you want to reject tour plan?</h6>
            </div>
          </div>
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowRejectModal}
            >
              <span onClick={handleReject}>
                <CommonButton title={"Reject"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowRejectModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Tour plan modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleshowDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Body className="show-grid">
          {message === "Delete" && (
            <div className="row text-center">
              <h5>Delete Tour Plan</h5>
              <div className="mb-5">
                <h6>Are you sure you want to Delete tour plan?</h6>
              </div>
            </div>
          )}
          {message === "Reject" && (
            <div className="row text-center">
              <h5>Reject Tour Plan Delete Request</h5>
              <div className="mb-5">
                <h6>Are you sure you want to reject tour plan?</h6>
              </div>
            </div>
          )}
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowDeleteModal}
            >
              <span onClick={handleDelete}>
                {message === "Delete" && (
                  <CommonButton title={"Delete"} width={"100%"} />
                )}
                {message === "Reject" && (
                  <CommonButton title={"Reject"} width={"100%"} />
                )}
              </span>
            </div>
            <div className="col-6" onClick={handleshowDeleteModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
      {loading && <Loader />}
    </div>
  );
};

export default ViewPlannedMTP;
