import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import VideoDescription from "../Components/VideoDescription";

const VideoPlayerModal = ({ showModal, handleClose, videoUrl, videoDr }) => {
  const handleShowModal = () => {
    handleClose();
  };
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey"
    >
      {/* <Modal.Header closeButton>
      </Modal.Header> */}
      <Modal.Body className="show-grid" style={{ padding: "0rem" }}>
        <ReactPlayer url={videoUrl} controls={true} width="100%" />
        <VideoDescription videoDet={videoDr} />
      </Modal.Body>
      {/* <Modal.Footer className="doctor360Modal_survey_button">
      </Modal.Footer> */}
    </Modal>
  );
};

export default VideoPlayerModal;
