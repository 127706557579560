import { logInApi } from "../../services/logInApi";
import {
    GET_EMP_DETAILS_SUCCESS,
    GET_EMP_DETAILS_FAIL,
} from "../constants/action-types";

export const loginAction = (userName, password,accessToken) => async (dispatch) => {
    // console.log("response usert action callll");
    try {
        const response = await logInApi(userName, password,accessToken); // Wait for the Promise to resolve
        // console.log("response usert----------->", response?.data?.records[0]);
        if (response ) {
            dispatch({
                type: GET_EMP_DETAILS_SUCCESS,
                payload: response?.data?.records[0], // Assuming response.data contains the actual data
            });
            return { success: true, data: response.data }; // Return success and data
        } else {
            dispatch({
                type: GET_EMP_DETAILS_FAIL,
            });
            return { success: false, error: "Invalid response" }; // Return failure and error message
        }
    } catch (error) {
        dispatch({
            type: GET_EMP_DETAILS_FAIL,
        });
        return { success: false, error: error.message }; // Return failure and error message
    }
};
