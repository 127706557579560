import axios from "axios";
import { configs } from "../../config";

export const cloneDCRApi = async (contactId ,customerId,date, accessToken,managerId) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/CloneDCRSurveyNew`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        EmpId: contactId,
        DCRdate : date,
        Customer : customerId,
        managerId:managerId
    }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };