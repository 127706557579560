import React, { useState, useEffect, useCallback } from "react";
import "react-calendar/dist/Calendar.css";
import "./AttendanceDetail.css";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector, connect, useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import Loader from "../../Common/Loader";
import { getLeaveListAPi } from "../../services/leaves/getLeaveListApi";
import { getAllAttendance } from "../../Redux/actions/leaveAttendanceAction";
import { getFestivalHoliDayAPi } from "../../Redux/actions/holidayAction";

const AttendanceDetails = () => {
  const [events, setEvents] = useState([]);
  const [festivalHolidayData, setFestivalHolidayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewDate, setViewDate] = useState(new Date()); // State to track the current view date

  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const state = useSelector((state) => state?.userDetails?.details?.State__c);

  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);

  // Function to fetch data for the given date range
  const fetchData = useCallback(async (startDate, endDate) => {

    setLoading(true);

    try {
      const response = await dispatch(getAllAttendance(accessToken, empId));
      const attendanceData = response?.data?.records;
      const leaveResponse = await getLeaveListAPi(accessToken, empId)
      const leaveDataArray = leaveResponse?.data?.records

      if (response) {
        const leaveEvents = attendanceData?.map(leave => ({
          title: 'leaveEvents.Status__c === "CL" ? "CL" : "ppp"',
          start: new Date(leave?.Leave_Start_Date__c),
          end: new Date(leave?.Leave_End_Date__c),
          allDay: true,
          type: 'leave'
        })) || [];
console.log("attendanceData",attendanceData)
        const attendanceEvents = attendanceData?.map(attendance => {
          let eventType = '';
          let title = '';

          switch (attendance.Status__c) {
            case 'P':
              eventType = 'present';
              title = 'P';
              break;
            case 'WO':
              eventType = 'off';
              title = 'WO';
              break;
            case 'CL':
            case 'PL':
            case 'SL':
              eventType = 'leave';
              title = attendance.Status__c;
              break;
            default:
              eventType = 'absent';
              title = 'A';
          }

          return {
            title: title,
            start: new Date(attendance.Date1__c),
            end: new Date(attendance.Date1__c),
            allDay: true,
            type: eventType,
          };
        }) || [];



        const filteredAttendanceEvents = attendanceEvents.filter(event => {
          return !leaveEvents.some(leaveEvent => {
            return (
              leaveEvent.start.toDateString() === event.start.toDateString() ||
              (event.end && leaveEvent.start <= event.end && leaveEvent.end >= event.start)
            );
          });
        });

        const sundayEvents = generateSundayEvents(startDate, endDate);
console.log("sundayEvents",sundayEvents)
        const holidayEvents = (festivalHolidayData || []).map(holiday => {
          const [name, date] = holiday.split(' on '); 
          const [day, month, year] = date.trim().split('/'); 
          return {
            title: name.trim(), 
            start: new Date(year, month - 1, day), 
            end: new Date(year, month - 1, day), 
            allDay: true,
            type: 'holiday'
          };
        });        
        const combinedEvents = [...holidayEvents, ...filteredAttendanceEvents, ...sundayEvents];

        // Deduplicate and prioritize events
        const uniqueEvents = combinedEvents.reduce((acc, event) => {
          const dateKey = normalizeDate(event.start).toDateString();
          if (!acc[dateKey] || getEventPriority(event.type) < getEventPriority(acc[dateKey].type)) {
            acc[dateKey] = event;
          }
          return acc;
        }, {});
        setEvents(Object.values(uniqueEvents));
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }

    setLoading(false);
  }, [accessToken, empId, festivalHolidayData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const festivalHolidayData = await dispatch(getFestivalHoliDayAPi(accessToken,empId,state));
        const response = JSON.parse(festivalHolidayData?.data)
        setFestivalHolidayData(response);
      } catch (error) {
        console.error("Error fetching festival holiday data:", error);
      }
      setLoading(false);
    })();
  }, [accessToken]);

  useEffect(() => {
    // Fetch data for the currently visible date range
    const startDate = new Date(viewDate.getFullYear(), viewDate.getMonth(), 1);
    const endDate = new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 0);
    fetchData(startDate, endDate);
  }, [viewDate, fetchData]);

  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const getEventPriority = (type) => {
    switch (type) {
      case 'holiday':
        return 1;
      case 'leave':
        return 2;
      case 'present':
        return 3;
      case 'absent':
        return 4;
      case 'off':
        return 5;
      default:
        return 6;
    }
  };

  const getDayEventType = (date) => {
    const normalizedDate = normalizeDate(date);
    const event = events.find(event => {
      const eventStart = normalizeDate(new Date(event.start));
      const eventEnd = normalizeDate(new Date(event.end));

      return normalizedDate >= eventStart && normalizedDate <= eventEnd;
    });

    return event?.type;
  };

  const generateSundayEvents = (startDate, endDate) => {
    const sundays = [];
    let currentDate = new Date(startDate);

    currentDate.setDate(startDate.getDate() + (7 - startDate.getDay()) % 7);

    while (currentDate <= endDate) {
      sundays.push({
        title: "WO",
        start: new Date(currentDate),
        end: new Date(currentDate),
        allDay: true,
        type: 'off'
      });

      currentDate.setDate(currentDate.getDate() + 7);
    }

    return sundays;
  };

  const CustomEvent = ({ title }) => (
    <div className="custom-event">
      {title}
    </div>
  );

  const CustomToolbar = (props) => {
    const goToBack = () => {
      props.onNavigate('PREV');
      setViewDate(new Date(props.date));
    };

    const goToNext = () => {
      props.onNavigate('NEXT');
      setViewDate(new Date(props.date));
    };

    const label = () => {
      const date = new Date(props.date);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${month} ${year}`;
    };

    return (
      <div className="d-flex justify-content-between align-items-center mb-3">
        <button onClick={goToBack} className="table-navigation-buttons">Back</button>
        <span className="current-month">{label()}</span>
        <button onClick={goToNext} className="table-navigation-buttons">Next</button>
      </div>
    );
  };

  return (
    <div className="attendanceDetails_container">
      <div className="attendanceDetails_calendar mt-3 mb-4">
        <Calendar
          localizer={localizer}
          views={['month']}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          eventPropGetter={(event) => {
            let style = {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              textAlign: 'center',
            };

            switch (event.type) {
              case 'present':
                style.backgroundColor = 'rgb(237, 250, 237)'; // Light green for Present
                break;
              case 'leave':
                style.backgroundColor = 'rgb(252, 242, 255)';
                // Light purple for CL, PL, SL
                break;
              case 'absent':
                style.backgroundColor = 'rgb(252, 229, 192)'; // Light orange for Absent
                break;
              case 'holiday':
                style.backgroundColor = 'rgb(255, 230, 230)'; // Light red for Holiday
                break;
              case 'off':
                style.backgroundColor = 'rgb(247, 247, 247)'; // Light grey for Week Off
                break;
              default:
                style.backgroundColor = 'rgb(255, 255, 255)'; // Default white for others
            }

            return { style };
          }}


          dayPropGetter={(date) => {
            const eventType = getDayEventType(date);
            let style = {};

            if (eventType === 'leave') {
              style = {
                backgroundColor: 'rgb(252, 242, 255)',
                borderRadius: '0',
              };
            } else if (eventType === 'holiday') {
              style = {
                backgroundColor: 'rgb(255, 230, 230)',
                borderRadius: '0',
              };
            } else if (eventType === 'present') {
              style = {
                backgroundColor: 'rgb(237, 250, 237)',
                borderRadius: '0',
              };
            } else if (eventType === 'absent') {
              style = {
                backgroundColor: 'rgb(252, 229, 192)',
                borderRadius: '0',
              };
            } else if (eventType === 'off') {
              style = {
                backgroundColor: 'rgb(247, 247, 247)',
                borderRadius: '0',
              };
            }

            return { style };
          }}
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar,
          }}
        />
      </div>
      <div className="mt-6">
        <Card>
          <Card.Body>
            <div>
              <p className="legend-title">Legends</p>
            </div>
            <div className="row mt-3 attendanceDetails_footer">
              <div className="col-3">
                <span className="attendanceDetails_footerPresent">P</span> <span className="all-title">Present</span>
              </div>
              <div className="col-3">
                <span className="attendanceDetails_footerAbsent">A</span> <span className="all-title">Absent</span>
              </div>
              <div className="col-3">
                <span className="attendanceDetails_footerLeaves">L</span> <span className="all-title">Leave</span>
              </div>
              <div className="col-3">
                <span className="attendanceDetails_footerOff">WO</span> <span className="all-title">Week Off</span>
              </div>
              <p></p>
              <div className="col-3">
                <span className="attendanceDetails_holidayOff">-</span> <span className="all-title">Holiday</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {loading && <Loader />}
    </div>
  );
};


export default connect(null, {
  getAllAttendance,
  getFestivalHoliDayAPi
})(AttendanceDetails);
