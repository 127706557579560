import Button from "react-bootstrap/Button";
import "./SuccessButton.css";
const SuccessButton = ({ buttonText, height, width }) => {
  const handleButtonClick = () => {};
  return (
    <Button onClick={handleButtonClick} className="successButton" style={{height:height,width:width}}>
      {buttonText}
    </Button>
  );
};

export default SuccessButton;
