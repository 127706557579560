import axios from "axios";
import { configs } from "../../config";

export const deleteNTPApi = async (UserId, tourSrNo, date, tourType, place, accessToken,message) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/deleteTourPlan`,
      method: "post",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        TourPlan_SRNo: tourSrNo,
        EmpId : UserId,
        TPDate : date,
        TypeOfTourPlan : tourType,
        Place: place,
        Message: message
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };