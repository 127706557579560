import { getAllAttendanceApi } from "../../services/leaves/getAllAttendance";
import { logInApi } from "../../services/logInApi";
import {
    LEAVEDETAILS_SUCCESS,
    LEAVEDETAILS_FAIL,
} from "../constants/action-types";

export const getAllAttendance = (accessToken, empId) => async (dispatch) => {
    try {
        const response = await getAllAttendanceApi(accessToken, empId); // Wait for the Promise to resolve
        if (response) {
            dispatch({
                type: LEAVEDETAILS_SUCCESS,
                payload: response?.data?.records, // Assuming response.data contains the actual data
            });
            return { success: true, data: response.data }; // Return success and data
        } else {
            dispatch({
                type: LEAVEDETAILS_FAIL,
            });
            return { success: false, error: "Invalid response" }; // Return failure and error message
        }
    } catch (error) {
        dispatch({
            type: LEAVEDETAILS_FAIL,
        });
        return { success: false, error: error.message }; // Return failure and error message
    }
};