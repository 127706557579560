import axios from "axios";
import { configs } from "../../config";

export const getTpDateWiseApi = async (TourSrNo, empId ,accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/query/?q=select id,name,Date__c, Station__c, Type_of_Tour_Plan__c, Route__c, Contact__c, Customer__c,Customer__r.Name, Customer__r.Address__c, Customer__r.MobileNumber__c,Customer__r.ContactPerson__c, Customer__r.Country__c, Customer__r.State__c, Customer__r.Pincode__c,Customer__r.Customer_Type__c,Customer__r.Designation__c  from Tour_Plan__c where TourPlan_SR_No__c= '${TourSrNo}'  and Contact__r.EmpId__c ='${empId}'`,
      method: "get",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };