import React from "react";
import Header from "../Common/Header";
import LeaveApplyComponent from "../Components/LeaveApplyComponent";

const LeaveApply = () => {
  return (
    <>
      {/* <Header title={"Leave Apply"} isShowDate={false} /> */}
      <LeaveApplyComponent />
    </>
  );
};

export default LeaveApply;
