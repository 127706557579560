import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import "./PlaylistModal.css";
import SuccessButton from "../../Common/SuccessButton";
import FailButton from "../../Common/FailButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DoctorImg from './../../assets/images/doctorsG.gif'
import CommonButton from "../../Common/CommonButton";

const PlaylistModal = ({ showModal, handleShowModal, type, playlistData }) => {
  const [modalTitle, setModalTitle] = useState("Add To Playlist");
  const [isCreatePlaylist, setIsCreatePlaylist] = useState(false);
  const [selectPlaylistData, setSelectPlaylistData] = useState(playlistData);
  const playlists = [
    {
      title: "Orthopedics Playlist 1",
      selected: true,
    },
    {
      title: "Gynecology Playlist 1",
      selected: false,
    },
    {
      title: "Pediatrics Playlist 1",
      selected: false,
    },
    {
      title: "Pediatrics Playlist 2",
      selected: false,
    },
  ];

  const handleAddNewOnlick = () => {
    setModalTitle("Create New Playlist");
    setIsCreatePlaylist(true);
  };

  const handleCloseModal = () => {
    handleShowModal(selectPlaylistData);
    setModalTitle("Add To Playlist");
    setIsCreatePlaylist(false);
  };
  const handleChange = (e) => {
    console.log(e.target.checked);
  };

  if (type === "addPlaylist") {
    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="playlistModal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="playlistModal_title"
            id="contained-modal-title-vcenter"
            centered
          >
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          {!isCreatePlaylist && (
            <Container>
              {playlists.map((playlist) => {
                return (
                  <div className="playlistModal_playlist_title">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={playlist.title}
                        selected={playlist.selected}
                      />
                    </FormGroup>
                  </div>
                );
              })}
            </Container>
          )}
          {isCreatePlaylist && (
            <Container>
              <Col xs={6} md={12}>
                <TextField
                  label={"Playlist Name"}
                  className="myProfile_textfield"
                  focused
                />
              </Col>
            </Container>
          )}
        </Modal.Body>
        {!isCreatePlaylist && (
          <Modal.Footer className="playlistModal_button">
            <div onClick={handleShowModal} style={{ width: "10rem" }}>
              <SuccessButton buttonText={"Save"} />
            </div>
            <div onClick={handleAddNewOnlick} style={{ width: "12rem" }}>
              <SuccessButton buttonText={"Add new playlist"} />
            </div>
          </Modal.Footer>
        )}
        {isCreatePlaylist && (
          <Modal.Footer className="playlistModal_button">
            <div onClick={handleShowModal} style={{ width: "10rem" }}>
              <SuccessButton buttonText={" Add Playlist"} />
            </div>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
  if (type === "selectPlaylist") {
    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="playlistModal"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title
            className="playlistModal_title"
            id="contained-modal-title-vcenter"
            centered
          >
            Select Category
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="show-grid text-center">
          <img src={DoctorImg} style={{height:'10rem'}}/>
          <Container>
            {selectPlaylistData.map((playlist, index) => {
              return (
                <div className="playlistModal_playlist_title" key={playlist.id}>
                  <FormGroup
                    onChange={(e) => {
                      let tempPlaylistData = [...selectPlaylistData];
                      tempPlaylistData[index].selected = e.target.checked;
                      setSelectPlaylistData(tempPlaylistData);
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      value={playlist.id}
                      label={playlist.heading}
                      checked={playlist.selected}
                    />
                  </FormGroup>
                </div>
              );
            })}
          </Container>
        </Modal.Body>
        <Modal.Footer className="playlistModal_button">
          <div onClick={handleCloseModal} style={{ width: "10rem" }}>
            <CommonButton title={"Save"} width={'10rem'}/>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default PlaylistModal;
