import React, { createContext, useState, useContext, useEffect } from "react";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn") === "true"
  );

  const handleLoginAuth = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("isLoggedIn", "true")
    const currentDate = new Date();
    sessionStorage.setItem("lastLoggedInDate", currentDate.toString());
  };

  const handleLogoutAuth = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem("isLoggedIn");
    localStorage.removeItem("isLoggedIn")

  };

  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.removeItem("isLoggedIn");
    } else {
      sessionStorage.setItem("isLoggedIn", "true");
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, handleLoginAuth, handleLogoutAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
