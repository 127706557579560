import React from "react";

import Header from "../Common/Header";
import DCRSurveyForm from "../Components/DCRSurveyForm";
import { Helmet } from 'react-helmet';

const DCRSurvey = () => {
  return (
    <>
    <Helmet>
        <title>DCR Survey</title>
      </Helmet>
      {/* <Header title={"DCR Survey"} isShowDate={false} /> */}
      <DCRSurveyForm />
    </>
  );
};

export default DCRSurvey;
