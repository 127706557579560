import React, { useState, useEffect, useLayoutEffect } from "react";
// import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Chart, registerables, _adapters } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./CommonMultiAxisLineChart.css";

const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function CommonMultiAxisLineChart({ value }) {
  const [trendData, setTrendData] = useState({
    labels: monthLabels,
    cancelledAppointmnets: [
      500000, 450000, 300000, 300000, 500000, 400000, 200000, 250000, 350000,
      150000, 275000, 325000,
    ],
    completedAppointments: [
      20000, 25000, 25000, 30000, 25000, 25000, 35000, 40000, 40000, 50000,
      45000, 40000,
    ],
  });
  useLayoutEffect(() => {
    Chart.register(ChartDataLabels);
    Chart.register(...registerables);
  });

  useEffect(() => {
    if (value === "Month") {
      setTrendData({
        labels: monthLabels,
        cancelledAppointmnets: [
          15000, 50000, 300000, 300000, 400000, 400000, 450000, 250000,
          350000, 320000, 380000, 525000,
        ],
        completedAppointments: [
          15000, 20000, 30000, 27000, 30000, 25000, 31000, 36000, 35000, 37000,
          38000, 45000,
        ],
      });
    }
    if (value === "Day") {
      setTrendData({
        labels: [
          "9 am",
          "10 am",
          "11 am",
          "12 pm",
          "1 pm",
          "2 pm",
          "3 pm",
          "4 pm",
          "5 pm",
          "6 pm",
        ],
        cancelledAppointmnets: [0, 5, 2, 3, 4, 4, 3, 2, 2, 1],
        completedAppointments: [0, 0, 1, 2, 1, 1, 0, 1, 0, 2],
      });
    }
    if (value === "Year") {
      setTrendData({
        labels: [
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
        ],
        cancelledAppointmnets: [
          1000, 1200, 1100, 1150, 1300, 1100, 900, 1000, 1100, 1200,
        ],
        completedAppointments: [
          300, 350, 350, 250, 200, 200, 300, 400, 350, 200,
        ],
      });
    }
  }, [value]);
  const data = [1, 2, 3, 4];
  return (
    <div className="Multi_Axis_chart">
      <div className="chart_container">
        <Line
          data={{
            labels: trendData.labels,
            datasets: [
              {
                label: "Own contribution",
                data: trendData.cancelledAppointmnets,
                borderColor: "#00affa",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                yAxisID: "y",
              },
              {
                label: "Sales",
                data: trendData.completedAppointments,
                borderColor: "#7953cd",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
                yAxisID: "y1",
              },
            ],
          }}
          options={{
            responsive: true,
            interaction: {
              mode: "index",
              intersect: false,
            },
            stacked: false,
            plugins: {
              title: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              y: {
                type: "linear",
                display: true,
                position: "left",
              },
              y1: {
                type: "linear",
                display: true,
                position: "right",
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          }}
          height={90}
          width={130}
        />
      </div>
    </div>
  );
}

Chart.register(...registerables);

export default CommonMultiAxisLineChart;
