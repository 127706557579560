import axios from "axios";
import { configs } from "../../config";

export const managerLeaveApprove = async (accessToken, leaveObj) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/ApproveLeaveApplication`,
        method: "patch",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: leaveObj
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const leaveCount = async (accessToken, empId) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT  Probation_period__c,PL__C, SL__C,CL__C FROM Contact WHERE id = '${empId}'`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },

    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};