import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import CommonDropdown from "./../../Common/CommonDropdown";
import BackButton from "../../Common/BackButton";
import "./ExpenseClaim.css";
import CommonButton from "../../Common/CommonButton";
import { Helmet } from "react-helmet";
// import Form from "react-bootstrap/Form";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { getExpensesApi } from "../../services/expenses/getExpensesApi";
import Loader from "../../Common/Loader";
import { deletExpenseApi } from "../../services/expenses/deleteExpenseApi";
import { sentClaimsForApprovalApi } from "../../services/expenses/sentClaimsForApprovalApi";
import question from "./../../assets/images/red-question-11802.svg";
import check from "./../../assets/images/check-mark-3280.svg";
import cross from "./../../assets/images/remove.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { sendFirebaseNotificationApi } from "../../services/firebase/sendFirebaseNotification";
import draft from "./../../assets/images/draft.png";
import Modal from "react-bootstrap/Modal";
import FailButton from "./../../Common/FailButton";
import DeleteGif from "./../../assets/images/delete1.gif";
import { ToastContainer, toast } from 'react-toastify';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export const AllClaims = () => {
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const [SelectedClaim, setSelectedClaim] = useState();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedApproval, setSelectedApproval] = useState();
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false);

  const empDetails = useSelector((state) => state?.userDetails?.details);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const division = useSelector(
    (state) => state?.userDetails?.details?.Division__c
  );
  useEffect(() => {
    getExpenses();
  }, []);

  const getExpenses = async () => {
    setLoading(true);
    try {
      const result = await getExpensesApi(empDetails.Id, accessToken);
      setClaims(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDeleteExpense = async (claim) => {
    setLoading(true);
    try {
      await deletExpenseApi(SelectedClaim.sfId, accessToken);
      getExpenses();
      setShowDeleteRequestModal(false);
      toast.success("Expense clain deleted successfully.")
    } catch (error) {
      setLoading(false);
    }
  };

  const handleApproveClaim = async (claim) => {
    let claimObj = {
      empId: empDetails.Id,
      claimId: selectedApproval.sfId,
    };
    setLoading(true);

    try {
      await sentClaimsForApprovalApi(claimObj, accessToken);
      await sendFirebaseNotificationApi(
        empDetails.ReportsToId,
        "Expense Request",
        "You have new expense request",
        accessToken
      );
      getExpenses();
      toast.success("Expense claim sent for approval");
    } catch (error) {
      setLoading(false);
    }
  };

  const handleshowDeleteRequestModal = () => {
    setShowDeleteRequestModal(!showDeleteRequestModal);
  };

  const handleDeleteRequestClick = (claim) => {
    setSelectedClaim(claim);
    setShowDeleteRequestModal(true);
  };
  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const handleApproveClick = (claim) => {
    setSelectedApproval(claim)
    setShowApproveModal(true);
  };

  const pendingMessage = `Pending - ${claims.filter(claim => claim.status === "Pending For Approval" || claim.status === "Saved" || claim.status === "Pending for Release" ).length}`;
  const approvedMessage = `Approved - ${claims.filter(claim => claim.status === "Approved" || claim.status === "Released" ).length}`;
  const rejectedMessage = `Rejected - ${claims.filter(claim => claim.status === "Rejected").length}`;

  return (
    <div>
      {loading && <Loader />}
      <Helmet>
        <title>All Claims</title>
      </Helmet>
      <div className="m-4">
        <div className="row pt-3">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="mb-2">
              <BackButton title={"All Claims"} />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mt-md-0 d-flex align-items-center justify-content-end expense-buttons">
            <div className="d-flex">
              <Link to="/createExpenseClaim" className="mr-2">
                <CommonButton title={"Create New Expense"} width={"10rem"} style={{ marginRight: '0.5rem' }} />
              </Link>
              {(designation === "VSE" && division === "PET") ? (
                <Link to="/approve-claims" >
                  <CommonButton title={"Approve Claims"} width={"10rem"} style={{ marginRight: '0.5rem' }} />
                </Link>
                )
                : (designation === "VSE" || designation === "BE") ? "" :
                (
                  <Link to="/approve-claims" >
                  <CommonButton title={"Approve Claims"} width={"10rem"} style={{ marginRight: '0.5rem' }} />
                </Link>
              )}
            </div>
          </div>

        </div>

        {/* {claims.filter(data => data.status === "Pending For Approval").length > 0 ? (
          <Card className="monthlyClaim_mobile">
            <Card.Body>
              <h4
                className="mb-4"
                style={{ textAlign: "center" }}
              >
                Monthly Claim Status
              </h4>
              <div className="table-wrapper-scroll-y custom-scrollbar">
                <table className="w-100" style={{ textAlign: "center" }}>
                  <tr className="ladder_heading">
                    <th className="custom-header">Expense Date</th>
                    <th className="custom-header">Transaction Date</th>
                    <th className="custom-header">Claim Id</th>
                    <th className="custom-header">Claim Type</th>
                    <th className="custom-header">Claim Sub Type</th>
                    <th className="custom-header">Claim Amount</th>
                    <th className="custom-header">Status</th>
                    <th className="custom-header">Actions</th>
                    <th className="custom-header"></th>
                  </tr>

                  {claims?.map((Claim) => {
                    let claimDate = Claim.claimDate;

                    if (claimDate && claimDate.includes(" to ")) {
                      let [fromDate, toDate] = claimDate.split(" to ");

                      if (fromDate && toDate) {
                        const formatDate = (date) => {
                          const [year, month, day] = date.split("-");
                          const months = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                          ];
                          return `${day} ${months[parseInt(month) - 1]} ${year}`;
                        };

                        switch (Claim.claimType) {
                          case "Local Conveyance":
                          case "Daily Allowance":
                          case "Travel Allowance":
                            fromDate = formatDate(fromDate);
                            toDate = formatDate(toDate);
                            claimDate = `${fromDate} To ${toDate}`;
                            break;

                          default:
                            claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                            break;
                        }
                      } else {
                        claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                      }
                    } else {
                      claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                    }
                    return (
                      <tr className="ladder_heading_data">
                        <td>{claimDate}</td>
                        <td>{formatDateStringDDMMYYYY(Claim.transactionDate)}</td>
                        <td>{Claim.claimId}</td>
                        <td>{Claim?.claimType}</td>
                        <td>{Claim?.allowanceType}</td>
                        <td>₹{Claim?.claimAmount}</td>
                        <td>
                          {" "}
                          {(Claim?.status === "Saved") && (<img className="MTP_approved" src={draft} alt="" />)}
                          {(Claim?.status === "Pending For Approval") && (
                            <img className="MTP_approved" src={question} alt="" />
                          )}
                          {Claim?.status === "Rejected" && (
                            <img className="MTP_approved" src={cross} alt="" />
                          )}
                          {Claim?.status === "Approved" && (
                            <img className="MTP_approved" src={check} alt="" />
                          )}
                          {Claim?.status}
                        </td>
                        <td>
                          {Claim?.status === "Saved" && (
                            <span
                              onClick={() => {
                                // handleApproveClaim(Claim);
                                handleApproveClick(Claim)
                              }}
                            >
                              <CommonButton title={"Send for Approval"} />
                            </span>
                          )}
                        </td>
                        <td>
                          {(Claim?.status === "Saved" ||
                            Claim?.status === "Pending For Approval") && (
                              <span
                                onClick={() => {
                                  // handleDeleteExpense(Claim);
                                  handleDeleteRequestClick(Claim)
                                }}
                                style={{ paddingLeft: "10px" }}
                              >
                                <DeleteIcon className="MTP_Delete" />
                              </span>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <h6 className="mt-5" style={{ fontWeight: 'bold', textAlign: "center" }}>No records to display</h6>
        )} */}
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          >
            <Tab
              eventKey="Pending"
              title={pendingMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {claims.filter(data => (data.status === "Pending For Approval" || data.status === "Saved" || data.status === "Pending for Release")).length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4
                          className="mb-4"
                          style={{ textAlign: "center" }}
                        >
                          Monthly Claim Status
                        </h4>
                        <div className="table-wrapper-scroll-y custom-scrollbar">
                          <table className="w-100" style={{ textAlign: "center" }}>
                            <tr className="ladder_heading">
                              <th className="custom-header">Expense Date</th>
                              <th className="custom-header">Transaction Date</th>
                              <th className="custom-header">Claim Id</th>
                              <th className="custom-header">Claim Type</th>
                              <th className="custom-header">Claim Sub Type</th>
                              <th className="custom-header">Claim Amount</th>
                              <th className="custom-header">Status</th>
                              <th className="custom-header">Actions</th>
                              <th className="custom-header"></th>
                            </tr>

                            {claims?.map((Claim) => {
                              if (Claim.status === "Pending For Approval" || Claim.status === "Saved" || Claim.status === "Pending for Release") {
                                let claimDate = Claim.claimDate;

                                if (claimDate && claimDate.includes(" to ")) {
                                  let [fromDate, toDate] = claimDate.split(" to ");

                                  if (fromDate && toDate) {
                                    const formatDate = (date) => {
                                      const [year, month, day] = date.split("-");
                                      const months = [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ];
                                      return `${day} ${months[parseInt(month) - 1]} ${year}`;
                                    };

                                    switch (Claim.claimType) {
                                      case "Local Conveyance":
                                      case "Daily Allowance":
                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(fromDate);
                                        toDate = formatDateStringDDMMYYYY(toDate);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                        break;
                                    }
                                  } else {
                                    claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                  }
                                } else {
                                  claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                }
                                return (
                                  <tr className="ladder_heading_data">
                                    <td>{claimDate}</td>
                                    <td>{formatDateStringDDMMYYYY(Claim.transactionDate)}</td>
                                    <td>{Claim.claimId}</td>
                                    <td>{Claim?.claimType}</td>
                                    <td>{Claim?.allowanceType}</td>
                                    <td>₹{Claim?.claimAmount}</td>
                                    <td>
                                      {" "}
                                      {(Claim?.status === "Saved") && (<img className="MTP_approved" src={draft} alt="" />)}
                                      {(Claim?.status === "Pending For Approval" || Claim?.status === "Pending for Release") && (
                                        <img className="MTP_approved" src={question} alt="" />
                                      )}
                                      {Claim?.status === "Rejected" && (
                                        <img className="MTP_approved" src={cross} alt="" />
                                      )}
                                      {(Claim?.status === "Approved" || Claim?.status === "Released" ) && (
                                        <img className="MTP_approved" src={check} alt="" />
                                      )}
                                      {Claim?.status}
                                    </td>
                                    <td>
                                      {Claim?.status === "Saved" && (
                                        <span
                                          onClick={() => {
                                            // handleApproveClaim(Claim);
                                            handleApproveClick(Claim)
                                          }}
                                        >
                                          <CommonButton title={"Send for Approval"} />
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {(Claim?.status === "Saved" ||
                                        Claim?.status === "Pending For Approval" ||
                                        Claim?.status === "Pending for Release") && (
                                          <span
                                            onClick={() => {
                                              // handleDeleteExpense(Claim);
                                              handleDeleteRequestClick(Claim)
                                            }}
                                            style={{ paddingLeft: "10px" }}
                                          >
                                            <DeleteIcon className="MTP_Delete" />
                                          </span>
                                        )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6 className="mt-5" style={{ fontWeight: 'bold', textAlign: "center" }}>No records to display</h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Approved"
              title={approvedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {claims.filter(data => data.status === "Approved" || data.status === "Released").length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4
                          className="mb-4"
                          style={{ textAlign: "center" }}
                        >
                          Monthly Claim Status
                        </h4>
                        <div className="table-wrapper-scroll-y custom-scrollbar">
                          <table className="w-100" style={{ textAlign: "center" }}>
                            <tr className="ladder_heading">
                              <th className="custom-header">Expense Date</th>
                              <th className="custom-header">Transaction Date</th>
                              <th className="custom-header">Claim Id</th>
                              <th className="custom-header">Claim Type</th>
                              <th className="custom-header">Claim Sub Type</th>
                              <th className="custom-header">Claim Amount</th>
                              <th className="custom-header">Status</th>
                              {/* <th className="custom-header">Actions</th> */}
                              {/* <th className="custom-header"></th> */}
                            </tr>

                            {claims?.map((Claim) => {
                              if (Claim.status === "Approved" || Claim.status === "Released") {
                                let claimDate = Claim.claimDate;

                                if (claimDate && claimDate.includes(" to ")) {
                                  let [fromDate, toDate] = claimDate.split(" to ");

                                  if (fromDate && toDate) {
                                    const formatDate = (date) => {
                                      const [year, month, day] = date.split("-");
                                      const months = [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ];
                                      return `${day} ${months[parseInt(month) - 1]} ${year}`;
                                    };

                                    switch (Claim.claimType) {
                                      case "Local Conveyance":
                                      case "Daily Allowance":
                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(fromDate);
                                        toDate = formatDateStringDDMMYYYY(toDate);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                        break;
                                    }
                                  } else {
                                    claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                  }
                                } else {
                                  claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                }
                                return (
                                  <tr className="ladder_heading_data">
                                    <td>{claimDate}</td>
                                    <td>{formatDateStringDDMMYYYY(Claim.transactionDate)}</td>
                                    <td>{Claim.claimId}</td>
                                    <td>{Claim?.claimType}</td>
                                    <td>{Claim?.allowanceType}</td>
                                    <td>₹{Claim?.claimAmount}</td>
                                    <td>
                                      {" "}
                                      {(Claim?.status === "Saved") && (<img className="MTP_approved" src={draft} alt="" />)}
                                      {(Claim?.status === "Pending For Approval" || Claim?.status === "Pending for Release") && (
                                        <img className="MTP_approved" src={question} alt="" />
                                      )}
                                      {Claim?.status === "Rejected" && (
                                        <img className="MTP_approved" src={cross} alt="" />
                                      )}
                                      {(Claim?.status === "Approved" || Claim?.status === "Released") && (
                                        <img className="MTP_approved" src={check} alt="" />
                                      )}
                                      {Claim?.status}
                                    </td>
                                    {/* <td>
                                      {Claim?.status === "Saved" && (
                                        <span
                                          onClick={() => {
                                            // handleApproveClaim(Claim);
                                            handleApproveClick(Claim)
                                          }}
                                        >
                                          <CommonButton title={"Send for Approval"} />
                                        </span>
                                      )}
                                    </td> */}
                                    {/* <td>
                                      {(Claim?.status === "Saved" ||
                                        Claim?.status === "Pending For Approval") && (
                                          <span
                                            onClick={() => {
                                              // handleDeleteExpense(Claim);
                                              handleDeleteRequestClick(Claim)
                                            }}
                                            style={{ paddingLeft: "10px" }}
                                          >
                                            <DeleteIcon className="MTP_Delete" />
                                          </span>
                                        )}
                                    </td> */}
                                  </tr>
                                );
                              }
                            })}
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6 className="mt-5" style={{ fontWeight: 'bold', textAlign: "center" }}>No records to display</h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Deleted"
              title={rejectedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {claims.filter(data => data.status === "Rejected").length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4
                          className="mb-4"
                          style={{ textAlign: "center" }}
                        >
                          Monthly Claim Status
                        </h4>
                        <div className="table-wrapper-scroll-y custom-scrollbar">
                          <table className="w-100" style={{ textAlign: "center" }}>
                            <tr className="ladder_heading">
                              <th className="custom-header">Expense Date</th>
                              <th className="custom-header">Transaction Date</th>
                              <th className="custom-header">Claim Id</th>
                              <th className="custom-header">Claim Type</th>
                              <th className="custom-header">Claim Sub Type</th>
                              <th className="custom-header">Claim Amount</th>
                              <th className="custom-header">Status</th>
                              <th className="custom-header">Remarks</th>
                              {/* <th className="custom-header"></th> */}
                            </tr>

                            {claims?.map((Claim) => {
                              if (Claim.status === "Rejected") {
                                let claimDate = Claim.claimDate;

                                if (claimDate && claimDate.includes(" to ")) {
                                  let [fromDate, toDate] = claimDate.split(" to ");

                                  if (fromDate && toDate) {
                                    const formatDate = (date) => {
                                      const [year, month, day] = date.split("-");
                                      const months = [
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ];
                                      return `${day} ${months[parseInt(month) - 1]} ${year}`;
                                    };

                                    switch (Claim.claimType) {
                                      case "Local Conveyance":
                                      case "Daily Allowance":
                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(fromDate);
                                        toDate = formatDateStringDDMMYYYY(toDate);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                        break;
                                    }
                                  } else {
                                    claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                  }
                                } else {
                                  claimDate = formatDateStringDDMMYYYY(Claim?.claimDate);
                                }
                                return (
                                  <tr className="ladder_heading_data">
                                    <td>{claimDate}</td>
                                    <td>{formatDateStringDDMMYYYY(Claim.transactionDate)}</td>
                                    <td>{Claim.claimId}</td>
                                    <td>{Claim?.claimType}</td>
                                    <td>{Claim?.allowanceType}</td>
                                    <td>₹{Claim?.claimAmount}</td>
                                    <td>
                                      {" "}
                                      {(Claim?.status === "Saved") && (<img className="MTP_approved" src={draft} alt="" />)}
                                      {(Claim?.status === "Pending For Approval" || Claim?.status === "Pending for Release") && (
                                        <img className="MTP_approved" src={question} alt="" />
                                      )}
                                      {Claim?.status === "Rejected" && (
                                        <img className="MTP_approved" src={cross} alt="" />
                                      )}
                                      {(Claim?.status === "Approved" || Claim?.status === "Released") && (
                                        <img className="MTP_approved" src={check} alt="" />
                                      )}
                                      {Claim?.status}
                                    </td>
                                    <td>{Claim?.remark}</td>
                                    {/* <td>
                                      {Claim?.status === "Saved" && (
                                        <span
                                          onClick={() => {
                                            // handleApproveClaim(Claim);
                                            handleApproveClick(Claim)
                                          }}
                                        >
                                          <CommonButton title={"Send for Approval"} />
                                        </span>
                                      )}
                                    </td> */}
                                    {/* <td>
                                      {(Claim?.status === "Saved" ||
                                        Claim?.status === "Pending For Approval") && (
                                          <span
                                            onClick={() => {
                                              // handleDeleteExpense(Claim);
                                              handleDeleteRequestClick(Claim)
                                            }}
                                            style={{ paddingLeft: "10px" }}
                                          >
                                            <DeleteIcon className="MTP_Delete" />
                                          </span>
                                        )}
                                    </td> */}
                                  </tr>
                                );
                              }
                            })}
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6 className="mt-5" style={{ fontWeight: 'bold', textAlign: "center" }}>No records to display</h6>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal
        show={showDeleteRequestModal}
        onHide={handleshowDeleteRequestModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>Delete Expense Claim</h5>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row text-center">
            <h6>Are You Sure You Want To Delete the Expense Claim?</h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowDeleteRequestModal}
            >
              <span onClick={handleDeleteExpense}>
                <CommonButton title={"Yes"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowDeleteRequestModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showApproveModal}
        onHide={handleshowApproveModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>Send For Approval</h5>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row text-center">
            <div className="row mb-2">
              <h6 className="mt-2">
                Send expense claim to Manager for approval
              </h6>
            </div>
          </div>
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={handleshowApproveModal}
            >
              <span onClick={handleApproveClaim}>
                <CommonButton title={"Send"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowApproveModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <ToastContainer />
    </div >
  );
};
