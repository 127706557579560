import Doctor360 from "../Components/Doctor3601";
import Header from "../Common/Header";

const Doctors360 = () => {
  return (
    <>
      {/* <Header title={"Doctors 360"} isShowDate={false} /> */}
      <Doctor360 />
    </>
  );
};

export default Doctors360;
