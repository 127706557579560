import React from "react";
import Highcharts from "highcharts";
//import ReactHighcharts from 'react-highcharts';
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsReact from "highcharts-react-official";
import "./Chart3D.css";

highcharts3d(Highcharts);

const Chart3D = ({ xAxis, yAxis }) => {
  const options = {
    chart: {
      renderTo: "container",
      type: "column",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 0,
        depth: 20,
        viewDistance: 25,
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: xAxis,
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    tooltip: {
      headerFormat: "<b>{point.key}</b><br>",
      pointFormat: "Score: {point.y}",
    },
    title: {
      text: "",
      align: "left",
    },
    // subtitle: {
    //   text: 'Source: ' +
    //     '<a href="https://ofv.no/registreringsstatistikk"' +
    //     'target="_blank">OFV</a>',
    //   align: 'left'
    // },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    series: [
      {
        data: yAxis,
        // colorByPoint: true
      },
    ],
  };
  return (
    <div>
      <div className="Chart3D">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default Chart3D;
