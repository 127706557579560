import React, { useEffect, useState, forwardRef, useRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { callAverageApi } from "../../services/reports/attendanceClaimDcrApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";
import { callAverageReportDownloadApi } from "../../services/reports/callAverageReportApi";
import { toast, ToastContainer } from "react-toastify";

const CallAverageReport = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterEmpId, setFilterEmpId] = useState('');
  const [loading, setLoading] = useState(false);
  const [callAvg, setCallAvg] = useState([]);
  const [error, setError] = useState('');
  const [dataFetched, setDataFetched] = useState(false);

  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const userId = useSelector((state) => state?.userDetails?.details?.Id);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);



  const tableRef = useRef();

  // useEffect(() => {
  //   const fetchData = async () => {
  // setLoading(true);
  //     try {
  //       const callAverageResponse = await callAverageApi(accessToken, EmployeeId, designation);
  // const data = callAverageResponse?.data;
  //       if (Array.isArray(data) && data.length > 0) {
  //         const start = startDate ? new Date(startDate) : new Date('1900-01-01');
  // const end = endDate ? new Date(endDate) : new Date('2100-01-01');
  // start.setHours(0, 0, 0, 0);
  // end.setHours(23, 59, 59, 999);

  //         const filteredData = data.filter(item => {
  //           const itemDate = new Date(item?.StartDate);

  //           return itemDate >= start && itemDate <= end &&
  //             (!filterEmpId || filterEmpId === item?.EmpCode);
  //         });
  //         const sortedData = filteredData?.sort((a, b) => new Date(b?.StartDate) - new Date(a?.StartDate));
  //         setCallAvg(sortedData);
  //       } else {
  //         setCallAvg([]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching call averages:', error);
  //     } finally {
  // setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [EmployeeId, accessToken, startDate, endDate, filterEmpId, designation]);

  const fetchData = async () => {
    setLoading(true); // Start loading state

    try {
      // Fetch data from API
      const response = await callAverageReportDownloadApi(
        accessToken,
        filterEmpId,
        startDate,
        endDate,
        designation,
        userId,
        division
      );

      // Log the entire response to understand its structure
      console.log("API Response:", response);

      // Check if response and response.data.DCR are defined and have records
      if (!response || !response.data || !Array.isArray(response.data)) {
        console.error("Unexpected response format:", response);
        throw new Error("Invalid response format");
      }

      // Access the array of DCRs
      const dataArray = response.data;
      console.log("Fetched Data Array:", dataArray);

      // Set default dates if not provided
      const start = startDate ? new Date(startDate) : new Date("1900-01-01");
      const end = endDate ? new Date(endDate) : new Date("2100-01-01");
      start.setHours(0, 0, 0, 0)
      end.setHours(23, 59, 59, 999)

      // Filter and sort data
      const filteredData = dataArray.filter((item) => {
        const itemDate = new Date(item?.Date__c);
        return itemDate >= (start && itemDate <= end) ||
          (!filterEmpId || filterEmpId === item?.EmpCode);
      });

      console.log("Filtered Data:", filteredData);

      const sortedData = filteredData.sort(
        (a, b) => new Date(b?.Date__c) - new Date(a?.Date__c)
      );

      console.log("Sorted Data:", sortedData);

      // Update state
      setCallAvg(sortedData);
      setDataFetched(sortedData.length > 0); // Set dataFetched based on the presence of data

    } catch (error) {
      if (error?.response?.status === 404) {
        toast.error("Provided employee code is not a valid subordinate.");
        setCallAvg([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setCallAvg([]); // Reset data to an empty array in case of other errors
      }
      setDataFetched(false); // Indicate no data was fetched in case of error
    } finally {
      setLoading(false); // End loading state
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError('Please enter only numbers.');
    } else {
      setError('');
    }
  };

  const handleExport = () => {
    if (!callAvg || callAvg.length === 0) {
      alert('No data available to export');
      return;
    }

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(callAvg.map(item => ({
      'Employee Code': item?.EmpCode,
      'Employee Name': item?.EmployeeName,
      'Start Date': formatDateStringDDMMYYYY(item?.StartDate),
      'End Date': formatDateStringDDMMYYYY(item?.EndDate),
      'No of Field working days': item?.FieldWorkingDays,
      'Total number of customer activity': item?.TotalOtherTypeActivities,
      'Total Customers': item?.TotalCustomerActivities,
      'Customers Avg': item?.CustomerAvg,
      'Total Retailers': item?.TotalRetailers,
      'Retailers Avg': item?.RetailerAvg,
      'Total Calls': item?.TotalCalls,
      'Total Avg': item?.CallsAvg
    })));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Call Average");

    // Export the workbook
    XLSX.writeFile(workbook, "Call_Average_Report.xlsx");
  };

  return (
    <>
      <div className="markAttendance_section container-fluid">
        <div className="row mb-5">
          <Card className="monthlyClaim_mobile">
            <Card.Body>
              <h4 className="mb-4 text-center">Call Average Report</h4>
              <div className="filter-section mb-4">
                <label style={{ marginRight: '1rem' }}>
                  Start Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    value={startDate}
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    onChange={e => setStartDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: '1rem' }}>
                  End Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: '1rem' }}>
                  EmpId
                  <input
                    className="CommonDropdown form-select"
                    type="text"
                    maxLength={4}
                    style={{
                      width: "8rem",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={filterEmpId}
                    onChange={e => setFilterEmpId(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </label>
                <span onClick={fetchData}>
                  <CommonButton title={"Submit"} className="btn btn-primary" />
                </span>
                {dataFetched && (
                  <span style={{ marginLeft: "10px" }} onClick={handleExport}>
                    <CommonButton title={"Download Excel"} />
                  </span>
                )}
              </div>
              {error && <span className="dcr-form-errors">{error}</span>}
              {loading ? (
                <div>Loading...</div>
              ) : (
                dataFetched && (
                  <div
                    id="table-to-xls"
                    className="table-wrapper-scroll-y custom-scrollbar"
                    style={{ padding: '20px', overflow: 'auto' }}
                  >
                    <table
                      className="w-100"
                      style={{ textAlign: "center" }}
                      ref={tableRef}
                    >
                      <thead>
                        <tr lassName="ladder_heading">
                          <th className="custom-header">Employee Code</th>
                          <th className="custom-header">Employee Name</th>
                          <th className="custom-header">Start Date</th>
                          <th className="custom-header">End Date</th>
                          <th className="custom-header">No of Field working days</th>
                          <th className="custom-header">Total number of customer activity</th>
                          <th className="custom-header">Total Customers</th>
                          <th className="custom-header">Customers Avg</th>
                          <th className="custom-header">Total Retailers</th>
                          <th className="custom-header">Retailers Avg</th>
                          <th className="custom-header">Total Calls</th>
                          <th className="custom-header">Total Avg</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callAvg.length > 0 ? (
                          callAvg.map((item, index) => (
                            <tr key={index} className="ladder_heading_data">
                              <td className='pl-3'>{item?.EmpCode}</td>
                              <td className='pl-3'>{item?.EmployeeName}</td>
                              <td className='pl-3'>{formatDateStringDDMMYYYY(item?.StartDate)}</td>
                              < td className='pl-3' > {formatDateStringDDMMYYYY(item?.EndDate)} </ td >
                              <td className='pl-3'>{item?.FieldWorkingDays}</td>
                              <td className='pl-3'>{item?.TotalOtherTypeActivities}</td>
                              <td className='pl-3'>{item?.TotalCustomerActivities}</td>
                              <td className='pl-3'>{item?.CustomerAvg}</td>
                              <td className='pl-3'>{item?.TotalRetailers}</td>
                              <td className='pl-3'>{item?.RetailerAvg}</td>
                              <td className='pl-3'>{item?.TotalCalls}</td>
                              <td className='pl-3'>{item?.CallsAvg}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              )}
            </Card.Body>
          </Card>
          <ToastContainer />
        </div>
      </div>
    </>
  );
});

export default CallAverageReport;