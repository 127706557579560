import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../Attendance/TodaysPlan.css";
import locationIcon from "./../../assets/images/location-59.svg";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTpDateWiseApi } from "../../services/NTP/getTpDateWiseApi";
import BackButton from "../../Common/BackButton";
import doctorIcon from "./../../assets/images/stethoscope-3539.svg";
import DoctorLogo from "./../../assets/images/doctorBlack.jpg";
import PharmaLogo from "./../../assets/images/pharmaLogo.jpg";
import profileIcon from "./../../assets/images/user-3297.svg";
import Loader from "../../Common/Loader";

const ViewNTP = ({ customers }) => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);

  let { date } = useParams();

  const modifiedTourSrNo = date?.replace(/;/g, "/");

  const [planDateWise, setPlanDateWise] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getTpDateWiseApi(
        modifiedTourSrNo,
        empId,
        accessToken
      );
      if (response?.status === 200) {
        setPlanDateWise(response?.data?.records);
        setLoading(false)
      } else {
        // setError(response?.msg);
      }
    })();
  }, [modifiedTourSrNo, empId, accessToken]);

  return (
    <div>
      <div className="container-fluid">
        <div className="mt-3">
          <BackButton title={`Plan for ${modifiedTourSrNo}`} />
        </div>
        <div className="row">
          {planDateWise?.map((doctor) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <Card className="mt-4 DoctorCard">
                  <Card.Body>
                    <div className="row">
                      <div className="col-4">
                        {doctor?.Customer__r?.Designation__c === "Retailer" ? (
                          <img
                            src={PharmaLogo}
                            style={{
                              height: "7rem",
                              width: "7rem",
                              borderRadius: "15px",
                            }}
                            alt="img"
                          />
                        ) : (
                          <img
                            src={DoctorLogo}
                            style={{
                              height: "7rem",
                              width: "7rem",
                              borderRadius: "15px",
                            }}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="col-8 doctorCard_details">
                        <div className="DoctorPharm_Name">
                          <span className="DoctorPharm_NameTitle">
                            {doctor?.Customer__r?.Name}
                          </span>
                        </div>
                       
                        <span>
                            <img
                              src={doctorIcon}
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                              alt="icon"
                            />
                            {doctor?.Customer__r?.Designation__c}
                          </span>
                        
                        <div
                          className="DoctorPharm_Post"
                          style={{ marginTop: "5px" }}
                        >
                          <span className="mr-1">
                          <img
                            src={profileIcon}
                            alt="profile"
                            style={{
                              height: "1rem",
                              marginRight: "3px",
                              marginBottom: "2px",
                            }}
                          />
                          {doctor?.Customer__r?.ContactPerson__c}
                        </span>
                          <span>
                            <img
                              src={mobileIcon}
                              style={{
                                height: "1rem",
                                marginRight: "3px",
                                marginBottom: "2px",
                              }}
                              alt="mobile"
                            />
                            {doctor?.Customer__r?.MobileNumber__c}
                          </span>
                        </div>
                        <div
                          className="DoctorPharm_Post"
                          style={{ marginTop: "5px" }}
                        >
                          <img
                            src={locationIcon}
                            style={{
                              height: "1rem",
                              marginRight: "3px",
                              marginBottom: "2px",
                            }}
                            alt="icon"
                          />
                          {doctor?.Customer__r?.Address__c}
                          <br />
                          <span>{doctor?.Customer__r?.Pincode__c}</span>
                          <br />
                          <span>
                            {doctor?.Customer__r?.State__c},
                            {doctor?.Customer__r?.Country__c}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      {loading && <Loader/>}
    </div>
  );
};

export default ViewNTP;
