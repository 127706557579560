import React from 'react'
import BackButton from '../../Common/BackButton'

const CommunityAndMentorship = () => {
  return (
    <div className=' mt-4'>
      <ul style={{fontSize:'1.2rem'}}>
        <li>Mentor Q&A.</li>
        <li>Product and team news.</li>
        <li>Awards and recognition.</li>
        <li>Calendar of training events.</li>
        <li>Peer-to-peer resources.</li>
      </ul>
    </div>
  )
}

export default CommunityAndMentorship
