import axios from "axios";
import { configs } from "../../config";

export const deleteRequestApi = async (UserId, tourSrNo,place, accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/deleteTPRequestToManager`,
      method: "post",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        EmpId : UserId,
        TPSrNo : tourSrNo,
        Place: place
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };