import axios from "axios";
import { configs } from "../config";
import { Buffer } from "buffer";

export const profileImageChangeApi = async (accessToken, EmployeeId, base64URL) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/sobjects/ContentVersion`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            Title: "Profile.png",
            PathOnClient: "Profile.png",
            FirstPublishLocationId: EmployeeId,
            VersionData: base64URL,
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const getProfileUrlApi = async (accessToken, EmployeeId) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT+Id,ContentDocumentId,Title,PathOnClient,VersionData+FROM+ContentVersion+WHERE+FirstPublishLocationId='${EmployeeId}' ORDER BY CreatedDate DESC LIMIT 1`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const finalProfileUrlApi = async (accessToken, getImageUrl) => {
    const options = {
        url: `${configs?.base_url}${getImageUrl}`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        responseType: 'arraybuffer'
    };
    try {
        const response = await axios(options);
        const base64ImageData = await Buffer.from(
            response.data,
            "binary"
        ).toString("base64");
        return base64ImageData;
    }
    catch (error) {
        console.log(error)
    }
};

