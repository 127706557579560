import axios from "axios";
import { configs } from "../../config";

export const getCustomer360Api = async (customerId ,accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Address__c,Area__c,Category__c,City__c,ContactPerson__c,Country__c,Customer_Type__c,Designation__c,Division__c,DOB__c,Email__c,Focus_Product_1__c,Focus_Product_2__c,Focus_Product_3__c,Hester_Business__c,Id,ISSecCustomer__c,MobileNumber__c,Name,Pincode__c,Population__c,Remarks__c,Route__c,State__c,Therapeutic_Segments__c,Total_Potential__c,Visit_Frequency__c,Whatsapp_Number__c FROM Customer__c where Id='${customerId}'`,
      method: "get",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };