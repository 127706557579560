import React from 'react'
import { Card } from 'react-bootstrap'
import E1 from '../../assets/images/Elearning1.jpg';
import E2 from '../../assets/images/elearning2.jpg';
import E3 from '../../assets/images/elearning3.jpg';
import { Link } from "react-router-dom";

const Elearning1 = () => {

  const eLearning = [
    {
      image : E1,
      heading : 'PERSONALIZED LEARNING',
      description : 'Personalization is an important part of online education. Users’ learning styles differ and users should have flexibility and choice.To personalize the learning experience.',
      link :'/personalized-learning'
    },
    {
      image : E2,
      heading : 'Learning Management System',
      description : 'With LMS, admins and managers have an opportunity to create courses and assessments and distribute them among users. Here are the most important functionalities:',
      link : '/lms'
    },
    {
      image : E3,
      heading : 'Community and Mentorship',
      description : 'It is important to have support from the team and leaders so that learners can ask questions, discuss topics, or share resources. The Community features are:',
      link : '/community-and-mentorship'
    },
  ]

  return (
    <div>
      <div className='row mb-5'>
        {eLearning.map((elearn) => {
          return(
            <div className='col-lg-4 col-md-4 col-sm-12 col-12 mt-4 mb-2'>
              <Link to={elearn.link}>
            <Card className='text-center'>
              <Card.Body>
                <img src={elearn.image}/>
                <h4>{elearn.heading}</h4>
                <p>{elearn.description}</p>
              </Card.Body>
            </Card>
            </Link>
          </div>
          );
        })}
       
      </div>
    </div>
  )
}

export default Elearning1
