import React, { useEffect, useState, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import BackButton from "../../Common/BackButton";
import "./ExpenseClaim.css";
import CommonButton from "../../Common/CommonButton";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { managerExpensesApi, managerExpensesNextApi, fetchAttachmentUrls, fetchBlobBase64 } from "../../services/expenses/managerExpensesApi";
import Loader from "../../Common/Loader";
import { managerClaimActionApi } from "../../services/expenses/managerClaimActionApi";
import { getExpenseClaimDataForManager } from "../../services/expenses/getExpenseClaimDataForManager";
import question from "./../../assets/images/red-question-11802.svg";
import check from "./../../assets/images/check-mark-3280.svg";
import cross from "./../../assets/images/remove.png";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import Modal from "react-bootstrap/Modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { saveAs } from "file-saver";
import FailButton from "../../Common/FailButton";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendFirebaseNotificationApi } from "../../services/firebase/sendFirebaseNotification";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ApproveClaim = () => {
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const empDetails = useSelector((state) => state?.userDetails?.details);
  const managerId = useSelector((state) => state?.userDetails?.details?.Id);


  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [attachmentUrls, setAttachmentUrls] = useState();
  const [contentTypes, setContentTypes] = useState();
  const [rejectReason, setRejectReason] = useState();
  const [rejectId, setRejectId] = useState();
  const [rejectionReason, setRejectionReason] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [filterEmpId, setFilterEmpId] = useState('');
  const [filteredClaims, setFilteredClaims] = useState(claims);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [claimsDataForManager, setClaimsDataForManager] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastRecordId, setLastRecordId] = useState('');
  const [hasMore, setHasMore] = useState(true);

  const tableRef = useRef(null);

  const getExpenses = useCallback(async () => {
    if (designation == "Admin" || designation == "Associate Vice President") {
      return;
    }
    setLoading(true);
    try {
      const result = await managerExpensesApi(empDetails.Id, accessToken);
      let allClaims = []
      let batchDone = true;
      let nextRecordsUrl = "";
      if (result) {
        allClaims = result.data.records;
        batchDone = result.data.done;
        nextRecordsUrl = result.data.nextRecordsUrl;
      }

      if (!result.data.done) {
        while (batchDone === false) {
          const response = await managerExpensesNextApi(nextRecordsUrl, accessToken);
          allClaims = [...allClaims, ...response.data.records];

          setClaims(allClaims);
          batchDone = response.data.done;
          nextRecordsUrl = response.data.nextRecordsUrl;
        }
      }
      setClaims(allClaims);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch expenses', error);
      setLoading(false);
    }
  }, [empDetails.Id, accessToken]);

  useEffect(() => {
    setFilteredClaims(claims);
  }, [claims]);


  const handleClaimAction = async (claimId, status, claim) => {
    if (status === "Rejected" && (!rejectReason || !rejectReason.trim())) {
      setLoading(false);
      setRejectionReason(true);
      return;
    }
    setLoading(true);
    let claimObj = {
      expenseClaimId: claimId,
      Status: status,
      ClaimRemark: status === ("Approved" || "Released") ? '' : rejectReason,
      isAVP: (designation === "Associate Vice President" || designation === "General Manager") ? true : false
    };
    try {
      const result = await managerClaimActionApi(claimObj, accessToken);

      setLoading(false);
      setRejectModal(false);

      // Directly update the state without needing a full re-fetch
      setClaims((prevClaims) =>
        prevClaims.map((item) =>
          item.Id === claimId ? { ...item, Status__c: status } : item
        )
      );

      // await sendFirebaseNotificationApi(
      //   claim.Expense_Claim_Approval__c,
      //   "Expense Claim",
      //   `Your claim has been ${status}`,
      //   accessToken
      // );
      if (status === "Approved" || status === "Released") {
        toast.success("Expense claim approved successfully.");
        getExpenses();
        getManagerData();
      } else if (status === "Rejected") {
        toast.error("Expense claim rejected successfully.");
        getExpenses();
        getManagerData();
      }
      else {
        getManagerData()
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // const handleInputChangeemp = (e) => {
  //   setFilterEmpId(e.target.value);
  //   if (e.target.value === '') {
  //     setFilteredClaims(claims);
  //   }
  // };


  // const handleSubmit = () => {
  //   if (filterEmpId) {
  //     const filtered = claims.filter(claim =>
  //       claim.Contact__r?.EmpId__c === filterEmpId
  //     );
  //     setFilteredClaims(filtered);
  //   } else {
  //     setFilteredClaims(claims);
  //   }
  // };

  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };
  const handleshowRejecteModal = (claimId, status) => {
    setRejectId(claimId);
    setRejectModal(!rejectModal);
  };
  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleApproveClick = async (urls, FileType) => {
    try {
      if (
        FileType.toLowerCase() === "png" ||
        FileType.toLowerCase() === "jpg" ||
        FileType.toLowerCase() === "jpeg"
      ) {
        setContentTypes("png"); // Set to "jpeg" as a generic type for images
        setAttachmentUrls(urls);
      } else if (FileType.toLowerCase() === "pdf") {
        setContentTypes("pdf");
        setAttachmentUrls(urls);
      } else {
        console.log("Unsupported file type");
      }

      setShowApproveModal(true);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  const downloadImage = (base64Data, contentType) => {
    const url = `data:image/${contentType};base64,${base64Data}`;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "attachment_image";
    anchor.click();
  };

  const handleDownloadPdf = () => {
    const byteCharacters = atob(attachmentUrls);
    const byteNumbers = new Array(byteCharacters?.length);
    for (let i = 0; i < byteCharacters?.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, "attachment.pdf");
  };

  const pendingMessage = `Pending - ${claims?.filter((claim) => claim.Status__c === "Pending For Approval" || claim.Status__c === "Pending for Release")?.length
    }`;
  const approvedMessage = `Approved - ${claims?.filter((claim) => claim.Status__c === "Approved" || claim.Status__c === "Released")?.length
    }`;
  const rejectedMessage = `Rejected - ${claims?.filter((claim) => claim.Status__c === "Rejected")?.length
    }`;

  const pendingcount = claimsDataForManager?.filter(item => {
    const expenseClaims = item.ExpenseClaim;
    return expenseClaims.Status__c === "Pending For Approval" || expenseClaims.Status__c === "Pending for Release";
  }).length

  const pendingMessageForManager = `Pending - ${pendingcount}`;

  const approvependingcount = claimsDataForManager?.filter(item => {
    const expenseClaims = item.ExpenseClaim;
    return expenseClaims.Status__c === "Approved" || expenseClaims.Status__c === "Released";
  }).length

  const approveMessageForManager = `Approved - ${approvependingcount}`;

  const rejectedCountForManager = claimsDataForManager?.filter(item => {
    const expenseClaims = item.ExpenseClaim;
    return expenseClaims.Status__c === "Rejected";
  }).length

  const rejectedMessageForManager = `Rejected - ${rejectedCountForManager}`;



  useEffect(() => {
    getExpenses();
  }, [getExpenses]);

  // Download file click on View Attachment

  const handleViewAttachment = async (claimId, claimURL) => {
    setLoading(true)
    try {
      if (claimId) {
        // Fetch the attachment for the specific claim
        const attachment = await fetchAttachmentUrls(claimId, accessToken);

        if (attachment && attachment.VersionData) {
          // Convert the attachment data to base64
          const base64Data = await fetchBlobBase64(attachment.VersionData, accessToken);
          const contentType = attachment.FileType || 'application/octet-stream';

          // Find and update the specific claim
          const updatedClaims = claims.map((claim) =>
            claim.Id === claimId
              ? { ...claim, url: base64Data, contentType: contentType }
              : claim
          );

          // Update the state with the new claims array
          setClaims(updatedClaims);

          // Call handleApproveClick with the updated URL
          handleApproveClick(base64Data, attachment?.FileType);
        } else {
          // If no attachment found, pass the original claimURL
          handleApproveClick(claimURL);
        }
      }
    } catch (error) {
      console.error("Error fetching attachment:", error);
      // On error, fall back to the original claimURL
      handleApproveClick(claimURL);
    }
    setLoading(false)
  };

  const getManagerData = async (loadMore = false, id = '') => {
    try {
      // Validate date range
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDifference = end - start;
      const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days
      console.log("running on approve")
      const limitSize = 5000

      if (dayDifference > 35) {
        setErrorMessage("Please choose a start date and end date with a maximum difference of 35 days.");
        return;
      } else {
        setErrorMessage("");
      }

      setLoading(true);
      const ResponseData = await getExpenseClaimDataForManager(accessToken, filterEmpId, startDate, endDate, managerId, loadMore ? id : '', limitSize);
      const response = JSON.parse(ResponseData)

      if (!response) {
        setClaimsDataForManager([]);
        setHasMore(false);
        return;
      }
      const { lastRecordId, expenseClaims } = response;
      if (loadMore) {
        // Append new sorted records to the existing data
        setClaimsDataForManager((prevData) => [...prevData, ...expenseClaims]);
      } else {
        // Set sorted data for the first load
        setClaimsDataForManager(expenseClaims);
      }
      setLastRecordId(lastRecordId);
      setHasMore(!!lastRecordId);
      console.log("Fetched and Sorted Records (Ascending):", expenseClaims);

      // setClaimsDataForManager(response);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Ensure loading state is reset on error
    }
  }

  const handleLoadMore = () => {
    if (hasMore) {
      getManagerData(true, lastRecordId); // Pass true for load more and the lastRecordId
    }
  };

  const handleButtonSubmit = async () => {
    getManagerData()
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError('Please enter only numbers.');
    } else {
      setError('');
    }
  };

  const getClaimDate = (expenseClaims) => {
    switch (expenseClaims.Allowance_Type__c) {
      case "Local Conveyance":
        return expenseClaims?.From_Date__c || expenseClaims?.To_Date__c;
      case "Travel Allowance":
        return expenseClaims?.TA_Arrival_Date__c || expenseClaims?.TA_Depature_Date__c;
      case "Daily Allowance":
        return expenseClaims?.From_Date__c || expenseClaims?.DADate__c;
      default:
        return expenseClaims?.Date__c;
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Helmet>
        <title>Approve Claim</title>
      </Helmet>
      <div className="m-4">
        <div className="row mb-4">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="mb-2">
              <BackButton title={"Approve Claims"} />
            </div>
          </div>
        </div>
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          >
            {designation === "Admin" || designation === "Associate Vice President" ? (
              <Tab
                eventKey="Pending"
                title={pendingMessageForManager}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {(designation === "Associate Vice President" || designation === "Admin") && (
                      <>
                        <div className="filter-section mb-4">
                          <label style={{ marginRight: '1rem' }}>
                            Start Date
                            <input
                              className="CommonDropdown form-select"
                              type="date"
                              value={startDate}
                              style={{
                                width: "100%",
                                color: "rgb(46, 49, 146)",
                                fontSize: "14px",
                                backgroundImage: "none",
                                paddingRight: "14px",
                              }}
                              onChange={e => setStartDate(e.target.value)}
                            />
                          </label>
                          <label style={{ marginRight: '1rem' }}>
                            End Date
                            <input
                              className="CommonDropdown form-select"
                              type="date"
                              style={{
                                width: "100%",
                                color: "rgb(46, 49, 146)",
                                fontSize: "14px",
                                backgroundImage: "none",
                                paddingRight: "14px",
                              }}
                              value={endDate}
                              onChange={e => setEndDate(e.target.value)}
                            />
                          </label>
                          <label style={{ marginRight: '1rem' }}>
                            EmpId
                            <input
                              className="CommonDropdown form-select"
                              type="text"
                              maxLength={4}
                              style={{
                                width: "8rem",
                                color: "rgb(46, 49, 146)",
                                fontSize: "14px",
                                backgroundImage: "none",
                                paddingRight: "14px",
                              }}
                              value={filterEmpId}
                              onChange={e => setFilterEmpId(e.target.value)}
                              onKeyPress={handleKeyPress}
                            />
                          </label>
                          <span onClick={handleButtonSubmit}>
                            <CommonButton title={"Submit"} className="btn btn-primary" />
                          </span>
                          {/* {dataFetched && (

                                <span style={{ marginLeft: "10px" }} onClick={exportToExcel}>
                                  <CommonButton title={'Download Excel'} className="btn btn-primary" />
                                </span>
                              )} */}

                          {errorMessage && (
                            <div style={{ color: 'red', marginTop: '0.5rem' }}>
                              {errorMessage}
                            </div>
                          )}

                        </div>
                      </>
                    )}
                    {/* {claims?.filter(data => data.status === "Pending For Approval")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                    {claimsDataForManager?.filter(item => {
                      const expenseClaims = item.ExpenseClaim;
                      return expenseClaims.Status__c === "Pending For Approval" || expenseClaims.Status__c === "Pending for Release";
                    }).length > 0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  <th className="custom-header">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {claimsDataForManager.slice().sort((a, b) => {
                                  const aExpenseClaims = a.ExpenseClaim;
                                  const bExpenseClaims = b.ExpenseClaim;
                                  const aDate = getClaimDate(aExpenseClaims);
                                  const bDate = getClaimDate(bExpenseClaims);
                                  return new Date(bDate) - new Date(aDate); // Sort in descending order
                                }).map((item, index) => {
                                  const expenseClaims = item.ExpenseClaim;
                                  if (expenseClaims.Status__c === "Pending For Approval" || expenseClaims.Status__c === "Pending for Release") {
                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (expenseClaims.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(expenseClaims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(expenseClaims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(expenseClaims?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(expenseClaims?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (expenseClaims?.From_Date__c && expenseClaims?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(expenseClaims?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(expenseClaims?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            expenseClaims?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          expenseClaims?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = expenseClaims?.CreatedDate?.split("T")

                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>{formatDateStringDDMMYYYY(transactionDate?.[0])}</td>
                                        <td>{expenseClaims?.Contact__r?.EmpId__c}</td>
                                        <td>{expenseClaims?.Contact__r?.Name}</td>
                                        <td>{expenseClaims?.Contact__r?.Division__c}</td>
                                        <td>{expenseClaims?.Place_From__c || expenseClaims?.TA_Depature_Station__c}</td>
                                        <td>{expenseClaims?.Place_To__c || expenseClaims?.TA_Arrival_Station__c || expenseClaims?.City__c}</td>
                                        <td>{expenseClaims?.Distance_Travelled_Km__c}</td>
                                        <td>{expenseClaims?.Allowance_Type__c}</td>
                                        <td>{expenseClaims.Daily_Allowance_Type__c || expenseClaims?.Local_Conveyance_Type__c || expenseClaims?.Travel_allowance_Type__c || expenseClaims?.Type_of_General_Expense__c}</td>
                                        <td>{expenseClaims?.Daily_Allowance__c || expenseClaims?.GE_Claim_Amount__c || expenseClaims?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims.url
                                              )
                                            }
                                          > */}
                                          {expenseClaims.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(expenseClaims.Id, expenseClaims.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(expenseClaims.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                          {/* {Claims.hasAttachments__c &&
                                          <button onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            {String(Claims.hasAttachments__c)}
                                          </button>
                                        } */}
                                          {/* s */}
                                        </td>

                                        <td>
                                          {" "}
                                          {(expenseClaims?.Status__c === "Saved" ||
                                            expenseClaims?.Status__c ===
                                            "Pending For Approval" ||
                                            expenseClaims?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {expenseClaims?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(expenseClaims?.Status__c === "Approved" || expenseClaims?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {expenseClaims?.Status__c}
                                        </td>
                                        {(expenseClaims?.Status__c ===
                                          "Pending For Approval" || expenseClaims.Status__c === "Pending for Release") && (
                                            <td className="reject-commomn-button">
                                              <span
                                                onClick={() => {
                                                  const action = (designation === "Associate Vice President" || designation === "General Manager") ? "Release" : "Approved";
                                                  handleClaimAction(expenseClaims.Id, action, expenseClaims);
                                                }}

                                              >
                                                <CommonButton
                                                  title={"Approve"}
                                                  width={"5rem"}
                                                />
                                              </span>
                                              <span
                                                onClick={() => {
                                                  setSelectedClaim(expenseClaims);
                                                  handleshowRejecteModal(
                                                    expenseClaims.Id,
                                                    "Rejected"
                                                  );
                                                  // handleClaimAction(Claims.sfId, "Rejected");
                                                }}
                                                className="reject-button"
                                              >
                                                <CommonButton
                                                  title={"Reject"}
                                                  width={"5rem"}
                                                />
                                              </span>
                                            </td>
                                          )}
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                  {(hasMore && (claimsDataForManager.length > 0)) &&
                    <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
                      <CommonButton title={"Load More"} />
                    </span>
                  }
                </div>
              </Tab>
            ) : (

              <Tab
                eventKey="Pending"
                title={pendingMessage}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {/* {claims?.filter(data => data.status === "Pending For Approval")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                    {claims?.filter(
                      (data) => (data?.Status__c === "Pending For Approval" || data?.Status__c === "Pending for Release")
                    )?.length > 0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  <th className="custom-header">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredClaims?.map((Claims, index) => {
                                  if (Claims.Status__c === "Pending For Approval" || Claims.Status__c === "Pending for Release") {
                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (Claims.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            Claims?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = Claims?.CreatedDate?.split("T")

                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>{formatDateStringDDMMYYYY(transactionDate?.[0])}</td>
                                        <td>{Claims.Contact__r?.EmpId__c}</td>
                                        <td>{Claims?.Contact__r?.Name}</td>
                                        <td>{Claims?.Contact__r?.Division__c}</td>
                                        <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                        <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                        <td>{Claims?.Distance_Travelled_Km__c}</td>
                                        <td>{Claims?.Allowance_Type__c}</td>
                                        <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                        <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims.url
                                              )
                                            }
                                          > */}
                                          {Claims.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(Claims.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                          {/* {Claims.hasAttachments__c &&
                                          <button onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            {String(Claims.hasAttachments__c)}
                                          </button>
                                        } */}
                                          {/* s */}
                                        </td>

                                        <td>
                                          {" "}
                                          {(Claims?.Status__c === "Saved" ||
                                            Claims?.Status__c ===
                                            "Pending For Approval" ||
                                            Claims?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {Claims?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {Claims?.Status__c}
                                        </td>
                                        {(Claims?.Status__c ===
                                          "Pending For Approval" || Claims.Status__c === "Pending for Release") && (
                                            <td className="reject-commomn-button">
                                              <span
                                                onClick={() => {
                                                  const action = (designation === "Associate Vice President" || designation === "General Manager") ? "Release" : "Approved";
                                                  handleClaimAction(Claims.Id, action, Claims);
                                                }}

                                              >
                                                <CommonButton
                                                  title={"Approve"}
                                                  width={"5rem"}
                                                />
                                              </span>
                                              <span
                                                onClick={() => {
                                                  setSelectedClaim(Claims);
                                                  handleshowRejecteModal(
                                                    Claims.Id,
                                                    "Rejected"
                                                  );
                                                  // handleClaimAction(Claims.sfId, "Rejected");
                                                }}
                                                className="reject-button"
                                              >
                                                <CommonButton
                                                  title={"Reject"}
                                                  width={"5rem"}
                                                />
                                              </span>
                                            </td>
                                          )}
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                </div>
              </Tab>
            )}

            {designation === "Admin" || designation === "Associate Vice President" ? (
              <Tab
                eventKey="Approved"
                title={approveMessageForManager}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {claimsDataForManager?.filter(item => {
                      const expenseClaims = item.ExpenseClaim;
                      return expenseClaims.Status__c === "Approved" || expenseClaims.Status__c === "Released" || expenseClaims.Status__c === "Release";
                    }).length > 0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  {/* <th className="custom-header">Remark</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {claimsDataForManager?.map((item, index) => {
                                  const expenseClaimsApproved = item.ExpenseClaim;
                                  if (expenseClaimsApproved.Status__c === "Approved" || expenseClaimsApproved.Status__c === "Released") {
                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (expenseClaimsApproved.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (expenseClaimsApproved?.From_Date__c && expenseClaimsApproved?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(expenseClaimsApproved?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            expenseClaimsApproved?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          expenseClaimsApproved?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = expenseClaimsApproved?.CreatedDate?.split("T")
                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>
                                          {formatDateStringDDMMYYYY(
                                            transactionDate?.[0]
                                          )}
                                        </td>
                                        <td>{expenseClaimsApproved.Contact__r?.EmpId__c}</td>
                                        <td>{expenseClaimsApproved.Contact__r?.Name}</td>
                                        <td>{expenseClaimsApproved.Contact__r?.Division__c}</td>
                                        <td>{expenseClaimsApproved?.Place_From__c || expenseClaimsApproved?.TA_Depature_Station__c}</td>
                                        <td>{expenseClaimsApproved?.Place_To__c || expenseClaimsApproved?.TA_Arrival_Station__c || expenseClaimsApproved?.City__c}</td>
                                        <td>{expenseClaimsApproved?.Distance_Travelled_Km__c}</td>
                                        <td>{expenseClaimsApproved?.Allowance_Type__c}</td>
                                        <td>{expenseClaimsApproved.Daily_Allowance_Type__c || expenseClaimsApproved?.Local_Conveyance_Type__c || expenseClaimsApproved?.Travel_allowance_Type__c || expenseClaimsApproved?.Type_of_General_Expense__c}</td>
                                        <td>{expenseClaimsApproved?.Daily_Allowance__c || expenseClaimsApproved?.GE_Claim_Amount__c || expenseClaimsApproved?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims?.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims?.url
                                              )
                                            }
                                          >
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span>
                                        )} */}
                                          {expenseClaimsApproved.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(expenseClaimsApproved.Id, expenseClaimsApproved.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(expenseClaimsApproved.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                        </td>

                                        <td>
                                          {" "}
                                          {(expenseClaimsApproved?.Status__c === "Saved" ||
                                            expenseClaimsApproved?.Status__c ===
                                            "Pending For Approval" ||
                                            expenseClaimsApproved?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {expenseClaimsApproved?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(expenseClaimsApproved?.Status__c === "Approved" || expenseClaimsApproved?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {expenseClaimsApproved?.Status__c}
                                        </td>
                                        {/* <td>{Claims?.rejectionReason}</td> */}
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                </div>
              </Tab>
            ) : (
              <Tab
                eventKey="Approved"
                title={approvedMessage}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {/* {claims?.filter(data => data.status === "Approved")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                    {claims?.filter((data) => data.Status__c === "Approved" || data.Status__c === "Released" || data.Status__c === "Release")?.length >
                      0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  {/* <th className="custom-header">Remark</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {claims?.map((Claims, index) => {
                                  if (Claims.Status__c === "Approved" || Claims.Status__c === "Released") {
                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (Claims.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            Claims?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = Claims?.CreatedDate?.split("T")
                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>
                                          {formatDateStringDDMMYYYY(
                                            transactionDate?.[0]
                                          )}
                                        </td>
                                        <td>{Claims.Contact__r?.EmpId__c}</td>
                                        <td>{Claims.Contact__r?.Name}</td>
                                        <td>{Claims.Contact__r?.Division__c}</td>
                                        <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                        <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                        <td>{Claims?.Distance_Travelled_Km__c}</td>
                                        <td>{Claims?.Allowance_Type__c}</td>
                                        <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                        <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims?.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims?.url
                                              )
                                            }
                                          >
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span>
                                        )} */}
                                          {Claims.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(Claims.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                        </td>

                                        <td>
                                          {" "}
                                          {(Claims?.Status__c === "Saved" ||
                                            Claims?.Status__c ===
                                            "Pending For Approval" ||
                                            Claims?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {Claims?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {Claims?.Status__c}
                                        </td>
                                        {/* <td>{Claims?.rejectionReason}</td> */}
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                </div>
              </Tab>
            )}

            {designation === "Admin" || designation === "Associate Vice President" ? (
              <Tab
                eventKey="Deleted"
                title={rejectedMessageForManager}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {claimsDataForManager?.filter(item => {
                      const expenseClaims = item.ExpenseClaim;
                      return expenseClaims.Status__c === "Rejected";
                    }).length > 0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  <th className="custom-header">Remark</th>
                                </tr>
                              </thead>
                              <tbody>
                                {claimsDataForManager?.map((item, index) => {
                                  const rejectedClaims = item.ExpenseClaim;
                                  if (rejectedClaims.Status__c === "Rejected") {

                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (rejectedClaims.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(rejectedClaims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(rejectedClaims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(rejectedClaims?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(rejectedClaims?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (rejectedClaims?.From_Date__c && rejectedClaims?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(rejectedClaims?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(rejectedClaims?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            rejectedClaims?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          rejectedClaims?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = rejectedClaims?.CreatedDate?.split("T")
                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>
                                          {formatDateStringDDMMYYYY(
                                            transactionDate?.[0]
                                          )}
                                        </td>
                                        <td>{rejectedClaims.Contact__r?.EmpId__c}</td>
                                        <td>{rejectedClaims.Contact__r?.Name}</td>
                                        <td>{rejectedClaims.Contact__r?.Division__c}</td>
                                        <td>{rejectedClaims?.Place_From__c || rejectedClaims?.TA_Depature_Station__c}</td>
                                        <td>{rejectedClaims?.Place_To__c || rejectedClaims?.TA_Arrival_Station__c || rejectedClaims?.City__c}</td>
                                        <td>{rejectedClaims?.Distance_Travelled_Km__c}</td>
                                        <td>{rejectedClaims?.Allowance_Type__c}</td>
                                        <td>{rejectedClaims.Daily_Allowance_Type__c || rejectedClaims?.Local_Conveyance_Type__c || rejectedClaims?.Travel_allowance_Type__c || rejectedClaims?.Type_of_General_Expense__c}</td>
                                        <td>{rejectedClaims?.Daily_Allowance__c || rejectedClaims?.GE_Claim_Amount__c || rejectedClaims?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims?.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims?.url
                                              )
                                            }
                                          >
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span>
                                        )} */}
                                          {rejectedClaims.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(rejectedClaims.Id, rejectedClaims.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(rejectedClaims.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                        </td>

                                        <td>
                                          {" "}
                                          {(rejectedClaims?.Status__c === "Saved" ||
                                            rejectedClaims?.Status__c ===
                                            "Pending For Approval" ||
                                            rejectedClaims?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {rejectedClaims?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(rejectedClaims?.Status__c === "Approved" || rejectedClaims?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {rejectedClaims?.Status__c}
                                        </td>
                                        <td>{rejectedClaims?.rejectionReason}</td>
                                      </tr>
                                    );
                                  }
                                })
                                }
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                </div>
              </Tab>
            ) : (
              <Tab
                eventKey="Deleted"
                title={rejectedMessage}
                className="markAttendance_tabsSection mt-4"
                style={{ fontFamily: 'GilroyMedium' }}
              >
                <div className="markAttendance_section container-fluid">
                  <div className="row mb-5">
                    {/* {claims?.filter(data => data.status === "Rejected")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                    {claims?.filter((data) => data.Status__c === "Rejected")?.length >
                      0 ? (
                      <Card className="monthlyClaim_mobile">
                        <Card.Body>
                          <h4 className="mb-4" style={{ textAlign: "center" }}>
                            Monthly Claim Status
                          </h4>
                          <div
                            id="table-to-xls"
                            className="table-wrapper-scroll-y custom-scrollbar"
                          >
                            <table
                              className="w-100"
                              style={{ textAlign: "center" }}
                              ref={tableRef}
                            >
                              <thead>
                                <tr className="ladder_heading">
                                  {/* <th>Claim Id</th> */}
                                  <th className="custom-header">Expense Date</th>
                                  <th className="custom-header">
                                    Transaction Date
                                  </th>
                                  <th className="custom-header">Employee Id</th>
                                  <th className="custom-header">Employee Name</th>
                                  <th className="custom-header">Department</th>
                                  <th className="custom-header">From Place</th>
                                  <th className="custom-header">To Place</th>
                                  <th className="custom-header">Distance (km)</th>
                                  <th className="custom-header">Claim Type</th>
                                  <th className="custom-header">
                                    Claim Sub Type
                                  </th>
                                  <th className="custom-header">Amount</th>
                                  <th className="custom-header">
                                    View Attachment
                                  </th>
                                  <th className="custom-header">Status</th>
                                  <th className="custom-header">Remark</th>
                                </tr>
                              </thead>
                              <tbody>
                                {claims?.map((Claims, index) => {
                                  if (Claims.Status__c === "Rejected") {
                                    let claimDate;
                                    let fromDate;
                                    let toDate;
                                    switch (Claims.Allowance_Type__c) {
                                      case "Local Conveyance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Travel Allowance":
                                        fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                        break;

                                      case "Daily Allowance":
                                        if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                          fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                          toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                          claimDate = `${fromDate} To ${toDate}`;
                                        }
                                        else {
                                          claimDate = formatDateStringDDMMYYYY(
                                            Claims?.DADate__c
                                          );
                                        }
                                        break;

                                      default:
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.Date__c
                                        );
                                        break;
                                    }
                                    let transactionDate = Claims?.CreatedDate?.split("T")
                                    return (
                                      <tr
                                        className="ladder_heading_data"
                                        key={index}
                                      >
                                        {/* <td>{Claims.sfId}</td> */}
                                        {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                        <td>{claimDate}</td>
                                        <td>
                                          {formatDateStringDDMMYYYY(
                                            transactionDate?.[0]
                                          )}
                                        </td>
                                        <td>{Claims.Contact__r?.EmpId__c}</td>
                                        <td>{Claims.Contact__r?.Name}</td>
                                        <td>{Claims.Contact__r?.Division__c}</td>
                                        <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                        <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                        <td>{Claims?.Distance_Travelled_Km__c}</td>
                                        <td>{Claims?.Allowance_Type__c}</td>
                                        <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                        <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                        <td>
                                          {/* {Claims?.url?.length > 0 && (
                                                      <span
                                                        onClick={() =>
                                                          handleApproveClick(
                                                            Claims?.url
                                                          )
                                                        }
                                                      >
                                                        <CommonButton
                                                          title={"View Attachment"}
                                                        />
                                                      </span>
                                                    )} */}
                                          {Claims.hasAttachments__c ?
                                            <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                              <CommonButton
                                                title={"View Attachment"}
                                                onClick={() => handleViewAttachment(Claims.Id)}
                                              />
                                            </span> :
                                            <p>-</p>
                                          }
                                        </td>

                                        <td>
                                          {" "}
                                          {(Claims?.Status__c === "Saved" ||
                                            Claims?.Status__c ===
                                            "Pending For Approval" ||
                                            Claims?.Status__c ===
                                            "Pending for Release") && (
                                              <img
                                                className="MTP_approved"
                                                src={question}
                                                alt=""
                                              />
                                            )}
                                          {Claims?.Status__c === "Rejected" && (
                                            <img
                                              className="MTP_approved"
                                              src={cross}
                                              alt=""
                                            />
                                          )}
                                          {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                            <img
                                              className="MTP_approved"
                                              src={check}
                                              alt=""
                                            />
                                          )}
                                          {Claims?.Status__c}
                                        </td>
                                        <td>{Claims?.rejectionReason}</td>
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    ) : (
                      <h6
                        className="mt-5"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        No records to display
                      </h6>
                    )}
                  </div>
                </div>
              </Tab>
            )}


          </Tabs>
        </div>
        {/* 
        <Card className="monthlyClaim_mobile">
          <Card.Body>
            <h4
              className="mb-4"
              style={{ textAlign: "center" }}
            >
              Monthly Claim Status
            </h4>
            <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
              <table className="w-100" style={{ textAlign: "center" }} ref={tableRef}>
                <thead>
                  <tr className="ladder_heading">
                    <th className="custom-header">Expense Date</th>
                    <th className="custom-header">Transaction Date</th>
                    <th className="custom-header">Employee Id</th>
                    <th className="custom-header">Employee Name</th>
                    <th className="custom-header">Department</th>
                    <th className="custom-header">Claim Type</th>
                    <th className="custom-header">Claim Sub Type</th>
                    <th className="custom-header">Amount</th>
                    <th className="custom-header">View Attachment</th>
                    <th className="custom-header">Status</th>
                    <th className="custom-header">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {claims?.map((Claims, index) => {
                    let claimDate = Claims.claimDate;
                    if (claimDate && claimDate.includes(' to ')) {
                      let [fromDate, toDate] = claimDate.split(' to ');
                      if (fromDate && toDate) {
                        const formatDate = (date) => {
                          const [year, month, day] = date.split('-');
                          const months = [
                            "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                          ];
                          return `${day} ${months[parseInt(month) - 1]} ${year}`;
                        };

                        switch (Claims.claimType) {
                          case "Local Conveyance":
                          case "Daily Allowance":
                          case "Travel Allowance":
                            fromDate = formatDate(fromDate);
                            toDate = formatDate(toDate);
                            claimDate = `${fromDate} To ${toDate}`;
                            break;

                          default:
                            claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                            break;
                        }
                      } else {
                        claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                      }
                    } else {
                      claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                    }
                    return (
                      <tr className="ladder_heading_data" key={index}>
                        <td>{claimDate}</td>
                        <td>{formatDateStringDDMMYYYY(TransactionDate?.[0])}</td>
                        <td>{Claims.Contact__r?.EmpId__c}</td>
                        <td>{Claims.Contact__r?.Name}</td>
                        <td>{Claims.Contact__r?.Division__c}</td>
                        <td>{Claims?.Allowance_Type__c}</td>
                        <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                        <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                        <td>
                          {Claims.attachmentUrls?.length > 0 && (
                            <span onClick={() => handleApproveClick(Claims.attachmentUrls[0].Body, Claims.attachmentUrls[0].ContentType)}>
                              <CommonButton title={"View Attachment"} />
                            </span>
                          )}
                        </td>

                        <td>
                          {" "}
                          {(Claims?.status === "Saved" ||
                            Claims?.status === "Pending For Approval") && (
                              <img className="MTP_approved" src={question} alt="" />
                            )}
                          {Claims?.status === "Rejected" && (
                            <img className="MTP_approved" src={cross} alt="" />
                          )}
                          {Claims?.status === "Approved" && (
                            <img className="MTP_approved" src={check} alt="" />
                          )}
                          {Claims?.status}
                        </td>
                        {Claims?.status === "Pending For Approval" && (
                          <td>
                            <span
                              onClick={() => {
                                handleClaimAction(Claims.sfId, "Approved");
                              }}
                            >
                              <CommonButton title={"Approve"} />
                            </span>
                            <span
                              onClick={() => {
                                handleshowRejecteModal(Claims.sfId, "Rejected")
                              }}
                              style={{ paddingLeft: "10px" }}
                            >
                              <CommonButton title={"Reject"} />
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card> */}
      </div>
      <Modal
        show={showApproveModal}
        onHide={handleshowApproveModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>View Attachment</h5>
          {(contentTypes === "png" ||
            contentTypes === "jpg" ||
            contentTypes === "jpeg") && (
              <span onClick={() => downloadImage(attachmentUrls, "jpeg")}>
                <DownloadForOfflineIcon
                  style={{
                    fontSize: 30,
                    color: "#00a87e",
                    float: "right",
                    marginLeft: "1rem",
                  }}
                />
              </span>
            )}
          {contentTypes === "pdf" && (
            <span onClick={handleDownloadPdf}>
              <DownloadForOfflineIcon
                style={{
                  fontSize: 30,
                  color: "#00a87e",
                  float: "right",
                  marginLeft: "1rem",
                }}
              />
            </span>
          )}
          {/* <span onClick={() => downloadImage(attachmentUrls)}>
            <DownloadForOfflineIcon style={{ fontSize: 30, color: '#00a87e', float: 'right', marginLeft: '1rem' }} />
          </span> */}
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row text-center">
            {/* <img src={attachmentUrls} alt="Attachment" className="img-fluid" /> */}
            {(contentTypes === "png" ||
              contentTypes === "jpg" ||
              contentTypes === "jpeg") && (
                <img
                  src={`data:image/jpeg;base64,${attachmentUrls}`}
                  alt="Attachment"
                  className="img-fluid"
                />
              )}
            {contentTypes === "pdf" && (
              // <embed src={`data:application/pdf;base64,${attachmentUrls}`} type="application/pdf" width="100%"/>
              <Document file={`data:application/pdf;base64,${attachmentUrls}`}>
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="" onClick={handleshowApproveModal}>
              <CommonButton
                title={"Ok"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={rejectModal}
        onHide={handleshowRejecteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>Enter Reason For Rejection</h5>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row">
            <p className="PlanMTP_leftHead">Enter the reason</p>
            <div className="container">
              <input
                type="text"
                className="CommonDropdown form-select"
                onChange={handleRejectReasonChange}
                style={{
                  width: "100%",
                  color: "rgb(46, 49, 146)",
                  fontSize: "14px",
                  backgroundImage: "none",
                  paddingRight: "14px",
                }}
              />
              {rejectionReason && (
                <p style={{ color: "red" }}>
                  Please enter a reason for rejection.
                </p>
              )}
            </div>
            {/* <input type="text" placeholder="Enter the reason" onChange={handleRejectReasonChange} /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="col-6" style={{ marginLeft: "-5.5px" }}>
              <span
                onClick={() => {
                  handleClaimAction(rejectId, "Rejected", selectedClaim);
                }}
              >
                <CommonButton title={"Yes"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowRejecteModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};
