import React from "react";
import Back from "./../assets/images/previous.png";
import { useNavigate } from "react-router-dom";
import ReplyIcon from '@mui/icons-material/Reply';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const BackButton = ({ title }) => {
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <div style={{display:'flex'}}>
      <span onClick={navigateBack} style={{marginTop:'5px'}}>
        <ArrowBackIcon className="back_button"/>
      </span>
      <span
        style={{ marginLeft: "1rem", fontFamily:'GilroyBold',  fontSize: "1.5rem" }}
      >
        {title}
      </span>
    </div>
  );
};

export default BackButton;
