import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SuccessButton from "./SuccessButton";
import FailButton from "./FailButton";
import { Link } from "react-router-dom";
import CommonButton from "./CommonButton";
import Edetail from './../assets/images/edetailModal.png'
import Rehersal from './../assets/images/rehersal.png'

const EdetailingModal = ({ showModal, handleClose, doctorName }) => {
  const handleShowModal = () => {
    handleClose();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey"
    >
      <Modal.Body className="show-grid">
        <div className="row text-center">
          <div
            className="col-6"
            style={{ marginLeft: "-5.5px" }}
            onClick={handleClose}
          >
            <Link to={`/edetailing/${doctorName}`}>
              <h5 style={{color:'black'}}>eDetailing</h5>
              <img src={Edetail} style={{height:'7rem',border:' 1px solid black', borderRadius: '2rem'}}/>
            </Link>
          </div>
          <div className="col-6" onClick={handleClose}>
            <Link to={`/rehearsal/${doctorName}`}>
            <h5 style={{color:'black'}}>Rehersal</h5>
            <img src={Rehersal} style={{height:'7rem',border:' 1px solid black', borderRadius: '2rem'}}/>
            </Link>
          </div>
        </div>
      </Modal.Body>
      
    </Modal>
  );
};

export default EdetailingModal;
