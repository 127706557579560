import React, { useState } from "react";
import Header from "../../Common/Header";
import "./AddNewDoctor.css";
import BackButton from "../../Common/BackButton";
import { useTheme } from "@mui/material/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import NewVisitFilterModal from "./NewVisitFilterModal";
import { getFormatedDate } from "../../Common/functions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateAndTime from "../../Common/DateAndTime";
import Stack from "@mui/material/Stack";
import DoctorCard from "../DoctorCard";
import SuccessButton from "../../Common/SuccessButton";
import FailButton from "../../Common/FailButton";
import Form from "react-bootstrap/Form";
import myImage1 from "./../../assets/images/doctor10.jpg";
import myImage2 from "./../../assets/images/doctor11.png";
import myImage3 from "./../../assets/images/doctor20.png";
import myImage4 from "./../../assets/images/doctor14.png";
import myImage5 from "./../../assets/images/doctor8.jpg";
import myImage6 from "./../../assets/images/doctor6.jpeg";
import filterIcon from "./../../assets/images/filter-6556.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const visitTypes = ["Doctor Visit", "Pharmacy Visit"];

function getStyles(name, visitType, theme) {
  return {
    fontWeight:
      visitType.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddNewVisit = () => {
  const theme = useTheme();
  const [visitType, setVisitType] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDateTime, setShowDateTime] = useState(false);
  const formattedDate = getFormatedDate(new Date());

  const doctorsArray = [
    {
      id: "1",
      image: myImage1,
      name: "Dr. Deepti Sharma",
      lastVisit: "Visited 11-06-2023 9AM",
      profession: "Gynecologist",
      phoneNo: 9828373658,
      area: "Navneet Memorial Hospital",
    },
    {
      id: "2",
      image: myImage2,
      name: "Dr. Rahul Gupta",
      lastVisit: "Visited 13-06-2023 3PM",
      profession: "Gynecologist",
      phoneNo: 9828373659,
      area: "Ansh Clinic Ahmedabad",
    },
    {
      id: "3",
      image: myImage3,
      name: "Dr. Om Bhargav",
      lastVisit: "Visited 14-06-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 9828373652,
      area: "ICON Hospital Pune",
    },
    {
      id: "4",
      image: myImage4,
      name: "Dr. Supriya Aggarwal",
      lastVisit: "Visited 05-06-2023 1PM",
      profession: "Gynecologist",
      phoneNo: 9828373653,
      area: "Sanchetti Hospital Pune",
    },
    {
      id: "5",
      image: myImage5,
      name: "Dr. Siddhartha Mukherjee",
      lastVisit: "Visited 15-06-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 9822876812,
      area: "Ratna Hospital Surat",
    },
    {
      id: "6",
      image: myImage6,
      name: "Dr. Suresh S. Chatterjee",
      lastVisit: "Visited 31-05-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 7709216585,
      area: "Navneet Memorial Hospital",
    },
  ];

  const handleChange = (event) => {
    setVisitType(event.target.value);
    const {
      target: { value },
    } = event;
    setVisitType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };
  const handleDateTimePicker = () => {
    setShowDateTime(!showDateTime);
  };
  return (
    <>
      <NewVisitFilterModal showModal={showModal} handleClose={handleClose} />
      <div>{/* <Header /> */}</div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-lg-8 col-md-10 col-sm-10 col-10">
            <BackButton title={"Add doctors to your beat"} />
          </div>
          <div className="col-lg-4 col-md-2 col-sm-2 col-2" style={{ textAlign: "right" }}>
            <span onClick={handleClose} className="addNewVisit_filterIcon">
              <img
                src={filterIcon}
                style={{
                  height: "2rem",
                  marginRight: "1rem",
                  borderRadius: "10px",
                }}
              />
            </span>
          </div>
        </div>
        <div className="row">
          <DoctorCard doctors={doctorsArray} />
        </div>
      </div>
      {showDateTime && (
        <DateAndTime
          showModal={showDateTime}
          handleClose={handleDateTimePicker}
        />
      )}
    </>
  );
};

export default AddNewVisit;
