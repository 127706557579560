import axios from "axios";
import { configs } from "../../config";

export const getAllNTPApi = async (empId, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=select id,Date__c,Customer__c,status__c, TourPlan_SR_No__c, Type_of_Tour_Plan__c, Place__c, SYD_Status__c, TPStatus__c, Clone_TP_EmpID__c  from Tour_Plan__c where Contact__r.EmpId__c ='${empId}' `,
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
