import React from 'react';
import { Button} from 'react-bootstrap'
import './SuccessButton.css'

const CommonButton = ({title, width}, {icon}) => {
  return (
      <Button className='common_button' style={{width:width,fontFamily:'GilroyMedium'}}><img src={icon}/>{title}</Button>
  )
}

export default CommonButton
