import { SET_PROFILE_IMAGE,FAIL_PROFILE_IMAGE } from "../constants/action-types";
import { finalProfileUrlApi } from "../../services/profileImageChangeApi";


// export const setProfileImageAction = (url) => ({
//   type: SET_PROFILE_IMAGE,
//   payload: url,
// });

export const setProfileImageAction = (accessToken, getImageUrl) => async (dispatch) => {
  try {
      const response = await finalProfileUrlApi(accessToken, getImageUrl); 
    //   console.log(response)
      if (response ) {
          dispatch({
              type: SET_PROFILE_IMAGE,
              payload: response, // Assuming response.data contains the actual data
          });
          return { success: true, dataImage: response }; // Return success and data
      } else {
          dispatch({
              type: FAIL_PROFILE_IMAGE,
          });
          return { success: false, error: "Invalid response" }; // Return failure and error message
      }
  } catch (error) {
      dispatch({
          type: FAIL_PROFILE_IMAGE,
      });
      return { success: false, error: error.message }; // Return failure and error message
  }
};