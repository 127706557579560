import "./SignUpForm.css";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

const SignUpForm = () => {
  const inputLables = [
    { id: Math.random(), label: "First Name", type: "text" },
    { id: Math.random(), label: "Last Name", type: "text" },
    { id: Math.random(), label: "Email address", type: "text" },
    { id: Math.random(), label: "Phone Number", type: "text" },
    { id: Math.random(), label: "Employee Id", type: "text" },
    { id: Math.random(), label: "Password", type: "text" },
    { id: Math.random(), label: "Confirm Password", type: "text" },
    { id: Math.random(), label: "", type: "radio" },
  ];
  return (
    <div className="signup_container">
      <div className="signup_heading">
        <h3>Sign Up</h3>
      </div>
      <div className="signup_form login_content" >
        {inputLables.map((ele) => {
          if (ele.type === "text") {
            return (
              <TextField
            className="login_input"
            label={ele.label}
            color="secondary"
            focused
            style={{ marginBottom: '1rem' }}
          />
            );
          } else if (ele.type === "radio") {
            return (
              <FormControl key={ele.id} className="signUp_radiofield">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            );
          }
        })}
      </div>
      <Stack className="signup_btn" direction="row" spacing={2}>
        <Button variant="contained">Sign Up</Button>
      </Stack>
      <div className="form_footer">

        Have a Account ? <Link to="/login">Sign In</Link>
      </div>
    </div>
  );
};

export default SignUpForm;
