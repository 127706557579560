import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ClockGif from "./../assets/images/clock.gif";
import CommonButton from "./CommonButton";
import { FormControl, FormControlLabel, Radio } from "@mui/material";
import { useSelector } from "react-redux";
import { sendRegularizationApi } from "../services/sendRegularizationApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegularizationModal = ({ regularizations, showModal, handleClose, errorMessage }) => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const [selectedDateId, setSelectedDateId] = useState(""); // Store selected date's Id
  const [errors, setErrors] = useState({
    logIn: '',
    logOut: '',
  });
  const [formData, setFormData] = useState({
    logIn: "",
    logOut: "",
  });

  useEffect(() => {
    setErrors({
      logIn: '',
      logOut: '',
    });
    setFormData({
      logIn: "",
      logOut: "",
    });
    setSelectedDateId("");
  }, [showModal]); // Reset errors and form data when modal opens

  const handleShowModal = () => {
    handleClose();
  };

  const handleInputChange = (id) => {
    console.log("id",id)
    setSelectedDateId(id);
  };

  const validateTime = (time, field) => {
    const timeRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/;
    if (!timeRegex.test(time)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: 'Please enter a valid time in HH:MM format.'
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [field]: ''
      }));
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    if (!regularizations || regularizations.length === 0) {
      handleClose();
      return;
    }
    if (!formData.logIn || !formData.logOut) {
      setErrors({
        loginLogout: 'Login and Logout times are required.'
      });
      return;
    }
    if (errors.logIn || errors.logOut) {
      return;
    }
    try {
      const selectedRegularization = regularizations.find(reg => reg.Id === selectedDateId);
      const selectedDate = selectedRegularization?.Date1__c;

      const resp = await sendRegularizationApi(empId, accessToken, selectedDate, formData.logIn, formData.logOut, selectedDateId);
      if (resp) {
        handleClose();
        toast.success("Attendance regularization marked Successfully.");
      }
    } catch (error) {
      console.error('Error submitting regularization:', error);
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleShowModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="AttendanceModal"
      >
        <Modal.Body className="show-grid">
          <div className="text-center">
            <img src={ClockGif} alt="clock" style={{ height: "8rem", width: "10rem" }} />
          </div>

          {regularizations?.filter(item => item.Date1__c !== null).filter(item => !['WO', 'SL', 'CL', 'PL'].includes(item.Status__c)).map(item => {
            const dateObj = new Date(item.Date1__c);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            const year = dateObj.getFullYear();
            const formattedDate = `${day} ${month} ${year}`;

            return (
              <div key={item.Id} className="text-center">
                <FormControl style={{ fontFamily: 'GilroyMedium' }}>
                  <FormControlLabel
                    value={item.Id}
                    control={<Radio />}
                    label={formattedDate}
                    checked={item.Id === selectedDateId}
                    onChange={() => handleInputChange(item.Id)}
                    style={{ fontFamily: 'GilroyMedium' }}
                  />
                </FormControl>
                {item.Id === selectedDateId && (
                  <div style={{ display: "flex" }}>
                    <input
                      className="CommonDropdown form-select"
                      type="time"
                      min="00:00"
                      max="23:59"
                      step="60"
                      value={formData.logIn}
                      name="logIn"
                      style={{
                        width: "100%",
                        color: "rgb(46, 49, 146)",
                        fontSize: "14px",
                        backgroundImage: "none",
                        paddingRight: "14px",
                      }}
                      onChange={(e) => {
                        onChange(e);
                        validateTime(e.target.value, 'logIn');
                      }}
                    />
                    <input
                      className="CommonDropdown form-select"
                      type="time"
                      min="00:00"
                      max="23:59"
                      step="60"
                      value={formData.logOut}
                      name="logOut"
                      style={{
                        width: "100%",
                        color: "rgb(46, 49, 146)",
                        fontSize: "14px",
                        backgroundImage: "none",
                        paddingRight: "14px",
                      }}
                      onChange={(e) => {
                        onChange(e);
                        validateTime(e.target.value, 'logOut');
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}

          <div>
            {errorMessage === 0 && (
              <div className="text-center">
                <p style={{ fontFamily: 'GilroyMedium' }}>No regularization date available.</p>
              </div>
            )}
            {errors.loginLogout && <span style={{ color: 'red' }}>{errors.loginLogout}</span>}
            <div className="text-center mt-2">
              <span onClick={onSubmit}><CommonButton width={"7rem"} title={"Submit"} /></span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};


export default RegularizationModal;
