import BottomNavbar from "../Common/BottomNavbar";
import Header from "../Common/Header";
import MyProfile from "../Components/MyProfile";

const MyProfilePage = () => {
  return (
    <>
      <div>{/* <Header title={"My Account"} isShowDate={false} /> */}</div>
      <div>
        <MyProfile />
      </div>
      {/* <div>
        <BottomNavbar />
      </div> */}
    </>
  );
};

export default MyProfilePage;
