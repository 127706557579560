/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import BackButton from "../../Common/BackButton";
import CommonDropdown from "../../Common/CommonDropdown";
import "./PlanMTP.css";
import RouteWiseData from "./../MonthlyTour/RouteWiseData";
import { useSelector } from "react-redux";
import { getRoutesApi, getRoutesApiManager } from "../../services/NTP/getRoutesApi";
import { getRouteWiseCustomersApi, getRoutesForManager1 } from "../../services/NTP/getRouteWiseCustomersApi";
import Loader from "../../Common/Loader";
import CommonButton from "../../Common/CommonButton";
import { createNTPOtherTPApi } from "../../services/NTP/createNTPOtherTPApi";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { getTPForEditing } from "../../services/NTP/getTPForEditing";
import Form from "react-bootstrap/Form";
import "../../Common/CommonDropdown.css";

const PlanMTP = () => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const UserId = useSelector((state) => state?.userDetails?.details?.Id);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const division = useSelector(
    (state) => state?.userDetails?.details?.Division__c
  );
  const managerId = useSelector(
    (state) => state?.userDetails?.details?.ReportsToId
  );
  const navigate = useNavigate();
  const { tourNo } = useParams();

  const [customerActivity, setCustomerActivity] = useState(false);
  const [fieldWork, setFieldWork] = useState(false);
  const [routesData, setRoutesData] = useState([]);
  const [error, setError] = useState("");
  const [routeWiseCustomer, setRouteWiseCustomer] = useState([]);
  const [date, setDate] = useState("");
  const [tour, setTour] = useState("");
  const [station, setStation] = useState("");
  const [route, setRoute] = useState("");
  const [routeDesc, setRouteDesc] = useState("");
  const [engagementActivity, setEngagementActivity] = useState("");
  const [SelectedStartTime, setSelectedStartTime] = useState(null);
  const [SelectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [editedCustomers, setEditedCustomers] = useState([]);
  const [tourSrno, setTourSrNo] = useState("");
  const [tourDate, setTourDate] = useState("");
  const [editTour, setEditTour] = useState(false);
  const [selectedPob, setSelectedPob] = useState("");
  const [pobError, setPobError] = useState('');
  const [managerRouteData, setManagerRouteData] = useState();
  const [routeName, setRouteName] = useState();
  const [routeDescription, setRouteDescription] = useState('');
  const [showHq, setShowHq] = useState();
  const [showVseName, setShowVseName] = useState();
  const [selectedVseId, setSelectedVseId] = useState('');
  const [hq, setHq] = useState('');
  const [vseName, setVseName] = useState('');
  const [allVseName, setAllVseName] = useState('');
  const [allHq, setAllHq] = useState('')

  useEffect(() => {
    if (tourNo !== undefined) {
      setEditTour(true);
      setTourSrNo(tourNo.split(":")?.[0]);
      setTourDate(tourNo.split(":")?.[1]);
    }
  }, [tourNo]);

  const validateForm = () => {
    const errors = {};
    if (!date) {
      errors.date = "*Date field is required.";
    }
    if (!station) {
      errors.station = "*Station field is required.";
    }
    if (!tour) {
      errors.tourPlan = "*Tour Plan field is required.";
    }
    if (!SelectedStartTime) {
      errors.startTime = "*Start time field is required.";
    }
    if (!SelectedEndTime) {
      errors.endTime = "*End time field is required.";
    }
    if (!selectedPlace) {
      errors.place = "*Place field is required.";
    }
    if (SelectedStartTime > SelectedEndTime) {
      errors.endTime = "*End time should be more than start time.";
    }
    return errors;
  };

  const handleStartTimeChange = (e) => {
    setSelectedStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setSelectedEndTime(e.target.value);
  };

  const handlePlaceChange = (e) => {
    setSelectedPlace(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleStationChange = (value) => {
    setStation(value);
  };

  const handleEngagementActivityChange = (value) => {
    setEngagementActivity(value);
  };

  const handleActivityChange = async (value) => {
    setTour(value);
    if (value === "Field Work") {
      setFieldWork(true);
      setCustomerActivity(false);
      if (designation === "VSE" || designation === "BE") {
        try {
          const response = await getRoutesApi(UserId, accessToken);
          if (response?.status === 200) {
            setRoutesData(response?.data?.records);
          } else {
            setError(response?.msg);
          }
        } catch (error) {
          setError('An error occurred while fetching routes for VSE.');
        }
      } else if (designation !== "VSE" || designation !== "BE") {
        try {
          const stage = "HQs"
          const vseName = "";
          const hqs = ""
          const response = await getRoutesForManager1(UserId, accessToken, designation, stage, hqs, vseName);

          const parsedResponse = typeof response?.data === 'string' ? JSON.parse(response.data) : response.data;

          if (Array.isArray(parsedResponse)) {
            const hqOptions = parsedResponse.map(item => item.HQ);
            console.log("hqOptions", hqOptions);
            setShowHq(hqOptions);
          } else {
            console.error('Expected an array, but received:', parsedResponse);
          }
          // const managerRoute = await getRoutesApiManager(UserId, designation, accessToken);
          // const dataArray = JSON.parse(managerRoute?.data);
          // const extractedData = dataArray.flatMap(record =>
          //   record.Routes.map(route => ({
          //     Name: route.Name,
          //     StartingPoint: route.StartingPoint,
          //     MidPoint: route.MidPoint,
          //     EndPoint: route.EndPoint
          //   })).map(route => `${route.Name} - ${route.StartingPoint}-${route.MidPoint}-${route.EndPoint}`)
          // );
          // setManagerRouteData(extractedData);
        } catch (error) {
          setError('An error occurred while fetching routes for manager.');
        }
      }
    } else if (value === "Customer Engagement Activity") {
      setCustomerActivity(true);
      setFieldWork(false);
    } else {
      setFieldWork(false);
      setCustomerActivity(false);
    }
  };
  console.log("allHq", allHq)
  // useEffect(() => {
  //   console.log("Component re-rendered");
  // }, [tour, fieldWork, customerActivity, routesData, managerRouteData, error ,routeName,route]);

  const TpEditDataFetch = useCallback(async () => {
    if (tourSrno && tourDate) {
      const response = await getTPForEditing(UserId, tourSrno, tourDate, accessToken);
      if (response?.status === 200) {
        const data = JSON.parse(response?.data);
        console.log("data", data)
        // const customerCount = data?.customers.length * data.POB;
        setDate(data?.TPDate);
        setStation(data?.Station);
        setTour(data?.TourPlan);
        setSelectedPob(data?.FixedPOB);
        setEditedCustomers(data?.customers);
        setAllVseName(data?.VSEName);
        setAllHq(data?.HQ)
        if (data?.TourPlan === "Field Work") {
          setRouteDesc(data?.RouteDesc);
          setRoute(data?.Route);
          setFieldWork(true);
        }
      }
    }
  }, [UserId, tourSrno, tourDate, accessToken]);

  useEffect(() => {
    TpEditDataFetch();
  }, [TpEditDataFetch]);

  const editedCustomerIds = useMemo(() => editedCustomers.map(item => item.doctorId), [editedCustomers]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getRouteWiseCustomersApi(UserId, accessToken, route, designation);
      if (response?.status === 200) {
        setRouteWiseCustomer(response?.data);
        setLoading(false);
      } else {
        setError(response?.msg);
      }
    })();
  }, [UserId, accessToken, route, designation]);

  const routes = useMemo(() => routesData?.map(item => `${item?.Name} - ${item?.Route_Description__c}`), [routesData]);

  const showManagerRoutes = useMemo(() => {
    if (!managerRouteData) return [];
    const flattenedRoutes = managerRouteData
      .filter((routesArray) => routesArray.length > 0)
      .flat();

    // Sort routes alphabetically
    return flattenedRoutes.sort((a, b) => {
      // Extract the route code and name from each string
      const extractCode = (route) => route.split(" - ")[0];
      return extractCode(a).localeCompare(extractCode(b));
    });
  }, [managerRouteData]);


  const handleHqChange = async (e) => {
    const hq = e.target.value;
    setShowVseName([]);
    setSelectedVseId('');
    setManagerRouteData([]);
    setHq(hq)
    const stage = "VSEs"
    const vseName = ""
    const response = await getRoutesForManager1(UserId, accessToken, designation, stage, hq, vseName);
    console.log("response", response);

    const parsedResponse = typeof response?.data === 'string' ? JSON.parse(response.data) : response.data;

    if (Array.isArray(parsedResponse)) {
      const vseOptions = parsedResponse.map(item => ({
        name: item.Name,
        id: item.Id
      }));
      console.log("vseOptions", vseOptions)
      setShowVseName(vseOptions);
    } else {
      console.error('Expected an array, but received:', parsedResponse);
    }
  }

  const handleVseNameChange = async (e) => {
    const selectedName = e.target.value;
    const selectedOption = showVseName.find(option => option.name === selectedName);
    setVseName(selectedName)
    if (selectedOption) {
      setSelectedVseId(selectedOption.id);
      console.log("Selected VSE Name:", selectedName);
      console.log("Selected VSE ID:", selectedOption.id);
      const stage = "Routes"
      const vseId = selectedOption.id
      const response = await getRoutesForManager1(UserId, accessToken, designation, stage, hq, vseId);
      console.log("response", response);
      const parsedResponse = typeof response?.data === 'string' ? JSON.parse(response.data) : response.data;
      console.log("parsedResponse", parsedResponse)
      if (Array.isArray(parsedResponse)) {
        const extractedData = parsedResponse.map(route => ({
          Name: route.Name,
          StartingPoint: route.StartingPoint,
          MidPoint: route.MidPoint,
          EndPoint: route.EndPoint
        })).map(route => `${route.Name} - ${route.StartingPoint}-${route.MidPoint}-${route.EndPoint}`);

        console.log("Formatted Route Data:", extractedData);
        // You can now set this formatted data to the appropriate state
        setManagerRouteData(extractedData);
      } else {
        console.error('Expected an array, but received:', parsedResponse);
      }
    }
  }

  const handleRouteChange = useCallback((value) => {
    if (designation === "VSE" || designation === "BE") {
      const routeDesc = value?.split(" - ")?.[0];
      const routeAllDesc = value?.split(" - ")?.[1];
      const selectedRoute = routesData?.find(route => route?.Route_Description__c === routeDesc);
      setRoute(routeDesc);
      setRouteName(routeDesc);
      setRouteDescription(routeAllDesc)
    }
    else if (designation !== "VSE" && designation !== "BE") {
      const managerRouteDesc = value?.split(" - ")?.[1];
      const selectedManegerRoute = showManagerRoutes.find(route => route.includes(managerRouteDesc));
      if (selectedManegerRoute) {
        const routeCode = selectedManegerRoute.split(" - ")[0];
        const routeDesc = selectedManegerRoute.split(" - ")[1];
        setRoute(routeCode)
        setRouteName(routeCode)
        setRouteDescription(routeDesc)
      } else {
        console.log("No matching route found.");
      }
    }


    // setLoading(true);
    // (async () => {
    //   const response = await getRouteWiseCustomersApi(UserId, accessToken, routeName, designation);
    //   if (response?.status === 200) {
    //     setRouteWiseCustomer(response?.data);
    //     setLoading(false);
    //   } else {
    //     setError(response?.msg);
    //   }
    // })();
  }, [routesData, showManagerRoutes, designation]);

  const handleOtherTPSubmit = useCallback(() => {
    setLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      (async () => {
        const response = await createNTPOtherTPApi(
          UserId,
          date,
          station,
          tour,
          SelectedStartTime,
          SelectedEndTime,
          selectedPlace,
          managerId,
          engagementActivity,
          accessToken,
        );
        if (response?.status === 200) {
          setLoading(false);
          toast.success("Tour plan saved successfully");
          navigate(-1);
        } else {
          setLoading(false);
          toast.error("Unable to create tour plan");
        }
      })();
    } else {
      setFormErrors(errors);
      setLoading(false);
    }
  }, [
    UserId, date, station, tour, SelectedStartTime, SelectedEndTime,
    selectedPlace, managerId, engagementActivity, accessToken, navigate
  ]);

  const handlePobChange = (e) => {
    const value = e.target.value;
    setSelectedPob(value);
  };
  // console.log("")
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setPobError('Please enter only numbers.');
    } else {
      setPobError('');
    }
  };
  const handleWheel = (e) => {
    e.preventDefault(); // Prevent the default scrolling behavior
  };

  const getThreeDaysAgo = () => {
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 7);
    return threeDaysAgo.toISOString().split('T')[0];
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12" style={{ background: "white" }}>
            {tourNo ?
              <BackButton title={"Edit Tour Plan"} />
              :
              <BackButton title={"Create Tour Plan"} />
            }
            <p className="mt-4 PlanMTP_leftHead">Select Day</p>
            <input
              className="CommonDropdown form-select"
              type="date"
              name="date"
              value={date}
              disabled={tourNo}
              // min={getThreeDaysAgo()}
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) => handleDateChange(e)}
              style={{
                width: "100%",
                color: "rgb(46, 49, 146)",
                fontSize: "14px",
                backgroundImage: "none",
                paddingRight: "14px",
              }}
            />
            {formErrors?.date && (
              <span className="PlanMTP_errors">{formErrors?.date}</span>
            )}
            <p className="mt-4 PlanMTP_leftHead">Station</p>
            <CommonDropdown
              className="PlanMTP_leftDrop"
              defaultOption={station ? station : "Select Station"}
              disableEdit={tourNo ? true : false}
              options={["Local", "Ex HQ", "Out Station"]}
              name="station"
              handleDropdownChange={handleStationChange}
            />
            {formErrors?.station && (
              <span className="PlanMTP_errors">{formErrors?.station}</span>
            )}
            <p className="mt-4 PlanMTP_leftHead">Type of Tour Plan</p>
            <CommonDropdown
              handleDropdownChange={handleActivityChange}
              className="PlanMTP_leftDrop"
              defaultOption={tour ? tour : "Select Tour"}
              disableEdit={tourNo ? true : false}
              options={[
                "Customer Engagement Activity",
                "Field Work",
                "Seminar/Conference",
                "Admin Work",
              ]}
            />
            {formErrors?.tourPlan && (
              <span className="PlanMTP_errors">{formErrors?.tourPlan}</span>
            )}

            {division === "AH" && customerActivity && (
              <>
                <p className="mt-4 PlanMTP_leftHead">Type of Customer Engagement</p>
                <CommonDropdown
                  className="PlanMTP_leftDrop"
                  defaultOption="Select Type of Customer Engagement"
                  options={[
                    "Paravet Meeting",
                    "Vet Meeting",
                    "Farmers Meeting",
                    "Sheep & Goat Farmers Meeting",
                  ]}
                  handleDropdownChange={handleEngagementActivityChange}
                />
              </>
            )}
            {division === "PET" && customerActivity && (
              <>
                <p className="mt-4 PlanMTP_leftHead">Type of Customer Engagement</p>
                <CommonDropdown
                  className="PlanMTP_leftDrop"
                  defaultOption="Select Type of Customer Engagement"
                  options={["Vet Meeting", "Retailer Meeting", "Dog Show"]}
                  handleDropdownChange={handleEngagementActivityChange}
                />
              </>
            )}
            {division === "PH" && customerActivity && (
              <>
                <p className="mt-4 PlanMTP_leftHead">Type of Customer Engagement</p>
                <CommonDropdown
                  className="PlanMTP_leftDrop"
                  defaultOption="Select Type of Customer Engagement"
                  options={[
                    "Vet Meeting",
                    "Farmers Meeting",
                    "Vaccinators Meeting",
                    "Supervisor Meeting",
                  ]}
                  handleDropdownChange={handleEngagementActivityChange}
                />
              </>
            )}
            {fieldWork && (
              <>
                {designation !== 'VSE' && (
                  <>
                    <p className="mt-4 PlanMTP_leftHead">HQ</p>
                    <Form.Select
                      aria-label="Default select example"
                      className="CommonDropdown"
                      onChange={(e) => handleHqChange(e)}
                      style={{ fontFamily: 'GilroyMedium' }}
                      disabled={tourNo ? true : false}
                    >
                      {tourNo && (<option key={allHq}>{allHq}</option>)}
                      <option key="">Select Hq</option>
                      {showHq && showHq.map((hq, index) => (
                        <option key={index} value={hq} style={{ fontFamily: 'GilroyMedium' }}>
                          {hq}
                        </option>
                      ))}
                    </Form.Select>

                    <p className="mt-4 PlanMTP_leftHead">Select Name</p>
                    <Form.Select
                      aria-label="Default select example"
                      className="CommonDropdown"
                      onChange={(e) => handleVseNameChange(e)}
                      style={{ fontFamily: 'GilroyMedium' }}
                      disabled={tourNo ? true : false}
                    >
                      {tourNo && (<option key={allVseName}>{allVseName}</option>)}
                      <option key="default">Select Name</option>
                      {showVseName?.map((vse, index) => (
                        <option key={index} value={vse?.name} style={{ fontFamily: 'GilroyMedium' }}>
                          {vse?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                )}

                <p className="mt-4 PlanMTP_leftHead">Route</p>
                <CommonDropdown
                  handleDropdownChange={handleRouteChange}
                  className="PlanMTP_leftDrop"
                  defaultOption={routeDesc ? routeDesc : "Select Route"}
                  disableEdit={tourNo ? true : false}
                  options={(designation === 'VSE' || designation === 'BE') ? routes : showManagerRoutes}
                />
                <p className="mt-4 PlanMTP_leftHead">Enter POB</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  disabled={tourNo}
                  value={selectedPob}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => handlePobChange(e)}
                  onKeyPress={handleKeyPress}
                  onWheel={handleWheel}
                />
                {pobError && <span className="dcr-form-errors">{pobError}</span>}
              </>
            )}
            {!fieldWork && (
              <>
                <p className="mt-4 PlanMTP_leftHead">Select Start Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => handleStartTimeChange(e)}
                />
                {formErrors?.startTime && (
                  <span className="PlanMTP_errors">
                    {formErrors?.startTime}
                  </span>
                )}
                <p className="mt-4 PlanMTP_leftHead">Select End Time</p>
                <input
                  className="CommonDropdown form-select"
                  type="time"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => handleEndTimeChange(e)}
                />
                {formErrors?.endTime && (
                  <span className="PlanMTP_errors">{formErrors?.endTime}</span>
                )}
                <p className="mt-4 PlanMTP_leftHead">Enter Place</p>
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => handlePlaceChange(e)}
                />
                {formErrors?.place && (
                  <span className="PlanMTP_errors">{formErrors?.place}</span>
                )}
                <div
                  onClick={handleOtherTPSubmit}
                  style={{ marginTop: "1rem", marginBottom: "3rem" }}
                >
                  <CommonButton title={"Save"} width={'10rem'} />
                </div>
              </>
            )}
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-5">
            <div className="row">
              <RouteWiseData
                doctors={routeWiseCustomer}
                showCheckbox={true}
                date={date}
                station={station}
                tour={tour}
                routeName={route}
                pob={selectedPob}
                editedCustomerIds={editedCustomerIds}
                editedCustomersData={editedCustomers}
                TpEditDataFetch={TpEditDataFetch}
                editTour={editTour}
                tourSrno={tourSrno}
                routeAllDesc={routeDescription}
                hq={hq}
                vseName={vseName}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </div>
  );
};

export default PlanMTP;