import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { useSelector } from "react-redux";
import { reportDownloadApi } from "../../services/reports/reportDownloadApi";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";
import { reportPOBReportDownloadApi } from "../../services/reports/reportForPOBApi";
import { expenseClaimReportDownloadApi } from "../../services/reports/expenseClaimApi";
import Loader from "../../Common/Loader";
import { toast, ToastContainer } from "react-toastify";

const ExpenseClaimReport = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterEmpId, setFilterEmpId] = useState('');
  const [loading, setLoading] = useState(false);
  const [allClaims, setAllClaims] = useState([]);
  const [error, setError] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [excloading, setExcloading] = useState(false)
  const [lastRecordId, setLastRecordId] = useState('');
  const [hasMore, setHasMore] = useState(true);



  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);

  // const fetchData = async () => {
  //   setLoading(true);


  //   try {

  //     // Fetch data with pagination parameters
  //     const claimResponse = await reportDownloadApi(
  //       accessToken,
  //       filterEmpId,
  //       startDate,
  //       endDate,
  //       "Expense",
  //       division // Pass lastId to fetch the next batch of records
  //     );

  //     const dataArray = claimResponse.data.Expense;
  //     console.log("Fetched Data:", dataArray);

  //     // Exit loop if no more data is returned


  //     // Update lastId to the ID of the last fetched record
  //     // Adjust this according to your data structure


  //     // Filter and sort the accumulated data
  //     const start = startDate ? new Date(startDate) : new Date('1900-01-01');
  //     const end = endDate ? new Date(endDate) : new Date('2100-01-01');
  //     start.setHours(0, 0, 0, 0)
  //     end.setHours(23, 59, 59, 999)

  //     const filteredData = dataArray.filter(item => {
  //       const itemDate = new Date(item.Date__c || item?.From_Date__c || item?.To_Date__c || item?.DADate__c || item?.TA_Arrival_Date__c || item?.TA_Depature_Date__c);
  //       return filterEmpId ?
  //         (itemDate >= start && itemDate <= end && filterEmpId === item?.Contact__r?.EmpId__c) :
  //         (itemDate >= start && itemDate <= end);
  //     });
  //     console.log("filteredData", filteredData)

  //     const sortedData = filteredData.sort((a, b) => {
  //       const dateFieldsA = [a?.To_Date__c, a?.DADate__c, a?.TA_Depature_Date__c, a?.Date__c].map(date => new Date(date)).filter(date => !isNaN(date));
  //       const dateFieldsB = [b?.To_Date__c, b?.DADate__c, b?.TA_Depature_Date__c, b?.Date__c].map(date => new Date(date)).filter(date => !isNaN(date));
  //       const latestDateA = dateFieldsA.length > 0 ? new Date(Math.max(...dateFieldsA)) : new Date('1900-01-01');
  //       const latestDateB = dateFieldsB.length > 0 ? new Date(Math.max(...dateFieldsB)) : new Date('1900-01-01');
  //       return latestDateB - latestDateA;
  //     });
  //     console.log("sortedData", sortedData)
  //     setAllClaims(sortedData);
  //     setDataFetched(true)
  //   } catch (error) {
  //     console.error('Error fetching expense claims:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async (loadMore = false, id = '') => {
    setLoading(true); // Start loading state

    try {
      // Fetch data from API, use the id (lastRecordId) when loading more
      const response = await expenseClaimReportDownloadApi(
        accessToken,
        filterEmpId,
        startDate,
        endDate,
        designation,
        EmployeeId,
        loadMore ? id : '',  // Pass the id (lastRecordId) if loading more
        division
      );

      // Parse the response data
      const dataArray = JSON.parse(response.data);
      console.log("API Response:", dataArray);

      // Check if data and records are available
      if (!dataArray || !dataArray.expenseClaims || dataArray.expenseClaims.length === 0) {
        setAllClaims([]); // Reset data to an empty array

        setHasMore(false); // No more records to fetch
        return;
      }

      const { expenseClaims, lastRecordId } = dataArray;

      // Sort the expense claims by date in ascending order (oldest first)
      const sortedData = expenseClaims.sort((a, b) => {
        const dateFieldsA = [a?.To_Date__c, a?.DADate__c, a?.TA_Depature_Date__c, a?.Date__c]
          .map(date => new Date(date))
          .filter(date => !isNaN(date));
        const dateFieldsB = [b?.To_Date__c, b?.DADate__c, b?.TA_Depature_Date__c, b?.Date__c]
          .map(date => new Date(date))
          .filter(date => !isNaN(date));

        const latestDateA = dateFieldsA.length > 0 ? new Date(Math.max(...dateFieldsA)) : new Date('1900-01-01');
        const latestDateB = dateFieldsB.length > 0 ? new Date(Math.max(...dateFieldsB)) : new Date('1900-01-01');

        // Change to ascending sort (oldest first)
        return latestDateA - latestDateB;
      });

      if (loadMore) {
        console.log("ifffff", sortedData);
        // Append new sorted records to the existing data
        setAllClaims((prevData) => [...prevData, ...sortedData]);
      } else {
        // Set sorted data for the first load
        setAllClaims(sortedData);
      }

      // Update the lastRecordId for pagination
      setLastRecordId(lastRecordId);

      // If there are more records, allow loading more
      setHasMore(!!lastRecordId);

      console.log("Fetched and Sorted Records (Ascending):", sortedData);

      // Set dataFetched based on the presence of data
      setDataFetched(sortedData.length > 0);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Provided employee code is not a valid subordinate.");
        setAllClaims([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setAllClaims([]); // Reset data to an empty array in case of other errors
      }
      setDataFetched(false); // Indicate no data was fetched in case of error
    } finally {
      setLoading(false); // End loading state
    }
  };



  const handleSubmit = () => {
    fetchData();
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchData(true, lastRecordId); // Pass true for load more and the lastRecordId
    }
  };



  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError('Please enter only numbers.');
    } else {
      setError('');
    }
  };

  const exportToExcel = async () => {
    let allData = [];  // Array to store all the fetched data
    let lastRecordIdd = '';  // Keep track of the last fetched record ID
    let hasMore = true;  // Boolean to indicate if more records are available

    setExcloading(true);  // Show loading indicator during export process

    try {
      // Loop until all data is fetched
      while (hasMore) {
        // Fetch a chunk of data
        const response = await expenseClaimReportDownloadApi(
          accessToken,
          filterEmpId,
          startDate,
          endDate,
          designation,
          EmployeeId,
          lastRecordIdd,  // Use lastRecordId to fetch the next batch of records
          division
        );

        // Parse the response
        const dataArray = JSON.parse(response.data);

        // If no records are returned, stop fetching
        if (!dataArray || !dataArray.expenseClaims || dataArray.expenseClaims.length === 0) {
          hasMore = false;
          break;
        }

        // Extract records and the next lastRecordId from the response
        const { expenseClaims, lastRecordId } = dataArray;

        // Merge the new records into allData
        allData = [...allData, ...expenseClaims];

        // Update the lastRecordId for the next API call
        lastRecordIdd = lastRecordId;

        // If there are no more records, stop the loop
        hasMore = !!lastRecordId;
      }

      // Sort the allData array by date in ascending order (oldest first) before exporting to Excel
      const sortedData = allData.sort((a, b) => {
        const dateFieldsA = [a?.To_Date__c, a?.DADate__c, a?.TA_Depature_Date__c, a?.Date__c]
          .map(date => new Date(date))
          .filter(date => !isNaN(date));
        const dateFieldsB = [b?.To_Date__c, b?.DADate__c, b?.TA_Depature_Date__c, b?.Date__c]
          .map(date => new Date(date))
          .filter(date => !isNaN(date));

        const latestDateA = dateFieldsA.length > 0 ? new Date(Math.max(...dateFieldsA)) : new Date('1900-01-01');
        const latestDateB = dateFieldsB.length > 0 ? new Date(Math.max(...dateFieldsB)) : new Date('1900-01-01');

        // Change to ascending sort (oldest first)
        return latestDateA - latestDateB;
      });

      // After fetching and sorting all records, export to Excel
      if (sortedData.length > 0) {
        // Generate Excel file from sortedData
        handleExport(sortedData);
      } else {
        console.log('No data available to export');
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Provided employee code is not a valid subordinate.");
        setAllClaims([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setAllClaims([]); // Reset data to an empty array in case of other errors
      }
    } finally {
      setExcloading(false);  // Hide loading indicator after export process
    }
  };



  const handleExport = (allDataClaims) => {
    if (!allDataClaims || allDataClaims.length === 0) {
      alert('No data available to export');
      return;
    }

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(allDataClaims.map(claim => ({
      'From Date': formatDateStringDDMMYYYY(claim?.From_Date__c) || formatDateStringDDMMYYYY(claim?.TA_Depature_Date__c),
      'To Date': formatDateStringDDMMYYYY(claim.To_Date__c) || formatDateStringDDMMYYYY(claim?.DADate__c) || formatDateStringDDMMYYYY(claim?.TA_Arrival_Date__c) || formatDateStringDDMMYYYY(claim?.Date__c),
      'Employee Id': claim.Contact__r?.EmpId__c || 'N/A',
      'Employee Name': claim.Contact__r?.Name || 'N/A',
      'Head Quarter': claim.Contact__r?.HQ__c || 'N/A',
      'Expense Type': claim?.Allowance_Type__c || 'N/A',
      'Expense Sub Type': claim?.Daily_Allowance_Type__c || claim?.Type_of_General_Expense__c || claim?.Travel_allowance_Type__c || 'N/A',
      'From Place': claim?.TA_Depature_Station__c || claim?.Place_From__c || 'N/A',
      'To Place': claim?.TA_Arrival_Station__c || claim?.Place_To__c || claim?.City__c || 'N/A',
      'Transport Mode': claim?.Local_Conveyance_Type__c || '-',
      'Distance in Kms': claim?.Distance_Travelled_Km__c || '-',
      'Claimed Amount': claim.Allowance_Type__c === 'Travel Allowance' ? claim.TA_Claim_Amount__c :
        claim.Allowance_Type__c === 'Daily Allowance' ? claim.Daily_Allowance__c :
          claim.Allowance_Type__c === 'Local Conveyance' ? claim.GE_Claim_Amount__c :
            claim.Allowance_Type__c === 'General Expense' ? claim.GE_Claim_Amount__c :
              '-',
      'Attendance Status': '-', // Placeholder if needed
      'Status': claim?.Status__c || 'N/A',
      'Auth Name': claim?.Expense_Claim_Approval__r?.Name || 'N/A',
      'Remark': claim?.Claim_Rejection_Reason__c || 'N/A'
    })));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Claim");

    // Export the workbook
    XLSX.writeFile(workbook, "Expense_Claim_Report.xlsx");
  };

  return (
    <>
      {excloading && <Loader />}
      <div className="markAttendance_section container-fluid">
        <div className="row mb-5">
          <Card className="monthlyClaim_mobile">
            <Card.Body>
              <h4 className="mb-4 text-center">Expense Claim Report</h4>
              <div className="filter-section mb-4">
                <label style={{ marginRight: '1rem' }}>
                  Start Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    value={startDate}
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    onChange={e => setStartDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: '1rem' }}>
                  End Date
                  <input
                    className="CommonDropdown form-select"
                    type="date"
                    style={{
                      width: "100%",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </label>
                <label style={{ marginRight: '1rem' }}>
                  EmpId
                  <input
                    className="CommonDropdown form-select"
                    type="text"
                    maxLength={4}
                    style={{
                      width: "8rem",
                      color: "rgb(46, 49, 146)",
                      fontSize: "14px",
                      backgroundImage: "none",
                      paddingRight: "14px",
                    }}
                    value={filterEmpId}
                    onChange={e => setFilterEmpId(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </label>
                <span onClick={handleSubmit}>
                  <CommonButton title={"Submit"} className="btn btn-primary" />
                </span>
                {dataFetched && (

                  <span style={{ marginLeft: "10px" }} onClick={exportToExcel}>
                    <CommonButton title={'Download Excel'} className="btn btn-primary" />
                  </span>
                )}

              </div>
              {error && <span className="dcr-form-errors">{error}</span>}
              {loading ? (
                <div>Loading...</div>
              ) : (
                dataFetched && (
                  <div
                    id="table-to-xls"
                    className="table-wrapper-scroll-y custom-scrollbar"
                    style={{ padding: '20px', overflow: 'auto' }}
                  >
                    <table
                      style={{ textAlign: "center", width: '120%' }}
                      ref={ref}
                    >
                      <thead>
                        <tr className="ladder_heading">
                          <th className="custom-header">From Date</th>
                          <th className="custom-header">To Date</th>
                          <th className="custom-header">Employee Id</th>
                          <th className="custom-header">Employee Name</th>
                          <th className="custom-header">Head Quarter</th>
                          <th className="custom-header">Expense Type</th>
                          <th className="custom-header">Expense Sub Type</th>
                          <th className="custom-header">From Place</th>
                          <th className="custom-header">To Place</th>
                          <th className="custom-header">Transport Mode</th>
                          <th className="custom-header">Distance in Kms</th>
                          <th className="custom-header">Claimed Amount</th>
                          <th className="custom-header">Attendance Status</th>
                          <th className="custom-header">Status</th>
                          <th className="custom-header">Auth Name</th>
                          <th className="custom-header">Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allClaims.map((claim, index) => {
                          console.log("enter")
                          const expenseDate = claim.From_Date__c && claim.To_Date__c ?
                            `${formatDateStringDDMMYYYY(claim.From_Date__c)} - ${formatDateStringDDMMYYYY(claim.To_Date__c)}` :
                            claim.Date__c ? formatDateStringDDMMYYYY(claim.Date__c) :
                              claim.DADate__c ? formatDateStringDDMMYYYY(claim.DADate__c) :
                                claim.TA_Depature_Date__c && claim.TA_Arrival_Date__c ?
                                  `${formatDateStringDDMMYYYY(claim.TA_Depature_Date__c)} - ${formatDateStringDDMMYYYY(claim.TA_Arrival_Date__c)}` :
                                  "-";

                          let amount;
                          switch (claim.Allowance_Type__c) {
                            case "Travel Allowance":
                              amount = claim.TA_Claim_Amount__c;
                              break;
                            case "Daily Allowance":
                              amount = claim.Daily_Allowance__c;
                              break;
                            case "Local Conveyance":
                              amount = claim.GE_Claim_Amount__c;
                              break;
                            case "General Expense":
                              amount = claim.GE_Claim_Amount__c;
                              break;
                            default:
                              amount = "-";
                              break;
                          }

                          return (
                            <tr key={index} className="ladder_heading_data">
                              <td className='pl-3'>{formatDateStringDDMMYYYY(claim?.From_Date__c) || formatDateStringDDMMYYYY(claim?.TA_Depature_Date__c)}</td>
                              <td className='pl-3'>{formatDateStringDDMMYYYY(claim.To_Date__c) || formatDateStringDDMMYYYY(claim?.DADate__c) || formatDateStringDDMMYYYY(claim?.TA_Arrival_Date__c) || formatDateStringDDMMYYYY(claim?.Date__c)}</td>
                              <td className='pl-3'>{claim.Contact__r?.EmpId__c}</td>
                              <td className='pl-3'>{claim.Contact__r?.Name}</td>
                              <td className='pl-3'>{claim.Contact__r?.HQ__c}</td>
                              <td className='pl-3'>{claim?.Allowance_Type__c}</td>
                              <td className='pl-3'>{claim?.Daily_Allowance_Type__c || claim?.Type_of_General_Expense__c || claim?.Travel_allowance_Type__c}</td>
                              <td className='pl-3'>{claim?.TA_Depature_Station__c || claim?.Place_From__c}</td>
                              <td className='pl-3'>{claim?.TA_Arrival_Station__c || claim?.Place_To__c || claim?.City__c}</td>
                              <td className='pl-3'>{claim?.Local_Conveyance_Type__c ? claim?.Local_Conveyance_Type__c : '-'}</td>
                              <td className='pl-3'>{claim?.Distance_Travelled_Km__c ? claim?.Distance_Travelled_Km__c : '-'}</td>
                              <td className='pl-3'>{amount}</td>
                              <td className='pl-3'>-</td>
                              <td className='pl-3'>{claim?.Status__c}</td>
                              <td className='pl-3'>{claim?.Expense_Claim_Approval__r?.Name}</td>
                              <td className='pl-3'>{claim?.Claim_Rejection_Reason__c}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              )}
            </Card.Body>
          </Card>
          <ToastContainer />
          {(hasMore && (allClaims.length > 0)) &&
            <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
              <CommonButton title={"Load More"} />
            </span>
          }
        </div>
      </div>
    </>
  );
});

export default ExpenseClaimReport;