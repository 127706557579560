import React from "react";
import SuccessButton from "../Common/SuccessButton";
import { Link } from "react-router-dom";
import CoomonCalender from "../Common/CoomonCalender";
import "./LeaveApplyComponent.css";
import AttendanceDetails from "./Attendance/AttendanceDetails";
import CommonButton from "../Common/CommonButton";
import BackButton from "../Common/BackButton";
import { useSelector } from "react-redux";

const AttendanceComponent = () => {
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  console.log("designation-->", designation)
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-3 mb-4">
          <div className="col-lg-8 col-md-6 col-sm-12 col-12 mb-3">
            <BackButton title={"Attendance Info"} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mt-md-0 d-flex align-items-center justify-content-end expense-buttons">
            <div className="d-flex">
              <Link to="/leaveApply" className="mr-2">
                <CommonButton title={"Apply For Leave"} width={"8rem"} style={{ marginRight: '0.5rem' }} />
              </Link>
              {(designation !== 'VSE' && designation !== 'BE') &&
                <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                  <Link to="/approve-leave">
                    <CommonButton title={"Approve Leave"} width={"8rem"} />
                  </Link>
                </div>
              }
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 col-md-3 col-sm-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <AttendanceDetails />
            </div>
          </div>
          <div className="row mt-5">
            {/* <div className="col-lg-8 col-md-8 col-sm-12"></div>
          <div className="col-lg-4 col-md-4 col-sm-12 leaveButton">
            <Link to="/leaveApply">
              <CommonButton title={"Apply For Leave"} width={"9rem"} />
            </Link>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceComponent;
