import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

const SearchableDropdown = ({
  options,
  defaultOption = "",
  handleDropdownChange = () => {},
  disableEdit = false,
}) => {
  return (
    <Form.Group>
      <Typeahead
        aria-label="Default select example"
        className="CommonDropdown"
        onChange={(selected) => handleDropdownChange(selected[0])}
        options={options}
        disabled={disableEdit}
        defaultSelected={defaultOption ? [defaultOption] : []}
        placeholder="Search MR..."
      />
    </Form.Group>
  );
};
export default SearchableDropdown;
