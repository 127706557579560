import React from 'react'
import PersonalizedLearning1 from './../Components/Elearning/PersonalizedLearning';
import BackButton from '../Common/BackButton';

const PersonalizedLearning = () => {
  return (
    <div className='container mt-4'>
        <BackButton title={'Personalized Learning'} />
      <PersonalizedLearning1 />
    </div>
  )
}

export default PersonalizedLearning
