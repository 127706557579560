import axios from "axios";
import { configs } from "../config";

export const actualBudgetApiTokenApi = async (accessToken, showId) => {
    const options = {
        url: `https://power-ability-1650.my.salesforce.com/services/apexrest/GetSalesTargetToken`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
    };
    try {
        const response = await axios(options);
        return response?.data?.access_token;
    }
    catch (error) {
        console.log(error)
    }
};
