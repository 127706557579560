import Card from "react-bootstrap/Card";
import "./DoctorCard.css";
import { Link } from "react-router-dom";
import DateAndTime from "../Common/DateAndTime";
import { useState } from "react";
import locationIcon from "./../assets/images/location-59.svg";
import mobileIcon from "./../assets/images/mobile-phone-2640.svg";
import clockIcon from "./../assets/images/circular-wall-clock-black-outline-17714.svg";
import doctorIcon from "./../assets/images/stethoscope-3539.svg";
import DoctorLogo from "../assets/images/doctorBlack.jpg"
import PharmaLogo from "../assets/images/pharmaLogo.jpg"

const formatDateOfBirth = (dob) => {
  const parts = dob.split('.');
  return `${parts[0]}/${parts[1]}/${parts[2]}`;
};

const DoctorCard = ({ showButtons = true, filterDoctors }) => {
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <DateAndTime showModal={showModal} handleClose={handleModal} />

      {filterDoctors.map((doctor,index) => {
        return (
          <div className="col-lg-3 col-md-6 col-sm-12 col-12" key={index}>
            <Card className="mt-4 DoctorCard">
              <Card.Body>
                <Link
                  to={{
                    pathname: `/doctors360/${doctor?.Id}`
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <div className="row">
                    <div className="col-4">
                      {doctor.Category__c === "Retailer" ?
                        <img
                          src={PharmaLogo}
                          style={{
                            height: "6rem",
                            width: "6rem",
                            borderRadius: "15px",
                          }}
                          alt="img"
                        />
                        :
                        <img
                          src={DoctorLogo}
                          style={{
                            height: "6rem",
                            width: "6rem",
                            borderRadius: "15px",
                          }}
                          alt="img"
                        />
                      }

                    </div>
                    <div className="col-8 doctorCard_details">
                      <div className="DoctorPharm_Name">
                        <span className="DoctorPharm_NameTitle">
                          {doctor.Name.length < 15 ? <span>{doctor.Name}</span> :
                            <span>{doctor.Name.slice(0, 15)}...</span>}
                        </span>
                      </div>
                      <div
                        className="DoctorPharm_Name"
                        style={{ marginTop: "5px" }}
                      >
                        <span className="DoctorPharm_lastVisit">
                          <img
                            src={clockIcon}
                            style={{
                              height: "1rem",
                              marginRight: "5px",
                              marginBottom: "2px",
                            }}
                            alt="clock"
                          />
                          <b>DOB: </b>{doctor.DOB__c}
                        </span>
                      </div>
                      <div
                        className="DoctorPharm_Post"
                        style={{ marginTop: "5px" }}
                      >
                        <span className="mr-3">
                          <img
                            src={doctorIcon}
                            style={{
                              height: "1rem",
                              marginRight: "3px",
                              marginBottom: "2px",
                            }}
                            alt="icon"
                          />
                          {doctor?.Designation__c}
                        </span>
                      </div>
                      <div
                        className="DoctorPharm_Post"
                        style={{ marginTop: "5px" }}
                      >
                        <span>
                          <img
                            src={mobileIcon}
                            style={{
                              height: "1rem",
                              marginRight: "3px",
                              marginBottom: "2px",
                            }}
                            alt="mobile"
                          />
                          {doctor?.MobileNumber__c}
                        </span>
                      </div>
                      <div
                        className="DoctorPharm_Post"
                        style={{ marginTop: "5px" }}
                      >
                        <img
                          src={locationIcon}
                          style={{
                            height: "1rem",
                            marginRight: "3px",
                            marginBottom: "2px",
                          }}
                          alt="icon"
                        />
                        {doctor?.City__c.length < 10 ? <span>{doctor?.City__c}</span> :
                            <span>{doctor?.City__c.slice(0, 10)}...</span>}, {doctor?.State__c}
                      </div>
                    </div>
                  </div>
                </Link>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </>
  );
};

export default DoctorCard;
