import axios from "axios";
import { configs } from "../../config";

export const createNTPOtherTPApi = async (UserId, date, station, tour, startTime, endTime, place,  ManagerId , engagement, accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/createTourPlanForOtherPlanType`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        UserId:UserId,
        TPdate : date,
        station: station,
        tour: tour,
        startTime: startTime,
        endTime: endTime,
        Place: place,
        ManagerId : ManagerId,
        Engagement: engagement,
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };