import axios from "axios";
import { configs } from "../../config";

export const getTotalPobApi = async (accessToken, userId) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/createDashboardForPOB`,
      method: "post",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        userId: userId
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };