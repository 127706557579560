import axios from "axios";
import { configs } from "../../config";

export const cloneNTPApi = async (Id, selectedTourPlan, date, planType, place, accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/cloneTourPlan`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        TourPlan_SRNo : selectedTourPlan,
        EmpId : Id,
        TypeOfTourPlan: planType,
        TPDate: date,
        Place: place
    }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };