import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
//import ReactHighcharts from 'react-highcharts';
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsReact from "highcharts-react-official";
import "./MultiColumnChart.css";

highcharts3d(Highcharts);
const MultiColumnChart = ({
  description,
  colors,
  xAxis,
  Total,
  Achieved 
}) => {
  const formattedAchieved = Achieved?.map(value => {
    if (value !== undefined && !isNaN(value)) {
      return parseFloat(value)?.toFixed(2);
    } else {
      return 0; // Or any default value you prefer
    }
  });
  const formattedTotal = Total?.map(value => {
    if (value !== undefined && !isNaN(value)) {
      return parseFloat(value)?.toFixed(2);
    } else {
      return 0; // Or any default value you prefer
    }
  });
  const options = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 0,
        depth: 20,
        viewDistance: 25,
      },
    },
    credits: {
      enabled: false,
    },
    colors: colors,

    title: {
      text: "",
    },
    // legend: {
    //   enabled: false,
    // },

    xAxis: {
      categories: xAxis,
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
        skew3d: true,
      },
    },

    tooltip: {
      //   headerFormat: "<b>{point.key}</b><br>",
      //   pointFormat:
      //     '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}',
    },

    plotOptions: {
      //   series: {
      //     pointStart: 2016,
      //   },
      column: {
        depth: 25,
      },
    },

    series: [
      {
        name: description[0],
        data: formattedAchieved.map(value => parseFloat(value)),
      },
      {
        name: description[1],
        data: formattedTotal.map(value=>parseFloat(value)),
      },
    ],
  };
  return (
    <div>
      <div className="Chart3D">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default MultiColumnChart;
