import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./EDetailing.css";
import EDetailingContent from "./EDetailingContent";
import { useEffect, useState } from "react";
import PlaylistModal from "./PlaylistModal";
import BackButton from "../../Common/BackButton";
import CommonButton from "../../Common/CommonButton";
import CoDoctorsModal from "./CoDoctorsModal";
import filterIcon from "./../../assets/images/filter-6556.svg";
import edetailingImage from "./../../assets/images/edetailing.jpg";
import Rehersal from "./../../Components/EDetailing/Rehersal";
import video1 from "./../../assets/videos/videoOrtho.mp4";
import videoImage from "./../../assets/images/orthoP.jpg";
import videoImage1 from "./../../assets/images/orthoProblems.jpg";
import labourJourney from "./../../assets/images/labourJourney.jpg";
import Knee from "./../../assets/images/knee.png";
import LegBone from "./../../assets/images/legBone.jpg";
import ObstetricsTrain from "./../../assets/images/obstetricsTrain.jpg";
import NormalLabour from "./../../assets/images/normalLabour.jpg";
import PediatricTrain from "./../../assets/images/pediatricsTrain.jpg";
import Neonatal from "./../../assets/images/neonatal.jpg";
import RespiratoryS from "./../../assets/images/respiratoryS.jpg";
import AnesthesiaI from "./../../assets/images/anethesiaImage.jpg";
import RadiologyImage from "./../../assets/images/radiologyImage.jpg";
import LiverImage from "./../../assets/images/LiverImage.jpg";
import CardiacImage from "./../../assets/images/cardiacImage.jpg";
import BrainMri from "./../../assets/images/brainMriImage.jpg";
import NephrologyTrain from "./../../assets/images/nephrologyTrain.png";
import KidneyImg from "./../../assets/images/kidneyImage.jpg";
import RenalImg from "./../../assets/images/RenalImg.jpg";
import DialysisImg from "./../../assets/images/dialysisImg.jpg";
import Rating_3 from "./../../assets/images/Rating_3.png";
import Rating_4 from "./../../assets/images/Rating_4.png";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CommonSearch from "../../Common/CommonSearch";
import { getEDetailingDataApi } from "../../services/eDetailing/getEDetailingDataApi";
import { useSelector } from "react-redux";

function EDetailing() {
  const [playlists, setPlaylists] = useState([]);
  const [filteredPlayList, setFilteredPlayList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  let { doctorName } = useParams();

  useEffect(() => {
    getEDetailingData();
  }, []);

  const getEDetailingData = async () => {
    try {
      const response = await getEDetailingDataApi(accessToken);
      setPlaylists(response);
      setFilteredPlayList(response);
    } catch (error) { }
  };

  const handleModal = (playlistData) => {
    if (playlistData) {
      setPlaylists(playlistData);
    }
    setShowModal(!showModal);
  };
  const handleSortChange = (value) => {
    let tempPlaylist = [...playlists];
    if (value === "relevance") {
      for (let i = 0; i < tempPlaylist.length / 2; i++) {
        handleSwap(tempPlaylist, i, tempPlaylist.length - 1 - i);
      }
      setPlaylists(tempPlaylist);
    }
    if (value === "popular") {
      for (let i = 0; i < tempPlaylist.length / 2; i++) {
        handleSwap(tempPlaylist, i, tempPlaylist.length - 1 - i);
      }
      setPlaylists(tempPlaylist);
    }

    if (value === "alphabatically") {
      sorting(tempPlaylist);
      setPlaylists(tempPlaylist);
    }
  };

  const handleSwap = (arr, i, j) => {
    let temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  };

  const sorting = (arr) => {
    arr.sort(function (a, b) {
      if (a.heading < b.heading) {
        return -1;
      }
      if (a.heading > b.heading) {
        return 1;
      }
      return 0;
    });
  };
  const handleClose1 = () => {
    setShowModal1(!showModal1);
  };

  const handleSerch = (e) => {
    let tempPlaylist = [...playlists];
    let filteredPlaylistTemp = tempPlaylist.filter((element) =>
      element.title.includes(e.target.value)
    );
    setFilteredPlayList(filteredPlaylistTemp);
  };

  return (
    <>
      <Helmet>
        <title>E-Detailing</title>
      </Helmet>
      <PlaylistModal
        showModal={showModal}
        handleShowModal={handleModal}
        type={"selectPlaylist"}
        playlistData={playlists}
      />
      <CoDoctorsModal showModal={showModal1} handleClose={handleClose1} />
      {/* <img src={edetailingImage} style={{ height: "35rem", width: "100%" }} /> */}
      <div className="eDetailing m-4">
        {/* <Tabs
          defaultActiveKey="Content"
          id="fill-tab-example"
          className="mt-3"
          fill
        > */}
        {/* <Tab eventKey="Content" title="Content" className=" mt-4 container"> */}
        <div className="eDetailing_search_container row mt-4">
          <div className="col-lg-11 col-md-8 col-sm-10 col-10">
            {/* <h3 className="mb-4">Welcome {doctorName}</h3> */}
            <BackButton title={"eDetailing"} />
          </div>
          {/* <div className="mt-4 mb-4">
            {" "}
            <CommonSearch
              searchPlaceHolder={`Search`}
              handleInputChange={handleSerch}
            />
          </div> */}
          <div className="customers_search mt-4 mb-4 row" style={{ display: "flex", justifyContent: "center" }}>
            <div className="col-12 col-sm-12 col-lg-6 col-md-6 " style={{ textAlign: "center" }}>
              <CommonSearch
                searchPlaceHolder={`Search...`}
                handleInputChange={(e) => handleSerch(e)}
              />
            </div>
            {/* <div className="col-2">
          <span onClick={handleModal}>
            {" "}
            <img
              src={filterIcon}
              alt="icon"
              style={{
                height: "2rem",
                marginRight: "1rem",
                borderRadius: "10px",
              }}
            />
          </span>
        </div> */}
          </div>
          <div className="col-lg-1 col-md-4 col-sm-2 col-2 row">
            {/* <div className="col-12" onClick={() => handleModal(false)}>
              <img
                src={filterIcon}
                style={{
                  height: "2rem",
                  marginRight: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            </div> */}
            {/* <div className="col-5" style={{ marginLeft: "1rem" }}>
              <Dropdown onSelect={(e) => handleSortChange(e)}>
                <Dropdown.Toggle
                  variant="info"
                  id="dropdown-basic"
                  className="filter_icon"
                >
                  <SortIcon style={{ fontSize: "2rem" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="popular">Popular</Dropdown.Item>
                  <Dropdown.Item eventKey="relevance">Relevance</Dropdown.Item>
                  <Dropdown.Item eventKey="alphabatically">
                    Alphabatically
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
        </div>

        <div>
          <div className="mt-2"></div>
          {/* <Tabs
            defaultActiveKey="Playlist"
            id="fill-tab-example"
            className="m-3 tabs_width"
            fill
          > */}
          {/* <Tab
              eventKey="Playlist"
              title="Playlist"
              className="markAttendance_tabsSection mt-4"
            > */}
          {/* <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-6 col-6"></div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                  <div onClick={handleClose1}>
                    <CommonButton title={"End Detailing"} />
                  </div>
                </div> */}
          {/* {playlists.map((playlist) => {
                  if (playlist.selected) { */}
          {/* return ( */}
          <EDetailingContent heading={""} playlistVideos={filteredPlayList} />
          {/* ); */}
          {/* }
                })} */}
        </div>
        {/* </Tab> */}

        {/* <Tab
              eventKey="Rehersal"
              title="Rehersal"
              className="markAttendance_tabsSection mt-4"
            >
              <Rehersal showName={false} />
            </Tab> */}
        {/* <Tab
              eventKey="BrandList"
              title="Brand List"
              className="markAttendance_tabsSection mt-4"
            >
              <div style={{ height: "30rem" }}>
                <h5 style={{ marginTop: "10rem", textAlign: "center" }}>
                  There are no brands currently.
                </h5>
              </div>
            </Tab>
          </Tabs> */}
        {/* </div> */}

        {/* </Tab> */}
        {/* <Tab
            eventKey="Rehersal"
            title="Rehearsal"
            className="markAttendance_tabsSection mt-4"
          >
            <Rehersal />
          </Tab> */}
        {/* <Tab
            eventKey="Presentation"
            title="Presentation"
            className="markAttendance_tabsSection mt-4"
          >
            <Presentaion />
          </Tab> */}
        {/* </Tabs> */}
      </div>
    </>
  );
}

export default EDetailing;
