import axios from "axios";
import { configs } from "../../config";

export const editNTPApi = async (UserId, date, station, tour, routeName, ReportToId, doctorIds , place, accessToken,tourSrno,splitedPob) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/EditNewTourPlan`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        UserId:UserId,
        TPdate : date,
        station: station,
        tour: tour,
        routeName: routeName,
        ManagerId: ReportToId,
        doctorIds : doctorIds,
        Place: place,
        TPSrNo :tourSrno,
        POB : splitedPob
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };