import axios from "axios";
import { configs } from "../../config";

export const getRouteWiseCustomersApi = async (empId, accessToken, routeName, designation) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/GetCustomerDataEmpWise/getCustomerDataEmpWiseForTP`,
    method: "patch",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      empId: empId,
      RouteName: routeName,
      designation: designation
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};


export const getRoutesForManager1 = async (empId, accessToken, designation, stage,hq,vseId) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/getRoutesForManager1`,
    method: "patch",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      empId: empId,
      designation: designation,
      stage: stage,
      hq:hq,
      vseId:vseId
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};  