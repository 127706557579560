import axios from "axios";
import { configs } from "../../config";

export const managerExpensesApi = async (empId, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Claim_Rejection_Reason__c, Allowance_Type__c, City__c, Claim_No__c, Contact__c, Contact__r.name, Contact__r.EmpId__c, Contact__r.Division__c, CreatedById, CreatedDate, DADate__c, Daily_Allowance_Type__c, Daily_Allowance__c, Date__c, Distance_Travelled_Km__c, Expense_Claim_Approval__c, From_Date__c, GE_Claim_Amount__c, Id, IsDeleted, LastModifiedById, LastModifiedDate, LastReferencedDate, LastViewedDate, Local_Conveyance_Type__c, Name, No_of_Days__c, Place_From__c, Place_To__c, Purpose_of_Travel__c, Status__c, SystemModstamp, TA_Arrival_Date__c, TA_Arrival_Station__c, TA_Arrival_Time__c, TA_Claim_Amount__c, TA_Depature_Date__c, TA_Depature_Station__c, TA_Depature_Time__c, Ticket_Number__c, To_Date__c, Travel_allowance_Type__c, Travel_allowance__c, Type_of_General_Expense__c,hasAttachments__c FROM Expense_Claim__c WHERE Expense_Claim_Approval__c ='${empId}'`,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.error("Error:", error.response ? error.response.data : error.message);
  }
};

export const managerExpensesNextApi = async (url, accessToken) => {
  const options = {
    url: `${configs?.base_url}${url}`,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.error("Error:", error.response ? error.response.data : error.message);
  }
};

export const fetchBlobBase64 = async (versionDataUrl, accessToken) => {
  try {
    const options = {
      url: `${configs?.base_url}${versionDataUrl}`,
      method: "get",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      responseType: "blob",
    };

    const response = await axios(options);
    const base64String = await blobToBase64(response?.data);
    return base64String
  } catch (error) {
    console.error('Failed to fetch or convert blob to Base64', error);
    return null;
  }
};

export const fetchAttachmentUrls = async (claimId, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT+Id,ContentDocumentId,FileType,Title,PathOnClient,VersionData+FROM+ContentVersion+WHERE+FirstPublishLocationId='${claimId}'`,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };
  try {
    const response = await axios(options);
    const records = response.data.records;
    const record = records[0];
    return record;
  } catch (error) {
    console.error(`Failed to fetch attachments for claimId ${claimId}`, error);
    return [];
  }
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = () => reject(new Error('Failed to read Blob as Base64'));
    reader.readAsDataURL(blob);
  });
};