import {
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_FAIL,
  GET_EMP_DETAILS_SUCCESS,
  GET_EMP_DETAILS_FAIL,
  BLANK_RECORD_ID,
  BLANK_RECORD_ID_FAIL,
  LOGOUT,
  SET_PROFILE_IMAGE,
  FAIL_PROFILE_IMAGE,
  REGULARIZATION_ID_FAIL,
  ACTUAL_BUDGET_API_SUCCESS,
  LOG_IN_TIME_SUCCESS,
  LOG_IN_TIME_FAIL,
  LEAVEDETAILS_SUCCESS,
  LEAVEDETAILS_FAIL,
  FESTIVALHOLIDAY_FAIL,
  FESTIVALHOLIDAY_SUCCESS
} from "../constants/action-types";

const initialState = {
  accessToken: null,
  error: null,
  ID: null
};

const initialuser = {
  details: ""
}

const initalCustomId = {
  customId: ''
}
const initalEmpId = {
  regularizationId: ''
}

const initialLogout = {
  logout: '',
  loggedIn: true
}
const initialImageState = {
  profileImageUrl: null,
};
const actualState = {
  actualValue: null,
};

const initialTime = {
  login: ''

}

const leaveDetails = {
  allLeaveDetails: []
}

const festivalHoliday =
{
  festivalHolidayDetails: []
}

export const accessTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload,
        error: null,
      };
    case GET_ACCESS_TOKEN_FAIL:
      return {
        ...state,
        accessToken: null,
        error: 'Failed to get access token',
      };
    case LOGOUT:
      return {
        ...state,
        accessToken: null,
        profileImageUrl: null,
      };
    default:
      return state;
  }
};

export const loginReducer = (state = initialuser, action) => {
  switch (action?.type) {
    case GET_EMP_DETAILS_SUCCESS:
      return {
        ...state,
        details: action?.payload,
        error: null,
      };
    case GET_EMP_DETAILS_FAIL:
      return {
        ...state,
        details: null,
        error: 'Failed to get access token',
      };
    case LOGOUT:
      return {
        ...state,
        details: null,
      };
    default:
      return state;
  }
};

export const blankRecordReducer = (state = initalCustomId, action) => {
  switch (action.type) {
    case BLANK_RECORD_ID:
      return {
        ...state,
        customId: action.payload,
        error: null,
      };
    case BLANK_RECORD_ID_FAIL:
      return {
        ...state,
        customId: null,
        error: 'Failed to get access token',
      };
    default:
      return state;
  }
};

export const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...initialState, // Reset to initial state
      };
    default:
      return state;
  }
};

export const profileImageReducer = (state = initialImageState, action) => {
  switch (action.type) {
    case SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImageUrl: action?.payload,
      };
    case LOGOUT:
      return {
        ...state,
        profileImageUrl: null,
      };
    default:
      return state;
  }
  // switch (action?.type) {
  //   case SET_PROFILE_IMAGE:
  //     return {
  //       ...state,
  //       profileImage: action?.payload,
  //       error: null,
  //     };
  //   case FAIL_PROFILE_IMAGE:
  //     return {
  //       ...state,
  //       details: null,
  //       error: 'Failed to get access token',
  //     };
  //   default:
  //     return state;
  // }
};

// export const regularizationIdReducer = (state = initalEmpId, action) => {
//   switch (action.type) {
//     case REGULARIZATION_ID_SUCCESS:
//       console.log("ndhbdgfc")
//       return {
//         ...state,
//         regularizationId: action.payload,
//         error: null,
//       };
//       case REGULARIZATION_ID_FAIL:
//         return {
//             ...state,
//             regularizationId: null,
//             error: 'Failed to get access token',
//         };
//     default:
//       return state;
//   }
// };
export const actualBudgetReducer = (state = actualState, action) => {
  switch (action.type) {
    case ACTUAL_BUDGET_API_SUCCESS:
      return {
        ...state,
        actualValue: action?.payload,
      };
    default:
      return state;
  }
}


export const signInTimeReducer = (state = initialTime, action) => {
  switch (action.type) {
    case LOG_IN_TIME_SUCCESS:
      return {
        ...state,
        customId: action.payload,
        error: null,
      };
    case LOG_IN_TIME_FAIL:
      return {
        ...state,
        customId: null,
        error: 'Failed to get access token',
      };
    default:
      return state;
  }
};

export const leaveReducer = (state = leaveDetails, action) => {
  switch (action?.type) {
    case LEAVEDETAILS_SUCCESS:
      return {
        ...state,
        allLeaveDetails: action?.payload,
        error: null,
      };
    case LEAVEDETAILS_FAIL:
      return {
        ...state,
        allLeaveDetails: null,
        error: 'Failed to get access token',
      };
    case LOGOUT:
      return {
        ...state,
        allLeaveDetails: null,
      };
    default:
      return state;
  }
};

export const festivalHolidayReducer = (state = festivalHoliday, action) => {
  switch (action?.type) {
    case FESTIVALHOLIDAY_SUCCESS:
      return {
        ...state,
        festivalHolidayDetails: action?.payload,
        error: null,
      };
    case FESTIVALHOLIDAY_FAIL:
      return {
        ...state,
        allLeaveDetails: null,
        error: 'Failed to get access token',
      };
    case LOGOUT:
      return {
        ...state,
        allLeaveDetails: null,
      };
    default:
      return state;
  }
};