import React, { useState } from "react";
import "./PlaylistComponent.css";
import { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ModalPopUp from "../../Common/ModalPopUp";
import Card from "react-bootstrap/Card";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const videoCardiology = [
  {
    id: Math.random(),
    video: "Cardiology Training",
    playlistName: "Cardiology",
    date: "Added on 17 Apr 2023",
    time: "03:00 Minutes",
    dur: 3,
  },
  {
    id: Math.random(),
    video: "Cardiology Instruments",
    playlistName: "Cardiology",
    date: "Added on 1 May 2023",
    time: "01:50 Minutes",
    dur: 1.5,
  },
];
const videoObstetrics = [
  {
    id: Math.random(),
    video: "Obstetrics Training",
    playlistName: "Obstetrics",
    date: "Added on 11 Apr 2023",
    time: "05:00 Minutes",
    dur: 5,
  },
  {
    id: Math.random(),
    video: "Obstetrics Instruments",
    playlistName: "Obstetrics",
    date: "Added on 01 Apr 2023",
    time: "03:25 Minutes",
    dur: 3.25,
  },
];
const videoNephrology = [
  {
    id: Math.random(),
    video: "Nephrology Training",
    playlistName: "Nephrology",
    date: "Added on 30 Apr 2023",
    time: "04:00 Minutes",
    dur: 4,
  },
  {
    id: Math.random(),
    video: "Nephrology Instruments",
    playlistName: "Nephrology",
    date: "Added on 01 Mar 2023",
    time: "02:51 Minutes",
    dur: 2.51,
  },
];

const PlaylistComponent = ({ playlistType }) => {
  const [playList, setPlayList] = useState(videoCardiology);
  const [playListHeading, setPlayListHeading] = useState("Cardiology");
  const [totalplay_1, setTotalplay_1] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    if (playlistType === "Cardiology") {
      setPlayList(videoCardiology);
      setPlayListHeading("Cardiology");
    } else if (playlistType === "Obstetrics") {
      setPlayList(videoObstetrics);
      setPlayListHeading("Obstetrics and Gynecology");
    } else if (playlistType === "Nephrology") {
      setPlayList(videoNephrology);
      setPlayListHeading("Nephrology");
    }
  }, [playlistType]);

  useEffect(() => {
    var totalplay = playList[0].dur + playList[1].dur;
    setTotalplay_1(totalplay.toString().split("."));
  }, [playList]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(playList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPlayList(items);
  }
  const checkTime = (value) => {
    value = parseInt(value);
    if (value < 9) {
      return "0";
    }
    return "";
  };

  const handleshowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <div>
      <ModalPopUp
        showModal={showDeleteModal}
        handleClose={handleshowDeleteModal}
        modalHeading=""
        modalContent={modalContent}
      />
      <div className="Cardiolog">
        <div className="row ">
          <table className="mt-3 PlaylistComponent_bar ml-0">
            <tr>
              <td>
                <p className="mt-2 ml-3" style={{ width: "17rem" }}>
                  <b>{playListHeading}</b>
                </p>
              </td>
              <td style={{ textAlign: "center" }}>
                <span
                  className="PlaylistComponent_play"
                  onClick={() => {
                    setModalContent("Play all videos in playlist?");
                    handleshowDeleteModal();
                  }}
                >
                  <PlayArrowIcon />
                  <span className="pl-2">Play</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <p className="mt-2">
                  Total Playtime {checkTime(totalplay_1[0])}
                  {totalplay_1[0]}:{checkTime(totalplay_1[1])}
                  {totalplay_1[1]} Minutes
                </p>
              </td>
              <td className=" PlaylistComponent_sharedel">
                <span
                  onClick={() => {
                    setModalContent("Do you want to share playlist?");
                    handleshowDeleteModal();
                  }}
                >
                  <ShareIcon className="mr-3 icon2" />{" "}
                </span>
                <span
                  onClick={() => {
                    setModalContent(
                      "Are you sure you want to delete playlist?"
                    );
                    handleshowDeleteModal();
                  }}
                >
                  <DeleteIcon className="mr-5 icon2" />
                </span>
              </td>
            </tr>
          </table>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="video" key={"video"}>
            {(provided) => (
              <div
                className="eDetailingContent_data row"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {playList.map((video, index) => {
                  return (
                    <Draggable
                      key={video.id}
                      draggableId={video.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <Card
                          className="mt-1"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Card.Body>
                            <div className="row">
                              <p>
                                <b>{video.video}</b>
                              </p>
                              <table
                                className="PlaylistComponent_table"
                                style={{ marginLeft: "10px" }}
                              >
                                <tr>
                                  <td className="Playlist_row">
                                    {video.playlistName}
                                  </td>
                                  <td className="Playlist_row">{video.date}</td>
                                  <td className="Playlist_row">{video.time}</td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        setModalContent(
                                          "Add video to next in queue?"
                                        );
                                        handleshowDeleteModal();
                                      }}
                                    >
                                      <AddCircleOutlineIcon className="icon1" />
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        setModalContent(
                                          "Are you sure you want to delete video?"
                                        );
                                        handleshowDeleteModal();
                                      }}
                                    >
                                      <DeleteIcon className="icon2" />
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </Card.Body>
                        </Card>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* {playlistType === "Obstetrics" && (
        <div className="Cardiolog">
          <div className="row ">
            <table className="mt-3 PlaylistComponent_bar ml-0">
              <tr>
                <td>
                  <p className="mt-2 ml-3" style={{ width: "17rem" }}>
                    <b>Obstetrics and Gynecology</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <span className="PlaylistComponent_play">
                    <PlayArrowIcon />
                    <span className="pl-2">Play</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p className="mt-2">Total Playtime 8:25 Minutes</p>
                </td>
                <td className=" PlaylistComponent_sharedel">
                  <ShareIcon className="mr-3 icon2" />{" "}
                  <DeleteIcon className="mr-5 icon2" />
                </td>
              </tr>
            </table>
          </div>
          {videoObstetrics.map((video) => {
            return (
              <div className="row">
                <p className="mt-3">
                  <b>{video.video}</b>
                </p>
                <table
                  className="PlaylistComponent_table"
                  style={{ marginLeft: "10px" }}
                >
                  <tr>
                    <td className="Playlist_row">{video.playlistName}</td>
                    <td className="Playlist_row">{video.date}</td>
                    <td className="Playlist_row">{video.time}</td>
                    <td>
                      <AddCircleOutlineIcon className="icon1" />
                    </td>
                    <td>
                      <DeleteIcon className="icon2" />
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      )} */}

      {/* {playlistType === "Nephrology" && (
        <div className="Cardiolog">
          <div className="row ">
            <table className="mt-3 PlaylistComponent_bar ml-0">
              <tr>
                <td>
                  <p className="mt-2 ml-3" style={{ width: "17rem" }}>
                    <b>Nephrology</b>
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  <span className="PlaylistComponent_play">
                    <PlayArrowIcon />
                    <span className="pl-2">Play</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <p className="mt-2">Total Playtime 06:51 Minutes</p>
                </td>
                <td className=" PlaylistComponent_sharedel">
                  <ShareIcon className="mr-3 icon2" />{" "}
                  <DeleteIcon className="mr-5 icon2" />
                </td>
              </tr>
            </table>
          </div>
          {videoNephrology.map((video) => {
            return (
              <div className="row">
                <p className="mt-3">
                  <b>{video.video}</b>
                </p>
                <table
                  className="PlaylistComponent_table"
                  style={{ marginLeft: "10px" }}
                >
                  <tr>
                    <td className="Playlist_row">{video.playlistName}</td>
                    <td className="Playlist_row">{video.date}</td>
                    <td className="Playlist_row">{video.time}</td>
                    <td>
                      <AddCircleOutlineIcon className="icon1" />
                    </td>
                    <td>
                      <DeleteIcon className="icon2" />
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default PlaylistComponent;
