import React from "react";
import Modal from "react-bootstrap/Modal";
import "./DateAndTime.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import RescheduleImg from "./../assets/images/Reschedule.gif";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonButton from "./CommonButton";
import { Button } from "react-bootstrap";

const DateAndTime = ({ showModal, handleClose }) => {
  const handleShowModal = () => {
    handleClose();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey RescheduleModal"
    >
      <Modal.Body className="show-grid">
        <div className=" text-center">
          <img
            src={RescheduleImg}
            style={{ height: "10rem", width: "10rem" }}
          />
          <h4 className="mt-4">Skip Visit</h4>
          <p style={{ fontWeight: "500", fontFamily:'GilroyMedium',paddingTop:'2rem' }}>
            Are you sure you want to skip this visit ?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        className="doctor360Modal_survey_button"
        style={{ alignSelf: "center" }}
      >
        <span onClick={handleShowModal}>
          <CommonButton title={"Yes"} width={"5rem"} />
        </span>
        <Button
          onClick={handleShowModal}
          style={{
            background: "#e1dbdb",
            color: "black",
            fontSize: "14px",
            fontWeight: "600",
            border: "0",
            borderRadius: "20px",
            width: "5rem",
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateAndTime;
