import axios from "axios";
import { configs } from "../../config";

export const getTPForEditing = async (Id , TPSrNo, tourDate, accessToken) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/GetTPForEditing`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data:{
        empId: Id,
        TPSrNo: TPSrNo,
        TPDate: tourDate,
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };