import { useState, useEffect } from "react";
import "./BirthdayReminder.css";
import CanvasConfetti from "../Common/CanvasConfetti";
import myImage1 from "../assets/images/doctorBlack.jpg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getCustomersApi } from "../services/customers/getCustomersApi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const BirthdayReminder = () => {
  const [fireConfetti, setFireConfetti] = useState(false);
  const style = { color: "#FFC0CB", fontSize: "2em" };
  const [currentBirthdayIndex, setCurrentBirthdayIndex] = useState(0);
  const [customersData, setCustomersData] = useState([]);
  const [birthdayReminder, setBirthdayReminder] = useState([]);
  const [noBirthdaysToday, setNoBirthdaysToday] = useState(false);



  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCustomersApi(empId, accessToken);
        // setCustomersData(response.data);


        const today = new Date();
        const formattedToday = `${today.getMonth() + 1}/${today.getDate()}`;

        const birthdayCustomers = response.data.filter(customer => {
          if (customer.DOB__c) {
            const dobParts = customer.DOB__c.split('.');
            const month = parseInt(dobParts[1]) - 1;
            const day = parseInt(dobParts[0]);
            const customerDOB = new Date(today.getFullYear(), month, day);
            const formattedDOB = `${customerDOB.getMonth() + 1}/${customerDOB.getDate()}`;
            return formattedDOB === formattedToday;
          }
        });

        if (birthdayCustomers.length > 0) {
          setBirthdayReminder(birthdayCustomers);
          setNoBirthdaysToday(false);
        } else {
          setNoBirthdaysToday(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [empId, accessToken]);




  const handleConfetti = (e) => {
    e.preventDefault();
    setFireConfetti(!fireConfetti);
  };

  const goToNextBirthday = () => {
    setCurrentBirthdayIndex((prevIndex) => (prevIndex + 1) % birthdayReminder.length);
  };

  const goToPreviousBirthday = () => {
    setCurrentBirthdayIndex((prevIndex) =>
      prevIndex === 0 ? birthdayReminder.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {fireConfetti && <CanvasConfetti />}
      <div  className="birthday_container">
      <div> 
        <h2 className="birthday_title" >Birthday Reminder</h2>
        </div>        {noBirthdaysToday ? (
          <div className="birthday_card no_birthday_card">
            <p style={{fontSize:'24px',fontFamily:'GilroyMedium'}}>No Birthdays Today!</p>
          </div>
        ) : (
          <>
            <div className="birthday_card" onClick={handleConfetti}>
              <Link
                to={`/doctors360/${birthdayReminder[currentBirthdayIndex]?.Id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="birthday_info">
                  <img src={myImage1} alt="Profile" className="birthday_photo" />
                  <div className="birthday_details">
                    <p className="birthday_name" style={{ cursor: "pointer" }}>{birthdayReminder[currentBirthdayIndex]?.ContactPerson__c}</p>
                    {/* <p className="birthday_date">{reminder.DOB__c}</p> */}
                  </div>
                </div>
              </Link>
            </div>


            {birthdayReminder.length > 1 && (
              <div className="birthday_navigation">
                <FaChevronLeft onClick={goToPreviousBirthday} className="arrow-icon left-arrow" />
                <FaChevronRight onClick={goToNextBirthday} className="arrow-icon right-arrow" />
              </div>
            )}

          </>
        )}
      </div>
    </>
  );
};

export default BirthdayReminder;
