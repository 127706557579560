import axios from "axios";
import { configs } from "../config";


export const getDcrAllListApi = async (empId,accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT CreatedById, CreatedDate, Customer__c, Date__c, DCR_Id__c, Employee__c,Employee__r.name,Customer__r.name, Id,  IsDeleted, LastModifiedById, LastModifiedDate, LastReferencedDate, LastViewedDate, Name, Next_Visit__c, OwnerId, POB__c, Print_Inputs__c, Product_discuss__c,  Promotional_Inputs__c, Rating__c, Remarks__c, Samples__c, Survey_Type__c, SystemModstamp, Visit_Time__c FROM DCR_Survey__c where Employee__r.ReportsToId  = '${empId}'  ORDER BY Date__c DEsc`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDcrStatusApi = async (doctorId,empId,accessToken) => {
const options = {
  url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT CreatedById, CreatedDate, Customer__c, Date__c, DCR_Id__c, Employee__c,Employee__r.name,Customer__r.name, Id,  IsDeleted, LastModifiedById, LastModifiedDate, LastReferencedDate, LastViewedDate, Name, Next_Visit__c, OwnerId, POB__c, Print_Inputs__c, Product_discuss__c,  Promotional_Inputs__c, Rating__c, Remarks__c, Samples__c, Survey_Type__c, SystemModstamp, Visit_Time__c FROM DCR_Survey__c where Customer__c  = '${doctorId}' and Employee__c='${empId}' ORDER BY Date__c DEsc limit 1`,
  method: "GET",
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  }
};
try {
  const response = await axios(options);
  return response;
} catch (error) {
  console.log(error);
}
};

export const getManagerDcrAllListApi = async (empId,accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT CreatedById, CreatedDate, Customer__c, Date__c, DCR_Id__c, Employee__c,Employee__r.name,Customer__r.name, Id,  IsDeleted, LastModifiedById, LastModifiedDate, LastReferencedDate, LastViewedDate, Name, Next_Visit__c, OwnerId, POB__c, Print_Inputs__c, Product_discuss__c,  Promotional_Inputs__c, Rating__c, Remarks__c, Samples__c, Survey_Type__c, SystemModstamp, Visit_Time__c FROM DCR_Survey__c where  Employee__c='${empId}' ORDER BY Date__c DEsc`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
