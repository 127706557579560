import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { useSelector } from "react-redux";
import { getFeedbackDataApi } from "../../services/home/getFeedbackDataApi";
import * as XLSX from 'xlsx'; // Import XLSX library
import CommonButton from "../../Common/CommonButton";

const VseDevReport = forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterEmpId, setFilterEmpId] = useState('');
    const [loading, setLoading] = useState(false);
    const [vseFeedback, setVseFeedback] = useState([]);
    const [error, setError] = useState('');

    const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const VseDevelopment = await getFeedbackDataApi(accessToken, EmployeeId);
                const filteredData = VseDevelopment?.data?.filter(item => {
                    const itemDate = new Date(item.feedbackDate);
                    const start = startDate ? new Date(startDate) : new Date('1900-01-01');
                    const end = endDate ? new Date(endDate) : new Date('2100-01-01');
                    return filterEmpId ?
                        (itemDate >= start && itemDate <= end && filterEmpId === item?.contactEmpId) :
                        (itemDate >= start && itemDate <= end);
                });
                const sortedData = filteredData?.sort((a, b) => new Date(b?.feedbackDate) - new Date(a?.feedbackDate));
                setVseFeedback(sortedData);
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [EmployeeId, accessToken, designation, filterEmpId, startDate, endDate]);

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            setError('Please enter only numbers.');
        } else {
            setError('');
        }
    };

    const downloadExcel = () => {
        const filteredData = vseFeedback.filter(data => (data?.contactDesignation === "VSE" || data?.contactDesignation === "BE") && (data?.feedbackDesignation !== "RSM" && data?.feedbackDesignation !== "RBM"));

        const ws = XLSX.utils.json_to_sheet(filteredData.map(data => ({
            "Feedback Date": formatDateStringDDMMYYYY(data?.feedbackDate),
            "Employee Code": data?.contactEmpId,
            "Employee Name": data?.mrName,
            "Employee Designation": data?.contactDesignation,
            "Feedback Given by (Emp Code)": data?.feedbackEmpId,
            "Feedback Given by (Emp Name)": data?.feedbackName,
            "Feedback Given by (Designation)": data?.feedbackDesignation,
            "Visual AID Detailing": data?.visualAIDDetailing,
            "Pre Call Activity": data?.preCallActivity,
            "Technical Knowledge of the VSE": data?.technicalKnowledgeOfTheVSE,
            "Selling Skills of the VSE": data?.sellingSkillsOfTheVSE,
            "Relationship With Customer": data?.relationshipWithCustomer,
            "Overall Strategy Implementation": data?.overallStrategyImplementation,
            "Any other remarks": data?.remarks || '' // Assuming remarks field is `remarks`
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'VSE Developmet');
        XLSX.writeFile(wb, 'VSE_Developmet_Report.xlsx');
    };

    return (
        <>
            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4 text-center">VSE Development Report</h4>
                            <div className="filter-section mb-4">
                                <label style={{ marginRight: '1rem' }}>
                                    Start Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        value={startDate}
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        onChange={e => setStartDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    End Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    EmpId
                                    <input
                                        className="CommonDropdown form-select"
                                        type="text"
                                        max={4}
                                        style={{
                                            width: "8rem",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={filterEmpId}
                                        onChange={e => setFilterEmpId(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </label>
                                <span onClick={downloadExcel} style={{marginLeft:'10px'}}>
                                    <CommonButton
                                        title={'Download Excel'}
                                        className="btn btn-primary"
                                    />
                                </span>
                            </div>
                            {error && <span className="dcr-form-errors">{error}</span>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div
                                    id="table-to-xls"
                                    className="table-wrapper-scroll-y custom-scrollbar"
                                    style={{ padding: '20px', overflow: 'auto' }}
                                >
                                    <table
                                        className="w-100"
                                        style={{ textAlign: "center" }}
                                        ref={ref}
                                    >
                                        <thead>
                                            <tr className="ladder_heading">
                                                <th className="custom-header">Feedback Date</th>
                                                <th className="custom-header">Employee Code</th>
                                                <th className="custom-header">Employee Name</th>
                                                <th className="custom-header">Employee Designation</th>
                                                <th className="custom-header">Feedback Given by (Emp Code)</th>
                                                <th className="custom-header">Feedback Given by (Emp Name)</th>
                                                <th className="custom-header">Feedback Given by (Designation)</th>
                                                <th className="custom-header">Visual AID Detailing</th>
                                                <th className="custom-header">Pre Call Activity</th>
                                                <th className="custom-header">Technical Knowledge of the VSE</th>
                                                <th className="custom-header">Selling Skills of the VSE</th>
                                                <th className="custom-header">Relationship With Customer</th>
                                                <th className="custom-header">Overall Strategy Implementation</th>
                                                <th className="custom-header">Any other remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vseFeedback?.map((data, index) => {
                                                // Check the condition before rendering
                                                if ((data?.contactDesignation === "VSE" || data?.contactDesignation === "BE") && (data?.feedbackDesignation !== "RSM" && data?.feedbackDesignation !== "RBM")) {
                                                    return (
                                                        <tr key={index} className="ladder_heading_data">
                                                            <td className='pl-3'>{formatDateStringDDMMYYYY(data?.feedbackDate)}</td>
                                                            <td className='pl-3'>{data?.contactEmpId}</td>
                                                            <td className='pl-3'>{data?.mrName}</td>
                                                            <td className='pl-3'>{data?.contactDesignation}</td>
                                                            <td className='pl-3'>{data?.feedbackEmpId}</td>
                                                            <td className='pl-3'>{data?.feedbackName}</td>
                                                            <td className='pl-3'>{data?.feedbackDesignation}</td>
                                                            <td className='pl-3'>{data?.visualAIDDetailing}</td>
                                                            <td className='pl-3'>{data?.preCallActivity}</td>
                                                            <td className='pl-3'>{data?.technicalKnowledgeOfTheVSE}</td>
                                                            <td className='pl-3'>{data?.sellingSkillsOfTheVSE}</td>
                                                            <td className='pl-3'>{data?.relationshipWithCustomer}</td>
                                                            <td className='pl-3'>{data?.overallStrategyImplementation}</td>
                                                            <td className='pl-3'>{data?.remarks || ''}</td>
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
});

export default VseDevReport;
