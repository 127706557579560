import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./MarkAttendance.css";
import TodaysPlan from "./Attendance/TodaysPlan";
import { useState } from "react";
import { useEffect } from "react";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import ClockGif from "./../assets/images/clock.gif";
import Modal from "react-bootstrap/Modal";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { getDateWiseDataApi } from "../services/SYD/getDateWiseDataApi";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Loader from "../Common/Loader";
import { format } from "date-fns";
import FailButton from "../Common/FailButton";
import "./ViewDailyVisitPlan.css";

function MarkAttendance() {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const Id = useSelector((state) => state?.userDetails?.details?.Id);

  const [loading, setLoading] = useState(false);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [selectedDateCustomers, setSelectedDateCustomers] = useState([]);
  const [showButtons, setShowButtons] = useState(true);
  const [today, setToday] = useState(new Date())

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


  // Initialize an array to store all dates
  const allDates = [];

  // Get the dates of the previous 3 days
  for (let i = 7; i >= 1; i--) {
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() - i);
    allDates.push(formatDate(previousDate));
  }

  // Get today's date
  allDates.push(formatDate(today));

  // Get the dates of the next 3 days
  // for (let i = 1; i <= 4; i++) {
  //   const nextDate = new Date(today);
  //   nextDate.setDate(today.getDate() + i);
  //   allDates.push(formatDate(nextDate));
  // }

  const [selectDate, setSelectDate] = useState(formatDate(today));

  const handleClose = () => {
    setShowAttendanceModal(!showAttendanceModal);
  };
  const handleDateChange = (e) => {
    setSelectDate(e.target.value);
  };

  const fetchDateWise = async () => {
    setLoading(true);
    const response = await getDateWiseDataApi(Id, selectDate, accessToken);
    if (response?.status === 200) {
      setSelectedDateCustomers(response?.data?.records);
      setLoading(false);
    } else {
    }
  };


  useEffect(() => {
    fetchDateWise();
  }, []);

  const onDateChange = () => {
    fetchDateWise();
    setShowAttendanceModal(false);
  };

  const pendingArray = selectedDateCustomers.filter(
    (obj) => obj.SYD_Status__c === "Pending"
  );
  const completedArray = selectedDateCustomers.filter(
    (obj) => obj.SYD_Status__c === "Completed"
  );

  const pendingMessage = `Pending - ${pendingArray.length}`;
  const completedMessage = `Completed - ${completedArray.length}`;
  const StartYourDayMessage = `Start Your Day - ${format(
    new Date(selectDate),
    "dd MMM yyyy"
  )}`;

  useEffect(() => {
    if(formatDate(today) < selectDate){
      setShowButtons(false)
    }
    else{
      setShowButtons(true)
    }
  },[selectDate, today])


  return (
    <>
      <div className="container-fluid">
        <div className="row px-2">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12 report_padding">
            <div className="mt-4 row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-8">
                <BackButton title={StartYourDayMessage} />
                <span></span>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-4 changeDateBtn"
                onClick={() => {
                  setShowAttendanceModal(true);
                }}
              >
                <CommonButton title={"Change Date"} />
              </div>
            </div>
            <div className="markAttendance">
              <Tabs
                defaultActiveKey="Pending"
                id="fill-tab-example"
                className="my-3 markAttendance_tab"
                fill
                style={{ display: "flex",fontFamily:'GilroyMedium',fontSize:'16px' }}
              >
                <Tab
                  eventKey="Pending"
                  title={pendingMessage}
                  className="markAttendance_tabsSection mt-4"
                  style={{fontFamily:'GilroyMedium'}}
                >
                  <div className="markAttendance_section container">
                    <div className="row mb-5">
                      {pendingArray.length === 0 && (
                        <p className="text-center mt-5 mb-5">
                          No Pending tours for the day.
                        </p>
                      )}
                      
                      <TodaysPlan
                        doctors={pendingArray}
                        showButtons={showButtons}
                        fetchDateWise={fetchDateWise}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Completed"
                  title={completedMessage}
                  className="markAttendance_tabsSection mt-4"
                >
                  <div className="markAttendance_section container">
                    <div className="row mb-5">
                    {completedArray.length === 0 && (
                        <p className="text-center mt-5 mb-5">
                          No Completed tours for the day.
                        </p>
                      )}
                      <TodaysPlan
                        doctors={completedArray}
                        showButtons={false}
                        fetchDateWise={fetchDateWise}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12 report_padding">
            <div className="mt-4">
              <h5>Day Planned</h5>
              <div className="row viewDailyVisitPlan">
                <div className="col-12 row">
                  <div className="col-12 p-0">
                    <div className="row"></div>
                    {selectedDateCustomers.map((doctor, index) => {
                      return (
                        <div className="row">
                          <div className="dailyVisit_box col-4">
                            <span
                              className="dailyVisit_boxData"
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "lightblue" : "lightgreen",
                                  fontFamily:'GilroySemibold',
                                  fontSize:'13px'
                              }}
                            >
                              {doctor?.Customer__r?.Name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
        {loading && <Loader />}
      </div>
      <Modal
        show={showAttendanceModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="AttendanceModal"
      >
        <Modal.Body className="show-grid">
          <h5 className="text-center">Change Date</h5>
          <div className="text-center">
            <img
              src={ClockGif}
              style={{ height: "8rem", width: "10rem" }}
              alt="image1"
            />
          </div>
          <div style={{ textAlign: "-webkit-center" }}>
            <RadioGroup
              value={selectDate}
              onChange={(e) => handleDateChange(e)}
              className="change_date"
              
            >
              {allDates.map((date) => (
                <FormControlLabel
                  value={date}
                  control={<Radio />}
                  key={date}
                  label={format(new Date(date), "dd MMM yyyy")}
                  style={{fontFamily:'GilroyMedium'}}
                />
              ))}
            </RadioGroup>
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div
              className="col-6"
              style={{ marginLeft: "-5.5px" }}
              onClick={onDateChange}
            >
              <span>
                <CommonButton title={"Submit"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleClose}>
              <FailButton
                buttonText={"Cancel"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MarkAttendance;
