import { useEffect, useState } from "react";
import EDetailingCard from "./EDetailingCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PlaylistModal from "./PlaylistModal";
import useWindowSize from "../../CustomHooks/useWidowSize";
import "./EDetailing.css";

const EDetailingContent = ({ heading, playlistVideos }) => {
  const [videos, setVideos] = useState(playlistVideos);
  const [showModal, setShowModal] = useState(false);
  const windowSize = useWindowSize();
  const handleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setVideos(playlistVideos);
  }, [playlistVideos]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(videos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setVideos(items);
  }
  let direction = "horizontal";

  if (windowSize.width <= 768) {
    direction = "vertical";
  }
  return (
    <div className="eDetailingContent_container container">
      <PlaylistModal
        showModal={showModal}
        handleShowModal={handleModal}
        type={"addPlaylist"}
        playlistData={[]}
      />
      {/* <div className="eDetailingContent_heading">
        <h5 className="mb-4">{heading}</h5>
      </div> */}
      {/* <div style={{marginTop:'1rem'}}></div> */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="videos" key={"videos"} direction={direction}>
          {(provided) => (
            <div
              className="eDetailingContent_data row"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {videos.map((video, index) => {
                return (
                  <Draggable
                    key={video.id}
                    draggableId={video.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <EDetailingCard video={video} provided={provided} />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EDetailingContent;
