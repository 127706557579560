import axios from "axios";
import { configs } from "../../config";

export const createDcr = async (accessToken, employeeId, selectedCustomerId, SelectedStartTime, selectedProduct, selectedSample, selectPromotional, selectedPob, selectedRemark, value, nextDate, date, imageData, fileType, repoertsToid, userIds) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/createDCRSurvey`,
    method: "patch",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      empid: employeeId,
      customerId: selectedCustomerId,
      visitTime: SelectedStartTime,
      productDiscuss: selectedProduct,
      samples: selectedSample,
      PromotionalInputs: selectPromotional,
      POB: selectedPob,
      Remarks: selectedRemark,
      Rating: value,
      nextVist: nextDate,
      dcrDate: date,
      fileData: imageData,
      fileType: fileType,
      managerId: repoertsToid,
      UserId: userIds
    }
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

export const createOtherTypeDcr = async (accessToken, employeeId, selectedRemark, date, imageData, fileType, userIds, customerActivity) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/createDCRSurveyForOtherTP`,
    method: "patch",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: {
      Remarks: selectedRemark,
      dcrDate: date,
      empid: employeeId,
      fileData: imageData,
      fileType: fileType,
      UserId: userIds,
      Other_Type_of_Tour_Plan: customerActivity
    }
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

