import axios from "axios";
import { configs } from "../../config";

export const getLeaveListAPi = async (accessToken, empId) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Approver_Employee__c,Leave_Type__c,Name,OwnerId,Reason__c,Session_1__c,Session_2__c,Leave_Start_Date__c,Leave_End_Date__c,Status__c,id FROM Leave__c where employee__c = '${empId}'`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};
