
import React, { useRef, useEffect } from 'react';
import "./MyProfile.css";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FailButton from "../Common/FailButton";
import { useState } from "react";
import BackButton from "../Common/BackButton";
import { Helmet } from 'react-helmet';
import CommonButton from "../Common/CommonButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { resetPasswordApi } from '../services/resetPasswordApi';
import { profileImageChangeApi, getProfileUrlApi, finalProfileUrlApi } from '../services/profileImageChangeApi'
import Loader from "../Common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import defaultImage from '../assets/images/Default-Profile.jpg'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { setProfileImageAction } from '../Redux/actions/setProfileImageAction';
import { connect } from "react-redux";

const MyProfile = ({ setProfileImageAction }) => {
  const [disabled, setDisabled] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState();
  const dialogRef = useRef(null);
  const fileInputRef = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const empName = useSelector((state) => state?.userDetails?.details?.Employee_Name__c);
  const empNo = useSelector((state) => state?.userDetails?.details?.Mobile_Number__c);
  const empEmail = useSelector((state) => state?.userDetails?.details?.Email);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const [profileImage, setProfileImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [photoError, setPhotoError] = useState();
  const [profImage, setProfImage] = useState(false);
  const profileUrl = useSelector((state) => state?.profileImageUrl?.profileImage)

  const inputLables = [
    { id: Math.random(), label: "Employee Id", type: "text", value: empId },
    { id: Math.random(), label: "Name", type: "text", value: empName },
    { id: Math.random(), label: "Email Address", type: "text", value: empEmail },
    { id: Math.random(), label: "Phone Number", type: "text", value: empNo, },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBackdropClick = (event) => {
    if (dialogRef.current && dialogRef.current === event.target) {
      event.stopPropagation();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setResetPassword(event.target.value)
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);

      if (newPassword !== value) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
    const isValid = validatePassword(value);
    if (!isValid) {
      setPasswordMessage('Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long.');
    } else {
      setPasswordMessage('');
    }
  };

  const handleCheckPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordMessage("Passwords don't match.");
    } else {
      setPasswordMessage('');
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    return regex.test(password);
  };

  const togglePasswordVisibility = (type) => {
    if (type === 'new') {
      setShowNewPassword(!showNewPassword);
    } else if (type === 'confirm') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const newEmpId = useSelector((state) => state?.userDetails?.details?.Id);

  const handleResetPassword = async () => {
    if (!newPassword || !confirmPassword || confirmPasswordError || passwordMessage) {
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords don't match.");
      return;
    }
    try {
      await resetPasswordApi(accessToken, newEmpId, resetPassword);
      setNewPassword('');
      setConfirmPassword('');
      setConfirmPasswordError('');
      setOpen(false);
      toast.success('Pasword updated successfully')
    } catch (error) {
      console.error("Error resetting password:", error);
    }

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!newPassword || !confirmPassword) {
      setConfirmPasswordError("New password and confirm password are required.");
      setFormSubmitted(true);
      return;
    }
    handleResetPassword();
  };
  const inputClass = formSubmitted && (!newPassword || !confirmPassword) ? "inputStyle error" : "inputStyle";
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const fileType = file.type;
  //     if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
  //       setPhotoError('Please select a JPG or PNG file.');
  //       return;
  //     }
  //   }

  //   if (file) {
  //     setPhotoError("")
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       const dataURL = reader.result;
  //       setImageUrl(dataURL)
  //       const base64URL = dataURL.split(',')[1];
  //       setSelectedFile(base64URL);
  //       try {
  //         setLoading(true);
  //         await profileImageChangeApi(accessToken, EmployeeId, base64URL);
  //         const updatedImageUrl = await getProfileUrlApi(accessToken, EmployeeId);
  //         const getImageUrl = updatedImageUrl?.data?.records[0]?.VersionData;

  //         const { dataImage } = await setProfileImageAction(accessToken, getImageUrl);
  //         setProfileImage(dataImage);
  //         setLoading(false);
  //       } catch (error) {
  //         console.error("Error updating profile image:", error);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const updatedImageUrl = await getProfileUrlApi(accessToken, EmployeeId);
        const getImageUrl = updatedImageUrl?.data?.records[0]?.VersionData;
        if (!getImageUrl) {
          setProfImage(true);
          setProfileImage(defaultImage);
          setLoading(false);
        }
        else {
          const { dataImage } = await setProfileImageAction(accessToken, getImageUrl);
          setProfileImage(dataImage);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching or updating profile image:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, EmployeeId]);

  return (
    <>
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <div className="container mt-3">
        <BackButton title={"My Account"} />
      </div>
      <div className="myprofile_container">
        <div className="myprofile_edit"></div>
        <div className="myprofile_image">

          {/* <div className="row"> */}
          {/* <div className="col-12 text-center"> */}
          {/* {profileImage && (
            <img
              src={profImage ? profileImage : `data:image/jpeg;base64,${profileImage}`}
              className='profile-image'
              alt=''
              onClick={handleImageClick}
            />
          )} */}
          <img
            src={defaultImage}
            className='profile-image'
            alt=''
          />
          {/* <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
          onChange={handleFileChange}
          /> */}
          <AddAPhotoIcon className='photo-icon' style={{ fontSize: 40, color: '#00a87e' }} />
          {/* </div> */}
          {/* </div> */}
          <div style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }} className='mt-2'>{photoError}</div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-3 col-md-3 col-sm-12 col-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="myprofile_form">
              {inputLables.map((ele) => {
                if (ele.type === "text") {
                  return (
                    <TextField
                      key={ele.id}
                      label={ele.label}
                      className="myProfile_textfield"
                      value={ele.value}
                      color="secondary"
                      disabled={disabled}
                      focused
                    />
                  );
                } else if (ele.type === "radio") {
                  return (
                    <FormControl
                      key={ele.id}
                      className="myProfile_radiofield"
                      disabled={disabled}
                    >
                    </FormControl>
                  );
                }
              })}
              {/* <div className="row">
                {disabled && (
                  <div
                    className="col-12"
                    onClick={() => setDisabled(!disabled)}
                  >
                    <CommonButton title={"Edit"} width={'80%'} />
                  </div>
                )}
                {!disabled && (
                  <>
                    <div
                      className="col-6"
                      onClick={() => setDisabled(!disabled)}
                    >
                      <CommonButton title={"Submit"} width={'100%'} />
                    </div>
                    <div
                      className="col-6"
                      onClick={() => setDisabled(!disabled)}
                    >
                      <FailButton buttonText={"Cancel"} />
                    </div>
                  </>
                )}
              </div> */}
              <div className="row">
                <div
                  style={{ marginTop: "15px" }}
                  className="col-12"
                  onClick={handleClickOpen}
                >
                  <CommonButton title={"Reset Password"} width={'80%'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
        ref={dialogRef}
        onClick={handleBackdropClick}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            // console.log(email);
            handleClose();
          },
          style: { borderRadius: '20px' }
        }}
      >
        <DialogTitle style={{ fontFamily: 'proxima-nova, sans-serif', fontWeight: '500' }}>Reset Password</DialogTitle>
        <DialogContent className="inputContainer">
          <input
            placeholder="New password *"
            className={`inputStyle ${inputClass}`}
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            name="newPassword"
            onChange={handleChange}
            required
          />
          <span onClick={() => togglePasswordVisibility('new')} className="eyeIcon1">
            {showNewPassword ? < FaEye /> : <FaEyeSlash />}
          </span>
          <input
            placeholder="Confirm password *"
            className={`inputStyle ${inputClass}`}
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
            onBlur={handleCheckPassword}
            required
          />
          <span onClick={() => togglePasswordVisibility('confirm')} className="eyeIcon2">
            {showConfirmPassword ? < FaEye /> : <FaEyeSlash />}
          </span>
          <div style={{ color: 'red', fontSize: '14px' }}>{confirmPasswordError}</div>
        </DialogContent>
        <span className='passwordMsg'>Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long.</span>

        <DialogActions>
          <div onClick={handleClose}>
            <FailButton width={"7rem"} buttonText={"Cancel"} />
          </div>
          <div onClick={handleSubmit}>
            <CommonButton width={"7rem"} title={"Submit"} />
          </div>
        </DialogActions>
      </Dialog>
      {loading && <Loader />}
      <ToastContainer />
    </>
  );
};

// export default MyProfile;
export default connect(null, {
  setProfileImageAction
})(MyProfile);
