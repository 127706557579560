import axios from "axios";
import { configs } from "../../config";

export const checkAttendanceApi = async (accessToken, EmpId, attendanceDate) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/checkAttendance`,
      method: "post",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data : {
        EmpId: EmpId,
        attendanceDate: attendanceDate
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };