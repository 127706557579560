import ManagerApprovePlan from "../Components/MonthlyTour/ManagerApprovePlans";
const ManagerApprovePlans = () => {
  return (
    <>
      {/* <Header title={"Monthly Tour Plan"} isShowDate={false} /> */}
      <ManagerApprovePlan />
    </>
  );
};

export default ManagerApprovePlans;
