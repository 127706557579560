import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SuccessButton from "./SuccessButton";
import FailButton from "./FailButton";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

const LogoutModal = ({ showModal, handleClose }) => {
  const handleShowModal = () => {
    handleClose();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="doctor360Modal_survey"
    >
      <Modal.Header closeButton>
        <h5>
          Logout <LockIcon />
        </h5>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="row text-center">
          <h6>Are you sure you want to logout ?</h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="doctor360Modal_survey_button">
        <div className="row w-100" style={{ textAlign: "center" }}>
          <div className="col-6" style={{ marginLeft: "-5.5px" }}>
            <Link to={"/login"}>
              <SuccessButton
                buttonText={"Yes"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                color="success"
                className="doctorName_button"
              />
            </Link>
          </div>
          <div className="col-6" onClick={handleClose}>
            <FailButton
              buttonText={"No"}
              height={"2rem"}
              width={"100%"}
              variant="contained"
              className="doctorName_button"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
