import React from "react";
import { Card } from "react-bootstrap";
import Chart3D from "./../Expense/Chart3D";
import CommonDropdown from "./../../Common/CommonDropdown";
import MultiColumnChart from "../../Common/MultiColumnChart";
const MultiColumnReport = ({ description, colors, xAxis, Total, Achieved }) => {
  return (
    <div>
      <Card style={{ minHeight: "19.20rem", paddingTop: "2rem" }}>
        <Card.Body>
          {/* <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <CommonDropdown
                defaultOption="Speciality"
                options={["Cipla", "Dolo", "Lyrica"]}
              />
            </div>
            <div className="col-4">
              <CommonDropdown
                defaultOption="Brand"
                options={["Cipla", "Dolo", "Lyrica"]}
              />
            </div>
          </div> */}
          <MultiColumnChart
            description={description}
            colors={colors}
            xAxis={xAxis}
            Total={Total}
            Achieved={Achieved}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default MultiColumnReport;
