import React from 'react'

const PersonalizedLearning = () => {
  return (
    <div className='mt-4'>
      <ul style={{fontSize:'1.2rem'}}>
        <li>Personal curriculum: each sales rep sees only courses and materials that are individually relevant</li>
        <li>Newsletter: trainees get regular emails with content that’s relevant to them</li>
        <li>Bookmarking: Students can save the most relevant materials to quickly find them in the future</li>
      </ul>
    </div>
  )
}

export default PersonalizedLearning
