import axios from "axios";
import { configs } from "../../config";

export const getVisitHistoryApi = async (accessToken,doctorId,empId) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Customer__c,Date__c,DCR_Id__c,Employee__c,Employee__r.name,Id,Name,Next_Visit__c,POB__c,Print_Inputs__c,Product_discuss__c,Promotional_Inputs__c,Rating__c,Remarks__c,Samples__c,Survey_Type__c,Visit_Time__c FROM DCR_Survey__c where  Customer__c ='${doctorId}'`,
    method: "get",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};

