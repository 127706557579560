import Modal from "react-bootstrap/Modal";
import "./CoDoctorsModal.css";
import DoctorCard from "../DoctorCard";
import myImage1 from "./../../assets/images/doctor10.jpg";
import myImage2 from "./../../assets/images/doctor11.png";
import myImage3 from "./../../assets/images/doctor20.png";
import myImage4 from "./../../assets/images/doctor14.png";
import myImage5 from "./../../assets/images/doctor8.jpg";
import myImage6 from "./../../assets/images/doctor6.jpeg";
import CommonButton from "../../Common/CommonButton";
import { Button } from "react-bootstrap";
import { doctorArray, doctorInfoArray } from "../../Common/Constants";

const CoDoctorsModal = ({ showModal, handleClose }) => {
  const handleCloseModal = () => {
    handleClose();
  };

  const doctorsArray = [
    {
      id: "1",
      image: myImage1,
      name: "Dr. Deepti Sharma",
      lastVisit: "Last Visited 11-06-2023 9AM",
      profession: "Gynecologist",
      phoneNo: 9828373658,
      area: "Navneet Memorial Hospital",
    },
    {
      id: "2",
      image: myImage2,
      name: "Dr. Rahul Gupta",
      lastVisit: "Last Visited 13-06-2023 3PM",
      profession: "Gynecologist",
      phoneNo: 9828373659,
      area: "Ansh Clinic Ahmedabad",
    },
    {
      id: "3",
      image: myImage3,
      name: "Dr. Om Bhargav",
      lastVisit: "Last Visited 14-06-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 9828373652,
      area: "ICON Hospital Pune",
    },
    {
      id: "4",
      image: myImage4,
      name: "Dr. Supriya Aggarwal",
      lastVisit: "Last Visited 05-06-2023 1PM",
      profession: "Gynecologist",
      phoneNo: 9828373653,
      area: "Sanchetti Hospital Pune",
    },
    {
      id: "5",
      image: myImage5,
      name: "Dr. Siddhartha Mukherjee",
      lastVisit: "Last Visited 15-06-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 9822876812,
      area: "Ratna Hospital Surat",
    },
    {
      id: "6",
      image: myImage6,
      name: "Dr. Suresh S. Chatterjee",
      lastVisit: "Last Visited 31-05-2023 5PM",
      profession: "Gynecologist",
      phoneNo: 7709216585,
      area: "Navneet Memorial Hospital",
    },
  ];

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="CoDoctorModal"
    >
      <Modal.Body className="show-grid">
        <div className="row">
          <h5 className="mb-2">Select Co-Doctors</h5>
          <p>Select doctor for which you've detailed a combine session</p>
          <DoctorCard doctors={doctorsArray} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span onClick={handleCloseModal}>
          <CommonButton title={"Submit"} width={"10rem"} />
        </span>
        <Button
          onClick={handleCloseModal}
          style={{
            fontSize: "14px",
            fontWeight: "600",
            border: "0",
            backgroundColor: "#4ad84a",
            borderRadius: "20px",
            background: "rgb(225, 219, 219)",
            color: "black",
            width: "10rem",
          }}
        >
          Skip
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoDoctorsModal;
