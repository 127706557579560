import Form from "react-bootstrap/Form";
import "./CommonDropdown.css";

const CommonDropdown = ({
  options,
  defaultOption = "",
  handleDropdownChange = () => {},
  disableEdit = false
}) => {
  return (
    <Form.Select
      aria-label="Default select example"
      className="CommonDropdown"
      onChange={(e) => handleDropdownChange(e.target.value)} 
      disabled={disableEdit}
      style={{ fontFamily: 'GilroyMedium' }}
    >
      {defaultOption !== "" && <option key="default">{defaultOption}</option>}
      {options.map((option, index) => {
        return <option key={index} value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
      })}
    </Form.Select>
  );
};
export default CommonDropdown;
