import Header from "../Common/Header";
import InputInventory from "../Components/InputInventory/InputInventory";

const InputInventoryPage = () => {
  return (
    <>
      {/* <Header title={"Input Inventory"} isShowDate={false} /> */}
      <InputInventory />
    </>
  );
};

export default InputInventoryPage;
