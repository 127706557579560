import { signInOutApi } from "../../services/signInOutApi";
import {
    LOG_IN_TIME_SUCCESS,
    LOG_IN_TIME_FAIL,
} from "../constants/action-types";

export const signInAction = (blankRecordId, accessToken, empName, Login, Logout, empId,userId) => async (dispatch) => {
    // console.log("response usert action callll");
    try {
        const response = await signInOutApi(blankRecordId, accessToken, empName, Login, Logout, empId,userId); // Wait for the Promise to resolve
        // console.log("response usert----------->", blankRecordId, accessToken, empName, Login, Logout, empId,userId);
        if (response ) {
            dispatch({
                type: LOG_IN_TIME_SUCCESS,
                payload: Login, // Assuming response.data contains the actual data
            });
            return { success: true, data: response }; // Return success and data
        } else {
            dispatch({
                type: LOG_IN_TIME_FAIL,
            });
            return { success: false, error: "Invalid response" }; // Return failure and error message
        }
    } catch (error) {
        dispatch({
            type: LOG_IN_TIME_FAIL,
        });
        return { success: false, error: error.message }; // Return failure and error message
    }
};
