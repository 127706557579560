import myImage1 from "../assets/images/doctor10.jpg";
import myImage2 from "../assets/images/doctor11.png";
import myImage3 from "../assets/images/doctor20.png";
import myImage4 from "../assets/images/doctor14.png";
import myImage5 from "../assets/images/doctor8.jpg";
import myImage6 from "../assets/images/doctor6.jpeg";
import myImage7 from "../assets/images/doctor15.png";
import myImage8 from "../assets/images/doctor17.png";
import Adcef from "../assets/torrentPharma/adcef_instause_syrup_30ml_0_0.jpg";
import Afoglip from "../assets/torrentPharma/afoglip_tablet_15s_0_1.jpg";
import Ahaglow from "../assets/torrentPharma/ahaglowGel.jpg";
import Britzilam from "../assets/torrentPharma/britzilam_oral_solution_100ml_0_0.jpg";
import Carnisure from "../assets/torrentPharma/carnisure_lq_granules_10x10gm_508814_0_0.jpg";
import Darbatitor from "../assets/torrentPharma/darbatitor_40mcg_injection_pfs_1_0.jpg";
import Evalon from "../assets/torrentPharma/evalon_cream_15gm_0_1.jpg";
import Fungicide from "../assets/torrentPharma/fungicide_lotion_90ml_36049_0_1.jpg";
import React from "react";
import dayjs from "dayjs";

export const doctorArray = [
  "Dr. Ashish Gupta",
  "Dr. Vijay Kumar",
  "Dr. Shriman Rastogi",
  "Dr. Anil Sharma",
  "Dr. Sanjay Tyagi",
];

export const doctorInfoArray = [
  {
    id: "1",
    image: myImage1,
    name: "Dr. Deepti Sharma",
    lastVisit: "11-06-2023 9AM",
    contactPerson: "Mr. Sharma",
    phoneNo: 9828373658,
    street: "3/285 Sri Aurobindo Marg",
    area: "Ansari Nagar",
    city: "New Delhi, 110029",
  },
  {
    id: "2",
    image: myImage2,
    name: "Dr. Rahul Gupta",
    lastVisit: "13-06-2023 3PM",
    contactPerson: "Mr. K. Joshi",
    phoneNo: 9828373659,
    street: "27, Kautilya Marg",
    area: "Chanakyapuri",
    city: "New Delhi, 110021",
  },
  {
    id: "3",
    image: myImage3,
    name: "Dr. Om Bhargav",
    lastVisit: "14-06-2023 5PM",
    contactPerson: "Mr. Bhargav",
    phoneNo: 9828373652,
    street: "Laxmi Narahari Nilayam",
    area: "Ameerpet",
    city: "Telangana, 500038",
  },
  {
    id: "4",
    image: myImage4,
    name: "Dr. Supriya Aggarwal",
    lastVisit: "05-06-2023 1PM",
    contactPerson: "Mrs. Aggarwal",
    phoneNo: 9828373653,
    street: "Puranik City Phase 3",
    area: "Internal Road",
    city: "Thane, 400615",
  },
  {
    id: "5",
    image: myImage5,
    name: "Dr. Siddhartha Mukherjee",
    lastVisit: "15-06-2023 5PM",
    contactPerson: "Mr. Mukherjee",
    phoneNo: 9822876812,
    street: "3/289 new street",
    area: "Ansari Nagar",
    city: "Delhi, 110029",
  },
  {
    id: "6",
    image: myImage6,
    name: "Dr. Suresh S. Chatterjee",
    lastVisit: "31-05-2023 5PM",
    contactPerson: "Mr. G. Sanjay ",
    phoneNo: 7709216585,
    street: " 5, Kumbena Agrahara Road",
    area: " Hudi, Bengaluru",
    city: "Bangalore, 560067",
  },
  {
    id: "7",
    image: myImage7,
    name: "Dr. Sudhir Phadke",
    lastVisit: "02-06-2023 5PM",
    contactPerson: "Mr. Mukherjee ",
    phoneNo: 9673639573,
    street: "J B Vaccha High Schoo",
    area: "Jehangir Road",
    city: "Mumbai, 400014",
  },
  {
    id: "8",
    image: myImage8,
    name: "Dr. Leena Desai",
    lastVisit: "10-06-2023 5PM",
    contactPerson: "Mrs. Pranav",
    phoneNo: 9921658974,
    street: "Akshya Nagar 1st Block",
    area: "Rammurthy nagar",
    city: "Bangalore-560016",
  },
];

export const claimOptons = [
  "Travel Allowance",
  "Daily Allowance",
  "Local Conveyance",
  "General Expense",
];
export const conveynanceOption = ["Bike", "Car"];
export const transportOptions = [
  "Select Transport Class",
  "Flight",
  "Train",
  "Bus",
  "Taxi Ac",
  "Taxi Non AC",
  "Auto",
  "Fastag/Toll",
];
export const stationOptions = [
  "Select Allowance Type",
  "HQ",
  "EX HQ",
  "Out Station Hotel",
  "Out Station Daily Allownace",
  "Out Station Own Arrangement",
];

export const typeOfGeneralExpense = [
  "Select Allowance Type",
  "Customer Meeting Expense",
  "Mobile Expense",
  "Misc Expense",
  "Sales Meeting Expense",
  "Courier Expense",
  "Printing/Stationary Expense",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const defaultClaimObj = {
  id: Math.random(),
  sn: 1,
  claimType: "",
  claimDate: dayjs(new Date()).format("YYYY-MM-DD"),
  claimAmount: 0,
  remarks: "",
  receipt: "",
};

export const email = "@pharma.com";

export const productsArray = [
  {
    name: "Adcef Syrup",
    img: Adcef,
    tag: "Adcef ",
    brand: "Adcef ",
  },
  {
    name: "Afoglip Tablet",
    img: Afoglip,
    tag: "Afoglip",
    brand: "Afoglip",
  },
  {
    name: "Ahaglow Gel",
    img: Ahaglow,
    tag: "Ahaglow",
    brand: "Ahaglow",
  },
  {
    name: "Britzilam Oral",
    img: Britzilam,
    tag: "Britzilam",
    brand: "Britzilam",
  },
  {
    name: "Carnisure Granules",
    img: Carnisure,
    tag: "Carnisure",
    brand: "Carnisure",
  },
  {
    name: "Darbatitor Injection",
    img: Darbatitor,
    tag: "Darbatitor",
    brand: "Darbatitor",
  },
  {
    name: "Evalon Cream",
    img: Evalon,
    tag: "Evalon",
    brand: "Evalon",
  },
  {
    name: "Fungicide Lotion",
    img: Fungicide,
    tag: "Fungicide",
    brand: "Fungicide",
  },
];

// const orthopedicVideos = [
//   {
//     id: 1,
//     title: "Introducing 3D Orthopedic Patient Education And Animation",
//     catagory: "Orthopedic",
//     subTitle: " - Dr Shailendra Patil",
//     videoUrl: video1,
//     type: "Video",
//     videoImage: videoImage,
//     dr: "Dr Shailendra Patil",
//     descrpt:
//       "updates 3D Orthopedic Patient Education software for Orthopedic Surgeons as well as patients education. The award winning 3D orthopedic animations have been used by thousands of orthopedic surgeons for online patient education.",
//     len: "05:45",
//     rating: Rating_3,
//     playTime: "02:33",
//   },
//   // {
//   //   id: 2,
//   //   title: "Orthopedic Instruments ",
//   //   subTitle:"- Involving the musculoskeletal system",
//   //   videoUrl: "https://www.youtube.com/watch?v=i8i_B5XpmqQ",
//   //   type: "PPT",
//   //   videoImage: OrthoInstruments,
//   // },
//   {
//     id: 3,
//     title: "Common Orthopedic Problems ",
//     subTitle: " - by Des Moines University",
//     catagory: "Orthopedic",
//     videoUrl: "https://www.youtube.com/watch?v=RdhKXS_5mXE",
//     type: "Video",
//     videoImage: videoImage1,
//     dr: "Des Moines University",
//     descrpt:
//       "updates 3D Orthopedic Patient Education software for Orthopedic Surgeons as well as patients education. The award winning 3D orthopedic animations have been used by thousands of orthopedic surgeons for online patient education.",
//     len: "17:33",
//     rating: Rating_3,
//   },
//   {
//     id: 4,
//     title: "Knee Replacement Surgery",
//     catagory: "Orthopedic",
//     subTitle: "by Dr. Nalli by Apollo Spectra Hospital",
//     videoUrl: "https://www.youtube.com/watch?v=wt_renA2mrw",
//     type: "PDF",
//     videoImage: Knee,
//     dr: "Dr. Nalli",
//     descrpt:
//       "updates 3D Orthopedic Patient Education software for Orthopedic Surgeons as well as patients education. The award winning 3D orthopedic animations have been used by thousands of orthopedic surgeons for online patient education.",
//     len: "02:25",
//     rating: Rating_3,
//   },
//   {
//     id: 5,
//     title: "Leg bone surgery",
//     subTitle: "- Dr. Nabil Ebraheim",
//     catagory: "Orthopedic",
//     videoUrl: "https://www.youtube.com/watch?v=WiDIF0wyfmY",
//     type: "PDF",
//     videoImage: LegBone,
//     dr: "Dr. Nabil Ebraheim",
//     descrpt:
//       "updates 3D Orthopedic Patient Education software for Orthopedic Surgeons as well as patients education. The award winning 3D orthopedic animations have been used by thousands of orthopedic surgeons for online patient education.",
//     len: "03:45",
//     rating: Rating_3,
//   },
// ];
// const obsteteicsVideos = [
//   {
//     id: 6,
//     title:
//       "Education and Training in Obstetrics and Gynecology at Mass General",
//     subTitle: "OB/GYN Hospitalist Colette Dominique, MD",
//     videoUrl: "https://www.youtube.com/watch?v=lrG4Y2FIZZs",
//     type: "Video",
//     catagory: "Obstetrics",
//     videoImage: ObstetricsTrain,
//     dr: "Dr Jeff Ecker",
//     descrpt:
//       "Jeff Ecker, MD, chief of Obstetrics and Gynecology at Massachusetts General Hospital, provides an overview of the training opportunities available to residents and fellows through his department. Here, Dr. Ecker and his team discuss education, physician wellbeing, diversity and inclusion, and much more",
//     len: "17:33",
//     rating: Rating_3,
//   },
//   // {
//   //   id: 7,
//   //   title: "Obsteteics Instrument",
//   //   videoUrl: "https://www.youtube.com/watch?v=xkx6wIFqmP0",
//   //   type: "PPT",
//   // },
//   {
//     id: 8,
//     title: "Stages of Labour",
//     subTitle: " - Motters Independent Midwifery",
//     catagory: "Obstetrics",
//     videoUrl: "https://www.youtube.com/watch?v=w0iDfcAYZWc",
//     type: "PDF",
//     videoImage: labourJourney,
//     videoImage: ObstetricsTrain,
//   },
//   {
//     id: 9,
//     title: "Normal Labour",
//     subTitle: " Obstetrics Normal Labor The Medical Education",
//     catagory: "Obstetrics",
//     videoUrl: "https://www.youtube.com/watch?v=jFdXx35VR-o",
//     type: "Video",
//     videoImage: NormalLabour,
//     dr: "Dr Jeff Ecker",
//     descrpt:
//       "Jeff Ecker, MD, chief of Obstetrics and Gynecology at Massachusetts General Hospital, provides an overview of the training opportunities available to residents and fellows through his department. Here, Dr. Ecker and his team discuss education, physician wellbeing, diversity and inclusion, and much more",
//     len: "17:33",
//     rating: Rating_3,
//   },
// ];
// const pediatricsVideos = [
//   {
//     id: 10,
//     title: "Pediatrics Training",
//     subTitle:
//       "Adam Said Yes to Pediatrics: The Benefits of a Pediatric Residency",
//     videoUrl: "https://www.youtube.com/watch?v=CFB3cGG5fxs",
//     type: "Video",
//     catagory: "Pediatrics",

//     videoImage: PediatricTrain,
//     dr: "Dr Jeff Ecker",
//     descrpt:
//       "Jeff Ecker, MD, chief of Obstetrics and Gynecology at Massachusetts General Hospital, provides an overview of the training opportunities available to residents and fellows through his department. Here, Dr. Ecker and his team discuss education, physician wellbeing, diversity and inclusion, and much more",
//     videoImage: PediatricTrain,
//     dr: "Dr Jeff Ecker",
//     descrpt:
//       "Jeff Ecker, MD, chief of Obstetrics and Gynecology at Massachusetts General Hospital, provides an overview of the training opportunities available to residents and fellows through his department. Here, Dr. Ecker and his team discuss education, physician wellbeing, diversity and inclusion, and much more",
//     len: "02:36",
//     rating: Rating_3,
//   },
//   // {
//   //   id: 11,
//   //   title: "Pediatrics Instruments",
//   //   videoUrl: "https://www.youtube.com/watch?v=fDm4mRWky_w",
//   //   type: "PDF",
//   // },
//   {
//     id: 12,
//     title: "Neonatal Reflexes",
//     catagory: "Pediatrics",
//     subTitle: "Demonstration Video || Paediatrics || Dr. Shankar Dey",
//     videoUrl: "https://www.youtube.com/watch?v=N0p9a1XWZWo",
//     type: "PPT",
//     videoImage: Neonatal,
//   },
//   {
//     id: 13,
//     title: "Respiratory System",
//     catagory: "Pediatrics",
//     subTitle: " - How The Respiratory System Works",
//     videoUrl: "https://www.youtube.com/watch?v=0fVoz4V75_E",
//     type: "PDF",
//     videoImage: RespiratoryS,
//   },
//   {
//     id: 14,
//     title: "Anesthesia",
//     catagory: "Pediatrics",
//     subTitle: "Spinal Anesthesia at the Total Joint Center",
//     videoUrl: "https://www.youtube.com/watch?v=B_tTymvDWXk",
//     type: "PPT",
//     videoImage: AnesthesiaI,
//   },
// ];
// const radiologyVideos = [
//   {
//     id: 15,
//     title: "Radiology Training",
//     catagory: "Radiology",
//     subTitle: "Cleveland Clinic Interventional Radiology Residency Programs",
//     videoUrl: "https://www.youtube.com/watch?v=dva5iUIdi5w",
//     type: "Video",
//     videoImage: RadiologyImage,
//     dr: "Ohio State University",
//     descrpt:
//       "Learn from alumni and current trainees about what makes Interventional Radiology training at The Ohio State University Wexner Medical Center great.",
//     len: "02:28",
//     rating: Rating_4,
//   },
//   // {
//   //   id: 16,
//   //   title: "Radiology Instrument",
//   //   videoUrl: "https://www.youtube.com/watch?v=rVqLekKov60",
//   //   type: "PPT",
//   // },
//   {
//     id: 17,
//     title: "Liver Hepatobilary",
//     catagory: "Radiology",
//     subTitle: "Dr. Lea Matsuoka, Hepatobiliary & Liver Surgery",
//     videoUrl: "https://www.youtube.com/watch?v=Umvhq0HnZwU",
//     type: "PDF",
//     videoImage: LiverImage,
//   },
//   {
//     id: 18,
//     title: "Types of Cardiac Imaging: What You Need to Know Webinar",
//     catagory: "Radiology",

//     subTitle: "Human Heart Anatomy (3D Medical Animation)",
//     videoUrl: "https://www.youtube.com/watch?v=SqOJP28W1Es",
//     type: "Video",
//     videoImage: CardiacImage,
//     dr: "Dr. Stefan Zimmerma",
//     descrpt:
//       "Cardiac imaging is a critical step in the accurate diagnosis of many heart conditions. Advanced imaging techniques can display three-dimensional pictures within the arteries, and perhaps provide clues to future cardiovascular events.",
//     len: "58:26",
//     rating: Rating_4,
//   },
//   {
//     id: 19,
//     title: "Brain Mri",
//     catagory: "Radiology",
//     subTitle: "Brain MRI – Valley Children’s Hospital",
//     videoUrl: "https://www.youtube.com/watch?v=CwjJsSkRfMQ",
//     type: "Video",
//     videoImage: BrainMri,
//     playTime: "03:55",
//     dr: "Madison Health",
//     descrpt:
//       "Cardiac imaging is a critical step in the accurate diagnosis of many heart conditions. Advanced imaging techniques can display three-dimensional pictures within the arteries, and perhaps provide clues to future cardiovascular events.",
//     len: "03:53",
//     rating: Rating_3,
//   },
// ];
// const nephrologyVideos = [
//   {
//     id: 20,
//     title: "Nephrology Training",
//     catagory: "Nephrology",
//     subTitle: "Interventional Nephrology Fellowship Program",
//     videoUrl: "https://www.youtube.com/watch?v=2ffgH6XdeJw",
//     type: "PPT",
//     videoImage: NephrologyTrain,
//   },
//   // {
//   //   id: 21,
//   //   title: "Nephrology Instrument",
//   //   videoUrl: "https://www.youtube.com/watch?v=vVSUSlFRvSA",
//   //   type: "Video",
//   // },
//   {
//     id: 22,
//     title: "Kidney Physiology",
//     catagory: "Nephrology",
//     subTitle:
//       "Kidney anatomy & physiology in Hindi | Structure | Functions | Location",
//     videoUrl: "https://www.youtube.com/watch?v=TCRae0j_6qA",
//     type: "Video",
//     videoImage: KidneyImg,
//     playTime: "12:58",
//     dr: "Rajneet Medical Education",
//     descrpt:
//       "Cardiac imaging is a critical step in the accurate diagnosis of many heart conditions. Advanced imaging techniques can display three-dimensional pictures within the arteries, and perhaps provide clues to future cardiovascular events.",
//     len: "12:57",
//     rating: Rating_3,
//   },
//   {
//     id: 23,
//     title: "Dialysis",
//     catagory: "Nephrology",

//     subTitle: "Dialysis Treatment | Ms. Ashwini Mane | Manipal Hospital Baner",
//     videoUrl: "https://www.youtube.com/watch?v=_WOLtIM2dIU",
//     type: "PDF",
//     videoImage: DialysisImg,
//   },
//   {
//     id: 24,
//     title: "Renal Replacement",
//     catagory: "Nephrology",
//     subTitle: " in the ICU by Nishigandha Pradhan, MD",
//     videoUrl: "https://www.youtube.com/watch?v=iWSewFlU6o8",
//     type: "PDF",
//     videoImage: RenalImg,
//   },
// ];
// export const videoData = [
//   { id: 1, heading: "Orthopedic", vidoes: orthopedicVideos, selected: true },
//   {
//     id: 2,
//     heading: "Obstetrics and Gynecology",
//     vidoes: obsteteicsVideos,
//     selected: true,
//   },
//   { id: 3, heading: "Pediatrics", vidoes: pediatricsVideos, selected: true },
//   { id: 4, heading: "Radiology", vidoes: radiologyVideos, selected: true },
//   { id: 5, heading: "Nephrology", vidoes: nephrologyVideos, selected: true },
// ];
export const leaveTypeOptions = [
  "Select Leave Type",
  "Sick Leave",
  "Casual Leave"
];
export const sessionOptions = [
  "Select Session",
  "Half Day",
  "Full Day"
];
export const dayOptions = [
  "Select Session",
  "First Half",
  "Second Half"
];