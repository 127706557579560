import React from "react";
import Header from "../Common/Header";
import Module from "./../Components/Module";

const Modules = () => {
  return (
    <div>
      {/* <Header title={"Modules"}/> */}
      <Module />
    </div>
  );
};

export default Modules;
