// actionCreators.js
import {
    BLANK_RECORD_ID,
    BLANK_RECORD_ID_FAIL
} from "../constants/action-types";
import { blankRecordCreateApi } from "../../services/blankRecordCreateApi";

export const CustomIdAction = (accessToken, empId) => async (dispatch) => {
    // console.log("response usert action callll");
    try {
        const response = await blankRecordCreateApi(accessToken, empId); // Wait for the Promise to resolve
        // console.log("response usert----------->", response?.data?.records[0]);
        if (response) {
            dispatch({
                type: BLANK_RECORD_ID,
                payload: response?.data, // Assuming response.data contains the actual data
            });
        } else {
            dispatch({
                type: BLANK_RECORD_ID_FAIL,
            });
        }
    } catch (error) {
        dispatch({
            type: BLANK_RECORD_ID_FAIL,
        });
    }
};
