import axios from "axios";
import { configs } from "../../config";

export const getApproveTPManagerApi = async (Id, accessToken) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/getTourPlansForApprovalManager?Tour_Plan_Approval__c=${Id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
