import axios from "axios";
import { configs } from "../config";

export const resetPasswordApi = async ( accessToken,newEmpId,resetPassword) => {
    const options = {
      url: `${configs?.base_url}/services/data/v58.0/sobjects/Contact/${newEmpId}`,
      method: "patch",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        Password__c: resetPassword,
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };

  // export default signOutApi;