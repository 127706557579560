import React, { useEffect, useState, forwardRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { getFeedbackDataApi } from "../../services/home/getFeedbackDataApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";

const AsmDevReport = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterEmpId, setFilterEmpId] = useState('');
  const [loading, setLoading] = useState(false);
  const [vseFeedback, setVseFeedback] = useState([]);
  const [error, setError] = useState('');

  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const VseDevelopment = await getFeedbackDataApi(accessToken, EmployeeId);
        const filteredData = VseDevelopment?.data?.filter(item => {
          const itemDate = new Date(item.feedbackDate);
          const start = startDate ? new Date(startDate) : new Date('1900-01-01');
          const end = endDate ? new Date(endDate) : new Date('2100-01-01');
          return filterEmpId ?
            (itemDate >= start && itemDate <= end && filterEmpId === item?.contactEmpId) :
            (itemDate >= start && itemDate <= end);
        });
        const sortedData = filteredData?.sort((a, b) => new Date(b?.feedbackDate) - new Date(a?.feedbackDate));
        setVseFeedback(sortedData);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [EmployeeId, accessToken, designation, filterEmpId, startDate, endDate]);

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError('Please enter only numbers.');
    } else {
      setError('');
    }
  };

  const handleExport = () => {
    if (!vseFeedback || vseFeedback?.length === 0) {
      alert('No data available to export');
      return;
    }
    const filteredData = vseFeedback.filter(item => item?.contactDesignation !== "VSE" && item?.contactDesignation !== "BE");

    // Convert data to worksheet
    const worksheet = XLSX?.utils?.json_to_sheet(filteredData?.map(item => ({
      'Feedback Date': formatDateStringDDMMYYYY(item?.feedbackDate) || 'N/A',
      'Employee Code': item?.contactEmpId || 'N/A',
      'Employee Name': item?.mrName || 'N/A',
      'Employee Designation': item?.contactDesignation || 'N/A',
      'Feedback Given by (Emp Code)': item?.feedbackEmpId || 'N/A',
      'Feedback Given by (Emp Name)': item?.feedbackName || 'N/A',
      'Feedback Given by (Designation)': item?.feedbackDesignation || 'N/A',
      'Overall Strategy Implementation': item?.overallStrategyImplementationASMDev || 'N/A',
      'Relationship with customer, of ASM': item?.relationshipWithCustomerASMDev || 'N/A',
      'Query handling by ASM': item?.queryHandlingByASMASMDev || 'N/A',
      'VSE development by ASM': item?.VSEDevelopmentByASMASMDev || 'N/A',
      'Technical Knowledge of ASM': item?.technicalKnowledgeOfASMASMDev || 'N/A',
      'Per customer output of VSE': item?.perCustomerOutputOfVSEASMDev || 'N/A'
    })));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ASM Development");

    // Export the workbook
    XLSX.writeFile(workbook, "ASM_Development_Report.xlsx");
  };

  return (
    <div className="markAttendance_section container-fluid">
      <div className="row mb-5">
        <Card className="monthlyClaim_mobile">
          <Card.Body>
            <h4 className="mb-4 text-center">ASM Development Report</h4>
            <div className="filter-section mb-4">
              <label style={{ marginRight: '1rem' }}>
                Start Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  value={startDate}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={e => setStartDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: '1rem' }}>
                End Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: '1rem' }}>
                EmpId
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  maxLength={4}
                  style={{
                    width: "8rem",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={filterEmpId}
                  onChange={e => setFilterEmpId(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </label>
              <span style={{marginLeft:'10px'}} onClick={handleExport}>
                <CommonButton title={"Download Excel"} />
              </span>
            </div>
            {error && <span className="dcr-form-errors">{error}</span>}
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div
                id="table-to-xls"
                className="table-wrapper-scroll-y custom-scrollbar"
                style={{ padding: '20px', overflow: 'auto' }}
              >
                <table
                  className="w-100"
                  style={{ textAlign: "center" }}
                  ref={ref}
                >
                  <thead>
                    <tr className="ladder_heading">
                      <th className="custom-header">Feedback Date</th>
                      <th className="custom-header">Employee Code</th>
                      <th className="custom-header">Employee Name</th>
                      <th className="custom-header">Employee Designation</th>
                      <th className="custom-header">Feedback Given by (Emp Code)</th>
                      <th className="custom-header">Feedback Given by (Emp Name)</th>
                      <th className="custom-header">Feedback Given by (Designation)</th>
                      <th className="custom-header">Overall Strategy Implementation</th>
                      <th className="custom-header">Relationship with customer, of ASM</th>
                      <th className="custom-header">Query handling by ASM</th>
                      <th className="custom-header">VSE development by ASM</th>
                      <th className="custom-header">Technical Knowledge of ASM</th>
                      <th className="custom-header">Per customer output of VSE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vseFeedback?.map((data, index) => {
                      // Check the condition before rendering
                      if (data?.contactDesignation !== "VSE" && data?.contactDesignation !== "BE") {
                        return (
                          <tr key={index} className="ladder_heading_data">
                            <td className='pl-3'>{formatDateStringDDMMYYYY(data?.feedbackDate)}</td>
                            <td className='pl-3'>{data?.contactEmpId}</td>
                            <td className='pl-3'>{data?.mrName}</td>
                            <td className='pl-3'>{data?.contactDesignation}</td>
                            <td className='pl-3'>{data?.feedbackEmpId}</td>
                            <td className='pl-3'>{data?.feedbackName}</td>
                            <td className='pl-3'>{data?.feedbackDesignation}</td>
                            <td className='pl-3'>{data?.overallStrategyImplementationASMDev}</td>
                            <td className='pl-3'>{data?.relationshipWithCustomerASMDev}</td>
                            <td className='pl-3'>{data?.queryHandlingByASMASMDev}</td>
                            <td className='pl-3'>{data?.VSEDevelopmentByASMASMDev}</td>
                            <td className='pl-3'>{data?.technicalKnowledgeOfASMASMDev}</td>
                            <td className='pl-3'>{data?.perCustomerOutputOfVSEASMDev}</td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
});

export default AsmDevReport;
