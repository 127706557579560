import axios from "axios";
import { configs } from "../../config";

export const getPromotionalInputs = async (employeeId, accessToken) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Contact__c,CreatedById,CreatedDate,Delivery_by_Date__c,Description_2__c,Description__c,Dimension_Set_ID__c,Distributed__c,Document_No__c,From_Location__c,Id,Issued_Quantity__c,Issue_Date__c,Issue_Quantity__c,Item_Category_Code__c,Item_jnl_Line_No__c,Item_No__c,Line_No__c,MR_Inventory_Id__c,Name,Product_Name__c,Quantity__c,ShortcutDimension7Code__c,Shortcut_Dimension_1_Code__c,Shortcut_Dimension_2_Code__c,Shortcut_Dimension_3_Code__c,Shortcut_Dimension_4_Code__c,Shortcut_Dimension_5_Code__c,Shortcut_Dimension_6_Code__c,Shortcut_Dimension_8_Code__c,Status__c,SystemModstamp,Total__c,To_Location_Code__c,type__c,Unit_of_Measure_Code__c,Unit_of_Measure__c FROM MR_Inventory__c where contact__c='${employeeId}'`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};