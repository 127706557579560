import React from 'react';
import FullVisitHistoryTable from '../../Components/Customers/FullVisitHistoryTable';
import { useLocation } from 'react-router-dom';
import { Card } from "react-bootstrap";
import BackButton from "../../Common/BackButton";

const FullVisitHistory = () => {
    const location = useLocation();
    const visitData = location.state?.visitData;
    return (
        <div className='container'>
            <div className="row mt-3 mb-4">
                <BackButton title={"Visit History"} />
            </div>
            <Card style={{ maxHeight: "400px", overflow: "auto" }}>
                <Card.Body className="p-2">
                    <FullVisitHistoryTable visitData={visitData} />
                </Card.Body>
            </Card>
        </div>
    );
}

export default FullVisitHistory;
