import React from "react";
import Header from "../Common/Header";
import SyncDataComp from "../Components/SyncData";

const SyncData = () => {
  return (
    <div>
      {/* <Header title={"Sync Data"} /> */}
      <SyncDataComp />
    </div>
  );
};

export default SyncData;
