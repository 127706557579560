import axios from "axios";
import { configs } from "../config";

export const signOutApi = async (blankRecordId, accessToken, empName, Logout, empId, status) => {
    const options = {
      url: `${configs?.base_url}/services/apexrest/AttendanceController`,
      method: "post",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      data: {
        EmpId: empId,
        Name: empName,
        Log_In: '',
        Log_Out: Logout,
        RecordId:blankRecordId,
        Status: status
      }
    };
    try {
      const response = await axios(options);
      return response;
    }
    catch (error) {
      console.log(error)
    }
  };

  // export default signOutApi;