import axios from "axios";
import { configs } from "../../config";

export const attendanceAccessApi = async (
  accessToken,
  EmployeeId,
  designation,
  limitSize,
  lastId
) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/AttendanceAccess`,
    method: "patch",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: EmployeeId,
      designation: designation,
      limitSize: limitSize,
      lastRecordId: lastId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const dcrAccessApi = async (
  accessToken,
  EmployeeId,
  designation,
  limitSize,
  lastId
) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/DCRSurveyAccess`,
    method: "patch",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: EmployeeId,
      designation: designation,
      limitSize: limitSize,
      lastRecordId: lastId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const tourPlanAccessApi = async (
  accessToken,
  designation,
  EmployeeId,
  limitSize,
  lastId
) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/getTourPlanDataForReportManager`,
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: EmployeeId,
      designation: designation,
      limitSize: limitSize,
      lastRecordId: lastId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const actualPlannedApi = async (
  accessToken,
  EmployeeId,
  designation,
  limitSize,
  lastId
) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/ReportForPOB`,
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      userId: EmployeeId,
      designation: designation,
      limitSize: limitSize,
      lastRecordId: lastId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const callAverageApi = async (accessToken,EmployeeId, designation) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/CallAverageReport`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            userId: EmployeeId,
            designation: designation
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const jointWorkingApi = async (
  accessToken,
  designation,
  EmployeeId,
  limitSize,
  lastId
) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/getTourPlanDataForJointWorking`,
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      empId: EmployeeId,
      designation: designation,
      limitSize: limitSize,
      lastRecordId: lastId,
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const tourPlanSummaryApi = async (accessToken,designation,EmployeeId) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/TourPlanReportSummary`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            userId: EmployeeId,
            designation: designation
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const pobTrendApi = async (accessToken,EmployeeId) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/POBSumByMonthSummary`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            userId: EmployeeId
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};

export const summaryCallAvgApi = async (accessToken,EmployeeId) => {
    const options = {
        url: `${configs?.base_url}/services/apexrest/CallAverageSummary`,
        method: "post",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            userId: EmployeeId
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};