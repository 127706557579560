import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomerImg from './../../assets/images/customers.jpg'
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import CommonButton from "../../Common/CommonButton";
import { Button } from "react-bootstrap";


const CustomersFilterModal = ({ showModal, handleClose }) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Body className="show-grid">
          <div className="text-center">
          <img alt="customer" src={CustomerImg} style={{width:'25rem'}} />
          </div>
          <Container>
            <div className="form_body">
              <div className="row mt-3">
                <div className="col-6">
                <h5>Customers</h5>
                  <FormGroup className="customerFilter">
                    <FormControlLabel control={<Checkbox />} label="HCPs" />
                    <FormControlLabel control={<Checkbox />} label="Doctors" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Retailers"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Pharmacist"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Others"
                    />
                  </FormGroup>
                  </div>
              <div className=" col-6">
                <h5>Last Visited</h5>
                
                  <FormGroup className="customerFilter">
                    <FormControlLabel control={<Checkbox />} label="7 days" />
                    <FormControlLabel control={<Checkbox />} label="14 days" />
                    <FormControlLabel control={<Checkbox />} label="1 Month" />
                    <FormControlLabel control={<Checkbox />} label="Skipped" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Speciality"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{display:'unset'}}>
          <div className="row text-center">
            <div className="col-6">
            <div onClick={handleClose} >
            <CommonButton title={'Save Changes'} width={'10rem'}/>
            </div>
            </div>
            <div className="col-6">
            <div onClick={handleClose} >
            <Button style={{fontSize: '14px', fontWeight: '600', border: '0', backgroundColor: '#4ad84a', borderRadius: '20px', background:'rgb(225, 219, 219)', color: 'black', width:'10rem'}} >Cancel</Button>
            </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomersFilterModal;
