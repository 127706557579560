import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getVisitHistoryApi } from '../../services/customers/getVisitHistoryApi';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../Common/CommonButton';
import "./VisitHistory.css"

const formatDateOfBirth = (dob) => {
    const parts = dob.split('-'); // Splitting by '-' instead of '.'
    const [year, month, day] = parts; // Destructuring the parts

    // Reordering parts in the desired format
    return `${day}/${month}/${year}`;
};


const VisitHistory = ({ doctorId }) => {
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const [visitData, setVisitData] = useState([]);
    const navigate = useNavigate();
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getVisitHistoryApi(accessToken, doctorId);
                if (response?.status === 200 && response?.data?.records) {
                    const extractedData = response.data.records.map(record => ({
                        date: record.Date__c,
                        contactName: record.Employee__r?.Name,
                        productDiscuss: record.Product_discuss__c,
                        input: record.Print_Inputs__c,
                        fieldWork: record.Remarks__c
                    }));
                    setVisitData(extractedData);
                } else {
                    console.log("Error fetching visit history:", response?.error);
                }
            } catch (error) {
                console.error("Error fetching visit history:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken, doctorId]);
    const handleShowMore = () => {
        setShowAll(true)
        navigate('/full-visit-history', { state: { visitData } });
    };

    return (
        <>
            <div>
                <table className="text-center w-100">
                    <tbody>
                        <tr className="ladder_heading">
                            <td className='custom-header pl-3'>Visit date</td>
                            <td className="custom-header">MR</td>
                            <td className="custom-header">Product Discuss</td>
                            <td className="custom-header">Input</td>
                            <td className="custom-header">Notes</td>
                        </tr>
                        {visitData.length === 0 ? (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No visit history available.
                                </td>
                            </tr>
                        ) : (
                            visitData.slice(0, showAll ? visitData.length : 5).map((visit, index) => (
                                <tr key={index} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                                    <td className='pl-3'>{formatDateOfBirth(visit.date)}</td>
                                    <td>{visit.contactName}</td>
                                    <td>{visit.productDiscuss}</td>
                                    <td>{visit.input}</td>
                                    <td>{visit.fieldWork}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                <span className="show-more-button" onClick={handleShowMore} style={{ display: 'flex', marginBottom:'0.5em',position:'absolute',left:'0',bottom:'0',marginLeft:'1rem' }}>
                    <div style={{ marginTop: '1rem' }}>
                        {!showAll && visitData.length >= 4 && (
                            <CommonButton title={"Show More"} />
                        )}
                    </div>
                </span>
                
            </div>
        </>
    );
}

export default VisitHistory
