import axios from "axios";
import { configs } from "../../config";

export const createFeedback = async (accessToken, name, rating1, rating2, rating3, rating4, rating5, rating6, rating7, rating8, rating9, rating10, rating11, rating12, question8, question7, nextDate, contact, feedbackName) => {
  const options = {
    url: `${configs?.base_url}/services/apexrest/feedbackform`,
    method: "post",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    // data: {
    //   Name: name,
    //   Visual_AID_Detailing__c: rating1,
    //   Pre_Call_Activity__c: rating2,
    //   Technical_Knowledge_of_the_VSE__c: rating3,
    //   Selling_Skills_of_the_VSE__c: rating4,
    //   Relationship_With_Customer__c: rating5,
    //   Overall_Strategy_Implementation__c: rating6,
    //   Any_other_remarks__c: question7,

    //   Overall_Strategy_Implementation_ASM_Dev__c: rating7,
    //   Relationship_with_customer_ASM_Dev__c: rating8,
    //   Query_handling_by_ASM_ASM_Dev__c: rating9,
    //   VSE_development_by_ASM_ASM_Dev__c: rating10,
    //   Technical_Knowledge_of_ASM_ASM_Dev__c: rating11,
    //   Per_customer_output_of_VSE_ASM_Dev__c: rating12,
    //   Any_other_remarks_ASM_Dev__c: question8,
    //   Contact__c: contactid,
    //   Date__c: nextDate,
    //   Contact: contact,
    //   FeedbackGivenBy: feedbackName
    // }

    data: {
      Name: name,
      Visual_AID_Detailing: rating1,
      Pre_Call_Activity: rating2,
      Technical_Knowledge_of_the_VSE: rating3,
      Selling_Skills_of_the_VSE: rating4,
      Relationship_With_Customer: rating5,
      Overall_Strategy_Implementation: rating6,
      Overall_Strategy_Implementation_ASM_Dev: rating7,
      Relationship_with_customer_ASM_Dev: rating8,
      Query_handling_by_ASM_ASM_Dev: rating9,
      VSE_development_by_ASM_ASM_Dev: rating10,
      Technical_Knowledge_of_ASM_ASM_Dev: rating11,
      Per_customer_output_of_VSE_ASM_Dev: rating12,
      Any_other_remarks_ASM_Dev: question8,
      Any_other_remarks: question7,
      feedbackDate: nextDate,
      Contact: feedbackName,
      FeedbackGivenBy: contact,
    }
  };
  try {
    const response = await axios(options);
    return response;
  }
  catch (error) {
    console.log(error)
  }
};
