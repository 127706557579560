import React from 'react';


const FullVisitFrequencyTable = ({ visitData }) => {
    if (!visitData) {
        return null;
    }

    const calculateVisitFrequency = () => {
        const visitFrequency = {};
        visitData.forEach(visit => {
            const year = visit.date.getFullYear(); // Extract year from the date
            const month = visit.date.getMonth();
            const mrName = visit.mrName;
            if (!visitFrequency[mrName]) {
                visitFrequency[mrName] = {};
            }
            if (!visitFrequency[mrName][year]) { // Store visits by both month and year
                visitFrequency[mrName][year] = {};
            }
            if (!visitFrequency[mrName][year][month]) {
                visitFrequency[mrName][year][month] = 1;
            } else {
                visitFrequency[mrName][year][month]++;
            }
        });
        return visitFrequency;
    };

    const visitFrequency = calculateVisitFrequency();

    return (
        <div className="container">
            <table className="text-center w-100">
                    <tr className="ladder_heading">
                        <td className='custom-header pl-3'>Month</td>
                        {/* <td>Year</td> */}
                        <td className="custom-header">MR</td>
                        <td className="custom-header">No Of Visits</td>
                    </tr>
                    {Object.keys(visitFrequency).map(mrName => (
                        Object.keys(visitFrequency[mrName]).map(year => (
                            Object.keys(visitFrequency[mrName][year]).map(month => (
                                <tr key={`${mrName}-${year}-${month}`} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white' }}>
                                    <td className='pl-3'>{new Date(0, parseInt(month)).toLocaleString('default', { month: 'long' })} {year}</td>
                                    {/* <td>{year}</td> */}
                                    <td>{mrName}</td>
                                    <td>{visitFrequency[mrName][year][month]}</td>
                                </tr>
                            ))
                        ))
                    ))}
                </table>
        </div>
    );
}

export default FullVisitFrequencyTable;
