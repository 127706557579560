import axios from "axios";
import { configs } from "../config";

export const actualBudget = async (empId, responseToken) => {
  const requestBody = {
    salesPersonCode: empId
  };
  const endpoint = 'https://api.businesscentral.dynamics.com/v2.0/b4183d8e-77fa-431a-8341-83d973a5ba4b/Production/ODataV4/SalesVsActualAPI_GetSalesTarget?Company=HBL-LIVE';
  // const options = {
  //   url: 'https://api.businesscentral.dynamics.com/v2.0/b4183d8e-77fa-431a-8341-83d973a5ba4b/Sandbox_V28-240523110417/ODataV4/SalesVsActualAPI_GetSalesTarget?Company=HBL-LIVE',
  //   method: "post",
  //   headers: {
  //     "Authorization": `Bearer ${responseToken}`,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*"
  //   },
  //   data:{
  //     empId:empId,
  //     // responseToken:responseToken
  //   }
  // };
  // try {
  //   const response = await axios(options);
  //   return response?.data?.access_token;
  // }
  // catch (error) {
  //   console.log(error)
  // }
  try {
    const response = await axios.post(endpoint, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${responseToken}`
      }
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return {
        success: false,
        error: `Unexpected status code: ${response.status}`,
        data: response.data
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        status: error.response.status,
        data: error.response.data
      };
    } else if (error.request) {
      return {
        success: false,
        error: "No response received from server",
        request: error.request
      };
    } else {
      return {
        success: false,
        error: error.message
      };
    }
  }
};


export const yearlyBudget = async (empId, responseToken) => {
  const requestBody = {
    salesPersonCode: empId
  };
  const endpoint = 'https://api.businesscentral.dynamics.com/v2.0/b4183d8e-77fa-431a-8341-83d973a5ba4b/Production/ODataV4/SalesVsActualAPI_GetSalesTargetYearly?Company=HBL-LIVE';
  try {
    const response = await axios.post(endpoint, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${responseToken}`
      }
    });

    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      };
    } else {
      return {
        success: false,
        error: `Unexpected status code: ${response.status}`,
        data: response.data
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        status: error.response.status,
        data: error.response.data
      };
    } else if (error.request) {
      return {
        success: false,
        error: "No response received from server",
        request: error.request
      };
    } else {
      return {
        success: false,
        error: error.message
      };
    }
  }
};